<mat-card appearance="outlined" fxLayout="row" style="height: 72px">

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex style="margin-left: 16px;">
        <ng-content select="[leftBtns]"></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-left: 40px; margin-right: 40px;">
        <button mat-mini-fab [color]="'primary'"
            (click)="copyUrl()"
            [cdkCopyToClipboard]="httpLink"
            [matTooltip]="'Copy link' | translate">
            <mat-icon>
                link
            </mat-icon>
        </button>
        <button mat-mini-fab [color]="isVideoActive() ? 'primary' : 'warn'"
            *ngIf="isToggleVideoActive() && !videoOverride; else videoOverrideBtn"
            (click)="toggleVideo()"
            [matTooltip]="'Toggle video' | translate">
            <mat-icon>
                {{ isVideoActive() ? 'videocam' : 'videocam_off' }}
            </mat-icon>
        </button>
        <button mat-mini-fab [color]="isAudioActive() ? 'primary' : 'warn'"
            *ngIf="isToggleAudioActive()"
            (click)="toggleAudio()"
            [matTooltip]="'Toggle audio' | translate">
            <mat-icon>
                {{ isAudioActive() ? 'mic' : 'mic_off' }}
            </mat-icon>
        </button>
        <button mat-mini-fab [color]="handRaise ? 'accent' : 'primary'"
            *ngIf="handRaiseActive"
            (click)="toggleHandRaise()"
            [matTooltip]="'Toggle hand' | translate">
            <mat-icon>back_hand</mat-icon>
        </button>
        <button mat-mini-fab [color]="isSharing ? 'accent' : 'primary'"
            *ngIf="shareActive"
            (click)="toggleFullScreen(false)"
            [matMenuTriggerFor]="shareSwitchMenu"
            [disabled]="!shareEnabled"
            [matTooltip]="'Present' | translate">
            <mat-icon>
                present_to_all
            </mat-icon>
        </button>
        <button mat-mini-fab color="primary"
            *ngIf="fullScreenActive"
            (click)="toggleFullScreen()"
            [matTooltip]="'Fullscreen' | translate">
            <mat-icon>
                {{ fullScreen ? 'fullscreen_exit' : 'fullscreen' }}
            </mat-icon>
        </button>
        <button mat-mini-fab color="primary"
            *ngIf="visualTypeActive"
            (click)="toggleFullScreen(false)"
            [matMenuTriggerFor]="visualTypeMenu"
            [matTooltip]="'Change video appearance' | translate">
            <mat-icon>
                settings_overscan
            </mat-icon>
        </button>
        <button mat-mini-fab
            *ngIf="recordingActive"
            (click)="onRecordingChange.emit(!isRecording)"
            [disabled]="!recordingEnabled"
            [matTooltip]="(isRecording ? 'Stop recording' : 'Start recording') | translate"
            [color]="isRecording ? 'warn' : 'primary'">
            <mat-icon>
                {{ isRecording ? 'stop' : 'fiber_manual_record' }}
            </mat-icon>
        </button>
        <button mat-mini-fab color="warn"
            (click)="toggleFullScreen(false); onLeave.emit(true)"
            [matTooltip]="'Leave' | translate">
            <mat-icon>
                logout
            </mat-icon>
        </button>
        <button mat-mini-fab color="warn"
            *ngIf="closeActive"
            (click)="toggleFullScreen(false); onClose.emit(true)"
            [matTooltip]="'Close' | translate">
            <mat-icon>
                close
            </mat-icon>
        </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex style="margin-right: 16px;">
        <ng-content select="[rightBtns]"></ng-content>
    </div>

</mat-card>

<mat-menu #shareSwitchMenu="matMenu" xPosition="before" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item
            *ngIf="hasScreenShareCapabilities"
            (click)="startShare('screen')"
            [class.mat-menu-highlight]="shareDeviceId === 'screen'">
            <mat-icon>screen_share</mat-icon>
            {{ 'Screen Sharing' | translate }}
        </button>
        <button mat-menu-item
            *ngFor="let device of videoDevices"
            (click)="startShare(device.deviceId)"
            [class.mat-menu-highlight]="shareDeviceId === device.deviceId">
            <mat-icon>videocam</mat-icon>
            {{ device.label }}
        </button>
        <button mat-menu-item
            *ngIf="isSharing"
            (click)="stopShare()">
            <mat-icon>cancel_presentation</mat-icon>
            {{ 'Cancel presentation' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #visualTypeMenu="matMenu" xPosition="before" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="setVisualType('fill')" [class.mat-menu-highlight]="visualType === 'fill'">{{ 'Fill' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('cover')" [class.mat-menu-highlight]="visualType === 'cover'">{{ 'Cover' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('contain')" [class.mat-menu-highlight]="visualType === 'contain'">{{ 'Contain' | translate }}</button>
    </ng-template>
</mat-menu>

<ng-template #videoOverrideBtn>
    <button mat-mini-fab [color]="videoOverrideActive ? 'primary' : 'warn'"
        *ngIf="isToggleVideoActive()"
        (click)="videoOverrideActive = !videoOverrideActive"
        [matTooltip]="'Toggle video' | translate">
        <mat-icon>
            {{ videoOverrideActive ? 'videocam' : 'videocam_off' }}
        </mat-icon>
    </button>
</ng-template>

export abstract class CourseState {
    
    public static offline: number = 0;
    public static online: number = 1;
 
    /**
     * @param state Stato del corso
     * @returns Restituisce lo stato del corso in stringa, utile per le traduzioni
     */
    public static getState(state: number): string {
        if(state === this.offline)
            return 'Offline';    
        else if(state === this.online)
            return 'Online';
    }
}

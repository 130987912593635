import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConnectionEvent, OpenVidu, Session, StreamEvent } from 'openvidu-browser';
import { firstValueFrom } from 'rxjs';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ConferenceService } from 'src/app/services/conference.service';
import { VideoSessionService } from 'src/app/services/video-session.service';

@Component({
  selector: 'app-room-preview',
  templateUrl: './room-preview.component.html',
  styleUrls: ['./room-preview.component.scss']
})
export class RoomPreviewComponent implements OnInit, OnDestroy {

  private _roomId: number;
  remoteParticipants: ClientStream[] = [];

  currentUser: User = undefined;

  OV: OpenVidu = undefined;
	session: Session = undefined;
	token: string = undefined;

  localParticipant: ClientStream = undefined;

  //@Input()
  //roomParticipants: UserDTO[];

  @Input()
  conferenceId: number;

  @Input()
  get roomId(): number { return this._roomId; }
  set roomId(value: number) {

    if (this.roomId && this.roomId !== value)
      this.ngOnDestroy();

    this._roomId = value;
    this.remoteParticipants = [];

    this.participants.emit(this.remoteParticipants);

    this.ngOnInit();

  }

  //private _usersAddedToRoom: boolean;

  //@Input()
  //get usersAddedToRoom(): boolean { return this._usersAddedToRoom; }
  //set usersAddedToRoom(value:boolean) {

    //this._usersAddedToRoom = value;

    //setTimeout(() => this.ngOnInit(), 7000);

  //}

  @Output()
  participants: EventEmitter<ClientStream[]> = new EventEmitter<ClientStream[]>();
 
  constructor(private conferenceService: ConferenceService,
              private videoSessionService: VideoSessionService,
              private authService: AuthService) { }
 
  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();

    //if (this.roomParticipants.length === 0)
    //  return;

    let lessonId = (await firstValueFrom(this.conferenceService.createConferenceRoom(this.conferenceId, this.roomId)) as any).Message;

    await this.videoSessionService.createSession(Number(lessonId))

    this.token = (await this.getToken())?.token;

    this.OV = new OpenVidu();

    let clientData = new ClientData(this.currentUser.id,
                                    `${this.currentUser.name} ${this.currentUser.surname}`,
                                    this.currentUser.profilePictureUrl,
                                    'publisher',
                                    'subject',
                                    'camera',
                                    undefined,
                                    true);

    this.localParticipant = new ClientStream(undefined, clientData);

    this.initOpenViduSession();

    await this.session.connect(this.token, clientData);
  }

  private async getToken() {
		try {

			return await firstValueFrom(this.conferenceService.generateTokenConference(this.conferenceId, this.roomId, true));
      
		} catch (e) {
			console.error(e);
    }
  }

  initOpenViduSession() {
    this.session = this.OV.initSession();

    this.session.on('connectionCreated', async (event: ConnectionEvent) => {
			console.warn(event?.connection?.connectionId === this.session?.connection?.connectionId ?
						       'YOUR OWN CONNECTION CREATED!' :
						       'OTHER USER\'S CONNECTION CREATED!');

			let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.hidden || clientData.type !== 'camera' || this.currentUser.id === clientData.userId)
        return;

      let index = this.remoteParticipants.findIndex(rp => rp.userId === clientData.userId);

      if (index !== -1)
        return;

      this.remoteParticipants.push(new ClientStream(undefined, clientData));

      this.participants.emit(this.remoteParticipants);
    });

    this.session.on('streamCreated', async (event: StreamEvent) => {
      console.warn('STREAM CREATED!');
      console.warn(event);

      let clientData = <ClientData>JSON.parse(event.stream.connection.data);
      let index = this.remoteParticipants.findIndex(rp => rp.userId === clientData.userId);

      if (index === -1)
        return;

      this.remoteParticipants[index].manager = this.session.subscribe(event.stream, undefined);

      this.participants.emit(this.remoteParticipants);
    });

    this.session.on('streamDestroyed', async (event: StreamEvent) => {
      console.warn('STREAM DESTROYED!');

      let index = this.remoteParticipants.findIndex(rp => rp.manager?.stream.streamId === event.stream.streamId);

      if (index === -1)
        return;

      this.remoteParticipants[index].manager = undefined;

      this.participants.emit(this.remoteParticipants);
    });

    this.session.on('connectionDestroyed', async (event: ConnectionEvent) => {
			console.warn(event.connection.connectionId === this.session.connection.connectionId ?
						       'YOUR OWN CONNECTION DESTROYED!' :
						       'OTHER USER\'S CONNECTION DESTROYED!');
	  
			let clientData = <ClientData>JSON.parse(event.connection.data);
      let index = this.remoteParticipants.findIndex(rp => rp.userId === clientData.userId);

      if (index === -1)
        return;

      this.remoteParticipants.splice(index, 1);

      this.participants.emit(this.remoteParticipants);
    });
  }

  ngOnDestroy(): void {
    this.session?.disconnect();

    this.remoteParticipants = [];

    this.participants.emit(this.remoteParticipants);

		delete this.localParticipant;
		delete this.session;
		delete this.OV;
  }

}

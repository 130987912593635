import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StationDTO } from 'src/app/models/dto/stationDTO';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-station-deactivate-popup',
  templateUrl: './station-deactivate-popup.component.html',
  styleUrls: ['./station-deactivate-popup.component.scss']
})
export class StationDeactivatePopupComponent implements OnInit {

  station: StationDTO = null;

  username: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
  password: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);

  constructor(public dialogRef: MatDialogRef<StationDeactivatePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private calendar: CalendarService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.station = this.data;
  }

  deactivate() {
    this.calendar.deactivateStation(this.station.id, {
      macAddress: this.station.macAddress,
      isHmi: true,
      username: this.username.value,
      password: this.password.value
    })
    .subscribe({
      next: () => this.dialogRef.close(true),
      error: err => this.snackBar.open(err.error.Message, 'Dismiss', {
        duration: 3000,
        verticalPosition: 'bottom'
      })
    });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

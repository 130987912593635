<div class="component-main">
    <div fxLayout="row">
        <div style="width:90%;max-width:700px;margin:0 auto">
            <mat-selection-list #shoes [multiple]="false">
                <mat-list-option (click)="goToSeller()">Seller</mat-list-option>
                <mat-list-option (click)="goToBuyer()">Buyer</mat-list-option>
            </mat-selection-list>
        </div>
        <!-- end of container-->
    </div>
</div>


export class WarningTypeDTO {
    public id: number;
    public name: string;
    public description: string;
    public frontEnd: number;
    public eMail: number;
    public socialWall: number;
    public created: Date;
}

export enum WarningType {
    DemoExpiration = 1
}

<div *ngIf="webinar" class="component-main column-arrange" [class.margin]="!isAuthenticated()">

    <ng-container *ngTemplateOutlet="isMobile ? mobileView : newLayout ? latestLayout : standardLayout"></ng-container>

</div>

<ng-template #latestLayout>

    <ng-container *ngTemplateOutlet="navigation"></ng-container>

    <div style="flex: 1">
        <ng-container *ngTemplateOutlet="webinarCard"></ng-container>
    </div>

    <mat-tab-group>
                
        <mat-tab *ngIf="isWebinarAuthor ||
                        currentUserIsPresenter ||
                        currentUser.isAdmin ||
                        (currentUser.isCustomerAdmin && currentUser.idCustomer === webinar?.lessonSession?.teacher?.customerId)"
            [label]="'Subscribers' | translate">
            <ng-container *ngTemplateOutlet="subscribersCard"></ng-container>
        </mat-tab>
        <mat-tab *ngIf="(isWebinarAuthor ||
                         currentUserIsPresenter ||
                         currentUser.isAdmin ||
                         (currentUser.isCustomerAdmin && currentUser.idCustomer === webinar?.lessonSession?.teacher?.customerId)) &&
                        webinar.lessonSession.startDate"
            [label]="'Presenters' | translate">
            <app-aggregate-data-table [lessonId]="webinar.idLesson" [userType]="'presenters'"></app-aggregate-data-table>
        </mat-tab>
        <mat-tab *ngIf="(isWebinarAuthor ||
                         currentUserIsPresenter ||
                         currentUser.isAdmin ||
                         (currentUser.isCustomerAdmin && currentUser.idCustomer === webinar?.lessonSession?.teacher?.customerId)) &&
                        webinar.lessonSession.startDate"
            [label]="'Participants' | translate">
            <app-aggregate-data-table [lessonId]="webinar.idLesson" [userType]="'participants'"></app-aggregate-data-table>
        </mat-tab>
        <mat-tab *ngIf="webinar.lessonSession.startDate" [label]="'Sessions' | translate">
            <app-joinedlesson-table [lessonId]="webinar.idLesson" [hashTagsToExport]="webinar.hashTag"></app-joinedlesson-table>
        </mat-tab>

    </mat-tab-group>

</ng-template>

<ng-template #standardLayout>

    <ng-container *ngTemplateOutlet="navigation"></ng-container>

    <div class="row-arrange" style="gap: 40px">
        
        <ng-container *ngTemplateOutlet="speakersCard, context: { compact: false }"></ng-container>
        <ng-container *ngTemplateOutlet="planningCard, context: { compact: false }"></ng-container>

        <div class="kpi-card">
            <ng-container *ngTemplateOutlet="priceCard"></ng-container>
        </div>
    
    </div>

    <ng-container *ngTemplateOutlet="toolbar"></ng-container>

    <div class="center-items">

        <div class="column-arrange" style="width: 80%">

            <ng-container *ngTemplateOutlet="webinarCard"></ng-container>
            <ng-container *ngIf="isWebinarAuthor ||
                                 currentUserIsPresenter ||
                                 currentUser.isAdmin ||
                                 (currentUser.isCustomerAdmin && currentUser.idCustomer === webinar?.lessonSession?.teacher?.customerId)"
                [ngTemplateOutlet]="subscribersCard"></ng-container>
            <app-joinedlesson-table
                *ngIf="webinar.lessonSession.stopDate"
                [lessonId]="webinar.idLesson"
                [hashTagsToExport]="webinar.hashTag">
            </app-joinedlesson-table>
        </div>

    </div>

</ng-template>

<ng-template #navigation>
    <div class="row-arrange center-items">
        <button mat-stroked-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
        <button mat-stroked-button [cdkCopyToClipboard]="href">
            <mat-icon>content_copy</mat-icon>
            {{ 'Copy link' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #toolbar>
    <div *ngIf="currentUser.id === moderator?.id ||
                isWebinarAuthor ||
                (currentUser.isCustomerAdmin && currentUser.idCustomer === webinar?.lessonSession?.teacher?.customerId)"
        class="row-arrange center-items">
        <!--button mat-fab extended color="primary"
            (click)="addSubscription()"
            [disabled]="webinar.state === 1">  
            <mat-icon>monetization_on</mat-icon>
            {{ (webinar.idSubscription ? 'Edit price' : 'Set price') | translate }}
        </button-->
        <mat-slide-toggle color="primary"
            (change)="toggleState($event)"
            [disabled]="changingState || webinar.state === 1" 
            [checked]="webinar.state === 1">
            {{ webinar.state ? 'Online' : 'Offline'  }}
        </mat-slide-toggle>
        <button mat-fab color="primary"
            (click)="editWebinar()"
            [disabled]="webinar?.lessonSession?.state === 1"
            [matTooltip]="'Edit' | translate">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-fab color="primary"
            *ngIf="isWebinarAuthor"
            (click)="createCourse()"
            [disabled]="webinar.state !== 1 ||
                        !webinar.lessonSession.stopDate ||
                        (webinar.lessonSession.recodingPlanned && !webinar.lessonSession.recordingUrl) ||
                        courseCreation"
            [matTooltip]="'Create course' | translate">
            <mat-icon>book</mat-icon>
        </button>
        <button mat-fab color="warn"
            (click)="deleteWebinar(webinar)"
            [disabled]="webinar?.lessonSession?.state === 1 && !webinar.lessonSession.stopDate"
            [matTooltip]="'Delete' | translate">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #subscribersCard>
    <mat-card appearance="outlined">

        <mat-card-header class="row-arrange center-items" style="justify-content: space-between">
            <mat-card-title>
                {{ 'Subscribers' | translate }} ({{ subscribersSource.data.length }})
            </mat-card-title>
            <div class="row-arrange center-items" style="justify-content: space-between">
                <button mat-fab color="primary"
                    (click)="addPaidUsers()"
                    *ngIf="(currentUser.isCustomerAdmin && currentUser.idCustomer === webinar?.lessonSession?.teacher?.customerId) && !webinar?.subscription?.isFree"
                    [matTooltip]="'Add paid users' | translate">
                <mat-icon>people</mat-icon>
                </button>
                <button mat-fab color="primary"
                    (click)="saveToCsvSubscribers()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <mat-divider style="margin-top: 7px"></mat-divider>

        <div class="table-container">
            <table mat-table matSort #subscribersSort="matSort" [dataSource]="subscribersSource" style="width: 100%">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="surname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getRole(element) | translate }}</td>
                </ng-container>
                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'City' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element?.idprovinceNavigation?.name }}</td>
                </ng-container>
                <ng-container matColumnDef="qualification">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Qualification' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element?.idqualificationNavigation?.name }}</td>
                </ng-container>
                <ng-container matColumnDef="telephone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Telephone' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.telephone }}</td>
                </ng-container>
                <ng-container matColumnDef="purchased data">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Purchased data' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.stateLastChangedDate | date: 'HH:mm - dd/MM/yyyy' }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="subscribersColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: subscribersColumns"></tr>
            </table>
        </div>
        
    </mat-card>
</ng-template>

<ng-template #webinarCard>
    <mat-card appearance="outlined" style="height: 100%">
        <img mat-card-image [src]="webinar.imageUrl" [alt]="webinar.name" loading="lazy">
        <mat-card-header class="row-arrange" style="gap: 0px; justify-content: space-between;">
            <mat-card-title>
                {{ webinar.name }}
            </mat-card-title>
            <!--mat-card-subtitle>
                {{ webinar.created | date: 'dd/MM/yyyy' }}
            </mat-card-subtitle-->

            <ng-container
                *ngIf="newLayout"
                [ngTemplateOutlet]="planningCard"
                [ngTemplateOutletContext]="{ compact: true }">
            </ng-container>

        </mat-card-header>
        <mat-card-content class="column-arrange" style="gap: 10px; overflow: hidden;">
            <span *ngIf="webinar.header" style="font-size: 14px; font-weight: bold;">
                {{ webinar.header }}
            </span>
            <div [innerHtml]="webinar.description"></div>
            <div [innerHTML]="webinar.showcaseSummary"></div>

            <div *ngIf="newLayout" class="row-arrange" style="justify-content: space-between">

                <div class="row-arrange kpi-light-card-theme" style="justify-content: flex-start; gap: 40px;">
                    <ng-container *ngTemplateOutlet="speakersCard, context: { compact: true }"></ng-container>
                    <ng-container *ngTemplateOutlet="priceCard"></ng-container>
                </div>

                <div class="row-arrange" style="justify-content: flex-end; align-items: flex-end;">
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                </div>

            </div>

        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #speakersCard let-compact="compact">
    <mat-card appearance="outlined" [class]="compact ? 'kpi-card-compact' : 'kpi-card'">
        <mat-card-header style="margin-bottom: 16px">
            <mat-card-title>
                {{ 'Speakers' | translate }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="column-arrange">

            <div class="row-arrange center-items" style="justify-content: flex-start; gap: 40px;">
                <app-profile-picture
                    *ngIf="!compact"
                    [url]="presenters[0]?.profilePictureUrl"
                    size="70px">
                </app-profile-picture>
                <div [class]="compact ? 'row-arrange' : 'column-arrange'" style="gap: 10px">
                    <span *ngFor="let presenter of presenters">
                        {{ presenter.name }} {{ presenter.surname }}
                    </span>
                </div>
            </div>

            <div *ngIf="!compact" class="align-end-items">
                <span>{{ 'Moderated by' | translate }} {{ moderator?.name }} {{ moderator?.surname }}</span>
            </div>

        </mat-card-content>                
    </mat-card>

    <mat-card *ngIf="compact" appearance="outlined" [class]="compact ? 'kpi-card-compact' : 'kpi-card'">
        <mat-card-header style="margin-bottom: 16px">
            <mat-card-title>{{ 'Moderated by' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="center-items" style="justify-content: flex-start">
            <span>{{ moderator?.name }} {{ moderator?.surname }}</span>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #planningCard let-compact="compact">
    <mat-card *ngIf="!compact" [style]="border(webinar)" appearance="outlined" class="kpi-card">
        <mat-card-header style="margin-bottom: 16px">
            <mat-card-title>
                {{ 'Planning' | translate }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="column-arrange">

            <div class="row-arrange center-items" style="justify-content: flex-start; gap: 40px;">
                <div class="center-items">
                    <button mat-fab class="mat-elevation-z0 kpi-indicator" style="background-color: #26c6da">
                        <mat-icon style="color: white">
                            timer
                        </mat-icon>
                    </button> 
                </div>
                <div class="column-arrange center-items" style="align-items: start">
                    <span class="kpi-text" style="font-size: 24px">
                        {{ isToday(webinar?.lessonSession?.startPlanned) ? ('Today' | translate) : (webinar?.lessonSession?.startPlanned | date: 'dd/MM/yyyy') }}
                    </span>

                    <ng-container *ngIf="!webinar?.lessonSession?.stopDate">
                        <span *ngIf="webinar?.lessonSession?.startDate; else statusWebinar" class="kpi-text">
                            {{ 'Running' | translate }} {{ ' from' | translate }} {{ getTimeDiff(webinar) }} {{ 'minutes' | translate }} 
                            {{ 'from' | translate }} {{ webinar?.lessonSession?.startDate | date: 'HH:mm' }}
                        </span>
                    </ng-container>

                </div>
            </div>

            <div class="row-arrange align-end-items">
                <span *ngIf="!webinar?.lessonSession?.stopDate; else finishedWebinar" class="kpi-text">
                    {{ 'from' | translate }}
                    {{ webinar?.lessonSession?.startPlanned | date: 'HH:mm' }}
                    {{ 'to' | translate }}
                    {{ webinar?.lessonSession?.endPlanned | date: 'HH:mm' }}
                </span>
            </div>

        </mat-card-content>    
    
    </mat-card>

    <div *ngIf="compact" class="column-arrange" style="align-items: flex-end;">

        <span *ngIf="!webinar?.lessonSession?.stopDate; else finishedWebinar" class="kpi-text">
            {{ webinar.lessonSession.startPlanned | date: 'dd/MM/yyyy' }}
            {{ 'from' | translate }}
            {{ webinar.lessonSession.startPlanned | date: 'HH:mm' }}
            {{ 'to' | translate }}
            {{ webinar.lessonSession.endPlanned | date: 'HH:mm' }}
        </span>

        <ng-container *ngIf="!webinar?.lessonSession?.stopDate">
            <span *ngIf="webinar?.lessonSession?.startDate; else statusWebinar" class="kpi-text">
                {{ 'Running' | translate }} {{ ' from' | translate }} {{ getTimeDiff(webinar) }} {{ 'minutes' | translate }} 
                {{ 'from' | translate }} {{ webinar?.lessonSession?.startDate | date: 'HH:mm' }}
            </span>
        </ng-container>

    </div>

    <ng-template #statusWebinar>
        <span *ngIf="notDone(webinar)" class="kpi-text">
            {{ 'Webinar not done' | translate }}
        </span>
        <span *ngIf="notDoneYet(webinar)" class="kpi-text">
            {{ 'Webinar not done yet' | translate }}
        </span>
    </ng-template>

    <ng-template #finishedWebinar>
        <span class="kpi-text">
            {{ 'Webinar took place' | translate}} {{ 'from' | translate }} {{ webinar?.lessonSession?.startDate | date: 'HH:mm' }}
            {{ 'to' | translate }} {{ webinar?.lessonSession?.stopDate | date: 'HH:mm' }}
        </span>
    </ng-template>
</ng-template>

<ng-template #priceCard>
    <app-price-card
        [routeEnabled]="webinar.state === 1 &&
                        (
                            currentUserIsParticipant ||
                            currentUserIsPresenter ||
                            isWebinarAuthor ||
                            (currentUser?.isCustomerAdmin && currentUser.idCustomer === webinar.lessonSession?.classroom?.customerId) ||
                            currentUser.isAdmin
                        ) &&
                        !webinar.lessonSession.stopDate &&
                        !notDone(webinar)"
        [routeUrl]="'/webinar'"
        [routeId]="webinar.id"
        [queryParams]="{ page: 'showcase' }"
        [routeMessage]="'Join webinar' | translate"
        [subCrudShow]="currentUser.id === moderator?.id || isWebinarAuthor"
        [subCrudDisabled]="webinar.state === 1"
        [subtitle]="getSubTitle()"
        [subscription]="webinar.subscription"
        [buyShow]="webinar.state === 1 && (currentUser?.isStudent || currentUser?.isSpeaker || currentUser?.isTeacher || currentUser?.isTutor) && !currentUserIsParticipant && !currentUserIsPresenter && !isWebinarAuthor"
        [buyDisabled]="currentstate != 1 || !canpurchased || !isstartget || webinar.lessonSession.stopDate"
        [logInToBuy]="true"
        [compact]="newLayout"
        (buyFree)="payWebinarFree()"
        (buyPay)="addToCartWebinar()"
        (subAdd)="addSubscription()"
        (subEdit)="addSubscription()">
    </app-price-card>
</ng-template>

<ng-template #mobileView>
    <p *ngIf="isDev()">DEV Version</p>
    <div class="column-arrange center-items" style="background-size: 100%" [style.height]="!showDeepLinkButton ? '90vh' : ''">
        <app-showcase-card
            [routeUrl]="'/webinar-showcase'"
            [routeId]="webinar.id"
            [queryParams]="{ page: 'showcase' }"
            [style]="border(webinar)"
            [subtitle]="webinar?.lessonSession?.startPlanned | date: 'dd/MM/yyyy - HH:mm'"
            [imgUrl]="webinar.imageUrl"
            [title]="webinar.name"
            [description]="webinar.header"
            [isMobile]="true"
            [subscription]="webinar?.subscription"
            [buyShow]="webinar.state === 1 && (currentUser?.isStudent || currentUser?.isSpeaker || currentUser?.isTeacher || currentUser?.isTutor)"
            [buyDisabled]="currentstate != 1 || !canpurchased || !isstartget || currentUserIsParticipant || currentUserIsPresenter ||  webinar.lessonSession.stopDate !== null || isWebinarAuthor"
            (buyFree)="payWebinarFree()"
            (buyPay)="addToCartWebinar()">

            <app-showcase-card-author-info bodyBottom
                [author]="moderator"
                [users]="presenters"
                mode="webinar"
                padding="0 16px">
            </app-showcase-card-author-info>

        </app-showcase-card>
        <ng-container *ngIf="showDeepLinkButton && (isDT() || isAixp() || isDev())">
            <button style="margin-top:25px;height:90px;width:180px;"
                color="primary"
                (click)="createDeepLink(webinar.id)"
                mat-raised-button>
               {{ "Open in app" | translate }}
            </button>
        </ng-container>
    </div>
</ng-template>

import { UserDTO } from './userDTO';

export class SurveyDTO {
  public id: number;
  public idAuthor: number;
  public name: string;
  public idStringName: number;
  public description: string;
  public idStringDescription: number;
  public surveyJSON: string;
  public isTemplate: number;
  public state: number;
  public created: Date;
  public user: UserDTO;
  
  constructor() {
    this.name = '';
    this.description = '';
    this.surveyJSON = ''; 
    this.isTemplate = 0;
  }
}

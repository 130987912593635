<div [style.padding]="padding" class="main-container">

    <a *ngIf="mode === 'author'"
        (click)="goToProfile()"
        class="truncate-text"
        style="cursor: pointer">
        {{ author?.name }} {{ author?.surname }}
    </a>

    <span *ngIf="mode === 'webinar'" [matTooltip]="getUsers()" matTooltipClass="multiline-tooltip" class="truncate-text">
        {{ 'Speaker' | translate }} {{ users[0]?.name }} {{ users[0]?.surname }} {{ users?.length > 1 ? ('and others' | translate) : '' }}
    </span>

    <span *ngIf="mode === 'webinar'" class="truncate-text" style="font-size: 12px; margin-top: 5px;">
        {{ 'Moderated by' | translate }} {{ author?.name }} {{ author?.surname }}
    </span>

</div>

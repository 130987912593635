<mat-card id="room-card" class="mat-elevation-z8" cdkDrag>
    <mat-card-header>
        <mat-card-title class="card-title">
            <span>{{ roomData.name }}</span>
            <button mat-icon-button
                (click)="onClose.emit(true)"
                [matTooltip]="'Close' | translate">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-title>
        <mat-card-subtitle>{{ roomData.description }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="room-image-preview">
            <app-room-preview
                [conferenceId]="conferenceId"
                [roomId]="roomData.id"
                (participants)="participants = $event">
            </app-room-preview>
        </div>
        <div class="accordion-container">
            <mat-accordion class="my-headers-align" [multi]="false"> 
                <mat-expansion-panel (closed)="selectedParticipants = []"> <!--(opened)="getUsers()"-->
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p>{{ 'Participants' | translate }} ({{ participants?.length ?? 0 }})</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="participants.length > 0; else noUsers">
                        <div *ngFor="let participant of participants" class="participants-container">
                            <app-profile-picture [url]="participant.picture" size="40px"></app-profile-picture>
                            <p style="margin-left: 10px;width: 200px;">{{ participant.name }}</p>
                            <mat-checkbox style="padding: 2px" [checked]="isSelected(participant.userId)" (change)="toggleSelection(participant.userId)"></mat-checkbox>
                        </div>
                    </ng-container>
                    <ng-template #noUsers>
                        <p style="width: 400px">{{ 'There are no users in the room' | translate }}</p>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-card-content>
    <mat-card-footer>
        <div class="buttons-container">
            <button mat-mini-fab color="primary" style="margin-top: 5px"
                (click)="addUsers()"
                [disabled]="selectedUsers.length === 0"
                [matTooltip]="'Add participants' | translate">
                <mat-icon>add</mat-icon>
            </button>
            <button mat-mini-fab color="primary" style="margin-top: 5px"
                (click)="removeUsers()"
                [disabled]="selectedParticipants.length === 0"
                [matTooltip]="'Remove participants' | translate">
                <mat-icon>remove</mat-icon>
            </button>
            <button mat-mini-fab color="warn" style="margin-top: 5px"
                (click)="removeAllUsers()"
                [disabled]="participants.length === 0"
                [matTooltip]="'Close virtual room' | translate">
                <mat-icon>power_settings_new</mat-icon>
            </button>
        </div>
    </mat-card-footer>
</mat-card>

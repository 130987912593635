export class AuthorProfileEdit {
    public IdUser: number;
    public NameExtendend: string;
    public BackgroundImageUrl: string;
    public AuthorPhotoUrl: string;
    public AuthorResume: string;
    public AuthorCV: string;
    public Language: string;
}

export class AuthorContact {
    public IdAuthor: number;
    public SenderName: string;
    public SenderSurname: string;
    public SenderEmail: string;
    public Subject: string;
    public Message: string;
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { TranslateService } from '@ngx-translate/core';

import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { CertificationPopUpComponent } from './certification-pop-up/certification-pop-up.component';

import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CertificationsDTO } from 'src/app/models/dto/CertificationsDTO';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent implements OnInit, OnDestroy {
  certifications: CertificationsDTO[] = [];
  backupCertifications: CertificationsDTO[] = [];
  classrooms: ClassroomDTO[] = [];

  value: string = '';
  mode: 'all' | 'incoming' | 'expired' | 'performed' = 'all';

  pageIndex: number = 0;
  pageSize: number = 10;
  totalCertifications: number = 0;
  loading: boolean = false;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private classroomService: ClassroomService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    public darkService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.fetchClassrooms();
    this.getCertifications();
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  fetchClassrooms(): void {
    this.classroomService.getClassrooms().subscribe({
      next: (res) => {
        this.classrooms = res.map((classroom: any) => ({
          ...classroom,
          customerId: classroom.customerId || null,
          type: classroom.type || '',
          eventId: classroom.eventId || null,
          teacherClassroom: classroom.teacherClassroom || false,
        }));
      },
      error: (err) => {
        console.error('Error fetching classrooms:', err);
      }
    });
  }

  getCertifications(): void {
    this.loading = true;
    this.exerciseService.getCertifications().subscribe({
      next: (data) => {
        const sorted = data.sort(
          (a, b) => new Date(b.plannedStartDate).getTime() - new Date(a.plannedStartDate).getTime()
        );
        this.backupCertifications = sorted;
        this.totalCertifications = sorted.length;
        this.resetCertifications();
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching certifications:', err);
        this.snackBar.open('Failed to fetch certifications.', 'Close', { duration: 3000 });
        this.loading = false;
      }
    });
  }

  resetCertifications(resetSearch = true): void {
    if (resetSearch) this.value = '';
    this.pageIndex = 0;
    this.changePage();
  }

  changePage(event?: PageEvent): void {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }

    let data = this.backupCertifications.slice();

    if (!Helper.isNullOrEmpty(this.value)) {
      const term = this.value.toLowerCase();
      data = data.filter(c => c.name?.toLowerCase().includes(term));
    }

    const start = this.pageIndex * this.pageSize;
    const end = start + this.pageSize;
    this.certifications = data.slice(start, end);
    this.totalCertifications = data.length;

    this.mapClassroomNames();
  }

  private mapClassroomNames(): void {
    this.certifications.forEach(cert => {
      const cls = this.classrooms.find(c => c.id === cert.idClassroom);
      cert.classroomName = cls?.name || 'Unknown classroom';
    });
  }

  openAddCertificationDialog(): void {
    const dialogRef = this.dialog.open(CertificationPopUpComponent, {
      width: '600px',
      data: { mode: 'add' }
    });

    dialogRef.afterClosed().subscribe(newCert => {
      if (newCert) {
        this.refreshCertifications();
      }
    });
  }

  refreshCertifications(): void {
    this.getCertifications();
  }

  openEditCertificationsDialog(cert: CertificationsDTO): void {
    const dialogRef = this.dialog.open(CertificationPopUpComponent, {
      width: '600px',
      data: { mode: 'edit', certification: { ...cert } }
    });

    dialogRef.afterClosed().subscribe(updatedCert => {
      if (updatedCert) {
        this.exerciseService.editCertification(updatedCert).subscribe({
          next: (resp) => {
            // find & replace in backup array
            const i = this.backupCertifications.findIndex(x => x.id === resp.id);
            if (i !== -1) {
              this.backupCertifications[i] = resp;
              this.changePage();
            }
            this.snackBar.open('Certification updated successfully.', 'Close', { duration: 3000 });
          },
          error: (err) => {
            console.error('Error updating certification:', err);
            this.snackBar.open('Failed to update certification.', 'Close', { duration: 3000 });
          }
        });
      }
    });
  }

  isEditable(cert: CertificationsDTO): boolean {
    return new Date(cert.plannedStartDate) > new Date();
  }

  async deleteCertification(cert: CertificationsDTO): Promise<void> {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete certification')),
        body: await firstValueFrom(
          this.translate.get('Are you sure you want to delete the certification?', { name: cert.name })
        )
      }
    });

    dialogRef.afterClosed().subscribe(userConfirmed => {
      if (!userConfirmed) return;

      this.exerciseService.deleteCertification(cert.id).subscribe({
        next: () => {
          this.backupCertifications = this.backupCertifications.filter(c => c.id !== cert.id);
          this.totalCertifications = this.backupCertifications.length;

          if (this.pageIndex > 0 && this.pageIndex * this.pageSize >= this.backupCertifications.length) {
            this.pageIndex--;
          }
          this.changePage();

          this.snackBar.open('Certification deleted successfully.', 'Close', { duration: 3000 });
        },
        error: (err) => {
          console.error('Error deleting certification:', err);
          this.snackBar.open('Failed to delete certification.', 'Close', { duration: 3000 });
        }
      });
    });
  }

  // run logic
  runCertification(certId: number, userId: number): void {
    console.log('Run Certification #', certId, ' for userId:', userId);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExercisePopUpComponent } from './exercise-pop-up/exercise-pop-up.component';
import { ExerciseDTO } from 'src/app/models/dto/exerciseDTO';
import { ExerciseService } from 'src/app/services/exercise.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { firstValueFrom } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ClassroomService } from 'src/app/services/classroom.service';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { AzureStorageService } from 'src/app/services/storage.service';
import { ExerciseSessionDTO } from 'src/app/models/dto/exerciseSessionDTO';
import { ExerciseResultsPopupComponent } from './exercise-results-popup/exercise-results-popup.component';
import { ExerciseSkillResultDTO } from 'src/app/models/dto/exerciseSkillResultDTO';
import { Helper } from 'src/app/helpers/helper';
import { isAfter, isBefore } from 'date-fns';
import { ExerciseProgressDTO } from 'src/app/models/dto/exerciseProgressDTO';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss']
})
export class ExercisesComponent implements OnInit, OnDestroy {
  exercises: ExerciseDTO[] = [];

  classrooms: ClassroomDTO[] = [];

  value: string = '';

  mode: 'all' | 'incoming' | 'expired' | 'performed' = 'all';

  pageIndex: number = 0;
  pageSize: number = 20;
  pageStart: number = 0;
  pageEnd: number = this.pageSize;
  totalExercises: number = 0;
  backupExercises: ExerciseDTO[] = [];
  fetchClassRoom: any;
  exercisesBackup: ExerciseDTO[];

  exerciseSkillResults: ExerciseSkillResultDTO[] = [];

  progressMap: { [exerciseId: number]: ExerciseProgressDTO } = {};

  loading: boolean = false;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private classroomService: ClassroomService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService,
    private azureService: AzureStorageService,
    public darkService: DarkThemeService
  ) {}

  ngOnInit(): void {
    this.getExercises();
    this.fetchClassRooms();
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  updatePagination(): void {
    this.pageStart = this.pageIndex * this.pageSize;
    this.pageEnd = this.pageStart + this.pageSize;
  }  

  getExercises(): void {
    this.loading = true;
    this.exerciseService.getExercises().subscribe({
      next: (data: ExerciseDTO[]) => {
        let filtered = data.sort(
          (a, b) => new Date(b.startPlanned).getTime() - new Date(a.startPlanned).getTime()
        );

        if (this.mode === 'expired') {
          filtered = filtered.filter(ex => this.isExpired(ex));
        } else if (this.mode === 'performed') {
          filtered = filtered.filter(ex => ex.results && ex.results.some(result => result.state >= 4)); // for this to work, include results ni the backend response so that each ExerciseDTO returned by getExercises() already includes the results.
        } else if (this.mode === 'incoming') {
          filtered = filtered.filter(ex => this.isIncoming(ex));
        }

        this.exercisesBackup = filtered;
        this.totalExercises = this.exercisesBackup.length;
        this.resetExercises();
        this.exercisesBackup.forEach(ex => this.getProgress(ex.id));
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching exercises:', err);
        this.snackBar.open('Failed to fetch exercises. Please try again.', 'Close', {
          duration: 3000
        });
        this.loading = false;
      }
    });
  }

  private isExpired(ex: ExerciseDTO): boolean {
    return isBefore(new Date(ex.endPlanned), new Date());
  }

  private isIncoming(ex: ExerciseDTO): boolean {
    return isAfter(new Date(ex.startPlanned), new Date());
  }

  toggleResults(exercise: ExerciseDTO): void {
    if (!exercise.results) {
      exercise.loading = true;
      this.exerciseService.getExerciseResults(exercise.id).subscribe({
        next: (results: ExerciseSessionDTO[]) => {
          if (this.auth.isStudent()) {
            const currentUserId = this.auth.getCurrentUser().id;
            results = results.filter(r => r.userId === currentUserId);
          }
          results.sort((a, b) => new Date(b.stopDate).getTime() - new Date(a.stopDate).getTime());
          exercise.results = results;
          exercise.loading = false;
        },
        error: (err) => {
          exercise.loading = false;
          console.log('No results');
        }
      });
    }
  }

  fetchClassRooms(): void {
    this.classroomService.getClassrooms().subscribe({
      next: (output) => {
        this.classrooms = output as ClassroomDTO[];
        if (this.classrooms?.length > 0) {
          this.mapClassroomsToExercises();
        } else {
          console.log('No classrooms fetched to map');
        }
      },
      error: (err) => {
        console.error('Error fetching classrooms:', err);
      }
    });
  }

  mapClassroomsToExercises(): void {
    this.exercises.forEach(exercise => {
      if (exercise.classroomIds) {
        exercise.classrooms = exercise.classroomIds
          .map(classroomId => {
            const classroom = this.classrooms.find(c => c.id === classroomId);
            return classroom?.name;
          })
          .filter(name => !!name) as string[];
      } else {
        exercise.classrooms = [];
      }
    });
  }

  resetExercises(resetSearch: boolean = true): void {
    if (resetSearch) {
      this.value = '';
    }
    this.pageIndex = 0;
    this.changePage();
  }

  goToClassrooms(classroomIds: number[]): void {
    if (!classroomIds || classroomIds.length === 0) {
      console.error('No classrooms available to navigate.');
      return;
    }
  
    this.router.navigate(['/classrooms']);
  }  

  getProgress(exerciseId: number): void {
    this.exerciseService.getProgress(exerciseId).subscribe({
      next: (data: ExerciseProgressDTO) => {
        this.progressMap[exerciseId] = data;
      },
      error: (err) => { console.error('Error fetching progress for', exerciseId, err); }
    });
  }

  updatePaginatedExercises(): void {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.backupExercises.length);
    this.exercises = this.backupExercises.slice(startIndex, endIndex);
  }

  onPageChange(event: any): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePagination();
  }
  
  changePage(event?: PageEvent): void {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }

    let data = this.exercisesBackup.slice();

    if (!Helper.isNullOrEmpty(this.value)) {
      const val = this.value.toLowerCase();
      data = data.filter(ex =>
        ex.name?.toLowerCase().includes(val) ||
        ex.description?.toLowerCase().includes(val)
      );
    }

    const minIndex = this.pageIndex * this.pageSize;
    const maxIndex = minIndex + this.pageSize;

    this.exercises = data.slice(minIndex, maxIndex);
    this.totalExercises = data.length;

    this.mapClassroomsToExercises();
  }

  openAddExerciseDialog(): void {
    const dialogRef = this.dialog.open(ExercisePopUpComponent, {
      width: '600px',
      data: { mode: 'add', page: 'exercise' },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((newExercise: ExerciseDTO) => {
      if (newExercise) {
        this.refreshExercises();
      }
    });
  }

  openEditExerciseDialog(exercise: ExerciseDTO): void {
    const dialogRef = this.dialog.open(ExercisePopUpComponent, {
      width: '600px',
      data: { mode: 'edit', exercise: { ...exercise } },
      autoFocus: false,
      restoreFocus: false
    });

    console.log('Exercise passed to edit dialog:', exercise);

    dialogRef.afterClosed().subscribe((updatedExercise: ExerciseDTO) => {
      if (updatedExercise) {
        this.exerciseService.editExercise(updatedExercise).subscribe({
          next: (response) => {
            const index = this.backupExercises.findIndex((e) => e.id === updatedExercise.id);
            if (index !== -1) {
              this.backupExercises[index] = response;
              this.updatePaginatedExercises();
            }
            this.refreshExercises();
            this.snackBar.open('Exercise updated successfully.', 'Close', { duration: 3000 });
          },
          error: (err) => {
            console.error('Error updating exercise:', err);
            this.snackBar.open('Failed to update exercise. Please try again.', 'Close', { duration: 3000 });
          }
        });
      }
    });
  }

  isEditable(exercise: ExerciseDTO): boolean {
    return new Date(exercise.startPlanned) > new Date();
  }

  async deleteExercise(exercise: ExerciseDTO): Promise<void> {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete exercise')),
        body: await firstValueFrom(
          this.translate.get('Are you sure you want to delete the exercise?', { name: exercise.name })
        )
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) return;

      this.exerciseService.deleteExercise(exercise.id).subscribe({
        next: () => {
          this.exercisesBackup = this.exercisesBackup.filter((e) => e.id !== exercise.id);
          this.totalExercises = this.exercisesBackup.length;

          const maxIndex = this.pageIndex * this.pageSize;
          if (this.pageIndex > 0 && maxIndex >= this.exercisesBackup.length) {
            this.pageIndex--;
          }
          this.changePage();

          this.snackBar.open('Exercise deleted successfully.', 'Close', { duration: 3000 });
        },
        error: (err) => {
          console.error('Error deleting exercise:', err);
          this.snackBar.open('Failed to delete exercise. Please try again.', 'Close', { duration: 3000 });
        }
      });
    });
  }

  resetSearch(): void {
    this.value = '';
    this.pageIndex = 0;
    this.pageSize = 10;
    this.changePage();
  }

  getFilteredExercises(searchValue: string): ExerciseDTO[] {
    if (!searchValue) return this.exercisesBackup;

    return this.exercisesBackup.filter(exercise => 
        exercise.name?.toLowerCase().includes(searchValue.toLowerCase()) || 
        exercise.description?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  refreshExercises(): void {
    this.getExercises();
  }

  openViewResultsDialog(exerciseId: number): void {
    this.exerciseService.getExerciseResults(exerciseId).subscribe(
      (results) => {
        this.dialog.open(ExerciseResultsPopupComponent, {
          data: results,
        });
      },
      (error) => {
        console.error('Error fetching exercise results:', error);
        alert('Failed to fetch exercise results. Check console for details.');
      }
    );
  }

  openPath(path: string): void {
    if (path) {
        window.open(path, '_blank');
    } else {
        console.error('Path is invalid or empty.');
    }
  }

  goToExercise(exerciseId: number, userId: number): void {
    if (!exerciseId || !userId) {
      console.error('Missing Exercise ID or User ID:', { exerciseId, userId });
      return;
    }
  
    this.router.navigate(['/exercise-details'], {
      queryParams: { exerciseId, userId }
    });
  }
  

  toggleDescription(exercise: ExerciseResultsPopupComponent): void {
    exercise.showFullDescription = !exercise.showFullDescription;
  }

  refreshPage(): void {
    this.router.navigate([this.router.url]);
  }  

}

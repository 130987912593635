<div fxLayout="column" [fxLayoutAlign]="isSignUpMode() && !msg ? 'top center' : 'center center'"  fxLayoutGap="20px" class="sfondo" [style.margin-bottom]="isSignUpMode() && !msg ? '15%' : ''" [style]="background">
    <mat-card appearance="outlined" class="mat-elevation-z8" style="width: 70%;">

        <mat-card-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
           <span style="margin:10px" *ngIf="isDev()"> {{'DEV Version'}}</span>

            <div *ngIf="isSignUpMode() && !msg; else cardHeader" style="width: 100%">

                <app-register-form
                    [codeCustomer]="codeCustomer"
                    [showUsername]="showUsername"
                    [showDlgs]="showDlgs"
                    [showPhone]="showPhone"
                    (result)="onUserRegistered()">
                </app-register-form>
                
            </div>

            <div *ngIf="isLoginMode() && requestPathHasValue()" fxLayout="column" fxLayoutGap="15px" style="width: 100%">
    
                <app-access-form
                    [idCustomer]="idCustomer"
                    [isMobile]="true">
                </app-access-form>
    
                <div fxLayoutAlign="center center">
                    <span>Oppure</span>
                </div>
    
            </div>
    
            <button style="width: 85%; white-space: pre-wrap; height: 60px; line-height: normal;"
                *ngIf="!isSignUpMode()"
                (click)="mode = 'signup'"
                mat-raised-button
                color="accent">
                {{ registerBtnText | translate }}
            </button>

        </mat-card-content>
        
    </mat-card>

    <div *ngIf="!isSignUpMode() &&
                showMobileApps &&
                !isFromApp &&
                playStoreUrl &&
                appStoreUrl"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayoutGap="10px">

        <div class="store-text">
            {{ 'You can enjoy all asynchronous content by downloading our mobile app' | translate }}
        </div>

        <div fxLayout="row">
            <a [href]="playStoreUrl" *ngIf="isAndroid() || isUnknown()">
                <img src="../../../assets/img/Google_Play_Badge.png" alt='Get it on Google Play' loading="lazy" class="store-btn">
            </a>
    
            <a [href]="appStoreUrl" *ngIf="isIos() || isUnknown()">
                <div style="padding: 10px" class="store-btn">
                    <img src="../../../assets/img/App_Store_Badge.svg" alt='Download on the App Store' loading="lazy">
                </div>
            </a>
        </div>

    </div>

</div>

<ng-template #cardHeader>
    <div fxLayout="column" fxLayoutAlign="center center">
        <img [src]="logo" style="width:55%" loading="lazy">
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="msg">
        <div style="text-align: center; white-space: pre-line; font-size: 3.5vmin;">
            {{ msg | translate }} 
        </div>
    </div>
</ng-template>

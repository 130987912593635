<div class="component-main" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

    <h2>{{ 'Exercises' | translate }}</h2>

    <!-- Action and Search Section -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="action-area" fxFlexFill>
        <!-- Search Box -->
        <div fxLayout="row" fxFlex="20" class="search-container">
            <mat-form-field fxFlex>
                <mat-label>{{ 'Search' | translate }}</mat-label>
                <input
                  matInput
                  [(ngModel)]="value"
                  (ngModelChange)="resetExercises(false)"
                  placeholder="{{ 'Type to search...' | translate }}"
                />
                <button
                  matSuffix
                  mat-icon-button
                  matTooltip="{{ 'Clear search' | translate }}"
                  (click)="resetExercises(true)">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <!-- Refresh and Add Exercise Buttons -->
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="60" fxLayoutGap="15px" style="margin-bottom: 14.6px">
            <button mat-fab color="primary" matTooltip="{{ 'Refresh' | translate }}" (click)="refreshExercises()">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-fab extended color="accent" matTooltip="{{ 'Add New Exercise' | translate }}" 
                    (click)="openAddExerciseDialog()" *ngIf="auth.isTeacher()">
                <mat-icon>add</mat-icon>
                {{ 'Add Exercise' | translate }}
            </button>
        </div>

        <!-- Mode Selection -->
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
            <mat-form-field color="primary">
                <mat-label>{{ 'Mode' | translate }}</mat-label>
                <mat-select [(value)]="mode" (selectionChange)="getExercises()">
                    <mat-option value="incoming">{{ 'Incoming' | translate }}</mat-option>
                    <mat-option value="performed">{{ 'Performed' | translate }}</mat-option>
                    <mat-option value="expired">{{ 'Expired' | translate }}</mat-option>
                    <mat-option value="all">{{ 'All' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="loading; else exercisesList" fxLayout="column" fxLayoutAlign="center center" style="height: 60vh;">
        <mat-spinner diameter="40"></mat-spinner>
    </div>

    <ng-template #exercisesList>
        <!-- Conditional No Exercises Message -->
        <div *ngIf="exercises.length === 0 && auth.isTeacher()" fxLayoutAlign="center center">
            <app-error-message customClass="warning"
                [errorTitle]="'No exercises available' | translate"
                [errorContent]="'Add an exercise using the button above' | translate">
            </app-error-message>
        </div>
        
        <div *ngIf="exercises.length === 0 && auth.isStudent()" fxLayoutAlign="center center">
            <app-error-message customClass="warning"
                [errorTitle]="'No exercises available' | translate"
                [errorContent]="'Check back later for active exercises' | translate">
            </app-error-message>
        </div>

        <!-- Exercise List -->
        <div style="width: 100%">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let exercise of exercises"
                                    style="margin-bottom: 10px; border-radius: 4px;"
                                    (opened)="toggleResults(exercise)">
                    
                    <mat-expansion-panel-header>
                        <div fxLayout="column" fxFlex>
                            <mat-panel-title fxLayout="row">
                                <span class="exercise-title" fxFlex style="vertical-align: middle; margin-top: 15px;">
                                    <strong>{{ exercise.name }}</strong>
                                </span>

                                <div *ngIf="auth.isTeacher()" style="margin-left: auto; width: 25%;">
                                    <div *ngIf="progressMap[exercise.id] as prog" style="margin-left: auto; padding: 5px;">
                                        <!-- Finished Progress -->
                                        <div fxFlex="50" style="text-align: center;">
                                            <div style="font-size: 13px; margin-bottom: 4px;">
                                            Submitted: {{ prog.finishedCount }}/{{ prog.studentsAssigned }}
                                            </div>
                                            <mat-progress-bar mode="determinate" [value]="prog.finishedPercentage" color="primary"></mat-progress-bar>
                                            <div style="font-size: 12px; margin-top: 2px;">
                                            {{ prog.finishedPercentage | number:'1.0-0' }}%
                                            </div>
                                        </div>
                                        <!-- Graded Progress -->
                                        <div fxFlex="50" style="text-align: center;">
                                            <div style="font-size: 13px; margin-bottom: 4px;">
                                            Graded: {{ prog.gradedCount }}/{{ prog.finishedCount }}
                                            </div>
                                            <mat-progress-bar mode="determinate" [value]="prog.gradedPercentage" color="accent"></mat-progress-bar>
                                            <div style="font-size: 12px; margin-top: 2px;">
                                            {{ prog.gradedPercentage | number:'1.0-0' }}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description style="margin-bottom: 7px; margin-top: 7px;">
                                <div class="textFont" fxFlex="20">
                                    {{ 'Teacher' | translate }}: {{ exercise.teacher?.name || 'N/A' }} {{ exercise.teacher?.surname || '' }}
                                </div>
                
                                <div class="textFont" fxFlex>
                                    {{ exercise.startPlanned | date: 'dd/MM/yyyy HH:mm' }}
                                    {{ 'to' | translate }}
                                    {{ exercise.endPlanned | date: 'HH:mm' }}
                                </div>
                
                                <div class="textFont" fxFlex>
                                    <span 
                                        class="textFont"
                                        *ngIf="exercise.classrooms?.length > 0" 
                                        (click)="goToClassrooms(exercise.classroomIds); $event.stopPropagation();">
                                        {{ exercise.classrooms.join(', ') }}
                                    </span>
                                    <ng-container *ngIf="!exercise.classrooms?.length">
                                        {{ 'No classroom assigned' | translate }}
                                    </ng-container>
                                </div>
                                
                                <div class="textFont" fxFlex *ngIf="auth.isStudent()">
                                    {{ 'Score' | translate }}:
                                    <ng-container *ngIf="exercise.score !== null; else noScore">
                                        {{ exercise.score }}
                                    </ng-container>
                                    <ng-template #noScore>
                                        N/A
                                    </ng-template>
                                </div>
                                
        
                                <div fxLayoutAlign="end center" fxFlex="30">
                                    
                                    <button *ngIf="auth.isTeacher()" mat-icon-button color="primary"
                                        (click)="openEditExerciseDialog(exercise); $event.stopPropagation();"
                                        [disabled]="!isEditable(exercise)"
                                        [matTooltip]="'Edit Exercise' | translate">
                                        <mat-icon>edit</mat-icon>
                                    </button>

                                    <button *ngIf="auth.isTeacher()" mat-icon-button color="warn"
                                        (click)="deleteExercise(exercise); $event.stopPropagation();"
                                        [matTooltip]="'Delete Exercise' | translate">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </mat-panel-description>
                        </div>
                    </mat-expansion-panel-header>
        
                    <mat-divider style="margin-bottom: 20px"></mat-divider>
                    
                    <!-- Description Section -->
                    <div>
                        <strong>{{ 'Description' | translate }}:</strong>
                        <p [ngClass]="{'truncate': !exercise.showFullDescription}">
                            {{ exercise.description || ('No additional details available' | translate) }}
                        </p>
                        <button *ngIf="exercise.description?.length > 100" (click)="exercise.showFullDescription = !exercise.showFullDescription">
                            {{ exercise.showFullDescription ? ('Show Less' | translate) : ('Show More' | translate) }}
                        </button>
                    </div>

                    <!-- Loading Spinner -->
                    <div *ngIf="exercise.loading" style="text-align: center; margin-top: 10px;">
                        <mat-spinner diameter="30"></mat-spinner>
                    </div>
                    
                    <!-- Results Section -->
                    <div *ngIf="exercise.results && exercise.results.length > 0; else noResults">
                        <strong>{{ 'Exercise Results' | translate }}:</strong>
                        <table mat-table [dataSource]="exercise.results" class="mat-elevation-z8">
                            <ng-container matColumnDef="userName">
                                <th mat-header-cell *matHeaderCellDef> User </th>
                                <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}"> {{ result.userName }} </td>  
                            </ng-container>
                    
                            <ng-container matColumnDef="startDate">
                            <th mat-header-cell *matHeaderCellDef> Start Date </th>
                            <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}"> {{ result.startDate ? (result.startDate | date: 'medium') : '---' }} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="stopDate">
                            <th mat-header-cell *matHeaderCellDef> Stop Date </th>
                            <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}"> {{ result.stopDate ? (result.stopDate | date: 'medium') : '---' }} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="rgbVideoPath">
                                <th mat-header-cell *matHeaderCellDef> RGB Video </th>
                                <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}">
                                    <mat-icon color="primary" [ngClass]="{'grayed-out': !result.rgbVideoPath}" [matTooltip]="result.rgbVideoPath ? 'RGB Video Available' : 'Not Available'">videocam</mat-icon>
                                </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="posevaVideoPath">
                                <th mat-header-cell *matHeaderCellDef> Poseva Video </th>
                                <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}">
                                    <mat-icon color="accent" [ngClass]="{'grayed-out': !result.posevaVideoPath}" [matTooltip]="result.posevaVideoPath ? 'Poseva Video Available' : 'Not Available'">play_circle_filled</mat-icon>
                                </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="mmSpeedPath">
                                <th mat-header-cell *matHeaderCellDef> MMSpeed </th>
                                <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}">
                                    <mat-icon color="warn" [ngClass]="{'grayed-out': !result.mmSpeedPath}" [matTooltip]="result.mmSpeedPath ? 'MMSpeed Data Available' : 'Not Available'">speed</mat-icon>
                                </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="mmTorquePath">
                                <th mat-header-cell *matHeaderCellDef> MMTorque </th>
                                <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}">
                                    <mat-icon color="primary" [ngClass]="{'grayed-out': !result.mmTorquePath}" [matTooltip]="result.mmTorquePath ? 'MMTorque Data Available' : 'Not Available'">settings</mat-icon>
                                </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="ioScannerPath">
                                <th mat-header-cell *matHeaderCellDef> IO Scanner </th>
                                <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}">
                                    <mat-icon color="accent" [ngClass]="{'grayed-out': !result.ioScannerPath}" [matTooltip]="result.ioScannerPath ? 'IO Scanner Data Available' : 'Not Available'">scanner</mat-icon>
                                </td>
                            </ng-container>                        

                            <ng-container matColumnDef="stateName">
                                <th mat-header-cell *matHeaderCellDef> State </th>
                                <td mat-cell *matCellDef="let result" [ngClass]="{'graded-row': (result.state === 5 || result.state === 4) && auth.isTeacher()}"> {{ result.stateName }} </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="results">
                                <th mat-header-cell *matHeaderCellDef> Results </th>
                                <td mat-cell *matCellDef="let row" [ngClass]="{'graded-row': (row.state === 5 || row.state === 4) && auth.isTeacher()}">
                                    <button mat-icon-button color="primary"
                                        (click)="goToExercise(row.exerciseId, row.userId)"
                                        [matTooltip]="'Go To Exercise' | translate">
                                        <mat-icon>assignment</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                    
                            <!-- Header Row -->
                            <tr mat-header-row *matHeaderRowDef="['userName', 'startDate', 'stopDate', 'rgbVideoPath', 'posevaVideoPath', 'mmSpeedPath', 'mmTorquePath', 'ioScannerPath', 'stateName', 'results']"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['userName', 'startDate', 'stopDate', 'rgbVideoPath', 'posevaVideoPath', 'mmSpeedPath', 'mmTorquePath', 'ioScannerPath', 'stateName', 'results'];"></tr>
                        </table>
                    </div>
                    
                    <ng-template #noResults>
                        <!-- If no results at all after possible filtering -->
                        <p *ngIf="auth.isStudent()">
                        {{ 'You have not submitted results for this exercise yet' | translate }}
                        </p>
                        <p *ngIf="auth.isTeacher() || auth.isAdmin()">
                        {{ 'No results available for this exercise' | translate }}
                        </p>
                    </ng-template>                  

                </mat-expansion-panel>
            </mat-accordion>
        </div>    
        
        <!-- Pagination -->
        <div style="width: 100%; display: flex; justify-content: center;">
            <mat-paginator
            [showFirstLastButtons]="true"
            [length]="totalExercises"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 20]"
            [color]="darkService.isSetDark ? 'accent' : 'primary'"
            (page)="changePage($event)"
            class="mat-elevation-z3">
            </mat-paginator>
        </div>
</ng-template>
</div>

import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import linkifyHtml from 'linkify-html';
import { Connection, ConnectionEvent, Session, SignalEvent } from 'openvidu-browser';
import { Helper } from 'src/app/helpers/helper';
import { SignalChatMessage } from 'src/app/models/conference-session/signalChatMessage';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { SignalType } from 'src/app/models/conference-session/clientSignalType';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DirectionalChat } from 'src/app/models/conference-session/directionalChat';
import { ForwardedMessage } from 'src/app/models/conference-session/forwardedMessage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';

const NOTIFICATION_SOUND: string = "../../assets/sound/note.mp3";
const NOTIFICATION_SOUND_PREF_KEY: string = "chatNotificationPref";

@Component({
  selector: 'app-directional-chat',
  templateUrl: './directional-chat.component.html',
  styleUrls: ['./directional-chat.component.scss']
})
export class DirectionalChatComponent implements OnInit, OnDestroy {

  private _session: Session;
  private _readMessages: boolean = true;
  private _notificationSound = new Audio();

  @ViewChild('list', { read: ElementRef }) messageList: ElementRef;

  @Input()
  set session (value: Session) {

    if (!value)
      return;

    this._session?.off(SignalType.directionalChat);
    this._session?.off(SignalType.directionalGlobalChat);
    this._session?.off('connectionCreated');
    this._session?.off('connectionDestroyed');

    this._session = value;

    this._session.on('connectionCreated', (event: ConnectionEvent) => {

      let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.hidden)
				return;

      if (clientData.userId === this.currentUser.id)
        return;

      if (this.addUsersOnConnection)
        this.addChat(event.connection);

      if (this.globalUsersChat && this.globalUsersChatEnabledRoles.includes(clientData.role)) {

        let index = this.chats.findIndex(c => c.type === 'global');

        if (index !== -1 && this.chats[index].connections.findIndex(c => c.connectionId === event.connection.connectionId) === -1)
          this.chats[index].connections.push(event.connection);

      }

      this.setInputText();

    });

    this._session.on('connectionDestroyed', (event: ConnectionEvent) => {

      let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.hidden)
				return;

      this.chats.forEach(chat => {

        let index = chat.connections.findIndex(c => c.connectionId === event.connection.connectionId)

        if (index !== -1)
          chat.type === 'direct' ?
          chat.active = false :
          chat.connections.splice(index, 1);

      });

      this.setInputText();

      this.onChatsChange.emit(this.chats);

    });

    this._session.on(SignalType.directionalChat, (event: SignalEvent) => {

      let clientData = <ClientData>JSON.parse(event.from.data);
      let connection = this._session.remoteConnections.get(event.from.connectionId);

			let index = this.addChat(connection);

      if (index === -1)
        return;

      let newMessage: SignalChatMessage = null;

      if (Helper.isJson(event.data)) {

        try {

          newMessage = <SignalChatMessage>JSON.parse(event.data);

        } catch (e) {

          console.error(e);
          return;

        }

      } else {

        newMessage = {
          userId: clientData.userId,
          message: event.data,
          username: clientData.name,
          picture: clientData.picture,
          date: new Date()
        };

      }

      if (!this._readMessages || (this._readMessages && this.currentChat !== index)) {

        newMessage.read = false;

        this.chats[index].unreadMessages++;

        this.unread.emit(this.getUnreadMessages());

        if (this.emitNotificationSound)
          this._notificationSound.play();
        
      }

      this.chats[index].messages.push(newMessage);

      if (this._readMessages)
        this.scrollTop();
        
    });

    this._session.on(SignalType.directionalGlobalChat, (event: SignalEvent) => {

      if (!this.globalUsersChat)
        return;

      let clientData = <ClientData>JSON.parse(event.from.data);

      let index = this.chats.findIndex(c => c.type === 'global' && c.connections.findIndex(c => c.connectionId === event.from.connectionId) !== -1);

      if (index === -1)
        return;

      let newMessage: SignalChatMessage = null;

      if (Helper.isJson(event.data)) {

        try {

          newMessage = <SignalChatMessage>JSON.parse(event.data);

        } catch (e) {

          console.error(e);
          return;

        }

      } else {

        newMessage = {
          userId: clientData.userId,
          message: event.data,
          username: clientData.name,
          picture: clientData.picture,
          date: new Date()
        };

      }

      if (!this._readMessages || (this._readMessages && this.currentChat !== index)) {

        newMessage.read = false;

        this.chats[index].unreadMessages++;

        this.unread.emit(this.getUnreadMessages());

        if (this.emitNotificationSound)
          this._notificationSound.play();
        
      }

      this.chats[index].messages.push(newMessage);

      if (this._readMessages)
        this.scrollTop();

    });
  }

  @Input()
  set read (value: boolean) {
    //this.sortChats(); Abilitare quando risolto bug https://github.com/angular/angular/issues/18847
    
    this._readMessages = value;

    this.currentChat = 0;
    this.onTabChange();

    if (!value && this.chats.length > 0)
      this.chats[this.currentChat].messages.forEach(m => m.read = true);
      
  }

  @Input()
  enabledRole: string = null;

  @Input()
  addUsersOnConnection: boolean = true;

  @Input()
  globalUsersChat: boolean = false;

  @Input()
  globalUsersChatEnabledRoles: string[] = [];

  @Input()
  aggregateChats: boolean = false;

  @Input()
  forwardActive: boolean = false;

  @Input()
  forwardChats: DirectionalChat[] = [];

  @Input()
  set forwardedMessage(value: ForwardedMessage) {

    if (!value || value.index < 0 || value.index >= this.chats.length)
      return;

    this.chats[value.index].messages.push(value.message);

  }

  @Input()
  replyActive: boolean = false;

  @Input()
  hideActive: boolean = false;

  @Input()
  title: string = 'Messages';

  @Output()
  unread: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onChatsChange: EventEmitter<DirectionalChat[]> = new EventEmitter<DirectionalChat[]>();

  @Output()
  onForwardedMessage: EventEmitter<ForwardedMessage> = new EventEmitter<ForwardedMessage>();

  currentChat: number = 0;
  chats: DirectionalChat[] = [];
  inputText: string = 'Message';
  showHiddenMessages: boolean = false;
  emitNotificationSound: boolean = true;

  message: FormControl<string> = new FormControl<string>(null, [Validators.maxLength(300)]);

  currentUser: User;

  constructor(private auth: AuthService, private snackBar: MatSnackBar) {
    this._notificationSound.src = NOTIFICATION_SOUND;

    this.emitNotificationSound = (localStorage.getItem(NOTIFICATION_SOUND_PREF_KEY) ?? 'true') === 'true';
  }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    if (this.globalUsersChat)
      this.chats.push({
        connections: [],
        messages: [],
        unreadMessages: 0,
        active: true,
        type: 'global'
      });

    if (this.aggregateChats)
      this.chats.push({
        connections: [],
        messages: [],
        unreadMessages: 0,
        active: true,
        type: 'aggregate'
      });

    this.onChatsChange.emit(this.chats);
  }

  ngOnDestroy(): void {

    this._session?.off(SignalType.directionalChat);
    this._session?.off(SignalType.directionalGlobalChat);
    this._session?.off('connectionCreated');
    this._session?.off('connectionDestroyed');

    this.chats = [];
    this._readMessages = true;

  }

  async sendMessage() {
    if (!this.message.value || this.chats.length === 0)
      return;

		let message = Helper.clean(this.message.value);

    if (!message || Helper.isJson(message))
			return;

    this.clearMessage();

    let data = linkifyHtml(message, {
      defaultProtocol: 'https',
      target: '_blank'
    });

		await this._session.signal({
      data: data,
      to: this.chats[this.currentChat].connections,
      type: this.chats[this.currentChat].type === 'global' ?
            SignalType.directionalGlobalChat :
            SignalType.directionalChat
    });

    let clientData = <ClientData>JSON.parse(this._session.connection.data);

    let newMessage: SignalChatMessage = {
      userId: clientData.userId,
      message: data,
      username: clientData.name,
      picture: clientData.picture,
      date: new Date()
    };

    this.chats[this.currentChat].messages.push(newMessage);
	}

  async forwardMessage(forwardChatIndex: number, chatMessage: SignalChatMessage) {
    if (!this.forwardChats || forwardChatIndex < 0 || forwardChatIndex >= this.forwardChats.length)
      return;

    let clientData = <ClientData>JSON.parse(this._session.connection.data);

    chatMessage.forwarded = true;

    let newMessage: SignalChatMessage = {
      userId: clientData.userId,
      message: chatMessage.message,
      username: clientData.name,
      picture: clientData.picture,
      forwarded: true,
      date: new Date()
    };

    await this._session.signal({
      data: JSON.stringify(newMessage),
      to: this.forwardChats[forwardChatIndex].connections,
      type: this.forwardChats[forwardChatIndex].type === 'global' ?
            SignalType.directionalGlobalChat :
            SignalType.directionalChat
    });

    this.snackBar.open('Message forwarded', undefined, { duration: 3000 });

    this.onForwardedMessage.emit({ index: forwardChatIndex, message: newMessage });
  }

  replyTo(userId: number) {
    let index = this.chats.findIndex(c => c.data?.userId === userId);

    if (index === -1) {
      let connIndex = this.chats[this.currentChat].connections.findIndex(c => {

        let clientData = <ClientData>JSON.parse(c.data); // Non ottimo

        return clientData.userId === userId;

      });

      if (connIndex === -1)
        return;

      index = this.addChat(this.chats[this.currentChat].connections[connIndex], true);
    }

    if (index !== -1)
      this.currentChat = index;
  }

  close() {
    this.onClose.emit(true);
  }

  onKeydownEvent(event: KeyboardEvent, enable: boolean): void {
    if (event.key === 'Enter' && !event.shiftKey && enable) {

      event.preventDefault();
      this.sendMessage();
      
    }
  }

  clearMessage() {
    this.message.setValue(null);
    this.message.markAsUntouched();
  }

  removeChat(userId: number) {
    let index = this.chats.findIndex(c => c.data?.userId === userId);

    if (index !== -1) {
      this.onChatsChange.emit(this.chats);
      return this.chats.splice(index, 1)[0];
    }
      
  }

  onTabChange(event?: MatTabChangeEvent) {
    this.clearMessage();
    this.setInputText();
    this.scrollTop();

    this.showHiddenMessages = false;

    let i = event?.index ?? this.currentChat;

    if (this.chats.length > 0) {

      if (i !== this.currentChat)
        this.chats[this.currentChat].messages.forEach(m => m.read = true);

      if (this.chats[i])
        this.chats[i].unreadMessages = 0;

    }
    
    this.currentChat = i;

    this.unread.emit(this.getUnreadMessages());
  }

  isChatActive(index: number = this.currentChat) {
    return (this.chats[index]?.active ?? false) && this.chats[index].connections.length > 0;
  }

  enableOptions(msg: SignalChatMessage, chatIndex: number, type?: 'reply' | 'forward' | 'hide') {
    let check = false;

    if (!type || type === 'reply')
      check = check ||
              (this.replyActive &&
               msg.userId !== this.currentUser.id &&
               this.chats[chatIndex].type !== 'direct');

    if (!type || type === 'forward')
      check = check ||
              this.forwardActive;

    if (!type || type === 'hide')
      check = check ||
              this.hideActive;

    return check;
  }

  toggleNotificationSound() {
    this.emitNotificationSound = !this.emitNotificationSound;

    localStorage.setItem(NOTIFICATION_SOUND_PREF_KEY, String(this.emitNotificationSound));
  }

  private addChat(connection: Connection, forceDirect: boolean = false) {
    if (!connection)
      return -1;

    let clientData = <ClientData>JSON.parse(connection.data);

    if (clientData.userId === this.currentUser.id || clientData.mode !== 'subject' || (this.enabledRole && clientData.role != this.enabledRole))
        return -1;

    let index = this.chats.findIndex(c => c.type === 'aggregate');

    // Se c'è la modalità aggregazione
    if (index !== -1 && !forceDirect) {

      let connIndex = this.chats[index].connections.findIndex(c => c.connectionId === connection.connectionId);

      if (connIndex === -1) {

        this.chats[index].connections.push(connection);
        this.onChatsChange.emit(this.chats);

      }

    } else {

      index = this.chats.findIndex(c => c.data?.userId === clientData.userId);

      if (index !== -1) {
  
        this.chats[index].data = clientData;
        this.chats[index].connections = [connection];
        this.chats[index].active = true;
  
      } else {
  
        index = this.chats.push({
          data: clientData,
          connections: [connection],
          messages: [],
          unreadMessages: 0,
          active: true,
          type: 'direct'
        }) - 1;
        
      }

      this.onChatsChange.emit(this.chats);

    }

    return index;
  }

  private getUnreadMessages() {
    return this.chats.map(c => c.unreadMessages).reduce((a, b) => a + b, 0);
  }

  private scrollTop() {
    if (!this.messageList)
      return;

    setTimeout(() => this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight);
  }

  private setInputText() {
    if (!this._readMessages || this.chats.length === 0)
      return;

    if (!this.chats[this.currentChat]?.active ?? false) {

      this.inputText = 'User is not connected';
      this.message.disable();

    } else if (this.chats[this.currentChat].connections.length === 0) {

      this.inputText = 'No users connected';
      this.message.disable();

    } else {

      this.inputText = 'Message';
      this.message.enable();

    }
  }

  private sortChats() {
    this.chats.sort((a, b) => {

      let valuesA = a.messages.map(m => m.date.getTime());
      let valuesB = b.messages.map(m => m.date.getTime());

      let maxDateA = Math.max(...(valuesA.length === 0 ? [0] : valuesA));
      let maxDateB = Math.max(...(valuesB.length === 0 ? [0] : valuesB));

      return maxDateB - maxDateA;

    });
  }

}

import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Session, SignalEvent } from 'openvidu-browser';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { SignalType } from 'src/app/models/conference-session/clientSignalType';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { SignalPriorityUpdate } from 'src/app/models/conference-session/signalPriorityUpdate';
import { SignalRaiseHand } from 'src/app/models/conference-session/signalRaiseHand';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { firstBy } from 'thenby';

const PARTICIPANT_POSITION_KEY: string = "participantPositionConfig";
const NOTIFICATION_SOUND: string = "../../assets/sound/note.mp3";

@Component({
  selector: 'app-session-participants',
  templateUrl: './session-participants.component.html',
  styleUrls: ['./session-participants.component.scss']
})
export class SessionParticipantsComponent implements OnInit, OnDestroy {

  private _session: Session;
  private _readNotifications: boolean = true;
  private _unreadNotifications: number = 0;
  private _notificationSound = new Audio();

  @Input()
  set session (value: Session) {

    if (!value)
      return;

    this._session?.off(SignalType.toggleHand);

    this._session = value;

    this._session.on(SignalType.toggleHand, (event: SignalEvent) => {

      let data: SignalRaiseHand = JSON.parse(event.data);
      let clientData: ClientData = JSON.parse(event.from.data);

      // Corrisponse al messaggio lowerAllHands
      if (data.userId == null) {

        if ((this.mode === 'standard' && (clientData.role === 'presenter' || clientData.role === 'publisher')) ||
            (this.mode === 'webinar' && clientData.role === 'moderator')) {

          this.onCurrentUserHandRaised.emit(false);
          this.participants.forEach(p => p.handRaised = false);

        }

        return;
      }

      if (data.userId === this.currentUser.id)
        this.onCurrentUserHandRaised.emit(data.raise);

      let index = this.participants.findIndex(p => p.userId === data.userId);

      if (index === -1)
        return;

      this.participants[index].handRaised = data.raise;
      this.participantsChange.emit(this.participants);

      if (!this._readNotifications && clientData.userId !== this.currentUser.id) {

        if (data.raise) {

          this.unread.emit(++this._unreadNotifications);
          this._notificationSound.play();

        } else if (this._unreadNotifications > 0) {

          this.unread.emit(--this._unreadNotifications);
          
        }

      }

    });

    this._session.on(SignalType.priorityUpdate, (event: SignalEvent) => {

      let data: SignalPriorityUpdate = JSON.parse(event.data);
      let clientData: ClientData = JSON.parse(event.from.data);

      let index = this.participants.findIndex(p => p.userId === clientData.userId);

      if (index === -1)
        return;

      this.participants[index].priority = data.priority;

      this.participants.sort(
        firstBy((a: ClientStream, b: ClientStream) => (b.priority ?? 0) - (a.priority ?? 0))
        .thenBy((a: ClientStream, b: ClientStream) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
      );

      this.participantsChange.emit(this.participants);

      let currentUserIndex = this.participants.findIndex(p => p.userId === this.currentUser.id);

      if (currentUserIndex !== -1)
        this.onPriorityPosition.emit(currentUserIndex);

    });

  }

  @Input()
  set read (value: boolean) {
    
    this._readNotifications = value;

    if (this._readNotifications) {
      this._unreadNotifications = 0;
      this.unread.emit(this._unreadNotifications);
    }
      
  }

  @Input()
  participants: ClientStream[] = [];

  @Input()
  presenters: ClientStream[] = [];

  @Input()
  dragBoundary: string = null;

  @Input()
  optionMenuBtn: any = null;

  @Input()
  lowerAllHandsActive: boolean = false;

  @Input()
  lowerHandEnabled: boolean = false;

  @Input()
  mode: 'standard' | 'webinar' = 'standard';

  @Input()
  overlay: boolean = true;

  @Input()
  selectionEnabled: boolean = false;

  @Input()
  selectionDisabled: boolean = false;

  @Input()
  selectedParticipants: number[] = [];

  @Input()
  selectedParticipantsDisabled: number[] = [];

  @Output()
  unread: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  participantsChange: EventEmitter<ClientStream[]> = new EventEmitter<ClientStream[]>();

  @Output()
  selectedParticipantsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output()
  onCurrentUserHandRaised: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onLowerAllHands: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onLowerHand: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onPriorityPosition: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentUser: User;
  currentParticipant: ClientStream;
  refresh: boolean = false;
  isDragging: boolean = false;
	participantsClass: 'top-container' | 'left-container' | 'right-container' = 'left-container';

  constructor(private auth: AuthService) {
    this._notificationSound.src = NOTIFICATION_SOUND;
  }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    if (this.overlay)
      this.participantsClass = (localStorage.getItem(PARTICIPANT_POSITION_KEY) as 'top-container' | 'left-container' | 'right-container') ?? 'top-container';
  }

  ngOnDestroy(): void {

    this._session?.off(SignalType.toggleHand);

    this.participants = [];
    this._readNotifications = true;
    this._unreadNotifications = 0;

  }

  dragStart(event: CdkDragStart) {
		this.isDragging = true;
	}

	dragEnd(event: CdkDragEnd) {
		this.isDragging = false;
		this.refresh = true;

		let height = window.innerHeight;
		let width = window.innerWidth;
		let heightArea = (height / 100) * 5; // 5% dell'altezza
		let widthArea = (width / 100) * 5; // 5% della larghezza
		
		let newDragPosition = event.dropPoint;

		if (newDragPosition.y < heightArea)
			this.participantsClass = 'top-container';
		//else if (newDragPosition.y > (height - heightArea))
		//	this.participantsClass = 'bottom-container';
		else if (newDragPosition.x < widthArea)
			this.participantsClass = 'left-container';
		else if (newDragPosition.x > (width - widthArea))
			this.participantsClass = 'right-container';

    if (this.overlay)
		  localStorage.setItem(PARTICIPANT_POSITION_KEY, this.participantsClass);
			
		setTimeout(() => this.refresh = false);
	}

  getOtherParticipants(type: 'presenters' | 'participants') {
    let currentParticipant = this.participants.find(p => p.userId === this.currentUser.id);

    if (currentParticipant == null)
      currentParticipant = this.presenters.find(p => p.userId === this.currentUser.id);

    if (this.currentParticipant != currentParticipant)
      this.currentParticipant = currentParticipant;

    return (type === 'presenters' ? this.presenters : this.participants).filter(p => p.userId !== this.currentUser.id);
  }

  lowerHand(userId: number) {
    if (this.lowerHandEnabled)
      this.onLowerHand.emit(userId);
  }

  isSelectedParticipant(userId: number) {
    return this.selectedParticipants.findIndex(sp => sp === userId) !== -1;
  }

  isSelectedParticipantDisabled(userId: number) {
    return this.selectedParticipantsDisabled.findIndex(spd => spd === userId) !== -1;
  }

  onSelectedParticipant(userId: number, operation: 'add' | 'remove') {
    let index = this.selectedParticipants.findIndex(sp => sp === userId);

    if (operation === 'add' && index === -1)
      this.selectedParticipants.push(userId);

    if (operation === 'remove' && index !== -1)
      this.selectedParticipants.splice(index, 1);

    this.selectedParticipantsChange.emit(this.selectedParticipants);
  }

  close() {
    this.onClose.emit(true);
  }

}

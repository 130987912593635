/*
export enum PackageState {
    deleted = 0,
    offline = 1,
    online = 2
}
*/

export abstract class PackageState {
    public static deleted: number = 0;
    public static offline: number = 1;
    public static online: number = 2;

    public static getState(state: number): string {
        if (state === this.deleted)
            return 'Deleted';
        if (state === this.offline)
            return 'Offline';
        if (state === this.online)
            return 'Online';
    }
}

export enum PackageContentType {
    master = 0,
    course = 1
}

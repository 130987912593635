<div [hidden]="!master" class="component-main">
    <div fxLayout="column" fxLayoutGap="20px">

        <div fxLayout="row">
            <div fxLayoutAlign="start center" fxFlex>
                <button mat-stroked-button (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                    {{ 'Back' | translate }}
                </button>
                <button mat-stroked-button style="margin-left: 10px;" [cdkCopyToClipboard]="href" *ngIf="!isOneToOne()">
                    <mat-icon>content_copy</mat-icon>
                    {{ 'Copy link' | translate }}
                </button>
            </div>
            <h2 fxFlex="60" fxLayoutAlign="center center">
                {{ getTitile() + getTitleConn() }}
                <span *ngIf="isOneToOne() && !currentUser.isStudent" [style.color]="darkService.isSetDark ? '#00acc1' : 'firebrick'">
                    {{ '&nbsp;' + getOneToOneUser() }}
                </span>
            </h2>
            <div fxLayoutAlign="end center" fxFlex style="font-weight: bold; padding-right: 20px;"
                *ngIf="isOneToOne()"
                [style.color]="getRemainingColor()">
                {{ remainingDays + ' ' + ('Oof' | translate) + ' ' + this.master.durationInDays + ' ' + ('days remaining' | translate) }}
            </div>
            <div fxFlex *ngIf="!isOneToOne()"></div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">

            <div fxLayout="column" fxLayoutGap="20px" fxFlex="25">

                <mat-card appearance="outlined" *ngIf="master" style="width: 100%">
                    <img mat-card-image [src]="master.imageUrl" [alt]="master.name" loading="lazy">
                    <div class="statusText mat-elevation-z3">
                        <h5 style="margin: 0%;">{{ (getMasterState().getState(master.state) | translate).toUpperCase() }}</h5>
                    </div>
                    <mat-card-header>
                        <mat-card-title>
                            {{ master.name }}
                        </mat-card-title>
                        <mat-card-subtitle style="font-size: 12px;" *ngIf="!isTemplate(); else templateSub">
                            {{ master.startDate | date: 'dd/MM/yyyy' }} -
                            {{ master.stopDate | date: 'dd/MM/yyyy' }}
                        </mat-card-subtitle>
                        <ng-template #templateSub>
                            <mat-card-subtitle>
                                {{ 'Personal' | translate }}
                            </mat-card-subtitle>
                        </ng-template>
                    </mat-card-header>
                    <mat-card-content style="overflow: hidden;">
                        {{ getHelper().getTruncateShowcase(master.description, 'No description', 250) }}
                    </mat-card-content>
                    <div fxFlex></div>
                    <mat-card-actions *ngIf="master" fxLayout="row" fxLayoutAlign="end center">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex>
                            <button mat-fab color="warn"
                                *ngIf="currentUser.id === master.idAuthor && currentUser.mastersEnabled && !isDeleted()"
                                (click)="deleteMaster()"
                                [matTooltip]="'Delete' | translate">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-fab color="primary"
                                *ngIf="currentUser.id === master.idAuthor && currentUser.mastersEnabled && !isOneToOne() && !isDeleted()"
                                (click)="updateMaster()"
                                [disabled]="master.state === getMasterState().finished"
                                [matTooltip]="'Edit' | translate">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <!--button mat-button [matTooltip]="'Share' | translate" (click)="share()">
                                <mat-icon>share</mat-icon>
                            </button-->
                        </div>
                        <button mat-raised-button color="accent"
                            *ngIf="currentUser.id === master.idAuthor &&
                                   currentUser.mastersEnabled &&
                                   master.state !== getMasterState().finished &&
                                   !isDeleted()"
                            (click)="changeState()">
                            {{ isTemplate() ?
                               ('Set' | translate) + ' ' +  (getMasterState().getState(master.state + 2) | translate)
                               :
                               ('Set' | translate) + ' ' +  (getMasterState().getState(master.state + 1) | translate) }}
                        </button>
                    </mat-card-actions>
                </mat-card>
    
                <app-price-card style="width: 100%"
                    *ngIf="!isDeleted()"
                    [subscription]="master.subscription"
                    [subCrudShow]="currentUser.id === master.idAuthor && currentUser.mastersEnabled && master.state < getMasterState().planned"
                    [subtitle]="getSubTitle()"
                    [buyShow]="getDisplayPayBtn()"
                    [buyDisabled]="getDisabledPayBtn()"
                    [renewShow]="isOneToOne()"
                    (buyFree)="payMasterFree()"
                    (buyPay)="addToCart()"
                    (subAdd)="addSubscription()"
                    (subEdit)="addSubscription()">
                </app-price-card>

            </div>

            <mat-tab-group fxFlex>

                <mat-tab [label]="'Dashboard' | translate">

                    <ng-template matTabContent>

                        <div fxLayout="row" fxLayoutGap="20px" class="tab-container">
                            <mat-card appearance="outlined" fxLayout="column" fxFlex *ngIf="!isTemplate()">
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ 'Live Lessons' | translate }}
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                                    <div fxLayoutAlign="center end" fxFlex="20">
                                        <app-spinner-container class="center-item" color="primary" [diameter]="75" [strokeWidth]="5"
                                            (click)="selectSpinnerValue()"
                                            [value]="spinnerValues[currentSpinnerValue]"
                                            [displayWith]="spinnerValue"
                                            [matTooltip]="getSpinnerTip() | translate">
                                        </app-spinner-container>
                                    </div>
                                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                                        <h2>
                                            {{ 
                                                doneLessons + ' ' + ('Live Lessons' | translate)
                                                + ' (' + lessonsDuration[0] + ' ' + ('hours' | translate)
                                                + ' ' + ('and' | translate)
                                                + ' ' + lessonsDuration[1] + ' ' + ('minutes' | translate)
                                                + ')'
                                            }}
                                        </h2>
                                        <h4>
                                            {{ 
                                                ('Of' | translate) + ' ' + lessons.data.length + ' ' + ('Live Lessons' | translate)
                                                + ' (' + lessonsPlannedDuration[0] + ' ' + ('hours' | translate)
                                                + ' ' + ('and' | translate)
                                                + ' ' + lessonsPlannedDuration[1] + ' ' + ('minutes' | translate)
                                                + ') ' + ('planned' | translate)
                                            }}
                                        </h4>
                                    </div>
                                </mat-card-content>
                            </mat-card>

                            <mat-card appearance="outlined" fxLayout="column" fxFlex *ngIf="!isTemplate()">
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ 'Attendance' | translate }}
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                                    <div fxLayoutAlign="center end" fxFlex="20">
                                        <app-spinner-container class="center-item" color="primary" [diameter]="75" [strokeWidth]="5"
                                            [value]="lessonAverageAttendance" [displayWith]="spinnerValue">
                                        </app-spinner-container>
                                    </div>
                                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                                        <h2>
                                            {{
                                                lessonAverageAttendanceTime[0] + ' ' + ('hours' | translate)
                                                + ' ' + ('and' | translate)
                                                + ' ' + lessonAverageAttendanceTime[1] + ' ' + ('minutes' | translate)
                                            }}
                                        </h2>
                                        <h4>{{ 'Average lesson attendance' | translate }}</h4>
                                    </div>
                                </mat-card-content>
                            </mat-card>

                            <mat-card appearance="outlined" fxLayout="column" *ngIf="lineChartLabels && lineChartLabels.length > 0"
                                fxFlex>
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ ('Average attendance trend' | translate) + ' (%)' }}
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px" fxFlex>
                                    <div class="graph">
                                        <canvas baseChart width="400px" height="110px"
                                            #attendanceChart
                                            [datasets]="lineChartData"
                                            [labels]="lineChartLabels"
                                            [options]="lineChartOptions"
                                            [type]="lineChartType">
                                        </canvas>
                                    </div>
                                </mat-card-content>
                            </mat-card>

                            <mat-card appearance="outlined" fxLayout="column" fxFlex *ngIf="isTemplate()">
                                <mat-card-header>
                                    <mat-card-title>
                                        {{ 'A collection of live lessons only for you' | translate }}
                                    </mat-card-title>
                                </mat-card-header>
                                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                                    <div fxLayoutAlign="center end" fxFlex="20">
                                        <button mat-fab style="background-color: #ffb22b;" class="mat-elevation-z0 btnNoHover">
                                            <mat-icon style="color: white;">
                                                person
                                            </mat-icon>
                                        </button>
                                    </div>
                                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                                        <h3>{{ 'Each time a personal live digital academy is purchased a lesson calendar must be defined by the author specifically for the user' | translate }}</h3>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </ng-template>

                </mat-tab>

                <mat-tab [label]="'Live Lessons' | translate" *ngIf="!isTemplate()">

                    <mat-card appearance="outlined" class="tab-container">
                        <!--mat-card-header>
                            <mat-card-title>
                                {{ 'Live Lessons' | translate }}
                            </mat-card-title>
                        </mat-card-header-->
                        <mat-card-content fxLayout="column">
                            <table mat-table [dataSource]="lessons" matSort #lessonsSort="matSort" style="width: 100%;">
        
                                <ng-container matColumnDef="typeId">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-icon [matTooltip]="getLessonType(element.typeId) | translate">
                                            {{ getLessonIcon(element.typeId) }}
                                        </mat-icon>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Lesson' | translate }}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{ element.name }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="teacher">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Teacher' | translate }}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{ element.teacher.name + ' ' + element.teacher.surname }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="startPlanned">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.startPlanned | date: 'dd/MM/yyyy HH:mm' }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="room">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Room' | translate }}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span>{{ element.room ? element.room.name : ('Virtual room' | translate) }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</th>
                                    <td mat-cell *matCellDef="let element">
        
                                        <button mat-icon-button *ngIf="element.typeId === 3" (click)="lessonContents(element)"
                                            [matTooltip]="'Contents' | translate">
                                            <mat-icon [matBadge]="element.lessonContent ? element.lessonContent.length : 0">
                                                attachment
                                            </mat-icon>
                                        </button>
        
                                        <ng-container *ngIf="(currentUser.id === element.teacherId || currentUser.id === master.idAuthor) && element.state === 2">
                                            
                                            <button mat-icon-button
                                                *ngIf="element.typeId === 3 || element.typeId === 4"
                                                (click)="editLessonElement(element)"
                                                [disabled]="master.state === getMasterState().finished"
                                                [matTooltip]="'Edit' | translate">
                                                <mat-icon>edit</mat-icon>
                                            </button>
        
                                            <button mat-icon-button color="warn"
                                                *ngIf="element.typeId === 3 || element.typeId === 4"
                                                (click)="deleteLesson(element)"
                                                [disabled]="master.state === getMasterState().finished"
                                                [matTooltip]="'Delete' | translate">
                                                <mat-icon>delete</mat-icon>
                                            </button>
        
                                            <button mat-icon-button
                                                *ngIf="element.typeId === 4"
                                                (click)="editSurvey(element)"
                                                [disabled]="master.state === getMasterState().finished"
                                                [matTooltip]="'Edit survey' | translate">
                                                <mat-icon>article</mat-icon>
                                            </button>
        
                                            <button mat-icon-button
                                                *ngIf="element.typeId === 8"
                                                (click)="updateConference(element.conference.id)"
                                                [disabled]="master.state === getMasterState().finished"
                                                [matTooltip]="'Edit' | translate">
                                                <mat-icon>edit</mat-icon>
                                            </button>
        
                                            <button mat-icon-button color="warn"
                                                *ngIf="element.typeId === 8"
                                                (click)="deleteConference(element.conference)"
                                                [disabled]="master.state === getMasterState().finished"
                                                [matTooltip]="'Delete' | translate">
                                                <mat-icon>
                                                    delete
                                                </mat-icon>
                                            </button>
        
                                        </ng-container>
                                        
                                        <button mat-icon-button
                                            *ngIf="element.typeId === 4"
                                            (click)="showSurvey(element.idSurvey)"
                                            [matTooltip]="'View survey' | translate">
                                            <mat-icon>preview</mat-icon>
                                        </button>
        
                                        <button mat-icon-button
                                            *ngIf="element.typeId === 4"
                                            (click)="showAnalyticsSurvey(element)"
                                            [matTooltip]="'View analytics' | translate">
                                            <mat-icon>analytics</mat-icon>
                                        </button>
        
                                        <button mat-icon-button
                                            *ngIf="element.typeId === 4"
                                            (click)="showResultSurvey(element)"
                                            [matTooltip]="'Show survey answer' | translate">
                                            <mat-icon [matBadgeHidden]="element.surveyAnswer.length === 0" 
                                                [matBadge]="element.surveyAnswer.length">
                                                people
                                            </mat-icon> 
                                        </button>
                                    </td>
                                      
                                </ng-container>
                    
                                <tr mat-header-row *matHeaderRowDef="lessonsCol"></tr>
                                <tr mat-row *matRowDef="let row; columns: lessonsCol;"
                                    [class.done]="row.state === 1 && row.stopDate"
                                    [class.notDone]="row.state === 2 && todayGreaterThanPlanned(row.endPlanned)"
                                    [class.open]="row.state === 1 && !row.stopDate"
                                    [class.survey]="row.typeId === 4"></tr>
                            </table>
                            <mat-paginator #lessonsPag 
                                [length]="lessons?.data.length ?? 0"
                                [pageSize]="10"
                                [pageSizeOptions]="[10, 20, 50]">
                            </mat-paginator>
                            
                        </mat-card-content>
                        <mat-card-actions fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
                            <button mat-raised-button color="accent"
                                *ngIf="master && (currentUser.id === master.idAuthor || currentUserIsTeacher) && isOneToOne()"
                                (click)="addLesson()"
                                [disabled]="master.state === getMasterState().finished || !currentUser.streamingEnabled"
                                [matTooltip]="'Add Live Lesson' | translate">
                                <mat-icon>add</mat-icon>
                                {{ 'Lesson' | translate }}
                            </button>
                            <button mat-raised-button color="accent"
                                *ngIf="master && (currentUser.id === master.idAuthor || currentUserIsTeacher) && !isOneToOne()"
                                (click)="addConference()"
                                [disabled]="master.state === getMasterState().finished || !currentUser.streamingEnabled"
                                [matTooltip]="'Add conference' | translate">
                                <mat-icon>add</mat-icon>
                                {{ 'Conference' | translate }}
                            </button>
                            <button mat-raised-button color="primary"
                                *ngIf="master && (currentUser.id === master.idAuthor || currentUserIsTeacher)"
                                (click)="addSurvey()"
                                [disabled]="master.state === getMasterState().finished"
                                [matTooltip]="'Add survey' | translate"><!--[disabled]="isTemplate()"-->
                                <mat-icon>add</mat-icon>
                                {{ 'Survey' | translate }}
                            </button>
                        </mat-card-actions>
        
                    </mat-card>

                </mat-tab>

                <mat-tab [label]="'Calendar' | translate" *ngIf="master && !isTemplate()">
                    <app-calendar
                        page="master"
                        [master]="master"
                        [teachers]="customerTeachers"
                        [updateEvents]="updateCalendar"
                        [disableClickMenu]="master.state === getMasterState().finished"
                        (allRooms)="rooms"
                        (lessonAdded)="getLessons()"
                        (lessonEdited)="getLessons()"
                        (lessonDeleted)="getLessons()"
                        class="tab-container"
                        style="margin: 20px;">
                    </app-calendar><!--[disableClickMenu]="isTemplate()"-->
                </mat-tab>

                <mat-tab [label]="'Teachers' | translate">
                    <ng-container *ngTemplateOutlet="masterTeachers"></ng-container>
                </mat-tab>

                <mat-tab [label]="'Participants' | translate" *ngIf="!isTemplate() && !isOneToOne()">
                    <ng-container *ngTemplateOutlet="masterStudents"></ng-container>
                </mat-tab>

                <mat-tab [label]="'Contents' | translate">
                    <ng-template matTabContent>

                        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px" style="margin-top: 20px">

                            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
                                <button mat-fab
                                    *ngIf="currentUser.id === master.idAuthor && !isDeleted()"
                                    (click)="addContent()"
                                    [disabled]="derived.data.length > 0"
                                    [matTooltip]="'Add content' | translate"
                                    color="accent">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <button mat-fab
                                    (click)="contentsMode = (contentsMode === 'cards' ? 'table' : 'cards')"
                                    [matTooltip]="'Change contents view' | translate" 
                                    color="primary">
                                    <mat-icon>view_list</mat-icon>
                                </button>
                                <button mat-fab
                                    (click)="refreshMaster.emit(true)"
                                    [matTooltip]="'Refresh' | translate"
                                    color="primary">
                                    <mat-icon>refresh</mat-icon>
                                </button>
                            </div>

                            <div *ngIf="contentsMode === 'cards' && mediaContents.length > 0" [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">
                                <app-master-content-card *ngFor="let mediaContent of mediaContents"
                                    [content]="mediaContent"
                                    [masterAuthorId]="master.idAuthor"
                                    [hideEdit]="derived.data.length > 0 || isDeleted()"
                                    [hideDelete]="derived.data.length > 0 || isDeleted()"
                                    (onView)="viewContent(mediaContent)"
                                    (onEdit)="editContent($event)"
                                    (onDelete)="deleteContent($event.id)">
                                </app-master-content-card>
                            </div>

                            <app-master-content-table style="width: 100%"
                                *ngIf="contentsMode === 'table' && mediaContents.length > 0"
                                [masterAuthorId]="master.idAuthor"
                                [masterState]="master.state"
                                [contents]="mediaContents"
                                [hideEdit]="derived.data.length > 0 || isDeleted()"
                                [hideDelete]="derived.data.length > 0 || isDeleted()"
                                (onView)="viewContent($event)"
                                (onEdit)="editContent($event)"
                                (onDelete)="deleteContent($event.id)">
                            </app-master-content-table>

                            <div *ngIf="mediaContents.length === 0">
                                <app-error-message customClass="warning"
                                    [errorTitle]="'There are no contents' | translate"
                                    [errorContent]="'Currently there are none to show' | translate">
                                </app-error-message>
                            </div>

                        </div>

                    </ng-template>

                </mat-tab>

                <mat-tab [label]="'Users' | translate" *ngIf="isTemplate()">
                    <mat-card appearance="outlined" fxFlex class="tab-container">
                        <!--mat-card-header>
                            <mat-card-title>
                                {{ 'Masters' | translate }}
                            </mat-card-title>
                        </mat-card-header-->
                        <mat-card-content>
                            <table mat-table matSort #derivedMastersSort="matSort" [dataSource]="derived" style="width: 100%;">
        
                                <ng-container matColumnDef="user">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Student' | role | translate }}</th>
                                    <td mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="start center"
                                        fxLayoutGap="10px" style="padding: 5px">
                                        <app-profile-picture [url]="element.classroom.classroomContent[0].user.profilePictureUrl"></app-profile-picture>
            
                                        <span class="truncate-text">
                                            {{ element.classroom.classroomContent[0].user.name + ' ' + element.classroom.classroomContent[0].user.surname }}
                                        </span>
                                    </td>
                                </ng-container>
        
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</th>
                                    <td mat-cell *matCellDef="let element" style="padding: 5px">
                                        <button mat-stroked-button (click)="goToMaster(element.id)">
                                            {{ 'Go to master' | translate }}
                                            <mat-icon>launch</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
            
                                <tr mat-header-row *matHeaderRowDef="derivedMastersCol"></tr>
                                <tr mat-row *matRowDef="let row; columns: derivedMastersCol;"></tr>
            
                            </table>
                            <mat-paginator #derivedMastersPag
                                [length]="derived?.data.length ?? 0"
                                [pageSize]="10"
                                [pageSizeOptions]="[10, 20, 50]">
                            </mat-paginator>
                        </mat-card-content>
                    </mat-card>
                </mat-tab>

            </mat-tab-group>
            
        </div>

    </div>

    <ng-template #NoParticipants>
        <div fxLayoutAlign="center center" style="text-align: center; margin: 20px;">
            <app-error-message customClass="warning"
                [errorTitle]="'There arent any participants' | translate"
                [errorContent]="'There are no participants in this master' | translate" 
                [center]="false">
            </app-error-message>
        </div>
    </ng-template>

    <ng-template #NoLessons>
        <div fxLayoutAlign="center center" style="text-align: center; margin: 20px;">
            <app-error-message customClass="warning"
                [errorTitle]="'There arent any lessons' | translate"
                [errorContent]="'There are no lessons in this master' | translate"
                [center]="false">
            </app-error-message>
        </div>
    </ng-template>

    <ng-template #masterTeachers>
        <mat-card appearance="outlined" fxFlex class="tab-container">
            <!--mat-card-header>
                <mat-card-title>
                    {{ 'Teachers' | translate }}
                </mat-card-title>
            </mat-card-header-->
            <mat-card-content>
                <table mat-table [dataSource]="teachers" matSort #teachersSort="matSort" style="width: 100%;">

                    <ng-container matColumnDef="participant">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Teacher' | translate }}</th>
                        <td mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="start center"
                            fxLayoutGap="10px" style="padding: 5px">
                            <div>
                                <app-profile-picture [url]="element.user.profilePictureUrl">
                                </app-profile-picture>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="center start">
                                <span style="font-weight: bold; cursor: pointer;" 
                                    *ngIf="element.userId != master?.idAuthor"
                                    (click)="goToAuthorProfile(element.userId)">
                                    {{ element.user.name + ' ' + element.user.surname }}
                                </span>
                                <span style="font-weight: bold; cursor: pointer;" [style.color]="darkService.isSetDark ? '#00acc1' : 'firebrick'"
                                    *ngIf="element.userId === master?.idAuthor"
                                    (click)="goToAuthorProfile(element.userId)">
                                    {{ element.user.name + ' ' + element.user.surname }}
                                </span>
                                <span style="color: gray; font-size: 10px;">
                                    {{ (element.userId === master?.idAuthor ? 'Organizer' : 'Teacher') | translate }}
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Joined' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.created | date: 'dd/MM/yyyy' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Customer' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.user.customer.name }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="teachersCol"></tr>
                    <tr mat-row *matRowDef="let row; columns: teachersCol;"></tr>

                </table>
                <mat-paginator #teachersPag
                    [length]="teachers?.data.length ?? 0"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 20, 50]">
                </mat-paginator>
            </mat-card-content>
            <mat-card-actions fxLayout="row" fxLayoutAlign="start end">
                <button mat-fab color="accent"
                    *ngIf="master &&
                           currentUser.id === master.idAuthor &&
                           currentUser.mastersEnabled &&
                           !isDeleted()"
                    (click)="addTeachers()"
                    [disabled]="master.state === getMasterState().finished"
                    [matTooltip]="'Add teachers' | translate">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
    
    <ng-template #masterStudents>
        <mat-card appearance="outlined" fxFlex class="tab-container">
            <!--mat-card-header>
                <mat-card-title>
                    {{ 'Participants' | translate }}
                </mat-card-title>
            </mat-card-header-->
            <mat-card-content>
                <table mat-table [dataSource]="students" matSort #studentsSort="matSort" style="width: 100%;">

                    <ng-container matColumnDef="participant">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Student' | role | translate }}</th>
                        <td mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 5px">
                            <div>
                                <app-profile-picture [url]="element.user.profilePictureUrl">
                                </app-profile-picture>
                            </div>

                            <span>{{ element.user.name + ' ' + element.user.surname }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Joined' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.created | date: 'dd/MM/yyyy' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Customer' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.user.customer.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            <div fxlayout="row" fxLayoutAlign="start center">
                                <mat-icon [matTooltip]="(element.user.havePaid ? 'Paid' : 'Booked') | translate">
                                    {{ element.user.havePaid ? 'payments' : 'bookmark_added' }}
                                </mat-icon>

                                <button mat-icon-button
                                    *ngIf="currentUser.id === master.idAuthor && !element.user.havePaid && master.state === getMasterState().planned"
                                    (click)="addUserAsPayed(element.userId)"
                                    [matTooltip]="'Set as paid' | translate"
                                    style="margin-left: 5px;">
                                    <mat-icon>
                                        payment
                                    </mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="studentsCol"></tr>
                    <tr mat-row *matRowDef="let row; columns: studentsCol;"></tr>

                </table>
                <mat-paginator #studentsPag
                    [length]="students?.data.length ?? 0"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 20, 50]">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </ng-template>

</div>

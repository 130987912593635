import { TranslationEdit } from "./dto/translationDTO";

export class CourseEdit {
    public Name: string;
    public Description: string;
    public Header: string;
    public Footer: string;
    public Summary: string;
    public IdAuthor: number;
    public Visibility: number;
    public mode: number;
    public ImageUrl: string;
    public Language: string;
    public SendNotification: number;
    public State: number;
    public ClassIds: number[];
    public NameTranslation: TranslationEdit;
    public DescriptionTranslation: TranslationEdit;
    public HeaderTranslation: TranslationEdit;
    public FooterTranslation: TranslationEdit;
    public SummaryTranslation: TranslationEdit;
}

<mat-card appearance="outlined">

    <mat-card-header class="row-arrange center-items" style="justify-content: space-between">
        <mat-card-title>
            {{ (userType === 'presenters' ? 'Presenters' : userType === 'participants' ? 'Participants' : 'Users') | translate }} ({{ participantsAggSource.data.length }})
        </mat-card-title>
        <button mat-fab color="primary"
            (click)="saveToCsv()"
            [matTooltip]="'Save csv' | translate">
            <mat-icon>save</mat-icon>
        </button>
    </mat-card-header>

    <mat-divider style="margin-top: 7px"></mat-divider>
    
    <div class="table-container">
        <table mat-table matSort #participantsAggSort="matSort" [dataSource]="participantsAggSource" style="width: 100%">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
            </ng-container>

            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.user.surname }}</td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Start' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.startDate | date: 'HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="stopDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'End' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.stopDate | date: 'HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="totalTimeInLessons">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Presence Time' | translate }} (hh:mm:ss)</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.totalTimeInLessons | slice:0:8 }}
                    <mat-progress-bar mode="determinate"
                        [value]="getPercentage(element.lessonsRange.max, element.lessonsRange.min, element.totalTimeInLessons)">
                    </mat-progress-bar>
                </td>
            </ng-container>

            <ng-container matColumnDef="totalSessions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Sessions' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.totalSessions }}</td>
            </ng-container>

            <ng-container matColumnDef="downlinkMin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Downlink min</th>
                <td mat-cell *matCellDef="let element">{{ element.downlinkMin ? (element.downlinkMin | number: '1.0-2') + ' Mbps' : '-'  }}</td>
            </ng-container>

            <ng-container matColumnDef="downlinkAvg">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Downlink avg</th>
                <td mat-cell *matCellDef="let element">{{ element.downlinkAvg ? (element.downlinkAvg | number: '1.0-2') + ' Mbps' : '-'  }}</td>
            </ng-container>

            <ng-container matColumnDef="rttMin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Rtt min</th>
                <td mat-cell *matCellDef="let element">{{ element.rttMin ? element.rttMin + ' ms' : '-'  }}</td>
            </ng-container>

            <ng-container matColumnDef="rttAvg">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Rtt avg</th>
                <td mat-cell *matCellDef="let element">{{ element.rttAvg ? element.rttAvg + ' ms' : '-'  }}</td>
            </ng-container>

            <ng-container matColumnDef="effectiveTypeMin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Classification' | translate }} min</th>
                <td mat-cell *matCellDef="let element">{{ element.effectiveTypeMin }}</td>
            </ng-container>

            <ng-container matColumnDef="effectiveTypeAvg">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Classification' | translate }} avg</th>
                <td mat-cell *matCellDef="let element">{{ element.effectiveTypeAvg }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="participantsAggColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: participantsAggColumns"></tr>

            <tr class="mat-row" style="height: 40px" *matNoDataRow>
                <td class="mat-cell" [colSpan]="participantsAggColumns.length" style="vertical-align: middle">
                    <span style="margin: 10px">
                        {{ 'No data' | translate }}
                    </span>
                </td>
            </tr>

        </table>
    </div>

</mat-card>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SocialWallBlogDTO } from 'src/app/models/dto/SocialWallBlogDTO';
import { WallService } from '../service/wall.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import linkifyHtml from 'linkify-html';

@Component({
  selector: 'app-wall-blog-popup',
  templateUrl: './wall-blog-popup.component.html',
  styleUrls: ['./wall-blog-popup.component.scss']
})
export class WallBlogPopupComponent implements OnInit {

  comment: FormControl<string> = new FormControl<string>(undefined, [Validators.required]);

  blog: SocialWallBlogDTO  = null;
  mode: 'edit' | 'reply' = undefined;
  title: string = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private wallService: WallService,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<WallBlogPopupComponent>
  ) { }

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.blog = this.data.blog;

    if (this.mode === 'edit' && this.blog != undefined)
      this.comment.setValue(this.blog.postContent);

    this.title = this.mode === 'reply'
               ? this.translate.instant('Reply to name', { name: `${this.blog.idauthorNavigation.name} ${this.blog.idauthorNavigation.surname}` })
               : this.translate.instant('Edit Comment');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  hasErrors() {
    return !this.comment.valid;
  }

  saveReply() {
    let socialWallBlogDTO = new SocialWallBlogDTO();

    socialWallBlogDTO.idauthor = this.auth.getCurrentUser()?.id;
    socialWallBlogDTO.idsocialWall = this.blog.idsocialWall;
    socialWallBlogDTO.idparent = this.blog.id;
    socialWallBlogDTO.postContent = linkifyHtml(this.comment.value, {
      defaultProtocol: 'https',
      target: "_blank"
    });

    this.wallService.postBlog(socialWallBlogDTO)
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: async err => {
          console.log(err);
          this.snackBar.open(await firstValueFrom(this.translate.get('Error adding comment')), undefined, { duration: 3000 });
        }
      });
  }

  saveEdit() {
    this.blog.postContent = this.comment.value;
      
    this.wallService.putBlog(this.blog.id, this.blog)
      .subscribe({
        next: async () => {
          this.dialogRef.close();
          this.snackBar.open(await firstValueFrom(this.translate.get('Comment edited')), undefined, { duration: 3000 });
        },
        error: async err => {
          console.log(err);
          this.snackBar.open(await firstValueFrom(this.translate.get('Error editing comment')), undefined, { duration: 3000 });
        }
      });
  }

}

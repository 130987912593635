import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SkillDTO } from 'src/app/models/dto/skillDTO';
import { SkillService } from 'src/app/services/skill.service';

@Component({
  selector: 'app-skill-dialog',
  templateUrl: './skill-dialog.component.html',
  styleUrls: ['./skill-dialog.component.scss'],
})
export class SkillDialogComponent implements OnInit {
  skillForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private skillService: SkillService,
    public dialogRef: MatDialogRef<SkillDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'create' | 'edit'; skill?: SkillDTO }
  ) {}

  ngOnInit(): void {
    this.skillForm = this.fb.group({
      name: [this.data.skill?.name || '', Validators.required],
      description: [this.data.skill?.description || '', Validators.required],
    });
  }

  save(): void {
    if (this.skillForm.valid) {
      const skillData = { ...this.data.skill, ...this.skillForm.value };
      if (this.data.mode === 'create') {
        this.skillService.createSkill(skillData).subscribe((createdSkill) => {
          this.dialogRef.close(createdSkill);
        });
      } else {
        this.skillService.updateSkill(skillData).subscribe((updatedSkill) => {
          this.dialogRef.close(updatedSkill);
        });
      }
    }
  }
}

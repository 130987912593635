<h1 mat-dialog-title>{{ 'Edit Post' | translate }}</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="10px">
    <mat-form-field color="primary">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <input matInput type="text" [formControl]="title">
        <mat-error *ngIf="title.hasError('required')">
            {{ 'Title is required' | translate }}
          </mat-error>
    </mat-form-field>
    <mat-form-field color="primary">
        <mat-label>{{ 'Description' | translate }}</mat-label>
        <textarea matInput [formControl]="description" style="min-height: 100px;"></textarea>
        <mat-error *ngIf="description.hasError('required')">
            {{ 'Description is required' | translate }}
          </mat-error>
    </mat-form-field>
    <mat-form-field color="primary">
        <mat-label>{{ 'Header' | translate }}</mat-label>
        <input matInput type="text" [formControl]="header">
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
        <h4 style="margin: 0">{{ 'Contents' | translate }}</h4>
        <div>
            <!--button mat-icon-button (click)="addCourseLink()" [matTooltip]="'Add content' | translate">
                <mat-icon>school</mat-icon>
            </button-->
            <button mat-icon-button (click)="fileInput.click()" [matTooltip]="'Add pictures' | translate">
                <input style="display: none" type="file" #fileInput (change)="addFile($event, 1)" accept="image/*" />
                <mat-icon>add_a_photo</mat-icon>
            </button>
            <button mat-icon-button (click)="fileInputVideo.click()" [matTooltip]="'Add videos' | translate">
                <input style="display: none" type="file" #fileInputVideo (change)="addFile($event, 2)" accept="video/*" />
                <mat-icon>video_call</mat-icon>
            </button>
        </div>
    </div>
    <mat-list *ngIf="socialWallPostEdited.socialWallContents.length > 0" style="max-height: 300px; overflow: auto;">
        <mat-list-item *ngFor="let content of socialWallPostEdited.socialWallContents">
            <span matListItemTitle>{{ getFileName(content) }}</span>
            <button mat-icon-button color="warn"
                (click)="deleteContent(content)"
                [matTooltip]="'Delete' | translate"
                matListItemMeta>
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" 
        [disabled]="hasErrors()"
        (click)="saveEdit()">
        Ok
    </button>
</div>

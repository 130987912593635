import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DataPointType } from 'src/app/models/signalR/dataPointType';
import { SignalRService } from 'src/app/services/signalr.service';
import { SetPointType } from 'src/app/models/signalR/setPointType';
import { StationAccess } from 'src/app/models/signalR/stationAccess';

const DP_CHECK_INTERVAL: number = 1000; // ms
const MAX_DP_SET_TIME: number = 10000; //ms

@Component({
  selector: 'app-smily-dashboard',
  templateUrl: './smily-dashboard.component.html',
  styleUrls: ['./smily-dashboard.component.scss']
})
export class SmilyDashboardComponent implements OnInit, OnDestroy {

  private stationId: number = null;

  stationName: string = null;
  connUser: StationAccess = null;

  speedSp: number = null;
  speedSpUnit: string = null;
  speedSpSet: number = null;
  speedSpSetTime: number = null;
  speedMax: number = 0;
  speedMin: number = 0;

  torqueSp: number = null;
  torqueSpUnit: string = null;
  torqueSpSet: number = null;
  torqueSpSetTime: number = null;
  torqueMax: number = 0;
  torqueMin: number = 0;

  rotationDir: string = null;
  rotationDirSet: string = null;
  rotationDirSetTime: number = null;

  lightSp: string = null;
  lightSpSet: string = null;
  lightSpSetTime: number = null;

  lightInt: number = null;
  lightIntSet: number = null;
  lightIntSetTime: number = null;
  lightIntMin: number = 0;
  lightIntMax: number = 0;

  lightModes: string[] = [];

  checkInterval: any = null;

  constructor(private signalR: SignalRService,
              private route: ActivatedRoute,
              private location: Location) { }

  ngOnInit(): void {

    this.stationId = Number(this.route.snapshot.paramMap.get('id'));
    
    if (this.route.snapshot.queryParamMap.has('stationName'))
      this.stationName = this.route.snapshot.queryParamMap.get('stationName');

    this.signalR.requestAccess(this.stationId);

    this.signalR.setPointAck.subscribe(res => {

      switch(res.setPoint) {

        // Caso speciale, vedi signalr.service.ts
        // Utilizzare sempre SetPointType per ogni altra richiesta
        case 'access':

          let data: StationAccess = JSON.parse(res.value);

          if (data.stationId === this.stationId && res.valueSet) {
            this.connUser = data;

            this.signalR.getDataPoint(this.stationId, DataPointType.speedMax);
            this.signalR.getDataPoint(this.stationId, DataPointType.speedMin);
            this.signalR.getDataPoint(this.stationId, DataPointType.torqueMax);
            this.signalR.getDataPoint(this.stationId, DataPointType.torqueMin);
            this.signalR.getDataPoint(this.stationId, DataPointType.lightIntMax);
            this.signalR.getDataPoint(this.stationId, DataPointType.lightIntMin);
            this.signalR.getDataPoint(this.stationId, DataPointType.lightModes);
          } else {
            this.connUser = null;
          }

          break;

        case SetPointType.speed:
          
          if (res.valueSet && Number(res.value) === this.speedSpSet)
            this.speedSpSetTime = null;
            
          break;

        case SetPointType.torque:
          
          if (res.valueSet && Number(res.value) === this.torqueSpSet)
            this.torqueSpSetTime = null;

          break;

        case SetPointType.direction:
          
          if (res.valueSet && res.value === this.rotationDirSet)
            this.rotationDirSetTime = null;

          break;

        case SetPointType.light:
          
          if (res.valueSet && res.value === this.lightSpSet)
            this.lightSpSetTime = null;

          break;

        case SetPointType.lightIntensity:
          
          if (res.valueSet && Number(res.value) === this.lightIntSet)
            this.lightIntSetTime = null;

          break;

        default:
          break;

      }

    });

    this.signalR.dataPoints.subscribe(res => {

      switch(res.dataPoint) {

        case DataPointType.speedSp:
          this.speedSp = Number(res.value);

          if (this.speedSpSetTime >= MAX_DP_SET_TIME ||
              this.speedSpSet == this.speedSp)
            this.speedSpSetTime = null;

          if (this.speedSpSetTime == null)
            this.speedSpSet = this.speedSp;
            
          break;

        case DataPointType.torqueSp:
          this.torqueSp = Number(res.value);

          if (this.torqueSpSetTime >= MAX_DP_SET_TIME ||
              this.torqueSpSet == this.torqueSp)
            this.torqueSpSetTime = null;

          if (this.torqueSpSetTime == null)
            this.torqueSpSet = this.torqueSp;

          break;

        case DataPointType.directionSp:
          this.rotationDir = res.value;

          if (this.rotationDirSetTime >= MAX_DP_SET_TIME ||
              this.rotationDirSet == this.rotationDir)
            this.rotationDirSetTime = null;

          if (this.rotationDirSetTime == null)
            this.rotationDirSet = this.rotationDir;

          break;

        case DataPointType.lightSp:
          this.lightSp = res.value;

          if (this.lightSpSetTime >= MAX_DP_SET_TIME ||
              this.lightSpSet == this.lightSp)
            this.lightSpSetTime = null;

          if (this.lightSpSetTime == null)
            this.lightSpSet = this.lightSp;

          break;

        case DataPointType.lightIntensity:
          this.lightInt = Number(res.value);

          if (this.lightIntSetTime >= MAX_DP_SET_TIME ||
              this.lightIntSet == this.lightInt)
            this.lightIntSetTime = null;

          if (this.lightIntSetTime == null)
            this.lightIntSet = this.lightInt;

          break;

        case DataPointType.speedMax:
          this.speedMax = Number(res.value);
          this.speedSpUnit = res.unit;
          break;

        case DataPointType.speedMin:
          this.speedMin = Number(res.value);
          break;
          
        case DataPointType.torqueMax:
          this.torqueMax = Number(res.value);
          this.torqueSpUnit = res.unit;
          break;

        case DataPointType.torqueMin:
          this.torqueMin = Number(res.value);
          break;

        case DataPointType.lightIntMax:
          this.lightIntMax = Number(res.value);
          break;

        case DataPointType.lightIntMin:
          this.lightIntMin = Number(res.value);
          break;

        case DataPointType.lightModes:
          this.lightModes = JSON.parse(res.value);
          break;

        default:
          break;
      }

    });

    this.updateDp();
    this.checkInterval = setInterval(() => this.updateDp(), DP_CHECK_INTERVAL);

  }

  ngOnDestroy(): void {
    this.signalR.revokeAccess(this.stationId);

    if (this.checkInterval != null)
      clearInterval(this.checkInterval);

    this.checkInterval = null;
  }

  updateDp(): void {
    this.signalR.getDataPoint(this.stationId, DataPointType.speedSp);
    this.signalR.getDataPoint(this.stationId, DataPointType.torqueSp);
    this.signalR.getDataPoint(this.stationId, DataPointType.directionSp);
    this.signalR.getDataPoint(this.stationId, DataPointType.lightSp);
    this.signalR.getDataPoint(this.stationId, DataPointType.lightIntensity);

    if (this.speedSpSetTime != null)
      this.speedSpSetTime += DP_CHECK_INTERVAL;

    if (this.torqueSpSetTime != null)
      this.torqueSpSetTime += DP_CHECK_INTERVAL;

    if (this.rotationDirSetTime != null)
      this.rotationDirSetTime += DP_CHECK_INTERVAL;

    if (this.lightSpSetTime != null)
      this.lightSpSetTime += DP_CHECK_INTERVAL;

    if (this.lightIntSetTime != null)
      this.lightIntSetTime += DP_CHECK_INTERVAL;
  }

  async setDataPoint(setPoint: SetPointType, $event: any) {

    switch(setPoint) {

      case SetPointType.speed:
        this.speedSpSetTime = 0;
        break;

      case SetPointType.torque:
        this.torqueSpSetTime = 0;
        break;

      case SetPointType.direction:
        this.rotationDirSetTime = 0;
        break;

      case SetPointType.light:
        this.lightSpSetTime = 0;
        break;

      case SetPointType.lightIntensity:
        this.lightIntSetTime = 0;
        break;

      default:
        break;
    }

    console.warn(`Setpoint ${setPoint} value set to ${$event.value}`);

    await this.signalR.setDataPoint(this.stationId, setPoint, String($event.value));
  }

  getSps() {
    return SetPointType;
  }

  goBack() {
    this.location.back();
  }

  getRotationDirString(direction: string) {
    return direction === 'CW' ? 'Clockwise' : 'Counter clockwise';
  }

}

<h1 mat-dialog-title>{{ 'Smily deactivation' | translate }}</h1>
<mat-dialog-content fxLayout="column" fxLayoutGap="20px">
    <mat-form-field fxFlex>
        <input matInput
            [placeholder]="'username' | translate"
            [formControl]="username"
            required>
            <mat-error *ngIf="username.hasError('required')">
                {{ 'Username is required' | translate }}
            </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
        <input matInput
            placeholder="Password"
            [formControl]="password"
            type="password"
            required>
            <mat-error *ngIf="password.hasError('required')">
                {{ 'Password is required' | translate }} 
            </mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button
        color="accent"
        (click)="deactivate()"
        [disabled]="!username.valid || !password.valid">
        {{ 'Deactivate' | translate }}
    </button>
</mat-dialog-actions>

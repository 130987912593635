import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CourseContentEdit } from 'src/app/models/courseContentEdit';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AzureStorageService } from 'src/app/services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { SubtitlesLanguages } from 'src/app/models/subtitlesLanguages';
import { Helper } from 'src/app/helpers/helper';

const VIDEO_ACCEPT = 'video/*,.mkv';
const PDF_ACCEPT = '.pdf';
const ZIP_ACCEPT = '.zip';
const AUDIO_ACCEPT = 'audio/*';

@Component({
  selector: 'app-content-add',
  templateUrl: './content-add.component.html',
  styleUrls: ['./content-add.component.scss']
})
export class ContentAddComponent implements OnInit {

  currentUser: User = null;
  sending: boolean = false;
  contentToEdit: CourseContentDTO = null;
  currentType: number = 0;
  type: 'standard' | 'scorm' | 'folder' = 'standard';
  subtitles: boolean = false;
  languages: { code: string, label: string }[] = [];
  //customOrder: boolean = false;
  title: string = undefined;

  idCourse: number = null;
  mediaSrc: FormControl = new FormControl(undefined, [Validators.required]);
  mediaLanguage: FormControl = new FormControl(undefined);
  thumbnailSrc: FormControl = new FormControl(undefined);
  name: FormControl = new FormControl('', [Validators.required, Validators.minLength(4)]);
  description: FormControl = new FormControl(null);
  order: FormControl = new FormControl(1, [Validators.required]);
  download: FormControl = new FormControl(false);
  subtitlesLanguages: FormControl = new FormControl([]);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;

  constructor(public dialogRef: MatDialogRef<ContentAddComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private auth: AuthService,
              public snackBar: MatSnackBar,
              private azureService: AzureStorageService,
              private pdfViewerService: NgxExtendedPdfViewerService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.idCourse = this.data.idCourse;

    Object
      .entries(SubtitlesLanguages)
      .forEach(value => {

        this.languages.push({
          code: value[1],
          label: value[0]
        });

    });
    
    this.mediaLanguage.setValue(this.languages[0].code);
    this.order.setValue(this.data.mode === 1 ? 1 : 3);

    if (this.data.type)
      this.type = this.data.type;

    //if (this.data.customOrder)
    //  this.customOrder = this.data.customOrder;

    if (this.data.lastIndex && this.data.mode === 1)
      this.order.setValue(this.data.lastIndex + 1);

    if (this.data.content != null) {
      this.contentToEdit = this.data.content;
      this.currentType = this.contentToEdit.type;

      this.type = this.contentToEdit.type === 100
                ? 'folder'
                : this.contentToEdit.type === 4
                ? 'scorm'
                : 'standard';

      this.order.setValue(this.contentToEdit.orderIndex);
      this.download.setValue(this.contentToEdit.downloadable == 1 ? true : false);

      if (this.contentToEdit.nameTranslation)
        this.name.setValue(this.contentToEdit.nameTranslation[this.currentUser.defaultLanguage]);

      if (this.contentToEdit.descriptionTranslation)
        this.description.setValue(this.contentToEdit.descriptionTranslation[this.currentUser.defaultLanguage]);

      this.nameTranslation = this.contentToEdit.nameTranslation;
      this.descriptionTranslation = this.contentToEdit.descriptionTranslation;
      
      this.subtitles = !Helper.isNullOrEmpty(this.contentToEdit.mediaLanguage);

      if (this.subtitles) {
        this.mediaLanguage.setValue(this.contentToEdit.mediaLanguage);
        this.subtitlesLanguages.setValue(this.contentToEdit.mediaSubtitlesLanguages?.split(' ') ?? []);
      }
    }

    this.setTitle();
  }

  async saveContent() {
    this.toggleDisable(true);

    let content = new CourseContentEdit();

    content.Name = this.name.value;
    content.Description = this.description.value;
    content.IdAuthor = this.currentUser.id;
    content.IdCourse = this.idCourse;
    content.Type = this.contentToEdit ? this.contentToEdit.type : 0;
    content.OrderIndex = this.order.value;
    content.Downloadable = this.download.value ? 1 : 0;
    content.NameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    content.DescriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);

    if (this.subtitles) {
      content.MediaLanguage = this.mediaLanguage.value;
      content.SubtitlesLanguages = this.subtitlesLanguages.value;
    }

    if (this.type === 'folder') {

      content.Type = 100;
      content.Duration = 0;

    } else if (this.mediaSrc.value) {

      content.Type = this.type === 'scorm' ? 4 : Helper.getFileExtensionCode(this.mediaSrc.value, 'coursecontent');
      content.Duration = await this.getFileDuration(this.mediaSrc.value, content.Type);

    }

    if (!this.contentToEdit) {

      if (this.mediaSrc.value) {

        this.azureService.postContent(content, this.mediaSrc.value, await this.getThumbnail(content.Type));
        this.onNoClick();

      } else {

        let id = this.type === 'folder'
               ? await this.azureService.postContentFolder(content, await this.getThumbnail(content.Type))
               : await this.azureService.postContent(content, null, await this.getThumbnail(content.Type));

        this.dialogRef.close(id);

      }

      this.snackBar.open('Adding content...', 'Dismiss', { duration: 3000 });

    } else {

      if (this.mediaSrc.value) {

        this.azureService.putContent(this.contentToEdit.id, content, this.mediaSrc.value, await this.getThumbnail(content.Type));
        this.onNoClick();

      } else {

        await this.azureService.putContent(this.contentToEdit.id, content, null, await this.getThumbnail(content.Type));
        this.dialogRef.close(true);

      }

      this.snackBar.open('Editing content...', 'Dismiss', { duration: 3000 });

    }

    this.toggleDisable(false);
  }

  async getThumbnail(type?: number) {
    if (this.thumbnailSrc.value)
      return this.thumbnailSrc.value;
    
    if (type === 2 && this.mediaSrc.value) {
      let base64 = await this.pdfViewerService.getPageAsImage(1, { width: 800 });
      return await fetch(base64).then(r => r.blob());
    }

    return null;
  }

  mediaAccept() {
    this.currentType = Helper.getFileExtensionCode(this.mediaSrc.value, 'coursecontent');
  }

  mediaDelete() {
    this.currentType = 0;
    this.thumbnailSrc.reset();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if(toggle) {
      this.name.disable();
      this.description.disable();
      this.order.disable();
      this.mediaSrc.disable();
      this.thumbnailSrc.disable();
      this.download.disable();
    } else {
      this.name.enable();
      this.description.enable();
      this.order.enable();
      this.mediaSrc.enable();
      this.thumbnailSrc.enable();
      this.download.enable();
    }
  }

  async getFileDuration(file: File, type: number): Promise<number> {
    if (!file)
      return 0;

    try {

      if (type === 1)
        return await Helper.getVideoDuration(file);
  
      if (type === 2)
        return this.pdfViewerService.numberOfPages();
  
      if (type === 5)
        return await Helper.getAudioDuration(file);

    } catch (e) {
      console.error(e);

      return 0;
    }
  }

  getAcceptedFiles(): string {
    if (this.type === 'scorm')
      return `${VIDEO_ACCEPT},${PDF_ACCEPT},${ZIP_ACCEPT}`;

    if (this.contentToEdit) {

      if (this.contentToEdit.type === 1)
        return VIDEO_ACCEPT;
      
      if (this.contentToEdit.type === 2)
        return PDF_ACCEPT;

      if (this.contentToEdit.type === 5)
        return AUDIO_ACCEPT;
        
    }

    return `${VIDEO_ACCEPT},${PDF_ACCEPT},${AUDIO_ACCEPT}`;
  }

  getBtnDisabled() {
    let check = !this.name.valid ||
                !this.order.valid ||
                this.sending;

    if (!this.contentToEdit && this.type !== 'folder')
      check = check ||
              !this.mediaSrc.valid;

    return check;
  }

  private setTitle() {
    if (this.contentToEdit)
      this.title = this.type === 'folder'
                 ? 'Edit Training Course Folder'
                 : 'Edit Training Course Content';

    else
      this.title = this.type === 'folder'
                 ? 'Add Training Course Folder'
                 : 'Add Training Course Content';
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { SubscriptionLevelDTO } from 'src/app/models/dto/subscriptionLevelDTO';
import { AzureStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-add-subscription-level',
  templateUrl: './add-subscription-level.component.html',
  styleUrls: ['./add-subscription-level.component.scss']
})
export class AddSubscriptionLevelComponent implements OnInit {

  sending: boolean = false;
  subscriptionToEdit: SubscriptionLevelDTO = null;

  nameKey: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(2)]);
  name: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(2)]);
  description: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
  summary: UntypedFormControl = new UntypedFormControl(null);
  header: UntypedFormControl = new UntypedFormControl(null);
  footer: UntypedFormControl = new UntypedFormControl(null);
  maxUsers: UntypedFormControl = new UntypedFormControl(10, [Validators.required, Validators.min(5)]);
  maxStorage: UntypedFormControl = new UntypedFormControl(10, [Validators.required, Validators.min(10)]);
  imageSrc: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);

  constructor(public dialogRef: MatDialogRef<AddSubscriptionLevelComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private snackBar: MatSnackBar,
              private subscriptionService: SubscriptionService,
              private azureService: AzureStorageService) { }

  ngOnInit(): void {

    if (this.data != null) {
      this.subscriptionToEdit = this.data.subscriptionLevel;

      this.nameKey.setValue(this.subscriptionToEdit.nameKey);
      this.name.setValue(this.subscriptionToEdit.name);
      this.description.setValue(this.subscriptionToEdit.description);
      this.summary.setValue(this.subscriptionToEdit.summary);
      this.header.setValue(this.subscriptionToEdit.header);
      this.footer.setValue(this.subscriptionToEdit.footer);
      this.maxUsers.setValue(this.subscriptionToEdit.userNoMax);
      this.maxStorage.setValue(this.subscriptionToEdit.storageMax);
    }

  }

  async saveSubscriptionLevel() {
    this.toggleDisable(true);

    let newSubscriptionLevel = new SubscriptionLevelDTO();

    newSubscriptionLevel.nameKey = this.nameKey.value;
    newSubscriptionLevel.name = this.name.value;
    newSubscriptionLevel.description = this.description.value;
    newSubscriptionLevel.summary = this.summary.value;
    newSubscriptionLevel.header = this.header.value;
    newSubscriptionLevel.footer = this.footer.value;
    newSubscriptionLevel.userNoMax = this.maxUsers.value;
    newSubscriptionLevel.storageMax = this.maxStorage.value;
    newSubscriptionLevel.imageUrl = await this.azureService.uploadFile(this.imageSrc.value);

    if (this.subscriptionToEdit == null) {
      this.subscriptionService.addSubscriptionLevel(newSubscriptionLevel)
        .subscribe({
          next: () => {
            this.snackBar.open('Subscription added successfully', 'Dismiss', { duration: 3000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
            this.toggleDisable(false);
          }
        });
    } else {
      newSubscriptionLevel.authoring = this.subscriptionToEdit.authoring;

      this.subscriptionService.editSubscriptionLevel(this.subscriptionToEdit.id, newSubscriptionLevel)
        .subscribe({
          next: () => {
            this.snackBar.open('Subscription edited successfully', 'Dismiss', { duration: 3000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
            this.toggleDisable(false);
          }
        });
    }

  }

  disabledOkBtn() {
    var check = !this.nameKey.valid ||
                !this.name.valid ||
                !this.description.valid ||
                !this.maxUsers.valid ||
                !this.maxStorage.valid ||
                this.sending;

    if (!this.subscriptionToEdit)
      return check || !this.imageSrc.valid;

    return check;
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if(toggle) {
      this.nameKey.disable();
      this.name.disable();
      this.description.disable();
      this.summary.disable();
      this.header.disable();
      this.footer.disable();
      this.maxUsers.disable();
      this.maxStorage.disable();
      this.imageSrc.disable();
    } else {
      this.nameKey.enable();
      this.name.enable();
      this.description.enable();
      this.summary.enable();
      this.header.enable();
      this.footer.enable();
      this.maxUsers.enable();
      this.maxStorage.enable();
      this.imageSrc.enable();
    }
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

import { TranslationEdit } from "./dto/translationDTO";

export class MasterEdit {
    public IdAuthor: number;
    public Name: string;
    public Description: string;
    public Header: string;
    public Footer: string;
    public Summary: string;
    public ImageUrl: string;
    public Language: string;
    public MaxParticipants: number;
    public Visibility: number;
    public State: number;
    //public videoPreviewUrl: string;
    //public document1Url: string;
    //public document2Url: string;
    //public document3Url: string;
    //public document4Url: string;
    //public document5Url: string;
    public type: number;
    public lessonsNo: number;
    public totalLessonsTime: number;
    public StartDate?: Date;
    public StopDate?: Date;
    public DurationInDays: number;
    public NameTranslation: TranslationEdit;
    public DescriptionTranslation: TranslationEdit;
    public HeaderTranslation: TranslationEdit;
    public FooterTranslation: TranslationEdit;
    public SummaryTranslation: TranslationEdit;
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VirtualRoomDTO } from 'src/app/models/dto/virtualRoomDTO';
import { firstValueFrom } from 'rxjs';
import { ConferenceService } from 'src/app/services/conference.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignalUserRoom } from 'src/app/models/conference-session/signalUserRoom';

@Component({
  selector: 'app-rooms-toolbar',
  templateUrl: './rooms-toolbar.component.html',
  styleUrls: ['./rooms-toolbar.component.scss']
})
export class RoomsToolbarComponent {

  @Input()
  overlay: boolean = true;

  @Input()
  rooms: VirtualRoomDTO[] = [];

  @Input()
  currentUserId: number;

  @Input()
  currentRoomId: number;

  @Input()
  conferenceId: number;

  @Input()
  selectEnabled: boolean = false;

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onSelect: EventEmitter<VirtualRoomDTO> = new EventEmitter<VirtualRoomDTO>();

  @Output() 
  onJoin: EventEmitter<SignalUserRoom> = new EventEmitter<SignalUserRoom>();

  constructor(private conferenceService: ConferenceService,
              private translate: TranslateService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void { }

  async createRoom(roomId: number) {
    await firstValueFrom(this.conferenceService.createConferenceRoom(this.conferenceId, roomId));
  }

  async joinRoom(roomId: number) {

    try {

      await this.createRoom(roomId);

      this.onJoin.emit({ virtualRoomId: roomId, action: 'add' });

      this.snackBar.open(await firstValueFrom(this.translate.get('Room joined')), 'Dismiss', { duration: 5000 });

    } catch (error) {
      console.error(error);

      this.snackBar.open(await firstValueFrom(this.translate.get('Error joining room')), 'Dismiss', { duration: 5000 });
    }

  }

}

<h1 mat-dialog-title>{{ ((isEdit ? 'Edit' : 'Add') | translate) + ' Smily' }}</h1>
<div mat-dialog-content>

    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput [formControl]="name" required>
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <input matInput [formControl]="description" required>
            <mat-error *ngIf="description.hasError('required')">
                {{ 'Description is required' | translate }}
            </mat-error>
            <mat-error *ngIf="description.hasError('minlength')">
                {{ 'Description should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!isEdit">
        <mat-form-field color="primary">
            <mat-label>{{ 'Room' | translate }}</mat-label>
            <mat-select [formControl]="room" required>
                <mat-option *ngFor="let r of rooms" [value]="r.id">{{ r.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-form-field color="primary">
            <mat-label>{{ 'Row' | translate }}</mat-label>
            <mat-select [formControl]="row" required>
                <mat-option *ngFor="let r of rows" [value]="r">{{ "row" | translate }} {{ r }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'Column' | translate }}</mat-label>
            <mat-select [formControl]="column" required>
                <mat-option *ngFor="let c of columns" [value]="c">{{ "column" | translate }} {{ c }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'Position' | translate }}</mat-label>
            <mat-select [formControl]="position" required>
                <mat-option *ngFor="let pos of positions" [value]="pos.value">{{ pos.description | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="saveStation()" [disabled]="btnDisabled()" color="accent">{{ "Ok" | translate }}</button>
</div>

<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <mat-form-field color="primary">
        <mat-label>{{ 'Comment' | translate }}</mat-label>
        <textarea matInput [formControl]="comment"></textarea>
        <mat-error *ngIf="comment.hasError('required')">
            {{ 'Comment required' | translate }}
        </mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" 
        [disabled]="hasErrors()"
        (click)="this.mode === 'reply' ? saveReply() : saveEdit()">
        Ok
    </button>
</div>

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Chart } from 'chart.js';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomerDTO, CustomerStorage } from 'src/app/models/dto/customerDTO';
import { CustomerResultDTO } from 'src/app/models/dto/customerResultDTO';
import { CsvExporterService } from 'src/app/services/csv-exporter.service';
import { CustomerStorageSizeTrackerDTO } from 'src/app/models/dto/customerStorageSizeTrackerDTO';
import { lessonResultsTotalDTO } from 'src/app/models/dto/lessonResultsTotalDTO';
import { Helper } from 'src/app/helpers/helper';

const PUBLISHED_STREAMING_CHART_ID: string = "PublishedStreamingChart"
const PUBLISHED_STREAMING_CHART_LABEL1: string = "Total published streaming time";
const PUBLISHED_STREAMING_CHART_LABEL2: string = "Total streaming time";
const PUBLISHED_STREAMING_CHART_COLOR1: string = "#5d9cec";
const PUBLISHED_STREAMING_CHART_COLOR2: string = "#ed5565";

const ACTIVE_USERS_CHART_ID: string = "ActiveUsersChart";
const ACTIVE_USERS_CHART_LABEL1: string = "Active users";
const ACTIVE_USERS_CHART_LABEL2: string = "Registered users";
const ACTIVE_USERS_CHART_COLOR1: string = "#a0d468";
const ACTIVE_USERS_CHART_COLOR2: string = "#ffce54";

const ONLINE_TIME_CHART_ID: string = "OnlineTimeChart";
const ONLINE_TIME_CHART_LABEL1: string = "Total online time";
const ONLINE_TIME_CHART_COLOR1: string = "#ac92ec";

const WEBINAR_PERFORMED_CHART_ID: string = "WebinarPerformedChart";
const WEBINAR_PERFORMED_CHART_LABEL1: string = "Webinar performed";
const WEBINAR_PERFORMED_CHART_LABEL2: string = "Total streaming time";
const WEBINAR_PERFORMED_CHART_LABEL3: string = "Total Participants";
const WEBINAR_PERFORMED_CHART_COLOR1: string = "#48cfad";
const WEBINAR_PERFORMED_CHART_COLOR2: string = "#4fc1e9";
const WEBINAR_PERFORMED_CHART_COLOR3: string = "#fc6e51";

@Component({
  selector: 'app-dashboard-customers',
  templateUrl: './dashboard-customers.component.html',
  styleUrls: ['./dashboard-customers.component.scss']
})
export class DashboardCustomersComponent implements OnInit {

  currentUser: User;
  currentDay = new Date().getDate();
  currentMonth = new Date().getMonth() + 1;
  currentYear = new Date().getFullYear();

  years: number[] = [
    this.currentYear,
    this.currentYear - 1,
    this.currentYear - 2,
    this.currentYear - 3,
    this.currentYear - 4,
    this.currentYear - 5
  ];
  months: { name: string, value: number }[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  customers: CustomerDTO[] = [];
  
  yearDate: FormControl<number> = new FormControl<number>(this.currentYear);
  monthDate: FormControl<number> = new FormControl<number>(this.currentMonth);
  customerForm: FormControl<number> = new FormControl<number>(undefined);
  customerSearchForm: FormControl<string> = new FormControl<string>(undefined);
  allCustomersView: FormControl<boolean> = new FormControl<boolean>(false);

  webinarResultsOfCustomer: lessonResultsTotalDTO[] = [];
  webinarResultStreamingTimeOfCustomerPerMonth: number = 0;
  webinarResultParticipantsOfCustomerPerMonth: number = 0;
  webinarResultNumberOfCustomerPerMonth: number = 0;

  webinarResultsOfAll: lessonResultsTotalDTO[] = [];
  webinarResultStreamingTimeOfAllPerMonth: number = 0;
  webinarResultParticipantsOfAllPerMonth: number = 0;
  webinarResultNumberOfAllPerMonth: number = 0;

  lessonResultsOfCustomer: lessonResultsTotalDTO[] = [];
  lessonResultsPerformedOfCustomerPerMonth: number = 0;
  lessonResultsStreamingTimeOfCustomerPerMonth: number = 0;

  lessonResultsOfAll: lessonResultsTotalDTO[] = [];
  lessonResultsPerformedOfAllPerMonth: number = 0;
  lessonResultsStreamingTimeOfAllPerMonth: number = 0;

  presentersOfWebinarOfCustomerPerMonth: number = 0;
  presentersOfWebinarOfAllPerMonth: number = 0;

  registeredUsersOfCustomerPerMonth: number = 0;
  registeredUsersOfCustomerPerYear: number[] = [];

  registeredUsersOfAllPerMonth: number = 0;
  registeredUsersOfAllPerYear: number[] = [];  

  storageCurrentSizeOfCustomer: number = 0;
  storageMaxSizeOfCustomer: number = 0;
  storagePercentageOfCustomer: number = 0;

  storagesOfAll: CustomerStorage[] = [];
  storageCurrentSizeOfAll: number = 0;
  storageMaxSizeOfAll: number = 0;
  storagePercentageOfAll: number = 0;

  dailyCustomerStorageOfCustomer: CustomerStorageSizeTrackerDTO[] = [];
  dailyCustomerStorageMaxOfCustomer: CustomerStorageSizeTrackerDTO = undefined;

  dailyCustomersStorageOfAll: CustomerStorageSizeTrackerDTO[] = [];
  dailyCustomersStorageMaxOfAll: CustomerStorageSizeTrackerDTO = undefined;

  customerResultsOfCustomer: CustomerResultDTO[] = [];
  customerResultsOfAll: CustomerResultDTO[] = [];
  customerResultsLastUpdate: Date = undefined;

  publishedStreamingTimeOfAllPerMonth: number = 0;
  streamTimeOfAllPerMonth: number = 0;
  activeUsersOfAllPerMonth: number = 0;
  onlineTimeOfAllPerMonth: number = 0;
  averageDailyStorageOfAllPerMonth: number = 0;

  publishedStreamingTimeOfCustomerPerMonth: number = 0;
  streamTimeOfCustomerPerMonth: number = 0;
  activeUsersOfCustomerPerMonth: number = 0;
  onlineTimeOfCustomerPerMonth: number = 0;
  averageDailyStorageOfCustomerPerMonth: number = 0;

  publishedStreamingTimeOfAllPerYear: number[] = [];
  streamTimeOfAllPerYear: number[] = [];
  activeUsersOfAllPerYear: number[] = [];
  onlineTimeOfAllPerYear: number[] = [];

  publishedStreamingTimeOfCustomerPerYear: number[] = [];
  streamTimeOfCustomerPerYear: number[] = [];
  activeUsersOfCustomerPerYear: number[] = [];
  onlineTimeOfCustomerPerYear: number[] = [];

  webinarResultStreamingTimeOfAllPerYear: number[] = [];
  webinarResultParticipantsOfAllPerYear: number[] = [];
  webinarResultNumberOfAllPerYear: number[] = [];

  webinarResultStreamingTimeOfCustomerPerYear: number[] = [];
  webinarResultParticipantsOfCustomerPerYear: number[] = [];
  webinarResultNumberOfCustomerPerYear: number[] = [];

  publishedStreamingChart: Chart;
  activeUsersChart: Chart;
  onlineTimeChart: Chart;
  webinarPerformedChart: Chart;

  columnsToDisplay: string[] = this.months.map(m => m.name);

  constructor(private auth: AuthService,
              private darkService: DarkThemeService,
              private translate: TranslateService,
              private exporter: CsvExporterService,
              private calendar: CalendarService) { }

  async ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.customerForm.setValue(this.currentUser.idCustomer);

    if (this.currentUser.isAdmin)
      await this.getCustomers();
    
    this.getDataOfCustomer();
  }

  async getDataOfCustomer() {
    this.customerForm.enable();

    this.getPresentersOfPerformedWebinarOfCustomerPerMonth();
    this.getStorageOfCustomer();
    this.getRegisteredUsersOfCustomerPerMonth();
    await this.getDailyStorageOfCustomer();
    await this.getRegisteredUsersOfCustomerPerYear();
    await this.getCustomersResultsOfCustomer();
    await this.getLessonsDataOfCustomer();
    await this.getWebinarDataOfCustomer();

    this.filterData(undefined);
  }

  async getDataOfAll() {
    this.customerForm.disable();

    this.getPresentersOfPerformedWebinarOfAllPerMonth();
    this.getStoragesOfAll();
    this.getRegisteredUsersOfAllPerMonth();
    this.getDailyStorageOfAllPerMonth();
    await this.getRegisteredUsersOfAllPerYear();
    await this.getCustomersResultsOfAll();
    await this.getLessonsDataOfAll();
    await this.getWebinarDataOfAll();

    this.filterData(undefined);
  }

  async filterData(type?: 'month' | 'year') {
    
    if (this.allCustomersView.value) {

      if (type === 'month') {

        this.getPresentersOfPerformedWebinarOfAllPerMonth();
        this.getDailyStorageOfAllPerMonth();
        this.getRegisteredUsersOfAllPerMonth();

      }

      if (type === 'year') {

        this.getPresentersOfPerformedWebinarOfAllPerMonth();
        this.getDailyStorageOfAllPerMonth();
        this.getRegisteredUsersOfAllPerMonth();
        await this.getRegisteredUsersOfAllPerYear();

      }

      let customerResultOfYear = this.customerResultsOfAll.filter(m => m.year === this.yearDate.value);
      let customerResultsOfMonth = customerResultOfYear.filter(m => m.month === this.monthDate.value);

      this.publishedStreamingTimeOfAllPerMonth = customerResultsOfMonth.reduce((partialSum, a) => partialSum + a.totalPublishedStreamTime, 0);
      this.streamTimeOfAllPerMonth = customerResultsOfMonth.reduce((partialSum, a) => partialSum + a.totalStreamTime, 0);
      this.activeUsersOfAllPerMonth = customerResultsOfMonth.reduce((partialSum, a) => partialSum + a.activeUsers, 0);
      this.onlineTimeOfAllPerMonth = customerResultsOfMonth.reduce((partialSum, a) => partialSum + a.totalOnlineTime, 0);
      this.averageDailyStorageOfAllPerMonth = customerResultsOfMonth.reduce((partialSum, a) => partialSum + a.averageArchivedStorage, 0);

      let lessonResultOfYear = this.lessonResultsOfAll.filter(t => t.year === this.yearDate.value);
      let lessonResultOfMonth = lessonResultOfYear.filter(t => t.month === this.monthDate.value);

      this.lessonResultsPerformedOfAllPerMonth = lessonResultOfMonth.reduce((partialSum, a) => partialSum + a.lessonResultCount, 0);
      this.lessonResultsStreamingTimeOfAllPerMonth = lessonResultOfMonth.reduce((partialSum, a) => partialSum + a.totalStreamingTime, 0);

      let webinarResultOfYear = this.webinarResultsOfAll.filter(t => t.year === this.yearDate.value);
      let webinarResultOfMonth = webinarResultOfYear.filter(t => t.month === this.monthDate.value);

      this.webinarResultStreamingTimeOfAllPerMonth = webinarResultOfMonth.reduce((partialSum, a) => partialSum + a.totalStreamingTime, 0);
      this.webinarResultParticipantsOfAllPerMonth = webinarResultOfMonth.reduce((partialSum, a) => partialSum + a.totalParticipantsNo, 0);
      this.webinarResultNumberOfAllPerMonth = webinarResultOfMonth.reduce((partialSum, a) => partialSum + a.lessonResultCount, 0);

      this.publishedStreamingTimeOfAllPerYear = [];
      this.streamTimeOfAllPerYear = [];
      this.activeUsersOfAllPerYear = [];
      this.onlineTimeOfAllPerYear = [];

      this.webinarResultStreamingTimeOfAllPerYear = [];
      this.webinarResultParticipantsOfAllPerYear = [];
      this.webinarResultNumberOfAllPerYear = [];

      this.months.forEach(month => {

        let currentCustomerResult = customerResultOfYear.filter(c => c.month === month.value);

        this.publishedStreamingTimeOfAllPerYear.push(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalPublishedStreamTime, 0));
        this.streamTimeOfAllPerYear.push(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalStreamTime, 0));
        this.activeUsersOfAllPerYear.push(currentCustomerResult.reduce((partialSum, a) => partialSum + a.activeUsers, 0));
        this.onlineTimeOfAllPerYear.push(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalOnlineTime, 0));

        let currentWebinarResult = webinarResultOfYear.filter(c => c.month === month.value);

        this.webinarResultStreamingTimeOfAllPerYear.push(currentWebinarResult.reduce((partialSum, a) => partialSum + a.totalStreamingTime, 0));
        this.webinarResultParticipantsOfAllPerYear.push(currentWebinarResult.reduce((partialSum, a) => partialSum + a.totalParticipantsNo, 0));
        this.webinarResultNumberOfAllPerYear.push(currentWebinarResult.reduce((partialSum, a) => partialSum + a.lessonResultCount, 0));

      });

    } else {

      if (type === 'month') {

        this.getPresentersOfPerformedWebinarOfCustomerPerMonth();
        this.getRegisteredUsersOfCustomerPerMonth();
  
      }

      if (type === 'year') {
       
        this.getPresentersOfPerformedWebinarOfCustomerPerMonth();
        this.getRegisteredUsersOfCustomerPerMonth();
        await this.getRegisteredUsersOfCustomerPerYear();

      }

      this.dailyCustomerStorageMaxOfCustomer = this.dailyCustomerStorageOfCustomer
        .filter(s => s.year === this.yearDate.value && s.month === this.monthDate.value)
        .reduce((a, b) => a?.dailyArchivedStorage > b?.dailyArchivedStorage ? a : b, undefined);

      let customerResultOfYear = this.customerResultsOfCustomer.filter(m => m.year === this.yearDate.value);
      let customerResultsOfMonth = customerResultOfYear.find(m => m.month === this.monthDate.value);

      this.publishedStreamingTimeOfCustomerPerMonth = customerResultsOfMonth?.totalPublishedStreamTime ?? 0;
      this.streamTimeOfCustomerPerMonth = customerResultsOfMonth?.totalStreamTime ?? 0;
      this.activeUsersOfCustomerPerMonth = customerResultsOfMonth?.activeUsers ?? 0;
      this.onlineTimeOfCustomerPerMonth = customerResultsOfMonth?.totalOnlineTime ?? 0;
      this.averageDailyStorageOfCustomerPerMonth = customerResultsOfMonth?.averageArchivedStorage ?? 0;

      let lessonResultOfYear = this.lessonResultsOfCustomer.filter(t => t.year === this.yearDate.value);
      let lessonResultOfMonth = lessonResultOfYear.find(t => t.month === this.monthDate.value);

      this.lessonResultsPerformedOfCustomerPerMonth = lessonResultOfMonth?.lessonResultCount ?? 0;
      this.lessonResultsStreamingTimeOfCustomerPerMonth = lessonResultOfMonth?.totalStreamingTime ?? 0;

      let webinarResultOfYear = this.webinarResultsOfCustomer.filter(t => t.year === this.yearDate.value);
      let webinarResultOfMonth = webinarResultOfYear.find(t => t.month === this.monthDate.value);

      this.webinarResultStreamingTimeOfCustomerPerMonth = webinarResultOfMonth?.totalStreamingTime ?? 0;
      this.webinarResultParticipantsOfCustomerPerMonth = webinarResultOfMonth?.totalParticipantsNo ?? 0;
      this.webinarResultNumberOfCustomerPerMonth = webinarResultOfMonth?.lessonResultCount ?? 0;

      this.publishedStreamingTimeOfCustomerPerYear = [];
      this.streamTimeOfCustomerPerYear = [];
      this.activeUsersOfCustomerPerYear = [];
      this.onlineTimeOfCustomerPerYear = [];

      this.webinarResultStreamingTimeOfCustomerPerYear = [];
      this.webinarResultParticipantsOfCustomerPerYear = [];
      this.webinarResultNumberOfCustomerPerYear = [];

      this.months.forEach(month => {

        let currentCustomerResult = customerResultOfYear.find(c => c.month === month.value);

        this.publishedStreamingTimeOfCustomerPerYear.push(currentCustomerResult?.totalPublishedStreamTime ?? 0);
        this.streamTimeOfCustomerPerYear.push(currentCustomerResult?.totalStreamTime ?? 0);
        this.activeUsersOfCustomerPerYear.push(currentCustomerResult?.activeUsers ?? 0);
        this.onlineTimeOfCustomerPerYear.push(currentCustomerResult?.totalOnlineTime ?? 0);

        let currentWebinarResult = webinarResultOfYear.find(c => c.month === month.value);

        this.webinarResultStreamingTimeOfCustomerPerYear.push(currentWebinarResult?.totalStreamingTime ?? 0);
        this.webinarResultParticipantsOfCustomerPerYear.push(currentWebinarResult?.totalParticipantsNo ?? 0);
        this.webinarResultNumberOfCustomerPerYear.push(currentWebinarResult?.lessonResultCount ?? 0);

      });

    }

    this.updatePublishedStreamingTimeChart();
    this.updateActiveUsersChart();
    this.updateOnlineTimeChart();
    this.updateWebinarPerformedChart();

  }

  exportTotalStreamingTime() {

    let exportedData: {
      year: number,
      month: number,
      totalStreamHours: string,
      totalPublishedStreamTimeHours: string
    }[] = [];
    
    this.months.forEach(month => {

      if (this.allCustomersView.value) {

        let currentCustomerResult =  this.customerResultsOfAll.filter(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          totalStreamHours: this.secondsToDhms(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalStreamTime, 0)),
          totalPublishedStreamTimeHours: this.secondsToDhms(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalPublishedStreamTime, 0))
        });

      } else {

        let currentCustomerResult = this.customerResultsOfCustomer.find(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          totalStreamHours: this.secondsToDhms(currentCustomerResult?.totalStreamTime ?? 0),
          totalPublishedStreamTimeHours: this.secondsToDhms(currentCustomerResult?.totalPublishedStreamTime ?? 0)
        });

      }

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "TotalStreamingTime",
        "TotalPublishedStreamingTime"
      ],
      this.allCustomersView.value ? 'Total Streaming Time of all customers' : `Total Streaming Time of ${this.currentCustomer().name}`
    );

  }

  exportTotalOnlineTime() {

    let exportedData: {
      year: number,
      month: number,
      hours: string
    }[] = [];
    
    this.months.forEach(month => {

      if (this.allCustomersView.value) {

        let currentCustomerResult = this.customerResultsOfAll.filter(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          hours: this.secondsToDhms(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalOnlineTime, 0))
        });

      } else {

        let currentCustomerResult = this.customerResultsOfCustomer.find(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          hours: this.secondsToDhms(currentCustomerResult?.totalOnlineTime ?? 0)
        });

      }

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "Time"
      ],
      this.allCustomersView.value ? 'Total Online Time of all customers' : `Total Online Time of ${this.currentCustomer().name}`
    );

  }

  exportWebinarData() {

    let exportedData: {
      year: number,
      month: number,
      webinarPerformed: number,
      streamingTime: string,
      participants: number
    }[] = [];
    
    this.months.forEach(month => {

      if (this.allCustomersView.value) {

        let currentWebinarResult = this.webinarResultsOfAll.filter(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          webinarPerformed: currentWebinarResult.reduce((partialSum, a) => partialSum + a.lessonResultCount, 0),
          streamingTime: this.secondsToDhms(currentWebinarResult.reduce((partialSum, a) => partialSum + a.totalStreamingTime, 0)),
          participants: currentWebinarResult.reduce((partialSum, a) => partialSum + a.totalParticipantsNo, 0)
        });

      } else {

        let currentWebinarResult = this.webinarResultsOfCustomer.find(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          webinarPerformed: currentWebinarResult?.lessonResultCount ?? 0,
          streamingTime: this.secondsToDhms(currentWebinarResult?.totalStreamingTime ?? 0),
          participants: currentWebinarResult?.totalParticipantsNo ?? 0
        });

      }

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "Webinar Performed",
        "Streaming Time",
        "Participants"
      ],
      this.allCustomersView.value ? 'Webinar Data of all customers' : `Webinar Data of ${this.currentCustomer().name}`
    );

  }

  exportActiveUsers() {

    let exportedData: {
      year: number,
      month: number,
      activeUsers: number,
      registeredUsers: number
    }[] = [];
    
    this.months.forEach(month => {

      if (this.allCustomersView.value) {

        let currentCustomerResult = this.customerResultsOfAll.filter(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          activeUsers: currentCustomerResult.reduce((partialSum, a) => partialSum + a.activeUsers, 0),
          registeredUsers: this.registeredUsersOfAllPerYear.length >= month.value - 1 ? this.registeredUsersOfAllPerYear[month.value - 1] : 0
        });

      } else {

        let currentCustomerResult = this.customerResultsOfCustomer.find(c => c.year === this.yearDate.value && c.month === month.value);

        exportedData.push({
          year: this.yearDate.value,
          month: month.value,
          activeUsers: currentCustomerResult?.activeUsers ?? 0,
          registeredUsers: this.registeredUsersOfCustomerPerYear.length >= month.value - 1 ? this.registeredUsersOfCustomerPerYear[month.value - 1] : 0
        });

      }

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "Active Users",
        "Registered Users"
      ],
      this.allCustomersView.value ? 'Users of all customers' : `Users of ${this.currentCustomer().name}`
    );

  }

  exportCustomerResults() {

    let exportedData: {
      year: number,
      month: number,
      averageArchivedStorage: string,
      maxArchivedStorage: string,
      totalOnlineTime: string,
      totalPublishedStreamTime: string,
      totalStreamTime: string,
      activeUsers: number,
      registeredUsers: number,
      webinarPerformed: number,
      lessonsPerformed: number
    }[] = [];

    if (this.allCustomersView.value) {

      let currentCustomerResult = this.customerResultsOfAll.filter(c =>  c.year === this.yearDate.value && c.month === this.monthDate.value);

      exportedData.push({
        year: this.yearDate.value,
        month: this.monthDate.value,
        averageArchivedStorage: this.formatKB(this.averageDailyStorageOfAllPerMonth),
        maxArchivedStorage: this.formatKB(this.dailyCustomersStorageMaxOfAll?.dailyArchivedStorage ?? 0),
        totalOnlineTime: this.secondsToDhms(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalOnlineTime, 0)),
        totalPublishedStreamTime: this.secondsToDhms(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalPublishedStreamTime, 0)),
        totalStreamTime: this.secondsToDhms(currentCustomerResult.reduce((partialSum, a) => partialSum + a.totalStreamTime, 0)),
        activeUsers: currentCustomerResult.reduce((partialSum, a) => partialSum + a.activeUsers, 0),
        registeredUsers: this.registeredUsersOfAllPerMonth,
        webinarPerformed: this.webinarResultNumberOfAllPerMonth,
        lessonsPerformed: this.lessonResultsPerformedOfAllPerMonth
      });

    } else {

      let currentCustomerResult = this.customerResultsOfCustomer.find(c => c.year === this.yearDate.value && c.month === this.monthDate.value);

      exportedData.push({
        year: this.yearDate.value,
        month: this.monthDate.value,
        averageArchivedStorage: this.formatKB(this.averageDailyStorageOfCustomerPerMonth),
        maxArchivedStorage: this.formatKB(this.dailyCustomerStorageMaxOfCustomer?.dailyArchivedStorage ?? 0),
        totalOnlineTime: this.secondsToDhms(currentCustomerResult?.totalOnlineTime ?? 0),
        totalPublishedStreamTime: this.secondsToDhms(currentCustomerResult?.totalPublishedStreamTime ?? 0),
        totalStreamTime: this.secondsToDhms(currentCustomerResult?.totalStreamTime ?? 0),
        activeUsers: currentCustomerResult?.activeUsers ?? 0,
        registeredUsers: this.registeredUsersOfCustomerPerMonth,
        webinarPerformed: this.webinarResultNumberOfCustomerPerMonth,
        lessonsPerformed: this.lessonResultsPerformedOfCustomerPerMonth
      });

    }

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "AverageArchivedStorage",
        "MaxArchivedStorage",
        "TotalOnlineTime",
        "TotalPublishedStreamTime",
        "TotalStreamTime",
        "TotalActiveUsers",
        "TotalRegisteredUsers",
        "TotalWebinarPerformed",
        "TotalLessonsPerformed"
      ],
      this.allCustomersView.value ? 'Customer Results of all customers' : `Customer Results of ${this.currentCustomer().name}`
    );

  }

  saveAllToCsv() {
    this.exportCustomerResults();
    this.exportTotalStreamingTime();
    this.exportTotalOnlineTime();
    this.exportActiveUsers();
    this.exportWebinarData();
  }

  currentCustomer() {
    return this.customers.find(c => c.id === this.customerForm.value);
  }

  isAdmin() {
    return this.currentUser.isAdmin;
  }

  formatKB(kilobytes: number) {
    if (!kilobytes || kilobytes <= 0)
      kilobytes = 0;

    return Helper.formatBytes(kilobytes * 1024, 2);
  }

  formatGB(gigabytes: number) {
    if (!gigabytes || gigabytes <= 0)
      gigabytes = 0;

    return Helper.formatBytes(gigabytes * 1024 * 1024 * 1024, 2);
  }

  secondsToDhms(seconds: string | number) {
    if (!seconds)
      return '0m';

    seconds = Number(seconds);

    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor(seconds % (3600 * 24) / 3600);
    let m = Math.floor(seconds % 3600 / 60);

    return `${d > 0 ? d + 'd' : ''} ${h > 0 ? h + 'h' : ''} ${m > 0 ? m + 'm' : '0m'}`;
  }

  private translateColumns() {
    return this.columnsToDisplay.map(c => this.translate.instant(c));
  }

  private async getCustomers() {
    this.customers = [];

    await firstValueFrom(this.calendar.getCustomerForAdmin())
      .then(res => {
        this.customers = res.filter(c => c.state === 1);
        this.customerForm.setValue(res.find( c => c.id === this.currentUser.idCustomer)?.id);

        this.customers.sort((a,b) => a.name.localeCompare(b.name));
      })
      .catch(e => console.error(e));
  }

  private async getCustomersResultsOfAll() {
    this.customerResultsOfAll = [];

    await firstValueFrom(this.calendar.getCustomersResults())
      .then(res => {
        this.customerResultsOfAll = res;
      })
      .catch(e => console.error(e));
  }

  private async getCustomersResultsOfCustomer() {
    this.customerResultsOfCustomer = [];
    this.customerResultsLastUpdate = undefined;

    await firstValueFrom(this.calendar.getCustomerResults(this.customerForm.value))
      .then(res => {
        this.customerResultsOfCustomer = res;
        this.customerResultsLastUpdate = this.customerResultsOfCustomer.map(a => a.updated).at(-1);
      })
      .catch(e => console.error(e));
  }

  private async getDailyStorageOfCustomer() {
    this.dailyCustomerStorageOfCustomer = [];
    //this.dailyCustomerStorageMaxOfCustomer = undefined;

    await firstValueFrom(this.calendar.getCustomerStorageSizeTracker(this.customerForm.value))
      .then(res => {
        this.dailyCustomerStorageOfCustomer = res;
        //this.dailyCustomerStorageMaxOfCustomer = res.reduce((a, b) => a.dailyArchivedStorage > b.dailyArchivedStorage ? a : b);
      })
      .catch(e => console.error(e));
  }

  private async getDailyStorageOfAllPerMonth() {
    this.dailyCustomersStorageOfAll = [];
    this.dailyCustomersStorageMaxOfAll = undefined;

    await firstValueFrom(this.calendar.getDailyCustomersStorageSizeTracker(this.monthDate.value, this.yearDate.value))
      .then(res => {
        this.dailyCustomersStorageOfAll = res;
        this.dailyCustomersStorageMaxOfAll = res.reduce((a, b) => a?.dailyArchivedStorage > b?.dailyArchivedStorage ? a : b, undefined);
      })
      .catch(e => console.error(e));
  }

  private async getStorageOfCustomer() {
    this.storageCurrentSizeOfCustomer = 0;
      this.storageMaxSizeOfCustomer = 0;
      this.storagePercentageOfCustomer = 0;

    await firstValueFrom(this.calendar.getCurrentAzureState(this.customerForm.value))
      .then(res => {
        this.storageCurrentSizeOfCustomer = res.currentSize;
        this.storageMaxSizeOfCustomer = res.maxSize;
        this.storagePercentageOfCustomer = (this.storageCurrentSizeOfCustomer / this.storageMaxSizeOfCustomer) * 100;
      })
      .catch(e => console.error(e));
  }

  private async getStoragesOfAll() {
    this.storagesOfAll = [];
    this.storageCurrentSizeOfAll = 0;
    this.storageMaxSizeOfAll = 0;
    this.storagePercentageOfAll = 0;

    await firstValueFrom(this.calendar.getCurrentAzureStates())
      .then(res => {
        this.storagesOfAll = res;
        this.storageCurrentSizeOfAll = res.reduce((partialSum, a) => partialSum + a.currentSize, 0);
        this.storageMaxSizeOfAll = res.reduce((partialSum, a) => partialSum + a.maxSize, 0);
        this.storagePercentageOfAll = (this.storageCurrentSizeOfAll / this.storageMaxSizeOfAll) * 100;
      })
      .catch(e => console.error(e));
  }

  private async getRegisteredUsersOfCustomerPerMonth() {
    this.registeredUsersOfCustomerPerMonth = 0;

    await firstValueFrom(this.calendar.getRegisteredUsers(this.customerForm.value, this.monthDate.value, this.yearDate.value))
      .then(res => {
        this.registeredUsersOfCustomerPerMonth = res;
      })
      .catch(e => console.error(e));
  }

  private async getRegisteredUsersOfAllPerMonth() {
    this.registeredUsersOfAllPerMonth = 0;

    await firstValueFrom(this.calendar.getAllRegisteredUsersForMonth(this.monthDate.value, this.yearDate.value))
      .then(res => {
        this.registeredUsersOfAllPerMonth = res;
      })
      .catch(e => console.error(e));
  }

  private async getRegisteredUsersOfCustomerPerYear() {
    this.registeredUsersOfCustomerPerYear = [];

    await firstValueFrom(this.calendar.getRegisteredUsersForYear(this.customerForm.value, this.yearDate.value))
      .then(res => {
        this.registeredUsersOfCustomerPerYear = res;
      })
      .catch(e => console.error(e));
  }

  private async getRegisteredUsersOfAllPerYear() {
    this.registeredUsersOfAllPerYear = [];

    await firstValueFrom(this.calendar.getAllRegisteredUsersForYear(this.yearDate.value))
      .then(res => {
        this.registeredUsersOfAllPerYear = res;
      })
      .catch(e => console.error(e));
  }

  private async getPresentersOfPerformedWebinarOfCustomerPerMonth() {
    this.presentersOfWebinarOfCustomerPerMonth = 0;

    await firstValueFrom(this.calendar.getPresentersOfPerformedWebinarForMonth(this.customerForm.value, this.monthDate.value, this.yearDate.value))
      .then( res => {
        this.presentersOfWebinarOfCustomerPerMonth = res;
      })
      .catch(e => console.error(e));
  }

  private async getPresentersOfPerformedWebinarOfAllPerMonth() {
    this.presentersOfWebinarOfAllPerMonth = 0;

    await firstValueFrom(this.calendar.getPresentersOfPerformedWebinarForAllCustomers(this.monthDate.value, this.yearDate.value))
      .then( res => {
        this.presentersOfWebinarOfAllPerMonth = res;
      })
      .catch(e => console.error(e));
  }

  private async getWebinarDataOfCustomer() {
    this.webinarResultsOfCustomer = [];

    await firstValueFrom(this.calendar.getLessonTotalResults(this.customerForm.value))
      .then(res => {
        this.webinarResultsOfCustomer = res;
      })
      .catch(e => console.error(e));
  }

  private async getWebinarDataOfAll() {
    this.webinarResultsOfAll = [];

    await firstValueFrom(this.calendar.getLessonAllTotalResults())
      .then(res => {
        this.webinarResultsOfAll = res;
      })
      .catch(e => console.error(e));
  }

  private async getLessonsDataOfCustomer() {
    this.lessonResultsOfCustomer = [];

    await firstValueFrom(this.calendar.getLessonsOnlyTotalResults(this.customerForm.value))
      .then(res => {
        this.lessonResultsOfCustomer = res;
      })
      .catch(e => console.error(e));
  }

  private async getLessonsDataOfAll() {
    this.lessonResultsOfAll = [];

    await firstValueFrom(this.calendar.getLessonsOnlyAllTotalResults())
      .then(res => {
        this.lessonResultsOfAll = res;
      })
      .catch(e => console.error(e));
  }

  private async updatePublishedStreamingTimeChart() {

    if (!this.publishedStreamingChart) {

      this.publishedStreamingChart = new Chart(PUBLISHED_STREAMING_CHART_ID, {
        type: 'bar', 
        data: {
          labels: this.translateColumns(),
          datasets: []
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio: 4,
          scales: {
            A: {
              type: 'linear',
              position: 'left',
              ticks: {
                callback: v => this.secondsToDhms(v),
                color: PUBLISHED_STREAMING_CHART_COLOR1
              }
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                callback: v => this.secondsToDhms(v),
                color: PUBLISHED_STREAMING_CHART_COLOR2
              }
            },
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          },
          plugins: {
            tooltip:{
              callbacks: {
              label: context => {
                if (!context?.parsed.y)
                  return '';
  
                return `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}`;
              }
            }
            },
            legend: {
              display: true,
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          }
        }
      });

    }

    this.publishedStreamingChart.data.datasets = [];
  
    this.publishedStreamingChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(PUBLISHED_STREAMING_CHART_LABEL1)),
      data: this.allCustomersView.value ? this.publishedStreamingTimeOfAllPerYear : this.publishedStreamingTimeOfCustomerPerYear,
      backgroundColor: PUBLISHED_STREAMING_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.publishedStreamingChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(PUBLISHED_STREAMING_CHART_LABEL2)),
      data: this.allCustomersView.value ? this.streamTimeOfAllPerYear : this.streamTimeOfCustomerPerYear,
      backgroundColor: PUBLISHED_STREAMING_CHART_COLOR2,
      maxBarThickness: 50,
      yAxisID: 'B'
    });

    this.publishedStreamingChart.update();
    
  }

  private async updateActiveUsersChart() {

    if (!this.activeUsersChart) {

      this.activeUsersChart = new Chart(ACTIVE_USERS_CHART_ID, {
        type: 'bar',
        data: {
          labels: this.translateColumns(),
          datasets: []
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio:4,
          scales: {
            A: {
              type: 'linear',
              position: 'left',
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            },
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            },
            y: {
              ticks: {
                display:false
              }
            },
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          }
        }
      });

    }

    this.activeUsersChart.data.datasets = [];
  
    this.activeUsersChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(ACTIVE_USERS_CHART_LABEL1)),
      data: this.allCustomersView.value ? this.activeUsersOfAllPerYear : this.activeUsersOfCustomerPerYear,
      backgroundColor: ACTIVE_USERS_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.activeUsersChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(ACTIVE_USERS_CHART_LABEL2)),
      data: this.allCustomersView.value ? this.registeredUsersOfAllPerYear : this.registeredUsersOfCustomerPerYear,
      backgroundColor: ACTIVE_USERS_CHART_COLOR2,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.activeUsersChart.update();
    
  }

  private async updateOnlineTimeChart() {

    if (!this.onlineTimeChart) {

      this.onlineTimeChart = new Chart(ONLINE_TIME_CHART_ID, {
        type: 'bar',
        data: {
          labels: this.translateColumns(),
          datasets: []
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio:4,
          scales: {
            A: {
              type: 'linear',
              position: 'left',
              ticks:{
                callback: v => this.secondsToDhms(v),
                color: this.darkService.isSetDark ? 'white' : ''
              },
            },
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            },
          },
          plugins: {
            tooltip:{
              callbacks: {
              label: context => {
                if (!context?.parsed.y)
                  return '';
  
                return `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}`;
              }
            }
            },
            legend: {
              display: true,
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          }
        }
      });

    }

    this.onlineTimeChart.data.datasets = [];
  
    this.onlineTimeChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(ONLINE_TIME_CHART_LABEL1)),
      data: this.allCustomersView.value ? this.onlineTimeOfAllPerYear : this.onlineTimeOfCustomerPerYear,
      backgroundColor: ONLINE_TIME_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.onlineTimeChart.update();
      
  }

  private async updateWebinarPerformedChart() {

    if (!this.webinarPerformedChart) {

      this.webinarPerformedChart = new Chart(WEBINAR_PERFORMED_CHART_ID, {
        type: "bar",
        data: {
          labels: this.translateColumns(),
          datasets: []
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio:4,
          scales: {
            A: {
              type: 'linear',
              position: 'left',
              ticks: {
                color: WEBINAR_PERFORMED_CHART_COLOR1
              }
            },
            B: {
              type: 'linear',
              position: 'right',
              display: false,
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            },
            C: {
              type: 'linear',
              position: 'right',
              ticks: {
                color: WEBINAR_PERFORMED_CHART_COLOR3
              }
            },
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            },
          },
          plugins: {
            tooltip:{
              callbacks: {
                label: context => {
                  if (!context?.parsed.y)
                    return '';
    
                  return context.dataset.yAxisID === 'B' ?
                         `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}` :
                         `${context?.dataset?.label ?? ''} : ${context.parsed.y}`;
                }
              }
            },
            legend: {
              display: true,
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          }
        }
      });

    }

    this.webinarPerformedChart.data.datasets = [];
  
    this.webinarPerformedChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(WEBINAR_PERFORMED_CHART_LABEL1)),
      data: this.allCustomersView.value ? this.webinarResultNumberOfAllPerYear : this.webinarResultNumberOfCustomerPerYear,
      backgroundColor: WEBINAR_PERFORMED_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.webinarPerformedChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(WEBINAR_PERFORMED_CHART_LABEL2)),
      data: this.allCustomersView.value ? this.webinarResultStreamingTimeOfAllPerYear : this.webinarResultStreamingTimeOfCustomerPerYear,
      backgroundColor: WEBINAR_PERFORMED_CHART_COLOR2,
      maxBarThickness: 50,
      yAxisID: 'B'
    });

    this.webinarPerformedChart.data.datasets.push({
      label: await firstValueFrom(this.translate.get(WEBINAR_PERFORMED_CHART_LABEL3)),
      data: this.allCustomersView.value ? this.webinarResultParticipantsOfAllPerYear : this.webinarResultParticipantsOfCustomerPerYear,
      backgroundColor: WEBINAR_PERFORMED_CHART_COLOR3,
      maxBarThickness: 50,
      yAxisID: 'C'
    });

    this.webinarPerformedChart.update();
   
  }

}

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { icheck, select2, inputmask, jquerybarrating, jqueryuidatepicker, nouislider, select2tagbox, sortablejs, autocomplete, bootstrapslider } from 'surveyjs-widgets';
import * as SurveyCore from 'survey-core';
import { SurveyCreatorModel } from 'survey-creator-core';
import { init as initCustomWidget } from "../customwidget";

icheck(SurveyCore);
select2(SurveyCore);
inputmask(SurveyCore);
jquerybarrating(SurveyCore);
jqueryuidatepicker(SurveyCore);
nouislider(SurveyCore);
select2tagbox(SurveyCore);
sortablejs(SurveyCore);
autocomplete(SurveyCore);
bootstrapslider(SurveyCore);

initCustomWidget(SurveyCore);

@Component({
  selector: 'app-survey-creator',
  templateUrl: './survey-creator.component.html',
  styleUrls: ['./survey-creator.component.scss']
})
export class SurveyCreatorComponent implements OnInit {

  surveyCreator: SurveyCreatorModel;

  @Input()
  set json(value: any) {

    SurveyCore.Serializer.addProperty("questionbase", "popupdescription:text");
    SurveyCore.Serializer.addProperty("page", "popupdescription:text");

    // punteggi per le risposte
    SurveyCore.Serializer.addProperty("question", { name: "score:number" });
    SurveyCore.Serializer.addProperty("itemvalue", { name: "score:number" });

    this.surveyCreator = new SurveyCreatorModel(
      {
        showEmbededSurveyTab: false,
        generateValidJSON: true,
        showJSONEditorTab: false,
        isAutoSave: true,
        showState: true
      }
    );

    // hide complete on test
    this.surveyCreator.onTestSurveyCreated.add((sender, options) => {

      options.survey.showCompletedPage = false;  
      options.survey.showNavigationButtons = true;

      options.survey.onComplete.add(() => {
        //Do something on completing the survey
        alert('completato');
      });

    });

    this.surveyCreator.text = value;
    this.surveyCreator.saveSurveyFunc = this.saveMySurvey;

  }

  @Output()
  surveySaved: EventEmitter<Object> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  saveMySurvey = () => this.surveySaved.emit(JSON.parse(this.surveyCreator.text));

}

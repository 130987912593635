import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-picture-preview',
  templateUrl: './picture-preview.component.html',
  styleUrls: ['./picture-preview.component.scss']
})
export class PicturePreviewComponent implements OnInit {

  @Input()
  src: string = null;

  @Input()
  tooltip: string = null;

  @Input()
  height: string = '200px';

  @Input()
  width: string = '200px';

  @Input()
  fit: string = 'contain';

  constructor() { }

  ngOnInit(): void { }

}

<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

  <mat-card appearance="outlined" class="mat-elevation-z0">
    <mat-card-header>

      <app-profile-picture
        mat-card-avatar
        [url]="currentUser.profilePictureUrl"
        size="40px">
      </app-profile-picture>

      <mat-card-title>
        {{ "Update Status" | translate }}
      </mat-card-title>

    </mat-card-header>

    <mat-card-content fxLayout="column" fxLayoutGap="10px">
      <mat-form-field appearance="outline">
        <mat-label>{{ "What's on your mind" | translate }}?</mat-label>
        <textarea matInput
          #autosize="cdkTextareaAutosize"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10"
          [formControl]="postText"
          (keyup)="resetForm(true)">
        </textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="postText.valid">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <input matInput [formControl]="postTitle" />
        <mat-error *ngIf="postTitle.hasError('maxlength')">
          {{ 'The title must be a maximum of 256 characters' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="postText.valid && classroomId < 0" [style.margin-top]="postTitle.hasError('maxlength') ? '10px' : ''" appearance="outline">
        <mat-label>{{ 'Who should see this' | translate }}?</mat-label>
        <mat-select [formControl]="visibility">
          <!-- <mat-option [value]="2" *ngIf="currentUser.isCustomerAdmin || currentUser.isAdmin">
            {{ 'Global' | translate }}
          </mat-option> -->
          <mat-option [value]="1" *ngIf="currentUser.isCustomerAdmin || currentUser.isAdmin || currentUser.isAuthor">
            {{ 'Public' | translate }}
          </mat-option>
          <mat-option [value]="0">{{ 'Private' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="
        postText.valid &&
        visibility.value === 0 &&
        classroomId < 0" 
        class="selection-list">
        <mat-selection-list [formControl]="selectClassroom" appearance="outline">
          <!-- selected -->
          <mat-list-option  *ngFor="let item of classrooms" [value]="item.id">
            {{ item.name }}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div *ngIf="
        postText.valid &&
        visibility.value === 1 &&
        classroomId < 0 &&
        currentUser.isAdmin"
        class="selection-list">
        <mat-selection-list [formControl]="selectCustomer" appearance="outline">
          <!-- [selected]="item.id == currentUser.idCustomer" -->
          <mat-list-option *ngFor="let item of customersArr" [value]="item.id">
            {{ item.name }}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div *ngIf="filePicture.length > 0">
        <span>{{ 'List of Attached Pictures' | translate }}</span>
        <mat-list>
          <mat-list-item *ngFor="let item of filePicture">
            <span matListItemTitle>{{ item.name }}</span>
            <button mat-icon-button
              matListItemMeta
              (click)="removeAttachment(item, 'picture')"
              [matTooltip]="'Remove' | translate"
              color="warn">
              <mat-icon>close</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>

      <div *ngIf="fileVideo.length > 0">
        <span>{{ 'List of Attached Videos' | translate }}</span>
        <mat-list>
          <mat-list-item *ngFor="let item of fileVideo">
            <span matListItemTitle>{{ item.name }}</span>
            <button mat-icon-button
              matListItemMeta
              (click)="removeAttachment(item, 'video')"
              [matTooltip]="'Remove' | translate"
              color="warn">
              <mat-icon>close</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>

      <div *ngIf="attachLink.length > 0">
        <span>{{ 'List of Attached Links' | translate }}</span>
        <mat-list>
          <mat-list-item *ngFor="let item of attachLink">
            <a matListItemTitle target="_blank" [href]="item.contentLink">
              {{ item.description }}
            </a>
            <button mat-icon-button
              matListItemMeta
              (click)="removeAttachment(item, 'link')"
              [matTooltip]="'Remove' | translate"
              color="warn">
              <mat-icon>close</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>

      <div *ngIf="attachEtcLink.length > 0">
        <span>{{ 'List of Attached Contents' | translate }}</span>
        <mat-list>
          <mat-list-item *ngFor="let item of attachEtcLink">
            <span matListItemTitle>{{ item.description }}</span>
            <button mat-icon-button
              matListItemMeta
              (click)="removeAttachment(item, 'etcLink')"
              [matTooltip]="'Remove' | translate"
              color="warn">
              <mat-icon>close</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>

      <link-preview [fromString]="userInputLinks"></link-preview>
    </mat-card-content>

    <mat-card-actions *ngIf="postText.valid" fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">

        <span>{{ 'Pictures and videos recommended aspect ratio is 16:9' | translate }}</span>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-icon-button (click)="addCourseLink()" [matTooltip]="'Add content' | translate">
            <mat-icon>school</mat-icon>
          </button>
  
          <button mat-icon-button (click)="fileInput.click()" [matTooltip]="'Add pictures' | translate">
            <input style="display: none" type="file" #fileInput (change)="onFileChange($event)" accept="image/*" />
            <mat-icon>add_a_photo</mat-icon>
          </button>
  
          <button mat-icon-button (click)="fileInputVideo.click()" [matTooltip]="'Add videos' | translate">
            <input style="display: none" type="file" #fileInputVideo (change)="onFileChange($event)" accept="video/*" />
            <mat-icon>video_call</mat-icon>
          </button>
        </div>

      </div>

      <div>
        <button mat-raised-button color="accent"
          [disabled]="postBtnDisabled()"
          (click)="postSocialWall()">
          <mat-icon>done</mat-icon>
          {{ 'Post' | translate }}
        </button>
      </div>
      
    </mat-card-actions>
  </mat-card>

  <mat-card appearance="outlined" class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>
        {{ 'Search' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center">
      <!--mat-form-field appearance="legacy" color="primary">
          <mat-label>{{ 'Hashtags' | translate }}</mat-label>
          <mat-select [(ngModel)]="hashTag">
              <mat-option value="all">{{ 'All hashtags' | translate }}</mat-option>
              <mat-option *ngFor="let item of hashTags" [value]="item">{{item}}</mat-option>
          </mat-select>
      </mat-form-field-->
      <mat-form-field color="primary" fxFlex>
        <mat-label>{{ 'Hashtags' | translate }}</mat-label>
        <input matInput type="text"
          [placeholder]="('Search' | translate) + '...'"
          [formControl]="hashTag"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let item of filteredHashTags | async" [value]="item">
            {{ item }}
          </mat-option>
        </mat-autocomplete>
        <button mat-icon-button
          matSuffix
          *ngIf="hashTag.value"
          (click)="hashTag.setValue('')"
          [matTooltip]="'Clear' | translate">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <div fxLayout="column" fxLayoutGap="20px">
    <mat-card *ngFor="let item of socialWallArr | hashTagsFilter: hashTag.value"
      appearance="outlined" 
      class="mat-elevation-z0"
      [ngStyle]="{ 'background-color': item.type == 0 ? 'none' : '#bfbbbb' }"
      [style.color]="isDark() && item.type == 1 ? 'black !important': ''">

      <mat-card-header>

        <app-profile-picture mat-card-avatar
          [url]="item.idauthorNavigation.profilePictureUrl"
          size="40px">
        </app-profile-picture>

        <mat-card-title>
          {{ item.title }}
        </mat-card-title>

        <mat-card-subtitle
          [style.color]="isDark() && item.type == 1 ? 'black !important': ''"
          style="width: calc(800px - 88px)">

          <span class="truncate-text" style="display: block">{{ getHtml(item.postText) }}</span>

          <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <span>{{ item.created | date: "HH:mm - dd/MM/yyyy" }}</span>
              <mat-icon [matTooltip]="(item.visibility === 2 ?
                                       'Global' :
                                       item.visibility === 1 ?
                                       'Public' :
                                       'Private') | translate">
                {{
                  item.visibility === 2 ?
                  'public' :
                  item.visibility === 1 ?
                  'people' :
                  'public_off'
                }}
              </mat-icon>
            </div>

            <span (click)="goToAuthorProfile(item.idauthor)" class="truncate-text" style="cursor: pointer">
              {{ item.idauthorNavigation.name }} {{ item.idauthorNavigation.surname }}
            </span>
          </div>

        </mat-card-subtitle>

      </mat-card-header>

      <!-- *ngIf="item.socialWallContents.length >= 1" -->
      <mat-card-content>

        <div class="pre-content" style="margin-right: 52px;" [innerHTML]="getDescription(item.postText)"></div>

        <div style="margin-top: 10px">
          <link-preview [fromString]="extractLinksFromText(item.postText)"></link-preview>
        </div>

        <div style="height: auto; margin-top: 10px;">

          <mat-carousel
            *ngIf="needCarousel(item)"
            [autoplay]="false"
            [hideArrows]="countCarousel(item) <= 1"
            [hideIndicators]="countCarousel(item) <= 1"
            [useKeyboard]="countCarousel(item) > 1"
            [useMouseWheel]="false"
            [maintainAspectRatio]="false"
            slideHeight="432px"
            color="accent">

            <!-- VIDEO -->
            <mat-carousel-slide *ngFor="let content of getCarouselSlides(item, 2)" [hideOverlay]="true">
              
              <app-player-events
                [media]="content.contentLink"
                [enableControls]="true"
                [disableSeekCheck]="true"
                [pause]="true"
                width="100%"
                height="100%"
                controls="nodownload"
                objectFit="contain">
              </app-player-events>

            </mat-carousel-slide>

            <!-- PICTURE -->
            <mat-carousel-slide *ngFor="let content of getCarouselSlides(item, 1)" [hideOverlay]="true">
              
              <img [src]="content.contentLink" class="carousel-element" loading="lazy" />

            </mat-carousel-slide>

          </mat-carousel>

          <div *ngIf="item.socialWallContents.length >= 1">
            <!-- ETC LINK -->
            <div style="text-align: center; margin-top: 20px" *ngFor="let content of item.socialWallContents">

              <div
                *ngIf="(content.type == 3 || content.type == 5 || content.type == 4) &&
                        content.contentLink.split('@@')[0] != ''"
                style="width: 100%; height: 432px; overflow: hidden;">
                <img 
                  *ngIf="content.contentLink.split('@@')[0]"
                  [src]="content.contentLink.split('@@')[0]"
                  class="carousel-element"
                  style="object-fit: cover"
                  loading="lazy" />

              </div>

              <div *ngIf="content.type == 3 || content.type == 5 || content.type == 4"
                style="font-size: medium; margin-top: 25px"
                [innerHTML]="content.description">
              </div>

              <div *ngIf="(content.type == 3 || content.type == 5 || content.type == 4) &&
                          content.contentLink.split('@@')[1] != ''"
                style="margin-top: 25px">

                <button mat-raised-button
                  (click)="goToEtcContent(content.contentLink.split('@@')[1])"
                  color="accent">
                  {{ 'Read More' | translate }}
                </button>

              </div>
            </div>

            <!-- EXTERNAL LINK -->
            <div style="text-align: center; margin-top: 20px" *ngFor="let content of item.socialWallContents">
              <div *ngIf="content.type == 0" [innerHTML]="content.description"></div>

              <div *ngIf="content.type == 0" style="margin-top: 25px">
                <a mat-raised-button [href]="content.contentLink" target="_blank">{{ 'Read More' | translate }}</a>
              </div>

            </div>

          </div>

        </div>

        <div fxLayout="column" fxLayoutGap="5px" style="margin-top: 10px">
          <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
            <button mat-button
              (click)="item.isLikeUser ? unlikePost(item.likeid, item.id) : likePost(item.id)"
              [style.color]="isDark() && item.type == 1 && !item.isLikeUser ? 'black' : item.isLikeUser ? '#24a0ed' : ''">
              <mat-icon>{{ item.isLikeUser ? 'thumb_up_alt' : 'thumb_up_off_alt' }}</mat-icon>
              {{ 'Like' | translate }}
            </button>

            <button mat-button
              *ngIf="currentUser.isCustomerAdmin || item.idauthor === currentUser.id"
              (click)="editPost(item)"
              [style.color]="isDark() && item.type == 1  ? 'black': ''">
              <mat-icon>edit</mat-icon>
              {{ 'Edit' | translate }}
            </button>

            <button mat-button
              *ngIf="currentUser.isCustomerAdmin || item.idauthor === currentUser.id"
              (click)="deletePost(item.id)"
              [style.color]="isDark() && item.type == 1  ? 'black': ''">
              <mat-icon>delete</mat-icon>
              {{ 'Delete' | translate }}
            </button>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between">
            <span *ngIf="item.socialWallLikes.length === 0"></span>

            <span
              *ngIf="item.socialWallLikes.length === 1"
              [style.color]="isDark() && item.type == 1  ? 'black': ''"
              style="align-content: center">
              {{
                item.socialWallLikes[0].iduserNavigation.id === currentUser.id ?
                ( item.socialWallLikes[0].iduserNavigation.name + ' ' + ('like this' | translate)) :
                ('like this element' | translate:{ user: item.socialWallLikes[0].iduserNavigation.name })
              }}
            </span>

            <span
              *ngIf="item.socialWallLikes.length > 1"
              (click)="showWhoLike(item.id)"
              [style.color]="isDark() && item.type == 1  ? 'black': ''"
              style="align-content: center">
              {{ item.socialWallLikes[0].iduserNavigation.name }} {{ 'and' | translate }}
              {{ item.socialWallLikes.length - 1 }} {{ 'other/s like this' | translate }}
            </span>

          </div>

        </div>

        <div fxLayout="row" fxLayoutGap="10px" style="margin-top: 10px">
          <mat-form-field appearance="outline" color="primary" fxFlex>
            <mat-label [style.color]="isDark() && item.type == 1 ? 'black': ''">{{ 'Write a comment' | translate }}</mat-label>
            <textarea matInput
              #autosize="cdkTextareaAutosize"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="10"
              [formControl]="txtComment"
              [style.color]="isDark() && item.type == 1 ? 'black': ''">
            </textarea>
          </mat-form-field>
          <button mat-icon-button matSuffix
              (click)="writeBlog(item.id)"
              [disabled]="!txtComment.value?.length"
              [matTooltip]="'Comment' | translate"
              [style.color]="isDark() && item.type == 1 ? 'black': ''">
              <mat-icon>comment</mat-icon>
            </button>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <ng-container
            *ngFor="let blog of item.socialWallBlogs"
            [ngTemplateOutlet]="blogContainer"
            [ngTemplateOutletContext]="{ blog: blog, blogChildren: item.SocialWallBlogChild, showReply: true }">
          </ng-container>
        </div>

      </mat-card-content>

    </mat-card>
  </div>
  <!-- end of post list-->

</div>

<ng-template #blogContainer let-blog="blog" let-blogChildren="blogChildren" let-showReply="showReply">
  <div fxLayout="row" fxLayoutAlign="start start">
      <app-profile-picture
          mat-card-avatar
          [url]="blog.idauthorNavigation?.profilePictureUrl"
          size="40px"
          style="margin: 0 10px 10px 0;">
      </app-profile-picture>
      <div fxLayout="column" fxLayoutAlign="start start" fxFlex class="commentContainer">
          <div fxLayoutAlign="space-between start" style="width: 100%; margin-bottom: 10px;">
              <span style="font-weight: bold; font-size: 14px;">
                  {{ blog.idauthorNavigation.name + ' ' + blog.idauthorNavigation.surname }}
              </span>
              <span style="color: gray; font-size: small;">
                  {{ blog.created | date: 'HH:mm - dd/MM/yyyy' }}
              </span>
          </div>
          <div [innerHTML]="blog.postContent" class="commentBody"></div>
          <div fxLayoutAlign="space-between center" class="actionBtnsContainer">
              <button mat-button *ngIf="showReply" (click)="replyBlog(blog)" class="actionBtns">
                  <mat-icon>reply</mat-icon>
                  {{ 'Reply' | translate }}
              </button>
              <div>
                  <button mat-icon-button
                      *ngIf="currentUser.id === blog.idauthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === blog.idauthorNavigation.customerId) || currentUser.isAdmin"
                      (click)="editBlog(blog)"
                      [matTooltip]="'Edit' | translate"
                      class="actionBtns">
                      <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button
                      *ngIf="currentUser.id === blog.idauthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === blog.idauthorNavigation.customerId) || currentUser.isAdmin"
                      (click)="deleteBlog(blog)"
                      [matTooltip]="'Delete' | translate"
                      class="actionBtns">
                      <mat-icon>delete</mat-icon>
                  </button>
              </div>
          </div>
      </div>
  </div>
  <div style="padding-left: 10%; margin-top: 10px;" *ngFor="let subBlog of getBlogChildren(blog.id, blogChildren)">
      <ng-container [ngTemplateOutlet]="blogContainer" [ngTemplateOutletContext]="{ blog: subBlog, blogChildren: [], showReply: false }"></ng-container>
  </div>
</ng-template>

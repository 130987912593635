import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user';
import { ConferenceService } from 'src/app/services/conference.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { MatDialog } from '@angular/material/dialog';
import { AppConferencePopUpComponent } from './app-conference-pop-up/app-conference-pop-up.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Helper } from 'src/app/helpers/helper';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { environment } from 'src/environments/environment';
import { CalendarService } from 'src/app/services/calendar.service';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LessonService } from 'src/app/services/lessons.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { NavigationExtras, Router } from '@angular/router';
import { JoinedLessonDTO } from 'src/app/models/dto/joinedLessonDTO';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { differenceInMilliseconds, differenceInMinutes, isBefore, isToday } from 'date-fns';
import { ConferencePresenterDTO } from 'src/app/models/dto/conferencePresenterDTO';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';

@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.scss']
})
export class ConferencesComponent implements OnInit, OnDestroy {

  private linkUrl: string = `${environment.apiUrl}/login/?conference=`;

  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  currentUser: User;
  intervalOnlinePresenters: any;

  conferencesBackup: ConferenceDTO[] = [];
  conferences: ConferenceDTO[] = [];
  classroom: ClassroomDTO;
  value: string;
  mode: 'all' | 'incoming' | 'expired' | 'performed' = 'incoming';

  selectedUser: number;

  allOnlinePresenters: JoinedLessonDTO[] = [];
  
  pageIndex: number = 0;
  pageNo: number = 0;
  pageSize: number = 10;
  pageStart: number = 0;
  pageEnd: number = this.pageSize;

  constructor(private auth: AuthService,
              private translate: TranslateService,
              private conferenceService: ConferenceService,
              private calendarService: CalendarService,
              private snackBar: MatSnackBar,
              private lessonService: LessonService,
              private router: Router,
              private dialog: MatDialog,
              public darkService : DarkThemeService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.getConferences();
    this.getAllOnlinePresenters();
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
    
    if (this.intervalOnlinePresenters != null) {
			clearInterval(this.intervalOnlinePresenters);
			this.intervalOnlinePresenters = null;
		}
  }

  async closeConference(conference: ConferenceDTO) {
    const dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Close conference')),
        body: await firstValueFrom(this.translate.get('Are you sure you want to close conference?', { name: conference.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(async res => {
        if (!res)
          return;

        this.lessonService.closeLesson(conference.lessonSession.id)
          .then(() => this.ngOnInit());
    });
  }

  activePlayButton(lesson: LessonSessionDTO) {
    return differenceInMinutes(lesson.startPlanned, new Date()) <= 59
        && this.isToday(lesson)
        && !lesson.stopDate;
  }

  isToday(lesson: LessonSessionDTO) {
    return isToday(lesson.startPlanned);
  }

  isExpired(lesson: LessonSessionDTO) {
    return isBefore(lesson.endPlanned, new Date()) && !lesson.startDate;
  }

  isAuthor(lesson: LessonSessionDTO) {
    return lesson.teacherId === this.currentUser.id;
  }

  isSubstitute(presenters: ConferencePresenterDTO[]) {
    return presenters?.some(p => p.idPresenter === this.currentUser.id && p.role === ConferencePresenterRole.Substitute) ?? false;
  }

  copyLink(httpLink: string) {
    return this.linkUrl.concat(httpLink);
  }

  async urlCopied() {
		this.snackBar.open(await firstValueFrom(this.translate.get('Conference link copied!')), 'Dismiss', {
			duration: 3000,
			verticalPosition: 'bottom'
		});
	}
  
  getConferences() {
    this.conferenceService
      .getConferences([1, 2], this.mode === 'all' || this.mode === 'expired' || this.mode === 'performed')
      .subscribe(conference => {
        this.conferencesBackup = this.mode === 'expired'
                               ? conference.filter(c => this.isExpired(c.lessonSession))
                               : this.mode === 'performed'
                               ? conference.filter(c => c.lessonSession.stopDate != undefined)
                               : conference;

        this.resetConferences();
      });
  }

  getUsersByClassroom(idClassroom: number) {
    this.classroom = null;

    this.calendarService.getClassroom(idClassroom)
      .subscribe(classroom => this.classroom = classroom);
  }

  goToConference(conference: ConferenceDTO) { 
    this.router.navigate(['/conference', conference.id]);
  }

  goToProfile(organizedId: number): void {
    this.router.navigate(['/authorprofile', organizedId]);
  }
  
  openReport(conference: ConferenceDTO) {
    this.router.navigate(['/conference-report', conference.lessonSession.id]);
  }

  async deleteConference(conference: ConferenceDTO) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete conference')),
        body:  await firstValueFrom(this.translate.get('Are you sure you want to delete conference?', { name: conference.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;
      
        this.conferenceService.deleteConference(conference.id)
          .subscribe({
            next: async () => {
             this.snackBar.open(await firstValueFrom(this.translate.get('Conference deleted')), undefined, { duration: 3000 });
             this.getConferences();
            },
            error: err => {
              this.snackBar.open('Error deleting conference', undefined, { duration: 3000 });
            }
          });
    });
  }

  updateConference(id: number) {
    this.conferenceService.getConference(id).subscribe({
      next: conference => {

        const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
          data: { conference: conference },
          width: '600px',
          autoFocus: false,
          restoreFocus: false
        });

        dialogRef.afterClosed().subscribe(res => {
          if (!res)
            return;

          this.ngOnInit();
        });
      }
    });
  }

  changePage(event?: PageEvent) {
    this.accordion.closeAll();

    let minIndex = 0; 
    let maxIndex = this.pageSize;
    
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;

      minIndex = event.pageIndex * event.pageSize;
      maxIndex = (event.pageIndex + 1) * event.pageSize;
    }

    this.pageStart = minIndex;
    this.pageEnd = maxIndex;

    this.conferences = this.conferencesBackup.slice();

    if (!Helper.isNullOrEmpty(this.value))
      this.conferences = this.conferences.filter(c => c.name?.toLowerCase().includes(this.value) || c.description?.toLowerCase().includes(this.value));
  
    //this.conferences.sort((a, b) => new Date(b.lessonSession.startPlanned).getTime() - new Date(a.lessonSession.startPlanned).getTime());
  }

  resetConferences(resetSearch: boolean = true) {
    if (resetSearch)
      this.value = null;
  
    this.pageIndex = 0;
    this.pageStart = 0;
    this.pageEnd = this.pageSize;
    this.changePage();
  }

  addPublicConference(): void { 
    const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
      width: '600px',
      data: { mode: "public", page: "conference" },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.ngOnInit();
    });
  }

  addPrivateConference(): void {
    const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
      width: '600px',
      data: { mode: "private", page: "conference" },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.ngOnInit();  
    });
  }

  getAllOnlinePresenters() {
    if (this.intervalOnlinePresenters != null) {
      clearInterval(this.intervalOnlinePresenters);
      this.intervalOnlinePresenters = null;
    }

    this.intervalOnlinePresenters = setInterval(() => {
      this.conferenceService.getOnlinePresenters().subscribe(results => this.allOnlinePresenters = results);
    }, 2000);
  }

  checkStreamingStatus(conference: ConferenceDTO) {
    return !(this.allOnlinePresenters?.filter(joinedLesson => joinedLesson.lessonsId == conference.lessonSession.id).length === 0
        &&   conference.lessonSession.state !== 2);
  }

  isAdmin() {
    return this.currentUser.isAdmin;
  }

  isCustomerAdmin() {
    return this.currentUser.isCustomerAdmin;
  }

  isTutor() {
    return this.currentUser.isTutor;
  }

  isTeacher() {
    return this.currentUser.isTeacher;
  }

  isSpeaker() {
    return this.currentUser.isSpeaker;
  }

  isStudent() {
    return this.currentUser.isStudent;
  }

  goToVideo(conference: ConferenceDTO) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        lessonId: conference.idLesson,
        title: conference.name
      }
    };

    this.router.navigate(['/lessonvideo'], navigationExtras);
  }

  border(conference: ConferenceDTO) {
    if (conference.lessonSession.stopDate)
      return null;

    let border = 'outline: solid thick ';

    if (conference.lessonSession.state !== 2)
      return border += 'green';

    let diff = differenceInMinutes(conference.lessonSession.startPlanned, new Date());

    if (diff < 0)
      return null;

    if (diff <= 120)
      return border += 'red';

    if (diff <= 1440)
      return border += 'orange';

    return null;
  }

  getTextStatus(conference: ConferenceDTO) {
    if (conference.lessonSession.stopDate)
      return null;

    if (conference.lessonSession.state !== 2)
      return this.checkStreamingStatus(conference) ? 'Conference in progress' : 'Conference paused';

    if (this.isExpired(conference.lessonSession))
      return 'Expired';

    let diff = differenceInMilliseconds(conference.lessonSession.startPlanned, new Date());

    if (diff < 0 || diff > (24 * 60 * 60000)) // 24 ore
      return null;

    return Helper.convertToHMS(diff);
  }

  showCard(show: boolean, id?: number) {
    if (!show) {
      this.selectedUser = undefined;
      return;
    }

    if (!id || id === this.selectedUser)
      return;

    this.selectedUser = id;

    //this.userService.get(id)
    //  .subscribe(user => this.selectedUser = user);
  }

  showRecording(lessonSession: LessonSessionDTO, presenters: ConferencePresenterDTO[]) {
    return lessonSession.recodingPlanned
        && (lessonSession.privateRecording ? this.isAuthor(lessonSession) || this.isSubstitute(presenters) : true)
  }

  getRecordingColor(lessonSession: LessonSessionDTO) {
    if (lessonSession.recordingUrl)
      return 'red';

    if (lessonSession.recordingElaborationQueue + lessonSession.recordingElaborating > 0)
      return 'yellowgreen';

    if (lessonSession.recordingElaborationError > 0)
      return 'orange';

    return 'grey';
  }

}

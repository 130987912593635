<mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column">
    <mat-card-header>
        <mat-card-title>
            {{ title | translate }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
        <div fxLayoutAlign="center end" fxFlex="20">
            <button mat-fab [style.backgroundColor]="color" class="mat-elevation-z0 btnNoHover">
                <mat-icon style="color: white;">
                    {{ icon }}
                </mat-icon>
            </button>
        </div>
        <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
            <div fxLayoutAlign="center center" fxLayout="row">
                <h2>{{ value }}</h2>
                <div *ngIf="nextValue != null" fxLayoutAlign="center center" fxLayout="row" class="alarmBlink">
                    <mat-icon>keyboard_double_arrow_right</mat-icon>
                    <h2>{{ nextValue }}</h2>
                </div>
            </div>
            <h4 *ngIf="unit != null">{{ unit }}</h4>
        </div>
    </mat-card-content>
</mat-card>

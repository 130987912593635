<h1 mat-dialog-title>{{ data.mode === 'create' ? 'Add Skill' : 'Edit Skill' }}</h1>
<div mat-dialog-content>
  <form [formGroup]="skillForm" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
    
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!skillForm.valid" (click)="save()">Save</button>
</div>

<mat-card appearance="outlined">

    <mat-card-header class="row-arrange center-items" style="justify-content: space-between">
        <mat-card-title>
            {{ 'Sessions' | translate }} ({{ getSessionsParticipants() }})
        </mat-card-title>
        <button mat-fab color="primary"
            (click)="saveToCsv()"
            [matTooltip]="'Save csv' | translate">
            <mat-icon>save</mat-icon>
        </button>
    </mat-card-header>

    <mat-divider style="margin-top: 7px"></mat-divider>
    
    <div class="table-container">
        <table mat-table matSort #sessionsSort="matSort" [dataSource]="sessionsSource" style="width: 100%">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
            </ng-container>

            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.user.surname }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.user.email }}</td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Start' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.startDate | date: 'HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="stopDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'End' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.stopDate | date: 'HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="timeInLessons">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Presence Time' | translate }} (hh:mm:ss)</th>
                <td mat-cell *matCellDef="let element">{{ element.timeInLessons | slice:0:8 }}
                    <mat-progress-bar mode="determinate"
                        [value]="getPercentage(element.lessonsRange.max, element.lessonsRange.min, element.timeInLessons)">
                    </mat-progress-bar>
                </td>
            </ng-container>

            <ng-container matColumnDef="room">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Room' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.station?.room?.name ?? ('Remote Connection' | translate) }}</td>
            </ng-container>

            <ng-container matColumnDef="station">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Station' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.station?.name ?? ('Remote Connection' | translate) }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="sessionsColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: sessionsColumns"></tr>

            <tr class="mat-row" style="height: 40px" *matNoDataRow>
                <td class="mat-cell" [colSpan]="sessionsColumns.length" style="vertical-align: middle">
                    <span style="margin: 10px">
                        {{ 'No data' | translate }}
                    </span>
                </td>
            </tr>

        </table>
    </div>

</mat-card>

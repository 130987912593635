import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { icheck, select2, inputmask, jquerybarrating, jqueryuidatepicker, nouislider, select2tagbox, sortablejs, autocomplete, bootstrapslider, prettycheckbox } from 'surveyjs-widgets';
import * as SurveyCore from 'survey-core';
import { init as initCustomWidget } from "../customwidget";

icheck(SurveyCore);
select2(SurveyCore);
inputmask(SurveyCore);
jquerybarrating(SurveyCore);
jqueryuidatepicker(SurveyCore);
nouislider(SurveyCore);
select2tagbox(SurveyCore);
sortablejs(SurveyCore);
autocomplete(SurveyCore);
bootstrapslider(SurveyCore);
prettycheckbox(SurveyCore);

initCustomWidget(SurveyCore);

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  survey: SurveyCore.Model;

  @Input()
  set json(value: any) {

    SurveyCore.Serializer.addProperty("questionbase", "popupdescription:text");
    SurveyCore.Serializer.addProperty("page", "popupdescription:text");

    this.survey = new SurveyCore.Model(value);

    if (this.isPreview === true)
      this.survey.mode = 'display';

    this.survey.onAfterRenderQuestion.add((survey, options) => {

      if (!options.question.popupdescription)
        return;

      // Add a button;
      const btn = document.createElement("button");
      btn.className = "btn btn-info btn-xs";
      btn.innerHTML = "More Info";
      btn.onclick = () => {
        // showDescription(question);
        alert(options.question.popupdescription);
      };

      const span = document.createElement("span");
      span.innerHTML = "  ";

      const header = options.htmlElement.querySelector("h5");

      header.appendChild(span);
      header.appendChild(btn);

    });

    this.survey.onComplete.add((result, options) => {
       
      let totalScore = +result.getQuizQuestionCount();
      let OkScore = +result.getCorrectedAnswerCount();
      let myScore = Math.round((OkScore / totalScore) * 100);

      this.result = result.data;
      
      this.submitSurvey.emit({
        data: result.data,
        scoreA: myScore
      });
      
    });

    if (this.risposte && this.risposte != '') {
      this.survey.data = JSON.parse(this.risposte);
      this.survey.mode = 'display';
    }

  }

  @Input()
  risposte: string = null;

  @Input()
  isPreview: boolean = false;

  @Output()
  submitSurvey = new EventEmitter<any>();

  result: any;

  constructor() { }

  ngOnInit(): void { }

}

import { SocialWallAdditionalCustomerDTO } from "./SocialWallAdditionalCustomerDTO";
import { SocialWallBlogDTO } from "./SocialWallBlogDTO";
import { SocialWallClassroomDTO } from "./SocialWallClasroomDTO";
import { SocialWallContentDTO } from "./SocialWallContentDTO";
import { SocialWallLikeDTO } from "./SocialWallLikeDTO";
import { UserDTO } from "./userDTO";

export class SocialWallDTO { 
    id: number;
    idauthor: number;
    visibility: number;
    type: number;
    idcustomer: number;
    title: string;
    idstringTitle: number;
    postText: string;
    idstringPostText: number;
    state: number;
    hashTags: string;
    created: Date;
    likeid: number = 0;
    isLikeUser: number = 0;
    idauthorNavigation : UserDTO;
    
    socialWallBlogs: SocialWallBlogDTO[] = [];
    SocialWallBlogChild: SocialWallBlogDTO[] = [];
    socialWallLikes: SocialWallLikeDTO[] = [];
    socialWallContents: SocialWallContentDTO[] = [];
    socialWallClassrooms: SocialWallClassroomDTO[] = [];
    socialWallAdditionalCustomers: SocialWallAdditionalCustomerDTO[] = [];
}

<div class="component-main center-item">
    <div style="margin-bottom: 20px;">
        <h2>{{ 'Translations' | translate }}</h2>
    </div>
    <div [hidden]="translations.data == null || translations.data.length === 0" style="width: 80%;" class="center-item">
        <mat-table [dataSource]="translations" class="mat-elevation-z8" style="margin-top: 20px; text-align: start; line-height: 1.6;">
            <ng-container matColumnDef="italian">
              <mat-header-cell *matHeaderCellDef> {{ 'Italian' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-form-field *ngIf="currentTranslate.id == element.id">
                    <textarea matInput [(ngModel)]="currentTranslate.it_IT"></textarea>
                </mat-form-field>
                <span *ngIf="currentTranslate.id != element.id">
                    {{ element.it_IT }}
                </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="english">
              <mat-header-cell *matHeaderCellDef> {{ 'English' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let element">
                  <mat-form-field *ngIf="currentTranslate.id == element.id">
                    <textarea matInput [(ngModel)]="currentTranslate.en_US"></textarea>
                </mat-form-field>
                <span *ngIf="currentTranslate.id != element.id">
                    {{ element.en_US }}
                </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="spanish">
                <mat-header-cell *matHeaderCellDef> {{ 'Spanish' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-form-field *ngIf="currentTranslate.id == element.id">
                      <textarea matInput [(ngModel)]="currentTranslate.es_ES"></textarea>
                  </mat-form-field>
                  <span *ngIf="currentTranslate.id != element.id">
                      {{ element.es_ES }}
                  </span>
                  </mat-cell>
            </ng-container>

            <ng-container matColumnDef="french">
              <mat-header-cell *matHeaderCellDef> {{ 'French' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-form-field *ngIf="currentTranslate.id == element.id">
                    <textarea matInput [(ngModel)]="currentTranslate.fr_FR"></textarea>
                </mat-form-field>
                <span *ngIf="currentTranslate.id != element.id">
                    {{ element.fr_FR }}
                </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="deutsch">
              <mat-header-cell *matHeaderCellDef> {{ 'German' | translate}} </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-form-field *ngIf="currentTranslate.id == element.id">
                    <textarea matInput [(ngModel)]="currentTranslate.de_DE"></textarea>
                </mat-form-field>
                <span *ngIf="currentTranslate.id != element.id">
                    {{ element.de_DE }}
                </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="russian">
                <mat-header-cell *matHeaderCellDef> {{ 'Russian' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-form-field *ngIf="currentTranslate.id == element.id">
                    <textarea matInput [(ngModel)]="currentTranslate.ru_RU"></textarea>
                </mat-form-field>
                <span *ngIf="currentTranslate.id != element.id">
                    {{ element.ru_RU }}
                </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="portuguese">
                <mat-header-cell *matHeaderCellDef> {{ 'Portuguese' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-form-field *ngIf="currentTranslate.id == element.id">
                    <textarea matInput [(ngModel)]="currentTranslate.pt_PT"></textarea>
                </mat-form-field>
                <span *ngIf="currentTranslate.id != element.id">
                    {{ element.pt_PT }}
                </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef><!-- {{ 'Edit' | translate}} --></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="currentTranslate.id != element.id" [matTooltip]="'Edit' | translate" (click)="editTranslation(element)">
                        <mat-icon style="color: black;">edit</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" *ngIf="currentTranslate.id == element.id" [matTooltip]="'Edit' | translate" (click)="sendEdits()" [disabled]="!enableSendButton() || sending" style="margin-right: 20px;">
                        <mat-icon>check</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" *ngIf="currentTranslate.id == element.id" [matTooltip]="'Cancel' | translate" (click)="cancelEdit()" [disabled]="sending">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-cell>
              </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator #paginator
            [pageIndex]="0"
            [pageSize]="20"
            [pageSizeOptions]="[10, 20, 50]"
            color="primary"
            showFirstLastButtons>
        </mat-paginator>
    </div>
    <div *ngIf="translations.data == null || translations.data.length === 0">
        <app-error-message customClass="warning"
            [errorTitle]="'There are no translations' | translate"
            [errorContent]="'Create a course to start editing translations' | translate">
        </app-error-message>
    </div>
</div>

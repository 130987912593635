import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SkillDTO } from 'src/app/models/dto/skillDTO';
import { SkillService } from 'src/app/services/skill.service';
import { SkillDialogComponent } from './skill-dialog/skill-dialog.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { User } from 'src/app/models/user';
import { MatAccordion } from '@angular/material/expansion';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit {
  skills: SkillDTO[] = [];
  displayedColumns: string[] = ['name', 'description', 'minMaxScore', 'actions'];
  loading = false;
  
  customers: any[] = [];
  customersBackup: any = [];
  currentCustomerUsers: User[] = [];
  currentCustomerId: number = null;
  currentCustomerName: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  value: string;
  searchValue: string = '';
  pageIndex: number = 0;
  pageNo: number = 0;
  pageSize: number = 10;
  pageStart: number = 0;
  pageEnd: number = this.pageSize;
  skillsBackup: SkillDTO[];

  constructor(
    private skillService: SkillService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchSkills();
    this.loadSkills();
  }

  fetchSkills(): void {
    this.loading = true;
    this.skillService.getSkills().subscribe({
      next: (skills) => {
        this.skills = skills;
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching skills:', err);
        this.snackBar.open('Failed to fetch skills. Please try again.', 'Dismiss', { duration: 3000 });
        this.loading = false;
      },
    });
  }

  loadSkills(): void {
    this.loading = true;
    this.skillService.getSkills().subscribe({
      next: (skills) => {
        this.skills = skills;
        this.loading = false;
      },
      error: () => {
        this.snackBar.open('Failed to load skills.', 'Close', { duration: 3000 });
        this.loading = false;
      }
    });
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(SkillDialogComponent, {
      width: '400px',
      data: { mode: 'create' },
    });

    dialogRef.afterClosed().subscribe((newSkill: SkillDTO) => {
      if (newSkill) {
        this.skills.push(newSkill);
        this.snackBar.open('Skill added successfully!', 'Dismiss', { duration: 3000 });
        this.loadSkills();
      }
    });
  }

  openEditDialog(skill: SkillDTO): void {
    const dialogRef = this.dialog.open(SkillDialogComponent, {
      width: '400px',
      data: { mode: 'edit', skill },
    });

    dialogRef.afterClosed().subscribe((updatedSkill: SkillDTO) => {
      if (updatedSkill) {
        const index = this.skills.findIndex((s) => s.id === updatedSkill.id);
        if (index > -1) {
          this.skills[index] = updatedSkill;
          this.snackBar.open('Skill updated successfully!', 'Dismiss', { duration: 3000 });
          this.loadSkills();
        }
      }
    });
  }

  async deleteSkill(id: number): Promise<void> {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete skill')),
        body: await firstValueFrom(
          this.translate.get('Are you sure you want to delete this skill?')
        ),
      },
    });
  
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) return;
  
      this.skillService.deleteSkill(id).subscribe({
        next: () => {
          this.skills = this.skills.filter((s) => s.id !== id);
          this.snackBar.open('Skill deleted successfully!', 'Dismiss', { duration: 3000 });
          this.loadSkills();
        },
        error: (err) => {
          console.error('Error deleting skill:', err);
          this.snackBar.open('Failed to delete skill. Please try again.', 'Dismiss', { duration: 3000 });
        },
      });
    });
  }
  

  changePage(event?: PageEvent): void {
    if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }

    const minIndex = this.pageIndex * this.pageSize;
    const maxIndex = minIndex + this.pageSize;

    this.skills = this.getFilteredSkills(this.searchValue).slice(minIndex, maxIndex);
  }

  getFilteredSkills(searchValue: string): SkillDTO[] {
      if (!searchValue) {
          return this.skillsBackup;
      }
      return this.skillsBackup.filter(skill =>
          skill.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          skill.description?.toLowerCase().includes(searchValue.toLowerCase())
      );
  }

}

<h2 mat-dialog-title>{{ 'People who liked this' | translate }}</h2>
<mat-dialog-content style="display: flex; flex-direction: column;">
  <mat-list>
    <mat-list-item *ngFor="let item of userArr">
      {{ item.name | translate }}
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>{{ 'Close' | translate }}</button>
</mat-dialog-actions>

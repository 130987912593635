<div hasBackdrop="false" id="conference-container">
    <app-user-video *ngIf="remoteParticipants.length > 0; else noSession" 
        [streamManager]="remoteParticipants[0].manager"
        [name]="remoteParticipants[0].name"
        [color]="remoteParticipants[0].color"
        visualType="cover">
    </app-user-video>
    <ng-template #noSession>
        <mat-drawer-container>
            <div class="drawer-title-container">
                <p>{{ 'Empty room' | translate }}</p>
            </div>
        </mat-drawer-container>
    </ng-template>
</div>

<div *ngIf="loading; else content" class="component-main" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayoutAlign="center center" style="height: 60vh;">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</div>

<ng-template #content>
  <!-- Title and Back Button -->
  <div>
    <div fxLayout="row" fxLayoutAlign="center start">
      <h2>{{ exerciseDetails.exercise?.name || 'Exercise Details' }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
      <button mat-stroked-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
      <button mat-fab color="primary" matTooltip="{{ 'Refresh' | translate }}" (click)="refreshPage()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <!-- PDF Wrapper -->
  <div id="exerciseDetailsContainer">
    <!-- Main Container -->
    <div *ngIf="!error && exerciseDetails; else errorBlock" style="width: 100%" class="component-main" fxLayout="column"
      fxLayoutGap="20px">
      <mat-card class="details-card">
        <mat-card-content class="textFont">

          <section class="session-details relative-container" fxLayout="row" fxLayoutGap="20px"
            fxLayoutAlign="space-between stretch">
            <!-- User & Session Data -->
            <div class="session-info-container">
              <h3 class="mb-4">{{ exerciseDetails.session?.userName || 'Unknown User' }}</h3>

              <div class="card-container">
                <!-- DateTime Block -->
                <mat-card class="p-shadow-4 equal-height-card">
                  <mat-card-content>
                    <div>
                      <div class="content-area">
                        <div class="title-row">
                          <span class="p-text-secondary p-mb-3"><b>DateTime</b></span>
                        </div>
                        <div class="p-text-bold p-text-md">
                          <p><b>Start:</b> {{ exerciseDetails.session?.startDate || 'Not Available' }}</p>
                          <p><b>Stop:</b> {{ exerciseDetails.session?.stopDate || 'Not Available' }}</p>
                          <p><b>Max Duration:</b> {{ (exerciseDetails.exercise?.maxDurationMinute || 0) / 60000 }} minutes</p>
                          <p><b>Total Duration:</b> {{ getTotalDuration() }}</p>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="center center" class="icon-container bg-blue">
                        <mat-icon class="icon-style">calendar_today</mat-icon>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>

                <!-- Score Block with Enhanced Display -->
                <mat-card class="p-shadow-4 equal-height-card">
                  <mat-card-content>
                    <div>
                      <div class="content-area">
                        <div class="title-row">
                          <span class="p-text-secondary p-mb-3"><b>Score</b></span>
                        </div>
                        <div class="score-container">
                          <div class="score-value">
                            {{ exerciseDetails.session?.score ?? '---' }}
                          </div>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="center center" class="icon-container bg-green">
                        <mat-icon class="icon-style">star</mat-icon>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>

            <!-- Grading Section or "Graded" Flag + Download Certificate Button + Notarize button -->
            <div fxFlex="1 1 35%" class="graded-container">
              <ng-container *ngIf="!isGradeSubmitted; else gradedButtons">
                <!-- Grading Section Trigger: ONLY VISIBLE IF TEACHER -->
                <ng-container *ngIf="auth.isTeacher()">
                  <button mat-raised-button color="accent" (click)="openGradePopup()">
                    <mat-icon>{{ gradingExpanded ? 'expand_less' : 'grading' }}</mat-icon>
                    {{ gradingExpanded ? 'Hide Grading' : 'Grade Exercise' }}
                  </button>

                  <!-- Grading Panel -->
                  <div *ngIf="gradingExpanded" class="grading-panel">
                    <h3>Grading</h3>
                    <p class="grading-instructions">Assign scores below.</p>

                    <!-- If only one skill (Overall) -->
                    <div
                      *ngIf="assignedSkills.length === 1 && assignedSkills[0]?.overall === 1; else multipleSkillsTemplate">
                      <div class="skills-container">
                        <label class="skills-label">{{ assignedSkills[0].name }}:</label>
                        <input type="number" min="{{ assignedSkills[0].minScore }}"
                          max="{{ assignedSkills[0].maxScore }}" class="skills-input"
                          [(ngModel)]="selectedScores[assignedSkills[0].id]" (input)="onScoreChange()" />
                      </div>
                    </div>

                    <!-- If multiple assigned skills -->
                    <ng-template #multipleSkillsTemplate>
                      <mat-tab-group animationDuration="0ms">
                        <mat-tab *ngFor="let skill of assignedSkills" [label]="skill.name">
                          <div class="skills-container">
                            <label class="skills-label">
                              {{ skill.name }} ({{ skill.minScore }}-{{ skill.maxScore }}):
                            </label>
                            <input type="number" [min]="skill.minScore" [max]="skill.maxScore" class="skills-input"
                              [(ngModel)]="selectedScores[skill.id]" (input)="onScoreChange()" />
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                    </ng-template>

                    <div fxLayoutAlign="center center" class="submit-grade-container">
                      <button mat-raised-button color="primary" (click)="submitGrade()" class="submit-grade-btn"
                        [disabled]="!canSubmitGrade() || isGradeSubmitted">
                        Submit Grade
                      </button>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #gradedButtons>
                <div class="buttons-container">
                  <div class="tooltip-wrapper" [matTooltip]="notarizationId ? 'Notarization ID: ' + notarizationId : ''">
                    <button *ngIf="!notarizationId && auth.isTeacher()" mat-raised-button style="background-color: rgb(80, 146, 207);"
                      (click)="notarizePDF()" [disabled]="exerciseDetails.session?.state !== 5">
                      Notarize PDF
                    </button>
                    <button *ngIf="notarizationId" mat-raised-button (click)="copyNotarizationId()">
                      Notarized <mat-icon class="copy-icon">content_copy</mat-icon>
                    </button>
                  </div>
                  <button mat-raised-button color="accent" (click)="downloadPDF()"
                    [disabled]="exerciseDetails.session?.state !== 5 && !exerciseDetails.session?.certificatePath">
                    Download PDF
                  </button>
                  <div class="graded-flag"
                    [ngClass]="{'grayed-out': exerciseDetails.session?.state !== 4 && exerciseDetails.session?.state !== 5 && !isGradeSubmitted}">
                    GRADED
                  </div>
                  
                </div>
              </ng-template>
            </div>

          </section>

          <mat-divider style="margin: 20px 0;"></mat-divider>

          <!-- Path Buttons - Main tabs -->
          <div class="path-buttons" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
            <button mat-raised-button class="path-button" [color]="activeTab === 'recordings' ? 'primary' : 'basic'"
              (click)="selectTab('recordings')">
              Exercise Results
            </button>

            <button *ngIf="isGradeSubmitted" mat-raised-button class="path-button"
              [color]="activeTab === 'skillResults' ? 'primary' : 'basic'" (click)="selectTab('skillResults')">
              Skill Results
            </button>
          </div>

          <!-- Content sections -->
          <section class="skill-section">
            <ng-container [ngSwitch]="activeTab">
              <!-- Recordings tab content -->
              <ng-container *ngSwitchCase="'recordings'">
                <!-- Recording subtabs -->
                <div class="recording-subtabs" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                  <button mat-stroked-button class="subtab-button"
                    [color]="activeRecordingSubTab === 'rgb' ? 'accent' : 'basic'"
                    (click)="selectRecordingSubTab('rgb')">
                    RGB Video
                  </button>
                  <button mat-stroked-button class="subtab-button"
                    [color]="activeRecordingSubTab === 'poseva' ? 'accent' : 'basic'"
                    (click)="selectRecordingSubTab('poseva')">
                    Poseva Video
                  </button>
                  <button mat-stroked-button class="subtab-button"
                    [color]="activeRecordingSubTab === 'graph' ? 'accent' : 'basic'"
                    (click)="selectRecordingSubTab('graph')">
                    Speed & Torque Graph
                  </button>
                  <button mat-stroked-button class="subtab-button"
                    [color]="activeRecordingSubTab === 'io' ? 'accent' : 'basic'" (click)="selectRecordingSubTab('io')">
                    IO Scanner
                  </button>
                </div>

                <!-- Recording content based on selected subtab -->
                <div class="recording-content" style="margin-top: 15px;">
                  <ng-container [ngSwitch]="activeRecordingSubTab">
                    <ng-container *ngSwitchCase="'rgb'">
                      <h3>RGB Video</h3>
                      <ng-container *ngIf="exerciseDetails?.session?.rgbVideoPath; else noDataRgb">
                        <div class="skill-container">
                          <p style="font-style: italic; color: #666;">(Insert RGB video component here)</p>
                        </div>
                      </ng-container>
                      <ng-template #noDataRgb>
                        <p style="color: #666;">No RGB video present for this exercise.</p>
                      </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchCase="'poseva'">
                      <h3>Poseva Video</h3>
                      <ng-container *ngIf="exerciseDetails?.session?.posevaVideoPath; else noDataPoseva">
                        <div class="skill-container">
                          <p style="font-style: italic; color: #666;">(Insert Poseva video component here)</p>
                        </div>
                      </ng-container>
                      <ng-template #noDataPoseva>
                        <p style="color: #666;">No Poseva video present for this exercise.</p>
                      </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchCase="'graph'">
                      <h3>Speed & Torque Graph</h3>
                      <ng-container
                        *ngIf="exerciseDetails?.session?.mmSpeedPath && exerciseDetails?.session?.mmTorquePath; else noDataGraph">
                        <div class="skill-container">
                          <canvas id="speedTorqueChart"></canvas>
                        </div>
                      </ng-container>
                      <ng-template #noDataGraph>
                        <p style="color: #666;">No Graph present for this exercise.</p>
                      </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchCase="'io'">
                      <h3>IO Scanner</h3>
                      <ng-container *ngIf="exerciseDetails?.session?.ioScannerPath; else noDataIo">
                        <div class="skill-container">
                          <p style="font-style: italic; color: #666;">(Insert IO Scanner viewer here)</p>
                        </div>
                      </ng-container>
                      <ng-template #noDataIo>
                        <p style="color: #666;">No IO Scanner present for this exercise.</p>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>

              <!-- Skill Results tab content -->
              <ng-container *ngSwitchCase="'skillResults'">
                <h3>Skill Results</h3>
                <div class="skill-results-container">
                  <table class="skill-results-table">
                    <thead>
                      <tr>
                        <th>Skill</th>
                        <th>Score</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Overall skill first, highlighted -->
                      <tr *ngIf="exerciseDetails?.OverallSkill?.SkillId" class="overall-skill-row">
                        <td class="skill-name">{{ exerciseDetails?.OverallSkill?.SkillName || 'Overall' }}</td>
                        <td>{{ exerciseDetails?.session?.skillScores?.[exerciseDetails?.OverallSkill?.SkillId] }}</td>
                        <td>{{ exerciseDetails?.session?.skillNotes?.[exerciseDetails?.OverallSkill?.SkillId] || 'No
                          notes' }}</td>
                      </tr>
                      <!-- Then other skills -->
                      <ng-container *ngFor="let skill of assignedSkills">
                        <tr *ngIf="skill.id !== exerciseDetails?.OverallSkill?.SkillId">
                          <td class="skill-name">{{ skill.name }}</td>
                          <td>{{ exerciseDetails?.session?.skillScores?.[skill.id] }}</td>
                          <td>{{ exerciseDetails?.session?.skillNotes?.[skill.id] || 'No notes' }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </ng-container>
          </section>

        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-template #errorBlock>
    <div class="error" fxLayoutAlign="center center">
      {{ error }}
    </div>
  </ng-template>
</ng-template>
<div *ngIf="content.type === 3 && currentUser.id === authorId && currentUser.coursesEnabled; else openToContent" fxLayoutAlign="start end" fxFlex="85">
    <button mat-icon-button color="primary"
        [disabled]="content.lessonSession.state !== 2"
        (click)="editSurvey()"
        [matTooltip]="'Edit survey' | translate">
        <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button color="warn"
        (click)="deleteContent()"
        [matTooltip]="'Delete' | translate">
        <mat-icon>delete</mat-icon>
    </button>
</div>

<ng-template #openToContent>
    <div fxLayout="column" fxLayoutAlign="end start">      
        <button mat-raised-button color="accent"
            *ngIf="!compact"
            (click)="goTo()">
            <mat-icon>
                {{ isSurvey ? 'content_paste' : 'launch' }}
            </mat-icon>
            {{ (isSurvey ? 'View survey results' : 'Go to content') | translate }}
        </button>
        <button mat-icon-button
            *ngIf="compact"
            [matTooltip]="(isSurvey ? 'View survey results' : 'Go to content') | translate"
            (click)="goTo()">
            <mat-icon>
                {{ isSurvey ? 'content_paste' : 'launch' }}
            </mat-icon>
        </button>
    </div>
</ng-template>

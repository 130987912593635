import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { SubscriptionSessionDTO } from 'src/app/models/dto/subscriptionSessionDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { CsvExporterService, PaymentReport, PaymentsTable } from 'src/app/services/csv-exporter.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { OrdersService } from 'src/app/services/orders.service'
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { TranslateService } from '@ngx-translate/core';
import { AzureStorageService } from 'src/app/services/storage.service';
import { lessonResultsTotalDTO } from 'src/app/models/dto/lessonResultsTotalDTO';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  currentYear = new Date().getFullYear().valueOf();
  currentMonth = new Date().getMonth();
  currentQuarter =  this.currentMonth === 0 || this.currentMonth === 1 || this.currentMonth === 2 ? 0 :
                    this.currentMonth ===  3 || this.currentMonth === 4 || this.currentMonth === 5 ? 1 : 
                    this.currentMonth === 6 || this.currentMonth === 7 || this.currentMonth === 8 ? 2 : 
                    this.currentMonth === 9 || this.currentMonth === 10 || this.currentMonth === 11 ? 3 : 4;
  currentUser: User = null;
  customers: CustomerDTO[] = [];
  paymentsList: SubscriptionSessionDTO[] = [];
  defaultList: SubscriptionSessionDTO[] = [];
  totalWebinar: number;
  totalCourses: number = 0;
  totalPackages: number;
  totalWebinarCost: number;
  totalCoursesCost: number;
  totalPackagesCost: number;
  webinarList: SubscriptionSessionDTO[] = [];
  packagesList: SubscriptionSessionDTO[] = [];
  coursesList: SubscriptionSessionDTO[] = [];
  selectedCustomer: number = 0;
  checked:boolean = false;
  selectedElement: any = null;
  allFeeObjects: CourseDTO[] = [];
  allFeeObjectsDefaultList: CourseDTO[] = [];
  selectedObject: number = 0;
  customerId: number;
  reportLink: string;
  totalWebinarResults: lessonResultsTotalDTO[] = [];
  totalAllWebinarResults: lessonResultsTotalDTO[] = [];
  totalMonthTime: number;
  totalAllMonthTime: number;
  customer: CustomerDTO[] = null;
  invoicePerMonth: number;
  webinarStreamingCost: number;

  normalList: SubscriptionSessionDTO[] = [];
  
  totalPrice: number;
  totalNumber: number;
  totalFee: number;

  objects: object[] = [
    {name: 'All', value: 0},
    {name: 'Webinar', value: 1},
    {name: 'Course', value: 2},
    {name: 'Package', value: 3}
  ]

  mode: FormControl = new FormControl(0);
  searchForm: FormControl = new FormControl();
  monthDate = new FormControl(this.currentMonth);
  yearDate = new FormControl(this.currentYear);
  customerForm = new FormControl();
  paymentType = new FormControl(0);
  objectType = new FormControl(0);
  objectFilter = new FormControl();
  cost = new FormControl(0);

  dataSource: MatTableDataSource<SubscriptionSessionDTO>;

  months: {name: string; value: number}[] = [
    {name: 'January', value: 0},
    {name: 'February', value: 1},
    {name: 'March', value: 2},
    {name: 'April', value: 3},
    {name: 'May', value: 4},
    {name: 'June', value: 5},
    {name: 'July', value: 6},
    {name: 'August', value: 7},
    {name: 'September', value: 8},
    {name: 'October', value: 9},
    {name: 'November', value: 10},
    {name: 'December', value: 11}
  ]

  quarters: {name: string; value: number}[] = [
    {name: 'All', value: 4},
    {name: 'January-March', value: 0},
    {name: 'April-June', value: 1 },
    {name: 'July-September', value: 2 },
    {name: 'October-December', value: 3}
  ]

  modes: {name: string; value:number}[] = [
    {name: 'Monthly', value: 0},
    {name: 'Quarterly',value : 1}
  ]

  quarterDate: FormControl = new FormControl(this.currentQuarter);

  years: number[] = [
    this.currentYear,
    this.currentYear - 1,
    this.currentYear - 2,
    this.currentYear - 3,
    this.currentYear - 4,
    this.currentYear - 5
  ]

  payments: object[] = [
    {name: 'All', value: 0},
    {name: 'Manual', value: 1},
    {name: 'Stripe', value: 2}
  ]

  costs: object[] = [
    {name: '> 0', value : 0},
    {name: '> 50', value : 50},
    {name: '> 100', value : 100},
    {name: '> 500', value : 500}
  ]

  displayedColumns: string[] = ['date', 'user', 'product', 'cartItemName', 'amount', 'currency', 'paymentType'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private auth: AuthService,
              private orderService: OrdersService,
              private calendar: CalendarService,
              private exporter: CsvExporterService,
              private translate: TranslateService,
              private storage: AzureStorageService,
              public darkService: DarkThemeService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.customerId = this.currentUser.idCustomer;
    this.getCustomers();
    this.getAllFeeObjects();
    if(this.isCustomerAdmin()) {
      this.getCustomer(this.customerId);
      this.getPayments();
    }

    if (this.isAdmin()) {
      this.getAllTotalWebinarSentStreamingTime();
      this.getPayments();
    }
  }

  isAdmin() {
    return this.currentUser.isAdmin;
  }

  isCustomerAdmin() {
    return this.currentUser.isCustomerAdmin;
  }
    
  getPayments() {
    this.orderService.getPaymentsList().subscribe(res => {
      this.paymentsList = res.subscriptionList;
      this.defaultList = res.subscriptionList;

      this.defaultList.sort((a, b) => {
        return new Date(b.created).valueOf() - new Date(a.created).valueOf();
      });

      if (this.isAdmin() || (this.isCustomerAdmin() && (this.invoicePerMonth === 1 || this.invoicePerMonth === 0))) {
        this.paymentsList = this.paymentsList.filter(s => new Date(s.created).getMonth() === this.monthDate.value && new Date(s.created).getFullYear() === this.yearDate.value );
        this.paymentsList.sort((a, b) => {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        });
      }

      else if (this.isCustomerAdmin() && this.invoicePerMonth === 2) {
        this.paymentsList = this.defaultList.filter(s => {
          let createdDate = new Date(s.created);

          if (this.quarterDate.value === 4)
            return  createdDate.getFullYear() === this.yearDate.value;

          let currentMonth = createdDate.getMonth();
          let currentQuarter = Math.floor(currentMonth / 3); 

          return currentQuarter === this.quarterDate.value && createdDate.getFullYear() === this.yearDate.value;
        });
        this.paymentsList = this.paymentsList.sort((a, b) => {
          return new Date(b.created).getTime() - new Date(a.created).getTime();
        });
      }
    
      this.dataSource = new MatTableDataSource(this.paymentsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.findTotals();
  
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'user':
            return `${item.user.name} ${item.user.surname}`;
          case 'product':
            return this.getObjectType(item);
          case 'date':
            return item.created;
          default:
            return item[property];
        }
      };
    })
  }

  private findTotals() {
    this.totalWebinar = this.dataSource.data.filter(s => this.getObjectType(s) === 'webinar').length;
    this.webinarList = this.dataSource.data.filter(s => this.getObjectType(s) === 'webinar');
    this.totalWebinarCost = this.webinarList.reduce((acc, item) => acc + item.amount, 0);
    this.totalPackages = this.dataSource.data.filter(s => this.getObjectType(s) === 'package').length;
    this.packagesList = this.dataSource.data.filter(s => this.getObjectType(s) === 'package');
    this.totalPackagesCost = this.packagesList.reduce((acc, item) => acc + item.amount, 0);
    this.totalCourses = this.dataSource.data.filter(s => this.getObjectType(s) === 'course').length;
    this.coursesList = this.dataSource.data.filter(s => this.getObjectType(s) === 'course');
    this.totalCoursesCost = this.coursesList.reduce((acc, item) => acc + item.amount, 0);
    this.totalNumber = this.totalWebinar + this.totalPackages + this.totalCourses;
    let percentageWebinar = (this.totalWebinarCost * 25)/100;
    let percentagePackages = (this.totalPackagesCost * 25)/100;
    let percentageCourses = (this.totalCoursesCost * 25)/100;
    this.totalPrice = this.totalWebinarCost + this.totalPackagesCost + this.totalCoursesCost;
    this.totalFee = percentageWebinar + percentagePackages + percentageCourses + this.webinarStreamingCost;
  }

  dateChanged() {
    this.paymentsList = this.defaultList.filter(s => new Date(s.created).getMonth() === this.monthDate.value && new Date(s.created).getFullYear() === this.yearDate.value );
    this.dataSource = new MatTableDataSource(this.paymentsList);
    this.findTotals();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'user':
          return `${item.user.name} ${item.user.surname}`;
        case 'product':
          return this.getObjectType(item);
        case 'date':
          return item.created;
        default:
          return item[property];
      }
    };
  }

  getObjectType(element:SubscriptionSessionDTO) {
    if(element.subscription.conference !== null)
      return 'webinar'
    if(element.subscription.package !== null) 
      return 'package'
    if(element.subscription.course !== null) 
      return 'course'
  }

  getCustomers() {
    this.customers = [];
    this.calendar.getCustomerForAdmin()
      .subscribe(output => this.customers = output);
  }

  getPaymentsByCustomer() {
    let payments = this.paymentsList;

    if (this.selectedCustomer !== 0)
      payments = payments.filter(o => o.subscription.idCustomer === this.selectedCustomer);

    this.dataSource.data = payments;
  }

  filter() {
    if ((this.isAdmin() && this.mode.value === 0) || (this.isCustomerAdmin() && (this.invoicePerMonth === 1 || this.invoicePerMonth === 0)))
      this.paymentsList = this.defaultList.filter(s => new Date(s.created).getMonth() === this.monthDate.value && new Date(s.created).getFullYear() === this.yearDate.value );
    else if ((this.isAdmin() && this.mode.value === 1) || (this.isCustomerAdmin() && this.invoicePerMonth === 2)) {
      this.paymentsList = this.defaultList.filter(s => {
        let createdDate = new Date(s.created);

        if (this.quarterDate.value === 4)
            return  createdDate.getFullYear() === this.yearDate.value;

        let currentMonth = createdDate.getMonth();
        let currentQuarter = Math.floor(currentMonth / 3);

        return currentQuarter === this.quarterDate.value && createdDate.getFullYear() === this.yearDate.value;;
      });
      this.paymentsList = this.paymentsList.sort((a, b) => {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
      });
    }
    
    let payments = this.paymentsList;

    if(this.selectedObject !== 0 && this.objectFilter.value.description === 'webinar') {
      let webinarList = this.defaultList.filter(s => this.getObjectType(s) === 'webinar');
      payments = webinarList.filter(e => e.subscription.conference.id === this.objectFilter.value.id);
    }
    if(this.selectedObject !== 0 && this.objectFilter.value.description === 'course') {
      let coursesList = this.defaultList.filter(s => this.getObjectType(s) === 'course');
      payments = coursesList.filter(e => e.subscription.course.id === this.objectFilter.value.id);
    }
    if(this.selectedObject !== 0 && this.objectFilter.value.description === 'package') {
      let packageList = this.defaultList.filter(s => this.getObjectType(s) === 'package');
      payments = packageList.filter(e => e.subscription.package.id === this.objectFilter.value.id);
    }

    if(this.paymentType.value === 0)
      payments = payments;

    if(this.paymentType.value === 2)
      payments = payments.filter(p => p.paymentType === 'credit card')
    
    if(this.paymentType.value === 1)
      payments = payments.filter(p => p.paymentType === 'manual')

    if(this.objectType.value === 0)
      payments = payments;
      
    this.allFeeObjects = this.allFeeObjectsDefaultList;

    if(this.objectType.value === 1) {
      payments = payments.filter(p => this.getObjectType(p) === 'webinar')
      this.allFeeObjects = this.allFeeObjects.filter(o => o.description === 'webinar')
    }

    if(this.objectType.value === 2) {
      payments = payments.filter(p => this.getObjectType(p) === 'course')
      this.allFeeObjects = this.allFeeObjects.filter(o => o.description === 'course')
    }

    if(this.objectType.value === 3){
      payments = payments.filter(p => this.getObjectType(p) === 'package')
      this.allFeeObjects = this.allFeeObjects.filter(o => o.description === 'package')
    }

    if(this.cost.value !== 0)
      payments = payments.filter(p => p.amount > this.cost.value)

    if(this.selectedCustomer !== 0) {
      payments = payments.filter(o => o.subscription.idCustomer === this.selectedCustomer);
      this.allFeeObjects = this.allFeeObjects.filter(o => o.idCustomer === this.selectedCustomer);
    }

    if(this.selectedCustomer === 0 && this.isAdmin() && this.mode.value === 0)
      this.totalAllMonthTime = this.totalAllWebinarResults.find(t => t.month === this.monthDate.value + 1 && t.year === this.yearDate.value)?.totalSentStreamingTime;
      if(this.totalAllMonthTime !== undefined)
        this.webinarStreamingCost = this.checkPrice(this.totalAllMonthTime);
      else
        this.webinarStreamingCost = 0;

    if(this.selectedCustomer === 0 && this.isAdmin() && this.mode.value === 1 ) 
      this.getAllQuarterlyWebinarTime();

    if(this.selectedCustomer !== 0 && this.isAdmin())
      this.getTotalWebinarSentStreamingTime(this.selectedCustomer);

    if (this.isCustomerAdmin() && (this.invoicePerMonth === 0 || this.invoicePerMonth === 1)) {
      this.totalMonthTime = this.totalWebinarResults.find(t => t.month === this.monthDate.value + 1 && t.year === this.yearDate.value)?.totalSentStreamingTime;
      if(this.totalMonthTime !== undefined)
        this.webinarStreamingCost = this.checkPrice(this.totalMonthTime);
      else
        this.webinarStreamingCost = 0;
    }

    if (this.isCustomerAdmin() && this.invoicePerMonth === 2)
      this.getQuarterlyWebinarTime();

    this.dataSource.data = payments;
    this.findTotals();
  }

  applyFilter (event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.dataSource.filter = value.trim().toLowerCase();
    this.findTotals();
  }

  saveCsv() {
    let monthlyPayments: PaymentReport[] = [];
    let monthlyUsersPayments: PaymentsTable[] = [];
    let customerName: string;
    let payments = this.dataSource.data;

    if ((this.isAdmin() && this.mode.value === 1 ) || this.invoicePerMonth === 2)
      monthlyPayments.push({ 
        year: this.yearDate.value,
        quarter: this.quarterDate.value === 4 ? '' : this.quarterDate.value + 1, 
        webinarPurchased: this.totalWebinar,
        webinarAmount:  this.formattedNumber(this.totalWebinarCost),
        coursesPurchased: this.totalCourses,
        coursesAmount:  this.formattedNumber(this.totalCoursesCost),
        packagesPurchased: this.totalPackages,
        packagesAmount:  this.formattedNumber(this.totalPackagesCost),
        totalPayments: this.totalNumber,
        totalAmount:  this.formattedNumber(this.totalPrice)
      });

    payments.filter(p => monthlyUsersPayments.push({
      year: this.yearDate.value,
      month: new Date(p.created).getMonth() + 1,
      day: new Date(p.created).getDate(),
      name: p.user.name,
      surname: p.user.surname,
      product: this.translate.instant(this.getObjectType(p)),
      productName: p.cartItemName,
      amount:  this.formattedNumber(p.amount),
      currency: p.currency,
      paymentType: this.getPaymentType(p)
    }))

    if ((this.isAdmin() && this.mode.value === 0 )|| this.invoicePerMonth === 1)
      monthlyPayments.push({ 
        year: this.yearDate.value,
        month: this.monthDate.value + 1, 
        webinarPurchased: this.totalWebinar,
        webinarAmount:  this.formattedNumber(this.totalWebinarCost),
        coursesPurchased: this.totalCourses,
        coursesAmount:  this.formattedNumber(this.totalCoursesCost),
        packagesPurchased: this.totalPackages,
        packagesAmount:  this.formattedNumber(this.totalPackagesCost),
        totalPayments: this.totalNumber,
        totalAmount: this.formattedNumber(this.totalPrice)
      });

    if (this.isAdmin()){
      let customer = this.customers.find(c => c.id === this.selectedCustomer)

    if (customer === undefined || customer === null)
      customerName = "all customers"
    else
    customerName = customer.name;
    }
    else
      customerName = this.currentUser.customerName;

    let mode = this.isAdmin() ? this.mode.value : this.isCustomerAdmin() ? (this.invoicePerMonth === 0 || this.invoicePerMonth === 1) ? 0 : this.invoicePerMonth === 2 ? 1 : 0 : 0;

    this.exporter.exportDataToCsv(
      monthlyPayments,
      ["Year", mode === 0 ? "Month" : "Quarter", "Webinar Purchased", "Webinar Amount", "Courses Purchased", "Courses Amount", "Packages Purchased", "Packages Amount", "Total Payments", "Total Collected"],
      `${mode === 1 ? 'Quarterly payments' : 'Monthly payments'} of ${customerName}`
    );

    this.exporter.exportDataToCsv(
      monthlyUsersPayments,
      ["Year", "Month", "Day", "Name", "Surname", "Product", "ProductName", "Amount", "Currency", "PaymentType"],
      `${mode === 1 ? 'Quarterly users payments' : 'Monthly users payments'} of ${customerName}`
    );
  }

  getPaymentType(element: SubscriptionSessionDTO) {
    if(element.paymentType === 'credit card')
      return 'stripe'
    else
      return 'manual'
  }

  getAllFeeObjects() {
    this.calendar.getAllFeeObjects().subscribe({
      next: res => {
        this.allFeeObjects = res;
        this.allFeeObjects = this.allFeeObjects.sort((a, b) => {
          let dateA = new Date(a.created);
          let dateB = new Date(b.created);
          return dateB.getTime() - dateA.getTime();
        });
        this.allFeeObjectsDefaultList = this.allFeeObjects;
      },
      error: err => {
        console.log(err.error.Message)
      }
    });
  }

  getReportLink() {
    let monthValue = this.monthDate.value + 1;
    if (this.invoicePerMonth === 2 || (this.isAdmin() && this.mode.value === 1))
      monthValue = this.quarterDate.value + 1;
    if(this.isAdmin())
      this.customerId = this.selectedCustomer === 0 ? 1 : this.selectedCustomer;
    this.calendar.getReportLink(this.customerId,monthValue,this.yearDate.value).subscribe(async res => {
      let response = res;
      let fileBlob: Blob = await this.storage.getFileFromUrl(response.reportUrl, "application/pdf");
      if (fileBlob) {
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(fileBlob);
        if(this.invoicePerMonth === 2 || (this.isAdmin() && this.mode.value === 1))
          downloadLink.download = `Report_Quarter_${monthValue}_${this.yearDate.value}.pdf`;
        else
          downloadLink.download = `Report_${monthValue}_${this.yearDate.value}.pdf`;
        downloadLink.click();
      }
    })
  }

  disablePdf(): boolean {
    if (this.quarterDate.value === 4)
      return true;

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1; 

    if ((this.isAdmin() && this.mode.value === 1 && this.selectedCustomer !== 0) || (this.isCustomerAdmin() && this.invoicePerMonth === 2))
      return !this.isQuarterPassed(this.yearDate.value, this.quarterDate.value + 1)
    else if ((this.isAdmin() && this.mode.value === 0) || (this.isCustomerAdmin() && this.invoicePerMonth === 1)) {
      if (currentYear > this.yearDate.value && this.dataSource?.data?.length > 0 && (this.isCustomerAdmin() || (this.isAdmin() && this.selectedCustomer !== 0))) {
        return false;
      }
      if (currentYear === this.yearDate.value && currentMonth > (this.monthDate.value + 1) && this.dataSource?.data?.length > 0 && (this.isCustomerAdmin() || (this.isAdmin() && this.selectedCustomer != 0))) {
        return false;
      }
    }

    return true;
  }

  isQuarterPassed(year:number,quarter:number) {
    let currentDate = new Date();
    let startMonth = (quarter - 1) * 3 + 1;

    if (currentDate.getFullYear() > year) {
      return true; 
    } else if (currentDate.getFullYear() === year && currentDate.getMonth() + 1 >= startMonth + 3) {
      return true; 
    } else {
      return false; 
    }
  }

  disableCsv(): boolean {
    if (this.dataSource?.data?.length > 0)
      return false
    else
      return true
  }

  //customer admin
  getTotalWebinarSentStreamingTime(customerId:number) {
    this.calendar.getLessonTotalResults(customerId).subscribe( res => {
      this.totalWebinarResults = res;
      this.totalMonthTime = 0;
      this.totalMonthTime = this.totalWebinarResults.find(t => t.month === this.monthDate.value + 1 && t.year === this.yearDate.value)?.totalSentStreamingTime;
      this.webinarStreamingCost = 0;
      if (this.totalMonthTime !== undefined)
        this.webinarStreamingCost = this.checkPrice(this.totalMonthTime)
      if (this.isCustomerAdmin && this.invoicePerMonth === 2 || (this.isAdmin && this.mode.value === 1)) {
        this.getQuarterlyWebinarTime();
      }
      this.findTotals();
    });
  }

 //customer signolo trimestrale
  private getQuarterlyWebinarTime() {
    let startMonth = this.quarterDate.value === 4 ? 1 : this.quarterDate.value * 3 + 1;
    let endMonth = this.quarterDate.value === 4 ? 11 : startMonth + 2;
    
    this.totalMonthTime = this.totalWebinarResults
      .filter(t => t.year === this.yearDate.value && t.month >= startMonth && t.month <= endMonth)
      .map(t => t.totalSentStreamingTime)
      .reduce((total, time) => total + time, 0);

    if (this.totalMonthTime !== undefined)
      this.webinarStreamingCost = this.checkPrice(this.totalMonthTime)
  }

  //tutti i customer trimestrale
  private getAllQuarterlyWebinarTime() {
    let startMonth = this.quarterDate.value === 4 ? 1 : this.quarterDate.value * 3 + 1;
    let endMonth = this.quarterDate.value === 4 ? 11 : startMonth + 2;
    this.webinarStreamingCost = 0;

    this.totalAllMonthTime = this.totalAllWebinarResults
      .filter(t => t.year === this.yearDate.value && t.month >= startMonth && t.month <= endMonth)
      .map(t => t.totalSentStreamingTime)
      .reduce((total, time) => total + time, 0);

    if (this.totalAllMonthTime !== undefined)
      this.webinarStreamingCost = this.checkPrice(this.totalAllMonthTime)
    else
      this.webinarStreamingCost = 0;
  }

  //tutti i customer mensile
  getAllTotalWebinarSentStreamingTime() {
    this.calendar.getLessonAllTotalResults().subscribe( res => {
      this.totalAllWebinarResults = res;
      this.totalAllMonthTime = this.totalAllWebinarResults.find(t => t.month === this.monthDate.value + 1 && t.year === this.yearDate.value)?.totalSentStreamingTime;
      this.webinarStreamingCost = 0;
      if(this.totalAllMonthTime !== undefined)
        this.webinarStreamingCost = this.checkPrice(this.totalAllMonthTime)
      else
        this.webinarStreamingCost = 0;
    });
  }

  getCustomer(idCustomer:number) {
    this.calendar.getCustumerForAdmin(idCustomer).subscribe(res => {
      this.customer = res;
      this.invoicePerMonth = this.customer[0].invoicePerMonth;
      if (this.isCustomerAdmin())
        this.getTotalWebinarSentStreamingTime(this.customerId);
    })
  }

  checkPrice(elapsedTime: number): number {
    let hourlyRate = 50;
    let intervalHours = 10;
    let hours = Math.floor(elapsedTime / 3600);
    let completedIntervals = Math.floor(hours / intervalHours);
    let totalAmount = completedIntervals * hourlyRate;
    
    return totalAmount;
  }

  formattedNumber(number:number) {
    let currentLang = this.translate.currentLang;
    let numberValue = number ?? 0;
    let formattedNumber: string = '';

    if (currentLang === 'it')
      formattedNumber = numberValue.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    else if (currentLang === 'en')
      formattedNumber = numberValue.toLocaleString('en-EN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    else if (currentLang === 'es')
      formattedNumber = numberValue.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    else if (currentLang === 'fr')
      formattedNumber = numberValue.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    else if (currentLang === 'ru')
      formattedNumber = numberValue.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    else if (currentLang === 'pt')
      formattedNumber = numberValue.toLocaleString('pt-PT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    else
      formattedNumber = numberValue.toLocaleString('en-EN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    if (isNaN(parseFloat(formattedNumber))) 
        formattedNumber = '0';
        
    return formattedNumber;
  }
}

import { Injectable } from '@angular/core';
import { PublisherProperties, Filter } from 'openvidu-browser';
import { Observable, firstValueFrom } from 'rxjs';
import { Lesson } from 'src/app/models/lesson';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { Helper } from 'src/app/helpers/helper';
import { AuthService } from './auth.service';
import { User } from 'src/app/models/user';

@Injectable({
    providedIn: 'root'
})
export class VideoSessionService {

    lesson: Lesson;
    cameraOptions: PublisherProperties[] = [null, null, null];
    scenario: number;

    private url = `${environment.apiUrl}/api/classrooms/lessons/`;
    private openViduUrl = `${environment.apiUrl}/api/openviduservice`;
    private openViduSessionsUrl = `${environment.apiUrl}/api/openviduservice/allsessions/`;
    private lessonUrl = `${environment.apiUrl}/api/v2/lessons/`;

    constructor(private http: HttpClient,
                private snackBar: MatSnackBar,
                public location: Location,
                private auth: AuthService) { }

    // Returns {0: sessionId, 1: token}
    generateTokenTeacher(lessonId: string) : Promise<any> {
        console.log(this.cameraOptions);

        const body = {
            role: 'PUBLISHER',
            data: 'token by publisher',
            kurentoOptions: this.auth.getCurrentUser().streamInfo.logo != 0 ? { allowedFilters: ['GStreamerFilter'] } : null
        };

        return firstValueFrom(this.http.post(`${this.url}${lessonId}/tokens`, body, { headers: Helper.getAuthHeader() }));
         /*    .pipe(
                map(response => localStorage.setItem('joinResponse', JSON.stringify(response))),
                catchError(error => this.handleError(error))
            ); */
    }

    generateTokenStudent(lessonId: string) : Promise<any> {
        try {
            const body = {
                role: 'SUBSCRIBER',
                data: 'token by subscriber',
                kurentoOptions: null
            };
            return firstValueFrom(this.http.post(`${this.url}${lessonId}/tokens`, body, { headers: Helper.getAuthHeader() }))
             /*   .pipe(
                    map(response => localStorage.setItem('joinResponse', JSON.stringify(response))),
                    catchError(error => this.handleError(error))
                );*/

        } catch (error) {

            this.snackBar.open('The teacher has not opened the lesson yet!', 'Dismiss', {
                duration: 3000,
                verticalPosition: 'top'
            });
            this.location.back();
        }
    }

    /* parte di Luca */

    generateTokenTeacherSubscriber(lessonId: string) : Promise<any> {
        console.log(this.cameraOptions);

        const body = {
            role: 'SUBSCRIBER',
            data: 'token by subscriber',
            kurentoOptions: null
        };

        return firstValueFrom(this.http.post(`${this.url}${lessonId}/tokensForFeedback`, body, { headers: Helper.getAuthHeader() }));
         /*    .pipe(
                map(response => localStorage.setItem('joinResponse', JSON.stringify(response))),
                catchError(error => this.handleError(error))
            ); */
    }

    generateTokenStudentPublisher(lessonId: string): Promise<any> {
        try {
            const body = {
                role: 'PUBLISHER',
                data: 'token by student publisher',
                kurentoOptions: this.auth.getCurrentUser().streamInfo.logo != 0 ? { allowedFilters: ['GStreamerFilter'] } : null
            };
            return firstValueFrom(this.http.post(`${this.url}${lessonId}/tokensForFeedback`, body, { headers: Helper.getAuthHeader() }))
             /*   .pipe(
                    map(response => localStorage.setItem('joinResponse', JSON.stringify(response))),
                    catchError(error => this.handleError(error))
                );*/

        } catch (error) {

            console.log(error);
        }
    }

    /* parte di Luca */

    joinLesson(lessonId: string, connectionId: string, streamId: string, role: string, bidirectional: number, position: number, prevStreamId?: string) {
        // define body
        let body: any = null;
        if (!environment.supportsBidirectional) {
            body = {
                connectionId,
                streamId,
                role,
                position,
                prevStreamId
            };
        } else {
            body = {
                connectionId,
                streamId,
                role,
                bidirectional,                                 /* parte di Luca */
                position,
                prevStreamId
            };
        }

        return firstValueFrom(this.http.put(`${this.url}${lessonId}`, body, { headers: Helper.getAuthHeader() }));
    }

    joinQuickLesson(lessonId: string, connectionId: string, streamId: string, role: string, bidirectional: number, position: number, prevStreamId?: string) {
        // define body
        let body: any = null;
        if (!environment.supportsBidirectional) {
            body = {
                connectionId,
                streamId,
                role,
                position,
                prevStreamId
            };
        } else {
            body = {
                connectionId,
                streamId,
                role,
                bidirectional,                                 /* parte di Luca */
                position,
                prevStreamId
            };
        }

        if (localStorage.getItem('easyLesson') == 'true') {
            localStorage.removeItem('easyLesson')
            return firstValueFrom(this.http.put(`${environment.apiUrl}/api/classrooms/quickLessons/${lessonId}`, body, { headers: Helper.getAuthHeader() }));
        }
    }

    getLesson(lessonId: number): Observable<LessonSessionDTO> {
        return this.http.get<LessonSessionDTO>(`${this.lessonUrl}/${lessonId}`, { headers: Helper.getAuthHeader() });
    }

    setLessonScenario(lessonId: number, scenario: number) {
        const body = {
            scenario
        };

        return firstValueFrom(this.http.put(`${this.url}${lessonId}/scenario`, body, { headers: Helper.getAuthHeader() }));
    }

    closeLesson(lessonId: string) {
        return firstValueFrom(this.http.delete(`${this.url}${lessonId}`, { headers: Helper.getAuthHeader() }));
    }

    kickUser(connectionId: string) {
        return firstValueFrom(this.http.delete(`${this.openViduUrl}/connections/${connectionId}`, { headers: Helper.getAuthHeader() }));
    }

    startRecording(lessonId: number) {
        return firstValueFrom(this.http.post(`${this.openViduUrl}/recording/${lessonId}/start`, { }, { headers: Helper.getAuthHeader() }));
    }

    stopRecording(lessonId: number) {
        return firstValueFrom(this.http.post(`${this.openViduUrl}/recording/${lessonId}/stop`, { }, { headers: Helper.getAuthHeader() }));
    }

    /**
     * Controlla se la Session creata con l'attivazione/creazione della lezione esiste ancora.
     * @param lesson
     */
    async createSession(lessonId: number) {
        let session = await firstValueFrom(this.http.get(`${this.openViduUrl}/sessions/${lessonId}`, { headers: Helper.getAuthHeader() }))
            .catch(() => console.warn("SESSION NOT FOUND"));

        if (session)
            return;

        await firstValueFrom(this.http.post(`${this.openViduUrl}/sessions/${lessonId}`, { }, { headers: Helper.getAuthHeader() }))
            .catch(() => console.warn("CAN'T CREATE THE SESSION"));
    }

    getOpenSessions(): Observable<any> {
        return this.http.get<any>(`${this.openViduSessionsUrl}`, { headers: Helper.getAuthHeader() });
    }

    public setStreamingSettings(options: PublisherProperties, currentUser: User) {
        if(currentUser != null && currentUser.streamInfo != null) {
            if(currentUser.streamInfo.logo === 1)
                options.filter = new Filter("GStreamerFilter", { command: `textoverlay text="${currentUser.streamInfo.logoText}" valignment=top halignment=right font-desc="${currentUser.streamInfo.fontName} ${currentUser.streamInfo.fontSize}"` });
            else if(currentUser.streamInfo.logo === 2)
                options.filter = new Filter("GStreamerFilter", { command: `gdkpixbufoverlay location=${currentUser.streamInfo.imageLogoLink} offset-x=10 offset-y=10 overlay-width=250` });
        }

        //this.cameraOptions1.filter = new Filter("GStreamerFilter", { command: 'gdkpixbufoverlay location=/images/saratoga_logo_dark.svg offset-x=10 offset-y=10 overlay-width=250' }); //funziona - kms 6.14.0 openvidu 2.15.0
        //this.cameraOptions1.filter = new Filter("GStreamerFilter", { command: 'chromahold target-r=255 target-g=165 target-b=0 tolerance=50' });
        //this.cameraOptions1.filter = new Filter("GStreamerFilter", { command: 'textoverlay text="Saratoga" valignment=top halignment=right font-desc="Sans 16"' });
        //this.cameraOptions1.filter = new Filter("GStreamerFilter", { command: 'clockoverlay valignment=bottom halignment=right shaded-background=true font-desc="Sans 20"' });

        /*
                    this.publisher1.stream.applyFilter("FaceOverlayFilter", null) funziona - kms 6.14.0/6.14.1 openvidu 2.15.0
                        .then(filter => {
                            filter.execMethod("setOverlayedImage", {
                                uri:"https://pngimg.com/uploads/hat/hat_PNG5717.png",
                                offsetXPercent:"-0.2F",
                                offsetYPercent:"-0.8F",
                                widthPercent:"1.3F",
                                heightPercent:"1.0F"
                            });
                    });*/

                    /*
                    this.publisher1.stream.applyFilter("ZBarFilter", null) funziona - kms 6.14.0/6.14.1 openvidu 2.15.0
                        .then(filter => {
                            filter.addEventListener("CodeFound", filterEvent => {
                                console.log('Bar code found!. Data: ' + filterEvent.data);
                            });
                    });*/

                    /*
                    this.publisher1.stream.applyFilter("ChromaFilter", { funziona - kms 6.14.1 openvidu 2.15.0
                        window: {
                            topRightCornerX: 0,
                            topRightCornerY: 0,
                            width: 50,
                            height: 50
                        },
                        backgroundImage: "https://www.maxpixel.net/static/photo/1x/Cool-Blue-Liquid-Lake-Abstract-Background-Clear-316144.jpg"
                    });*/

                    /*
                    this.publisher1.stream.applyFilter("CrowdDetectorFilter", { Non funziona - kms 6.14.0/6.14.1 openvidu 2.15.0
                        rois:
                        [
                                {
                                    id: 'roi1',
                                    points:
                                        [
                                            {x: 0,   y: 0},
                                            {x: 0.5, y: 0},
                                            {x: 0.5, y: 0.5},
                                            {x: 0,   y: 0.5}
                                        ],
                                    regionOfInterestConfig: {
                                        occupancyLevelMin: 10,
                                        occupancyLevelMed: 35,
                                        occupancyLevelMax: 65,
                                        occupancyNumFramesToEvent: 5,
                                        fluidityLevelMin: 10,
                                        fluidityLevelMed: 35,
                                        fluidityLevelMax: 65,
                                        fluidityNumFramesToEvent: 5,
                                        sendOpticalFlowEvent: false,
                                        opticalFlowNumFramesToEvent: 3,
                                        opticalFlowNumFramesToReset: 3,
                                        opticalFlowAngleOffset: 0
                                    }
                                }
                            ]
                        }
                    );*/
    }

    // removeUser(lessonId: number) {
    //     const body = JSON.stringify(lessonId);
    //     const headers = new Headers({ 'Content-Type': 'application/json' });
    //     const options = new RequestOptions({ headers });
    //     return this.http.post(this.url + '/remove-user', body, options)
    //         .pipe(
    //             map(response => response),
    //             catchError(error => this.handleError(error))
    //         );
    // }
}

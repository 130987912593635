import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExerciseSessionDTO } from 'src/app/models/dto/exerciseSessionDTO';

@Component({
  selector: 'app-exercise-results-popup',
  templateUrl: './exercise-results-popup.component.html',
  styleUrls: ['./exercise-results-popup.component.scss']
})
export class ExerciseResultsPopupComponent implements OnInit {
  displayedColumns: string[] = [
    'username',
    'startDate',
    'stopDate',
    'state',
    'rgbVideoPath',
    'posevaVideoPath',
    'mmSpeedPath',
    'mmTorquePath',
    'ioScannerPath',
    'statename',
    'results'
  ];
  loading: boolean;
  showFullDescription: any;
  
  constructor(
    public dialogRef: MatDialogRef<ExerciseResultsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public results: ExerciseSessionDTO[]
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.dialogRef.close();
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { SignalUserRoom } from 'src/app/models/conference-session/signalUserRoom';
import { VirtualRoomDTO } from 'src/app/models/dto/virtualRoomDTO';
import { ConferenceService } from 'src/app/services/conference.service';

@Component({
  selector: 'app-room-card',
  templateUrl: './room-card.component.html',
  styleUrls: ['./room-card.component.scss']
})
export class RoomCardComponent {

  private _roomData: VirtualRoomDTO;
  private _participants: ClientStream[] = [];

  selectedParticipants: number[] = [];

  //private _usersAddedToRoom: boolean;

  //@Input()
  //get usersAddedToRoom(): boolean { return this._usersAddedToRoom; }
  //set usersAddedToRoom(value: boolean) {

    //this._usersAddedToRoom = value;
    //setTimeout(() =>  this.getUsers(), 6000);

  //}

  get participants(): ClientStream[] { return this._participants; }
  set participants(value: ClientStream[]) {
    this._participants = value;

    this.onConnectedUsers.emit(this._participants);
  }

  @Input()
  get roomData(): VirtualRoomDTO { return this._roomData; }
  set roomData(value: any) {

    this._roomData = value;
    //this.getUsers();

  }

  @Input()
  conferenceId: number;

  @Input()
  selectedUsers: number[] = [];

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() 
  onData: EventEmitter<{ data: SignalUserRoom, selectedUsers: number[] }> = new EventEmitter<{ data: SignalUserRoom, selectedUsers: number[] }>();

  @Output()
  onConnectedUsers: EventEmitter<ClientStream[]> = new EventEmitter<ClientStream[]>();

  constructor(private conferenceService: ConferenceService,
              private translate: TranslateService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    //this.getUsers();
  }

  async createRoom() {
    await firstValueFrom(this.conferenceService.createConferenceRoom(this.conferenceId, this.roomData.id));
  }

  /*
  async getUsers() {
    await firstValueFrom(this.conferenceService.getUsersInRoom(this.conferenceId, this.roomData.id))
      .then(res => this.participants = res)
      .catch(err => {
        console.log(err);
        this.participants = [];
      });
  }
  */

  async addUsers() {

    try {

      await this.createRoom();

      await firstValueFrom(this.conferenceService.addUsersToRoom(this.conferenceId, this.roomData.id, this.selectedUsers));

      this.onData.emit({ data: { virtualRoomId: this.roomData.id, action: 'add' }, selectedUsers: this.selectedUsers });

      this.selectedParticipants = [];

      this.snackBar.open(await firstValueFrom(this.translate.get('Users added to the room')), 'Dismiss', { duration: 5000 });

    } catch (error) {
      console.error(error);

      this.snackBar.open(await firstValueFrom(this.translate.get('Error adding users to the room')), 'Dismiss', { duration: 5000 });
    }
    
  }

  isSelected(userId: number) {
    return this.selectedParticipants.findIndex(id => id === userId) !== -1;
  }

  toggleSelection(userId: number) {
    let index = this.selectedParticipants.findIndex(id => id === userId);

		index !== -1 ?
		this.selectedParticipants.splice(index, 1) : // Rimuovi l'userId dalla lista
		this.selectedParticipants.push(userId); // Aggiungi l'userId alla lista solo se non è già presente 
  }

  async removeUsers() {

    try {

      await firstValueFrom(this.conferenceService.removeUserFromRoom(this.conferenceId, this.roomData.id, this.selectedParticipants));

      this.selectedParticipants = [];

      //this.getUsers();

      this.snackBar.open(await firstValueFrom(this.translate.get('Users removed from the room')), 'Dismiss', { duration: 5000 });

    } catch (error) {
      console.error(error);

      this.snackBar.open(await firstValueFrom(this.translate.get('Error removing users from the room')), 'Dismiss', { duration: 5000 });
    }

  }

  async removeAllUsers() {

    try {

      await firstValueFrom(this.conferenceService.removeUserFromRoom(this.conferenceId, this.roomData.id, this.participants.map(rp => rp.userId)));

      this.selectedParticipants = [];

      //this.getUsers();

      this.snackBar.open(await firstValueFrom(this.translate.get('All users removed from the room')), 'Dismiss', { duration: 5000 });

    } catch (error) {
      console.error(error);

      this.snackBar.open(await firstValueFrom(this.translate.get('Error removing users from the room')), 'Dismiss', { duration: 5000 });
    }

  }

}

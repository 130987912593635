import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslationDTO } from 'src/app/models/dto/translationDTO';
import { AuthService } from 'src/app/services/auth.service';
import { LANGUAGES, Language } from 'src/app/services/translation.service';

@Component({
  selector: 'app-translations-form',
  templateUrl: './translations-form.component.html',
  styleUrls: ['./translations-form.component.scss']
})
export class TranslationsFormComponent {

  private _disabled: boolean = false;

  @Input()
  mode: 'input' | 'textarea' = 'input';

  @Input()
  label: string = undefined;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = value;

    if (value) {
      this.selectedLanguage.disable();
      this.selectedForm.disable();
    } else {
      this.selectedLanguage.enable();
      this.selectedForm.enable();
    }
  }

  @Input()
  set translation(value: TranslationDTO) {
    if (!value)
      return;

    this.result = value;
    this.resetForm();
  }

  @Output()
  translationChange: EventEmitter<TranslationDTO> = new EventEmitter<TranslationDTO>();

  languages: Language[] = [];

  selectedLanguage: FormControl<string> = new FormControl<string>(undefined);
  selectedForm: FormControl<string> = new FormControl<string>(undefined);

  showLanguageForm: boolean = false;
  result: TranslationDTO = new TranslationDTO();

  constructor(private auth: AuthService) {
    this.languages = LANGUAGES.filter(f => f.lang !== this.auth.getCurrentUser()?.defaultLanguage);
    this.resetForm();
  }

  private resetForm() {
    this.selectedLanguage.setValue(this.languages[0].code);
    this.setForm();
  }

  toggleForm() {
    this.showLanguageForm = !this.showLanguageForm;

    if (!this.showLanguageForm)
      this.resetForm();
  }

  setForm() {
    this.selectedForm = new FormControl(this.result[LANGUAGES.find(l => l.code === this.selectedLanguage.value)?.lang] ?? undefined);
  }

  sendData() {
    this.result[LANGUAGES.find(l => l.code === this.selectedLanguage.value)?.lang] = this.selectedForm.value;
    this.translationChange.emit(this.result);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExerciseDTO } from '../models/dto/exerciseDTO';
import { ClassroomDTO } from '../models/dto/classroomDTO';
import { Helper } from '../helpers/helper';
import { ExerciseSessionDTO } from '../models/dto/exerciseSessionDTO';
import { SkillDTO } from '../models/dto/skillDTO';
import { ExerciseSkillResultDTO } from '../models/dto/exerciseSkillResultDTO';
import { ExerciseProgressDTO } from '../models/dto/exerciseProgressDTO';
import { CertificationsDTO } from '../models/dto/CertificationsDTO';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {
  private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
  private bckEndUrlExercises = `${this.bckEndUrlBaseUrl}/exercise`;
  private bckEndUrlClassrooms = `${this.bckEndUrlBaseUrl}/classrooms`;
  private bckEndUrlSkills = `${this.bckEndUrlBaseUrl}/skill`;
  private bckEndUrlNotarization = `${this.bckEndUrlBaseUrl}/notarization`;
  private bckEndUrlCertification = `${this.bckEndUrlBaseUrl}/certification`;


  constructor(private http: HttpClient) { }

  public getExercises(): Observable<ExerciseDTO[]> {
    return this.http.get<ExerciseDTO[]>(`${this.bckEndUrlExercises}/list/{lang}`, { headers: Helper.getAuthHeader() })
  }

  public getExerciseById(id: number): Observable<ExerciseDTO> {
    return this.http.get<ExerciseDTO>(`${this.bckEndUrlExercises}/${id}`, { headers: Helper.getAuthHeader() });
  }

  public addExercise(exercise: ExerciseDTO): Observable<ExerciseDTO> {
    return this.http.post<ExerciseDTO>(`${this.bckEndUrlExercises}/create`, exercise, { headers: Helper.getAuthHeader() });
  }

  public editExercise(exercise: ExerciseDTO): Observable<ExerciseDTO> {
    return this.http.put<ExerciseDTO>(`${this.bckEndUrlExercises}/edit`, exercise, { headers: Helper.getAuthHeader() });
  }

  public deleteExercise(id: number): Observable<any> {
    return this.http.delete(`${this.bckEndUrlExercises}/delete/${id}`, { headers: Helper.getAuthHeader() });
  }

  public getClassrooms(): Observable<ClassroomDTO[]> {
    return this.http.get<ClassroomDTO[]>(this.bckEndUrlClassrooms, { headers: Helper.getAuthHeader() });
  }

  public postExerciseResults(exerciseResult: ExerciseSessionDTO): Observable<any> {
    return this.http.post<any>(`${this.bckEndUrlExercises}/post-exercise-results`, exerciseResult, { headers: Helper.getAuthHeader() });
  }

  getExerciseResults(exerciseId: number): Observable<ExerciseSessionDTO[]> {
    return this.http.get<ExerciseSessionDTO[]>(`${this.bckEndUrlExercises}/get-exercise-results/${exerciseId}`, {
      headers: Helper.getAuthHeader(),
    });
  }

  getExerciseDetails(exerciseId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${this.bckEndUrlExercises}/details/${exerciseId}/${userId}`, {
      headers: Helper.getAuthHeader(),
    });
  }

  getAssignedSkills(exerciseId: number): Observable<SkillDTO[]> {
    return this.http.get<SkillDTO[]>(`${this.bckEndUrlSkills}/get-assigned-skills/${exerciseId}`, { headers: Helper.getAuthHeader() });
  }

  // exercise.service.ts
  public setExerciseGradedWithNotes(exerciseId: number, userId: number, requestBody: { skillScores: { [skillId: number]: number | null };
   skillNotes: { [skillId: number]: string }; }): Observable<any> {
    return this.http.post(`${this.bckEndUrlExercises}/set-graded/${exerciseId}/${userId}`, requestBody, { headers: Helper.getAuthHeader() }
    );
  }


  // setExerciseGraded(exerciseId: number, userId: number, scores: { [skillId: number]: number }): Observable<any> {
  //   return this.http.post(`${this.bckEndUrlExercises}/set-graded/${exerciseId}/${userId}`, scores, { headers: Helper.getAuthHeader() });
  // }

  uploadPdfPath(exerciseId: number, userId: number, tempPath: string): Observable<any> {
    return this.http.post(`${this.bckEndUrlExercises}/upload-pdf/${exerciseId}/${userId}`, { tempPath }, { headers: Helper.getAuthHeader() });
  }

  getPdfDownloadUrl(exerciseId: number, userId: number): Observable<any> {
    return this.http.get(`${this.bckEndUrlExercises}/download-pdf/${exerciseId}/${userId}`, { headers: Helper.getAuthHeader() });
  }

  getExerciseSkillResults(exerciseId: number, userId: number): Observable<ExerciseSkillResultDTO[]> {
    return this.http.get<ExerciseSkillResultDTO[]>(`${this.bckEndUrlExercises}/get-skill-results/${exerciseId}/${userId}`, { headers: Helper.getAuthHeader() });
  }

  notarizeExercisePdf(exerciseId: number, userId: number): Observable<any> {
    return this.http.post(`${this.bckEndUrlNotarization}/notarize-pdf-hash/${exerciseId}/${userId}`, {}, { headers: Helper.getAuthHeader() });
  }

  getProgress(exerciseId: number): Observable<ExerciseProgressDTO> {
    return this.http.get<ExerciseProgressDTO>(`${this.bckEndUrlExercises}/progress/${exerciseId}`, { headers: Helper.getAuthHeader() });
  }

  public getCertifications(): Observable<CertificationsDTO[]> {
    return this.http.get<CertificationsDTO[]>(`${this.bckEndUrlCertification}/list/{lang}`, { headers: Helper.getAuthHeader() });
  }
  
  public getCertificationById(id: number): Observable<CertificationsDTO> {
    return this.http.get<CertificationsDTO>(`${this.bckEndUrlCertification}/list/${id}`, { headers: Helper.getAuthHeader() });
  }
  
  public addCertification(certification: CertificationsDTO): Observable<CertificationsDTO> {
    return this.http.post<CertificationsDTO>(`${this.bckEndUrlCertification}/create`, certification, { headers: Helper.getAuthHeader() });
  }
  
  public editCertification(certification: CertificationsDTO): Observable<CertificationsDTO> {
    return this.http.put<CertificationsDTO>(`${this.bckEndUrlCertification}/edit`, certification, { headers: Helper.getAuthHeader()});
  }
  
  public deleteCertification(id: number): Observable<any> {
    return this.http.delete(`${this.bckEndUrlCertification}/delete/${id}`, { headers: Helper.getAuthHeader()});
  }

}

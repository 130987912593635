<app-mobile-warning
  *ngIf="isMobile; else desktopLogin"
  [logo]="logo"
  [background]="backgroundImageUrl">
</app-mobile-warning>

<ng-template #desktopLogin>
  <div class="sfondo" [style]="backgroundImageUrl">
    <mat-grid-list cols="5" rowHeight="100%">
      <mat-grid-tile style="margin-left:10%" [colspan]="3" [rowspan]="1">
        <div fxFlex fxLayoutAlign="left center" >
          <img style="width:55%; max-width: 55%;" [src]="logo">
        </div>
      </mat-grid-tile>
        
      <mat-grid-tile [colspan]="2" [rowspan]="1" style="overflow:auto">
        
        <div fxLayout="column" fxLayoutAlign="start" style="width: 100%">

          <h2 class="motto">
            PERSONAL AREA
          </h2>

          <mat-card style="width: 400px" class="mat-elevation-z8">

            <mat-card-content fxLayout="column" fxLayoutGap="20px">

              <app-access-form
                *ngIf="isLoginMode()">
              </app-access-form>

              <app-register-form
                *ngIf="isSignUpMode()"
                (result)="changeMode('login')">
              </app-register-form>

              <mat-divider [inset]="true"></mat-divider>

              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

                <div *ngIf="isSignUpMode()">
                  <button mat-stroked-button (click)="changeMode('login')">
                    {{ 'Log In' | translate }}
                  </button>
                </div>

                <div *ngIf="isLoginMode()">
                  <button mat-stroked-button (click)="changeMode('signup')">
                    {{ 'Sign Up'| translate }}
                  </button>
                </div> 
      
              </div>

            </mat-card-content>

          </mat-card>

          <h3 class="companyName">
            Push Your Limits, Elevate Your Life
          </h3>

        </div>

      </mat-grid-tile>

    </mat-grid-list>
  </div>
</ng-template>

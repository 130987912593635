<div class="component-main">
    <div style="text-align: center">
        <h2>{{ 'My webinar' | translate }}</h2>
    </div>
    <div class="row-arrange">
        <mat-form-field color="primary">
            <mat-label>{{ 'Search' | translate }}</mat-label>
            <input matInput [formControl]="searchWordForm" (keyup)="applyFilter()">
            <button matSuffix mat-icon-button
                (click)="searchWordForm.setValue(undefined); applyFilter();"
                [disabled]="!searchWordForm.value"
                [matTooltip]="'Clear' | translate">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field *ngIf="isCustomerAdmin()">
            <mat-label>{{ 'Author' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="authorForm">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="searchForm"
                        [placeholderLabel]="'Search' | translate"
                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="0">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let author of webinarAuthorsList | search: searchForm.value: ['name']" [value]="author.id">
                    {{ author.name }} {{ author.surname }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isCustomerAdmin()">
            <mat-label>{{ 'Moderator' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="moderatorForm">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="searchForm"
                        [placeholderLabel]="'Search' | translate"
                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="0">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let moderator of webinarModeratorList | search: searchForm.value: ['name']" [value]="moderator.id">
                    {{ moderator.name }} {{ moderator.surname }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isCustomerAdmin()">
            <mat-label>{{ 'Speaker' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="speakerForm">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="searchForm"
                        [placeholderLabel]="'Search' | translate"
                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="0">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let speaker of webinarSpeakersList | search: searchForm.value: ['name']" [value]="speaker.id">
                    {{ speaker.name }} {{ speaker.surname }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'State' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="stateForm">
                <mat-option *ngFor="let state of states" [value]="state.value">
                    {{ state.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-table #table multiTemplateDataRows matSort [dataSource]="dataSource">

        <ng-container matColumnDef="plannedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Planned date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.lessonSession.startPlanned | date: 'dd/MM/yyyy HH:mm' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.name }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Description' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ getTruncateString(element.description, "", 100) }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="visibility">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Visibility' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">
                <span class="truncate-text">{{ getWebinarVisibility(element) | translate }}</span>
            </mat-cell> 
        </ng-container>
    
        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Price' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ getPrice(element.subscription) | translate }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="moderator">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Moderator' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ getWebinarModerator(element.conferencePresenter) }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Author' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ getWebinarAuthor(element.lessonSession) }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="speakers">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Speakers' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">
                <div class="speaker-list" [innerHTML]="getWebinarSpeakers(element.conferencePresenter).join('\n')"></div>
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'State' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;" [style.color]="getWebinarState(element.lessonSession, 'color')">
                <span class="truncate-text">{{ getWebinarState(element.lessonSession, 'name') | translate }}</span>
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="view">
            <mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">
                <button [matTooltip]="'View' | translate" (click)="goToWebinarShowcase(element.id)" mat-icon-button>
                    <mat-icon>settings</mat-icon>
                </button>
            </mat-cell> 
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        <tr class="mat-row" style="height: 40px" *matNoDataRow>
            <td class="mat-cell" [colSpan]="displayedColumns.length" style="vertical-align: middle">
                <span style="margin: 10px">
                    {{ 'No data' | translate }}
                </span>
            </td>
        </tr>

    </mat-table>

    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>

<h1 mat-dialog-title>{{ (isEdit ? 'Edit room' : 'Add room') | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="name" required>
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <input matInput type="text" [formControl]="description" required>
            <mat-error *ngIf="description.hasError('required')">
                {{ 'Description is required' | translate }}
            </mat-error>
            <mat-error *ngIf="description.hasError('minlength')">
                {{ 'Description should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field *ngIf="currentUser.isAdmin && !isEdit" color="primary">
            <mat-label>{{ 'Customer' | translate }}</mat-label>
            <mat-select [formControl]="customer">
                <mat-option [value]="0">{{ 'All Customer' | translate }}</mat-option>
                <mat-option *ngFor="let item of customers" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="saveRoom()" [disabled]="btnDisabled()" color="accent">{{ 'Ok' | translate }}</button>
</div>

<div class="component-main container">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ 'Active webinar' | translate }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isAuthenticated()">   

        <mat-form-field style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Search' | translate }}...</mat-label>
            <input matInput [formControl]="searchForm" (keydown.enter)="searchWord()" (keyup)="searchWord()" (isFocused)="searchWord()">
            <button matSuffix mat-icon-button [disabled]="!searchForm.value" [matTooltip]="'Search' | translate"
                (click)="searchWord()">
                <mat-icon>search</mat-icon>
            </button>
            <button matSuffix mat-icon-button [disabled]="!searchForm.value" [matTooltip]="'Clear' | translate"
                (click)="getWebinars()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Hashtags' | translate }}</mat-label>
            <mat-select [formControl]="hashTag" (selectionChange)="onHashTagsChange()">
                <mat-option [value]="0">{{ 'All hashtags' | translate }}</mat-option>
                <mat-option *ngFor="let item of hashTags" [value]="item">
                    {{item}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    
    <div *ngIf="conferences?.length > 0" [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">
        <app-showcase-card *ngFor="let conference of conferences" 
            [routeUrl]="'/webinar-showcase'"
            [routeId]="conference.id"
            [queryParams]="{ page: 'showcase' }"
            [routeMessage]="checkIfPaidWebinarIsOnline(conference) ? ('Join webinar' | translate) : ('Go to webinar' | translate)"
            [style]="border(conference)"
            [imgUrl]="conference.imageUrl"
            [subtitle]="conference.lessonSession.startPlanned | date: 'dd/MM/yyyy HH:mm'"
            [title]="conference.name"
            [description]="conference.header"
            [subscription]="conference.subscription"
            [compact]="compactMode"
            [enableHighlight]="compactMode">

            <app-showcase-card-author-info bodyBottom
                [author]="getModerator(conference)"
                [users]="getPresenters(conference)"
                mode="webinar"
                padding="0 16px">
            </app-showcase-card-author-info>

        </app-showcase-card>
    </div>

    <div *ngIf="conferences?.length == 0">
        <app-error-message customClass="warning"
            [errorTitle]="'There are no webinars' | translate"
            [errorContent]="'Currently there are none to show' | translate">
        </app-error-message>
    </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;

  currentUser: User;
  translations: MatTableDataSource<TranslationDTO> = new MatTableDataSource<TranslationDTO>();
  displayedColumns: string[] = ['italian', 'english', 'spanish', 'french', 'deutsch', 'russian', 'portuguese', 'edit'];
  currentTranslate: TranslationDTO = new TranslationDTO();
  sending: boolean = true;

  constructor(private translationService: TranslationService,
              private snackBar: MatSnackBar,
              private auth: AuthService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.translations.paginator = this.paginator;

    this.getTranslations();
  }

  editTranslation(translation: TranslationDTO) {
    this.currentTranslate = translation;
  }

  cancelEdit() {
    this.currentTranslate = new TranslationDTO();

    this.getTranslations();
  }

  sendEdits() {
    this.sending = true;

    var translation = new TranslationEdit();

    translation.it_IT = this.currentTranslate.it_IT;
    translation.en_US = this.currentTranslate.en_US;
    translation.es_ES = this.currentTranslate.es_ES;
    translation.fr_FR = this.currentTranslate.fr_FR;
    translation.de_DE = this.currentTranslate.de_DE;
    translation.ru_RU = this.currentTranslate.ru_RU;
    translation.pt_PT = this.currentTranslate.pt_PT;

    firstValueFrom(this.translationService.editTranslation(this.currentTranslate.id, translation))
      .then(() => {
        this.snackBar.open('Translation edited successfully', '', { duration: 3000 });
      }).catch(() => {
        this.snackBar.open('Error editing translation', '', { duration: 3000 });
      }).finally(() => {
        this.sending = false;
        this.currentTranslate = new TranslationDTO();
        this.getTranslations();
      });
  }

  enableSendButton() {
    if(this.currentTranslate.it_IT == null &&
       this.currentTranslate.en_US == null &&
       this.currentTranslate.es_ES == null &&
       this.currentTranslate.fr_FR == null &&
       this.currentTranslate.de_DE == null &&
       this.currentTranslate.ru_RU == null &&
       this.currentTranslate.pt_PT)
      return false;
    else
      return true;
  }

  getTranslations() {
    this.translationService.getTranslationsOfUser(this.currentUser.id)
      .subscribe(output => {
        this.translations = new MatTableDataSource<TranslationDTO>(output);
        this.translations.paginator = this.paginator;

        this.sending = false;
      });
  }
}

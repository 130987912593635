import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { DeviceCloudSettings } from 'src/app/models/deviceCloudSettings';
import { EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { ChecklistPopupComponent } from 'src/app/popup/checklist-popup/checklist-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { LANGUAGES, Language, TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

const STORE_CREDENTIALS_KEY: string = "accessCredentials";

@Component({
  selector: 'app-access-form',
  templateUrl: './access-form.component.html',
  styleUrls: ['./access-form.component.scss']
})
export class AccessFormComponent implements OnInit {

  submitProcessing: boolean = false;
  showPassword: boolean = false;

  username: FormControl<string> = new FormControl(undefined, [Validators.required]);
  password: FormControl<string> = new FormControl(undefined, [Validators.required]);
  language: FormControl<string> = new FormControl(undefined, [Validators.required]);
  remember: FormControl<boolean> = new FormControl(false);
  languages: Language[] = [];

  fieldsIncorrect: boolean = false;
  errorTitle: string;
  errorContent: string;
  customClass: string;

  @Input()
  idCustomer: number = undefined;

  @Input()
  isMobile: boolean = false;

  constructor(private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private calendar: CalendarService,
              private authSocialService: SocialAuthService,
              private translationService: TranslationService,
              private cookieService: CookieService,
              private translate: TranslateService
  ) {
    this.languages = LANGUAGES;
    //impostazioni per dev
    //this.language.setValue(this.translationService.currentLang);
    this.language.setValue('it');
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('token')) {

      let ibuttonToken = this.route.snapshot.queryParamMap.get('token');

      if (!Helper.isNullOrEmpty(ibuttonToken))
        this.loginIButton(ibuttonToken);

    } else if (this.cookieService.check('auth_token')) {

      let authToken = this.cookieService.get('auth_token');
      let domain = `.${Helper.getDomain(environment.apiUrl)}`;

      if (window.location.host.includes(domain))
          this.cookieService.delete('auth_token', '/', domain);
      else
          this.cookieService.delete('auth_token');
      
      if (!Helper.isNullOrEmpty(authToken))
          this.loginAuthToken(authToken);
                
    } else {

      let encryptedCredentials = localStorage.getItem(STORE_CREDENTIALS_KEY);

      if (Helper.isNullOrEmpty(encryptedCredentials))
        return;

      let credentials = <LoginCredentials>JSON.parse(atob(encryptedCredentials));

      if (!credentials)
        return;

      this.username.setValue(atob(credentials.username));
      this.password.setValue(atob(credentials.password));
      this.remember.setValue(true);
      
    }
  }

  login() {
    this.submitProcessing = true;

    console.log('Logging in...');

    if (this.remember.value === true) {

      if (!Helper.isNullOrEmpty(this.username.value) && !Helper.isNullOrEmpty(this.username.value)) {
        let credentials: LoginCredentials = { username: btoa(this.username.value), password: btoa(this.password.value) };

        localStorage.setItem(STORE_CREDENTIALS_KEY, btoa(JSON.stringify(credentials)));
      }

    } else {

      localStorage.removeItem(STORE_CREDENTIALS_KEY);

    }

    let config: DeviceCloudSettings = Helper.getDeviceConfig();

    if (config != null)
      console.log("Station config detected");

    let res = config == null
            ? this.auth.login(this.username.value, this.password.value, this.idCustomer)
            : this.auth.loginStation(this.username.value, this.password.value, config);

    res
      .then(() => {
        console.log('Logged in finally');
        this.accessPlatform(true);
      })
      .catch(async error => {
        console.log(error);

        if (error.status == 404) {
          this.errorTitle = await firstValueFrom(this.translate.get('Invalid field'));
          this.errorContent = await firstValueFrom(this.translate.get('Please check your email or password'));
        } else if (error.status == 401) {
          this.errorTitle = await firstValueFrom(this.translate.get('Not authorized'));
          this.errorContent = await firstValueFrom(this.translate.get('You are not authorized to access {{app}}', { app: EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Title) }));
        } else if (error.status == 400) {
          this.errorTitle = await firstValueFrom(this.translate.get('Login failed'));
          this.errorContent = await firstValueFrom(this.translate.get('Your demo is expired, please contact {{company}}', { company: environment.applicationCompanyName }));
        } else {
          this.errorTitle = await firstValueFrom(this.translate.get('Cannot connect'));
          this.errorContent = await firstValueFrom(this.translate.get('Do you have network connection problems?'));
        }

        this.handleError();
      });
  }

  loginIButton(rawToken: string) {
    this.submitProcessing = true;

    console.log('IButton logging in...');
    this.auth
      .loginIButton(rawToken)
      .then(async () => {
        console.log('Logged in finally');
        this.accessPlatform(this.auth.getCurrentUser().isStudent ? await this.openCheckList() : true);
      })
      .catch(error => {
        console.log(error);

        this.errorTitle = 'Invalid field';
        this.errorContent = 'Please try to remove and replace the iButton';
        this.handleError();
      });
  }

  loginSocial(provider: string): void {
    this.submitProcessing = true;

    this.authSocialService
      .signIn(provider)
      .then(user => {
        user.photoUrl = "";

        if (user.response.picture && user.response.picture.data.url) {
          user.photoUrl = user.response.picture.data.url;
          user.response = "";
        }

        console.log('Logging in...');
        this.auth
          .loginSocial(user)
          .then(data => {
            console.log('Logged in finally', data);
            this.accessPlatform(true);
          })
          .catch(error => {
            console.log(error);
            
            this.errorTitle = 'Login failed';
            this.errorContent = error.error.Message;
            this.handleError();
          });
      })
      .catch(err => {
        console.log(err);

        this.errorTitle = 'Login failed';
        this.errorContent = `Error logging in to ${provider}`;
        this.handleError();
      });
  }

  loginAuthToken(authToken: string) {
    this.submitProcessing = true;

    this.auth
      .loginAuthToken(authToken)
      .then(() => {
        console.log('Logged in finally');
        this.accessPlatform(true);
      })
      .catch(error => {
        console.log(error);

        this.errorTitle = 'Invalid field';
        this.errorContent = 'Provided token is not valid';
        this.handleError();
      });
  }

  changeLanguage($event: any) {
    this.language.setValue(this.translationService.setLang($event.value).code);
  }

  handleError() {
    this.customClass = 'fail';
    this.submitProcessing = false;
    this.fieldsIncorrect = true;
  }

  goToForgot() {
    if (!this.submitProcessing)
      this.router.navigate(['/forgot']);
  }

  async openCheckList(): Promise<boolean> {
    return await firstValueFrom(this.calendar.getCheckListOfCustomer(this.auth.getCurrentUser().idCustomer))
      .then(async output => {
        if (output != null && output.length > 0) {
          let dialogRef = this.dialog.open(ChecklistPopupComponent);

          await firstValueFrom(dialogRef.afterClosed())
            .then(result => {
              return result;
            });
        }

        return true;
      })
      .catch(err => {
        console.log(err);

        return true;
      });
  }

  accessPlatform(checklistResult: boolean) {
    this.submitProcessing = false;

    if (this.auth.isAuthenticated()) {

      // RIABILITARE PER SOCIAL SHARE
      if (!this.auth.getCurrentUser().isStudent)
        this.auth.navigateToRequestedPath();
      else if (checklistResult)
        this.auth.navigateToRequestedPath();
      
      /*
      if (!this.auth.getCurrentUser().isStudent)
          this.router.navigate(['showcase']);
      else if (checklistResult)
          this.router.navigate(['showcase']);
      */
    }
  }

  getSocialBtnDisabled(provider: string) {
    if (provider === 'FACEBOOK')
      return this.submitProcessing || Helper.isNullOrEmpty(environment.facebookAppId);
    if (provider === 'GOOGLE')
      return this.submitProcessing || Helper.isNullOrEmpty(environment.googleClientId);

    return true;
  }

}

class LoginCredentials {
  username: string;
  password: string;
}

<div class="component-main">
  <div style="margin-bottom: 20px">

    <!-- Page Title -->
    <div fxLayout="row" fxLayoutAlign="center center">
      <h2>Skills Management</h2>
    </div>

    <!-- Add Skill Button -->
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 20px">
      <button mat-fab color="accent" (click)="openCreateDialog()" [matTooltip]="'Add Skill'">
        <mat-icon>add</mat-icon>
      </button>
    </div>

  </div>

  <!-- Skills Table -->
  <div class="center-item" style="width: 100%">
    <mat-table [dataSource]="skills" class="mat-elevation-z8">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let skill" [ngClass]="{'overall': skill.overall === 1}"> {{ skill.name }} </mat-cell>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"> Description </mat-header-cell>
        <mat-cell *matCellDef="let skill" fxLayoutAlign="center center" [ngClass]="{'overall': skill.overall === 1}" > {{ skill.description || 'N/A' }} </mat-cell>
      </ng-container>

      <!-- Min/Max Score -->
      <ng-container matColumnDef="minMaxScore">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"> Score (Min - Max) </mat-header-cell>
        <mat-cell *matCellDef="let skill" fxLayoutAlign="center center" [ngClass]="{'overall': skill.overall === 1}"> {{ skill.minScore }} - {{ skill.maxScore }} </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center"> Actions </mat-header-cell>
        <mat-cell *matCellDef="let skill" fxLayoutAlign="end center" [ngClass]="{'overall': skill.overall === 1}">
          <button mat-icon-button color="primary" (click)="openEditDialog(skill)" [matTooltip]="'Edit Skill'">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="deleteSkill(skill.id)" [matTooltip]="'Delete Skill'">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Table Header and Row Definitions -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <!-- Paginator -->
    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="skills.length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 25, 50, 100]"
      (page)="changePage($event)"
      color="primary"
      class="mat-elevation-z3">
    </mat-paginator>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

const CHANGELOGS: ChangelogInfo[] = [
  {
    version: '4.0',
    releaseDate: '16/02/2022',
    newFeatures: [
      ['Smily IoT support', 'add'],
      ['Add and edit rooms', 'add'],
      ['Add and edit stations', 'add'],
      ['Discount codes', 'add'],
      ['Invoices', 'add'],
      ['Remote IoT management', 'add'],
      ['Smily IoT recording during lesson', 'add'],
      ['Remote station deactivation', 'add'],
      ['Package edit', 'add'],
      ['Select and add users to package and course', 'add'],
      ['Share link of courses, live digital academies and packages', 'add']
    ],
    fixAndImprovements: [
      ['Lot\'s of bug fixed around the whole application', 'done'],
      ['Search, reorder, pagination and csv export function in users and classroom pages', 'trending_up'],
      ['5GB course content limit', 'trending_up'],
      ['New translations', 'trending_up']
    ],
    breakingChanges: []
  },
  {
    version: '3.9',
    releaseDate: '12/04/2021',
    newFeatures: [
      ['Socialwall', 'add'],
      ['Masters', 'add'],
      ['Surveys', 'add'],
      ['Dark theme', 'add'],
      ['Stripe payments for courses and masters', 'add'],
      ['Sequence course mode', 'add'],
      ['Course content tracking and statistics', 'add'],
      ['Course content position memory', 'add'],
      ['One to one masters', 'add'],
      ['Ads banner', 'add'],
      ['Notification through Socialwall and Emails', 'add'],
      ['Global user registration', 'add'],
      ['Smily room map with online state, user connected and working state', 'add'],
      ['Streaming logo overlay', 'add'],
      ['New landing page on login', 'add'],
      ['Sidenav with handy actions and my courses and masters', 'add'],
      ['Course and Master showcase', 'add'],
      ['Favourite course content list', 'add'],
      ['Google Calendar integration', 'add'],
      ['Facebook and Google log in', 'add']
    ],
    fixAndImprovements: [
      ['Lot\'s of bug fixed around the whole application', 'done'],
      ['Course deletion now frees storage space', 'trending_up'],
      ['Faster content selection during streaming', 'trending_up'],
      ['New translations', 'trending_up']
    ],
    breakingChanges: []
  },
  {
    version: '3.8',
    releaseDate: '10/11/2020',
    newFeatures: [
      ['Courses now support pdf', 'add'],
      ['Customer admin dashboard', 'add'],
      ['Bidirectional streaming (from student to teacher)', 'add'],
      ['Streaming video appearance', 'add'],
      ['Chat between teacher and student with simple notifications', 'add']
    ],
    fixAndImprovements: [
      ['Lot\'s of bug fixed around the whole application', 'done'],
      ['New translations', 'trending_up']
    ],
    breakingChanges: []
  },
  {
    version: '3.7',
    releaseDate: '22/04/2020',
    newFeatures: [
      ['Training Courses', 'add'],
      ['Author profile', 'add'],
      ['Course edit, creation and delete', 'add'],
      ['Course content edit, creation and delete', 'add'],
      ['Likes and comments on course content', 'add'],
      ['Floating course content loader popup', 'add'],
      ['Email notification when a course is put online', 'add'],
      ['Author contact form', 'add'],
      ['Author profile edit and creation in user profile page', 'add'],
      ['Multilingual courses and translations', 'add'],
      ['Multilingual course and course content edit page', 'add'],
      ['Cloud storage space informations', 'add']
    ],
    fixAndImprovements: [
      ['Lot\'s of bug fixed around the whole application', 'done'],
      ['Revisited user profile page', 'trending_up'],
      ['Unified UI between pages', 'trending_up'],
      ['File uploads now support drag-and-drop', 'trending_up'],
      ['New translations', 'trending_up'],
    ],
    breakingChanges: [
      ['Changed user profile picture storage, old profile pictures won\'t be available', 'cached']
    ]
  },
  {
    version: '3.6',
    releaseDate: '05/03/2020',
    newFeatures: [
      ['Private recording', 'add'],
      ['Added picture in picture option during scenario', 'add'],
      ['Added new changelog page', 'add'],
      ['Added notes when closing a lesson', 'add'],
      ['Added classroom transfer to the next school year', 'add'],
      ['Added password recovery procedure', 'add'],
      ['Added student registration page', 'add']
    ],
    fixAndImprovements: [
      ['Minor bug fixes', 'done'],
      ['Fix bug when leaving streaming', 'done'],
      ['Fix user insert', 'done'],
      ['Revisited user insert error dialog to be more informative', 'trending_up'],
      ['New translations', 'trending_up']
    ],
    breakingChanges: []
  },
  {
    version: '3.5',
    releaseDate: '24/02/2020',
    newFeatures: [
      ['Scenario support', 'add'],
      ['Lesson statistics', 'add'],
      ['Classroom statistics', 'add'],
      ['Teacher statistics', 'add'],
      ['Student statistics', 'add'],
      ['New change password procedure', 'add'],
      ['Added checklist for students with iButton', 'add'],
      ['New page for lesson recordings', 'add'],
      ['New page for registry', 'add'],
      ['New page for stations', 'add'],
      ['Added csv export to registry and lesson statistics', 'add'],
      ['Students information during streaming', 'add'],
      ['Auto-saving settings for scenario', 'add'],
      ['Language selection in the footer', 'add'],
    ],
    fixAndImprovements: [
      ['Lot\'s of bug fixed around the whole application', 'done'],
      ['Revisited UI', 'trending_up'],
      ['Revisited some error dialog to be more informative', 'trending_up'],
      ['New translations', 'trending_up']
    ],
    breakingChanges: [
      ['Changed recording system, old recordings won\'t be available', 'cached']
    ]
  }
];

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {

  changelogs = CHANGELOGS;

  constructor() { }

  ngOnInit() {
  }

}

export class ChangelogInfo {
  version: string = "";
  releaseDate: string = "";
  newFeatures: string[][] = [];
  fixAndImprovements: string[][] = [];
  breakingChanges: string[][] = [];
}

<div class="component-main">
    <div fxLayout="column">
        <div class="card" style="text-align: center;">
            <div style="border-radius:200px; height:200px; width:200px; background: #ffe2e2; margin:0 auto;">
                <i class="">X</i>
            </div>
            <h1>Failed</h1>
            <p>We couldn't process your request.</p>
            <br />
            <button mat-raised-button color="primary" (click)="getstripeacctLink()">
                <mat-icon>
                    sync_problem
                </mat-icon>
                {{ 'Try Again' | translate }}
            </button>
        </div>
    </div>
</div>
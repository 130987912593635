import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EduPlanContentDTO } from 'src/app/models/dto/eduPlanContentDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { EduPlanService } from 'src/app/services/eduplan.service';

@Component({
  selector: 'app-edit-eduplancontent',
  templateUrl: './edit-eduplancontent.component.html',
  styleUrls: ['./edit-eduplancontent.component.scss']
})
export class EditEduplancontentComponent implements OnInit {
  currentUser: User = null;

  seqIndex: UntypedFormControl = new UntypedFormControl(0, [Validators.required, Validators.min(0)]);
  expirationDate: UntypedFormControl = new UntypedFormControl(new Date(), [Validators.required]);

  minDate: Date = new Date();
  maxDate: Date = new Date();

  constructor(private dialogRef: MatDialogRef<EditEduplancontentComponent>,
              @Inject(MAT_DIALOG_DATA) private data,
              private auth: AuthService,
              private eduPlanService: EduPlanService,
              private darkService: DarkThemeService,
              private snackBar: MatSnackBar) {
    this.maxDate.setFullYear(this.minDate.getFullYear() + 1);
  }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    if (this.data.content) {
      this.seqIndex.setValue(this.data.content.sequenceIndex);
      this.expirationDate.setValue(new Date(this.data.content.expirationDate));

      this.minDate.setFullYear(this.data.year, 0, 1);
      this.maxDate.setFullYear(this.minDate.getFullYear() + 1, 0, 1);
    }
  }

  saveContent() {
    let content: EduPlanContentDTO = this.data.content;
    content.sequenceIndex = this.seqIndex.value;
    content.expirationDate = this.expirationDate.value;

    this.eduPlanService.editEduPlanContent(content.idEducationalPlan, content.id, content)
      .subscribe(() => {
        this.snackBar.open('Educational plan content edited successfully', 'Dismiss', { duration: 3000 });
        this.dialogRef.close(true);
      },
      err => {
        this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
      });
  }

  btnDisabled() {
    return !this.seqIndex.valid
        || !this.expirationDate.valid;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  isDark() {
    return this.darkService.isSetDark;
  }

}

<h1 mat-dialog-title>{{ 'Billing information' | translate }}</h1>
<div mat-dialog-content>
  <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="type" color="primary">

    <mat-tab label="Fattura per persona fisica" [disabled]="type !== 0">
      <div fxLayoutGap="20px" style="margin-top: 10px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>Nome Profilo</mat-label>
          <input matInput [formControl]="profileName">
          <mat-error *ngIf="profileName.hasError('required')">
            Il nome profilo è richiesto
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Nazione</mat-label>
          <input matInput [formControl]="nation">
          <mat-error *ngIf="nation.hasError('required')">
            La nazione è richiesta
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <!-- <mat-form-field appearance="standard" color="primary" fxFlex>
          <mat-label>Provincia</mat-label>
          <input matInput [formControl]="province">
          <mat-error *ngIf="province.hasError('required')">
            La provincia è richiesta
          </mat-error>
        </mat-form-field>  -->
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>{{ 'Province' | translate }}</mat-label>
          <mat-select [formControl]="province" required >
            <mat-option *ngFor="let city of provinces" [value]="city.name">{{ city.name + ' (' + city.description + ')' }}</mat-option>
          </mat-select>
          <mat-error *ngIf="province.hasError('required')">
            {{ 'Province is required' | translate }} 
          </mat-error>
        </mat-form-field> 


        <mat-form-field color="primary" fxFlex>
          <mat-label>Città</mat-label>
          <input matInput [formControl]="city">
          <mat-error *ngIf="city.hasError('required')">
            La città è richiesta
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>CAP</mat-label>
          <input matInput [formControl]="cap">
          <mat-error *ngIf="cap.hasError('required')">
            Il CAP profilo è richiesto
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Indirizzo</mat-label>
          <input matInput [formControl]="address">
          <mat-error *ngIf="address.hasError('required')">
            L'indirizzo è richiesto
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>Nome</mat-label>
          <input matInput [formControl]="name">
          <mat-error *ngIf="name.hasError('required')">
            Il nome è richiesto
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Cognome</mat-label>
          <input matInput [formControl]="surname">
          <mat-error *ngIf="surname.hasError('required')">
            Il cognome è richiesto
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>Codice Fiscale</mat-label>
          <input matInput [formControl]="codiceFiscale">
          <mat-error *ngIf="codiceFiscale.hasError('required')">
            Il codice fiscale è richiesto
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>PEC</mat-label>
          <input matInput [formControl]="pec">
          <mat-error *ngIf="pec.hasError('email')">
            L'email inserita non è valida
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Codice Destinatario</mat-label>
          <input matInput [formControl]="recipientCode">
        </mat-form-field>
      </div>
    </mat-tab>

    <mat-tab label="Fattura per aziende" [disabled]="type !== 1">
      <div fxLayoutGap="20px" style="margin-top: 10px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>Nome Profilo</mat-label>
          <input matInput [formControl]="profileName">
          <mat-error *ngIf="profileName.hasError('required')">
            Il nome profilo è richiesto
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Nazione</mat-label>
          <input matInput [formControl]="nation">
          <mat-error *ngIf="nation.hasError('required')">
            La nazione è richiesta
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>Provincia</mat-label>
          <input matInput [formControl]="province">
          <mat-error *ngIf="province.hasError('required')">
            La provincia è richiesta
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Città</mat-label>
          <input matInput [formControl]="city">
          <mat-error *ngIf="city.hasError('required')">
            La città è richiesta
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>CAP</mat-label>
          <input matInput [formControl]="cap">
          <mat-error *ngIf="cap.hasError('required')">
            Il CAP profilo è richiesto
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Indirizzo</mat-label>
          <input matInput [formControl]="address">
          <mat-error *ngIf="address.hasError('required')">
            L'indirizzo è richiesto
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>Ragione Sociale</mat-label>
          <input matInput [formControl]="rSociale">
          <mat-error *ngIf="rSociale.hasError('required')">
            La ragione sociale è richiesta
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>P.IVA</mat-label>
          <input matInput [formControl]="pIva">
          <mat-error *ngIf="pIva.hasError('required')">
            La partita IVA è richiesta
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>Codice Fiscale Aziendale</mat-label>
          <input matInput [formControl]="codFiscaleAz">
          <mat-error *ngIf="codFiscaleAz.hasError('required')">
            Il codice fiscale aziendale è richiesto
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutGap="20px">
        <mat-form-field color="primary" fxFlex>
          <mat-label>PEC</mat-label>
          <input matInput [formControl]="pec">
          <mat-error *ngIf="pec.hasError('email')">
            L'email inserita non è valida
          </mat-error>
        </mat-form-field>
  
        <mat-form-field color="primary" fxFlex>
          <mat-label>Codice Destinatario</mat-label>
          <input matInput [formControl]="recipientCode">
        </mat-form-field>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" (click)="sendData()" [disabled]="!isValid()">Ok</button>
</div>

<div class="component-main">
    <div class="center">
        <div class="title">
            <h2> {{ isAdmin() ? currentCustomer()?.name : ''}} {{ 'Dashboard' | translate }}</h2>
        </div>
    </div>

    <div class="title" fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 20px">

        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" fxFlex="30"> 
            <div class="year">
                <mat-form-field>
                    <mat-label>{{ 'Year' | translate }}</mat-label>
                    <mat-select [formControl]="yearDate" (selectionChange)="filterData('year')">
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{ year }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="month">
                <mat-form-field>
                    <mat-label>{{ 'Month' | translate }}</mat-label>
                    <mat-select [formControl]="monthDate" (selectionChange)="filterData('month')">
                        <mat-option *ngFor="let month of months" [value]="month.value">
                            {{ month.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="isAdmin()">
                <mat-form-field style="width: 350px">
                    <mat-label>{{ 'Customer' | translate }}</mat-label>  
                    <mat-select [formControl]="customerForm" (selectionChange)="getDataOfCustomer()">
                        <mat-option>
                            <ngx-mat-select-search
                                [formControl]="customerSearchForm"
                                [placeholderLabel]="'Search' | translate"
                                [noEntriesFoundLabel]="'No data matching the word' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let customer of customers | search: customerSearchForm.value: ['name']" [value]="customer.id">
                            {{ customer.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
        </div>

        <div fxLayoutAlign="center center" fxLayoutGap="20px" fxFlex>
            <mat-checkbox 
                *ngIf="isAdmin()"
                (change)="$event.checked ? getDataOfAll() : getDataOfCustomer()"
                [formControl]="allCustomersView"> 
                <h4 [matTooltip]="'See all customers data' | translate"> {{ 'All customers' | translate }} </h4>
            </mat-checkbox>

            <button mat-fab color="primary" (click)="allCustomersView.value ? getDataOfAll() : getDataOfCustomer()" [matTooltip]="'Refresh' | translate">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>

        <div fxLayoutAlign="end center" fxLayoutGap="20px" fxFlex="30">
            <div>
                <p class="updateTime">{{ '(*) Data updated on' | translate }} {{ customerResultsLastUpdate | date: 'dd/MM/yyyy' }} 
                {{ 'at' | translate }} {{ customerResultsLastUpdate | date: 'HH:mm' }}</p>
            </div>

            <button mat-fab color="primary"
                (click)="saveAllToCsv()"
                [matTooltip]="'Save csv' | translate">
                <mat-icon>save</mat-icon>
          </button>
        </div>
    
    </div>

    <mat-card appearance="outlined" class="main" fxLayout="column">

        <mat-card-header fxLayout="row">
            <div fxFlex fxLayoutAlign="start center"  *ngIf="customerForm.value && !allCustomersView.value" style="margin-left:2.5%;">
                <p>{{ 'Customer created on' | translate }} {{ currentCustomer()?.created | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div fxFlex fxLayoutAlign="end center" style="margin-right:2.5%;" >
                <button mat-fab color="primary"
                    (click)="exportCustomerResults()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <mat-card-content>

            <ng-container>
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="center" class="matcard-list">
                
                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Total published stream time' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex>
                            <div class="value-container">
                                <mat-icon class="card-icon">access_time</mat-icon>
                                <h1 class="card-value">
                                    {{ (allCustomersView.value ? publishedStreamingTimeOfAllPerMonth : publishedStreamingTimeOfCustomerPerMonth) / 3600  | number : '1.2-2' }} h
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <h3>{{ secondsToDhms(allCustomersView.value ? publishedStreamingTimeOfAllPerMonth : publishedStreamingTimeOfCustomerPerMonth) }}</h3>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Total streaming time' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex>
                            <div class="value-container">
                                <mat-icon class="card-icon">access_time</mat-icon>
                                <h1 class="card-value">
                                    {{ (allCustomersView.value ? streamTimeOfAllPerMonth : streamTimeOfCustomerPerMonth) / 3600  | number : '1.2-2' }} h
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <h3>{{ secondsToDhms(allCustomersView.value ? streamTimeOfAllPerMonth : streamTimeOfCustomerPerMonth) }}</h3>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Active users' | translate }} / {{ 'registered' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex>
                            <div class="value-container">
                                <mat-icon class="card-icon">account_circle</mat-icon>
                                <h1 class="card-value" style="padding-right:10%">
                                    {{ (allCustomersView.value ? activeUsersOfAllPerMonth : activeUsersOfCustomerPerMonth) }}/{{ (allCustomersView.value ? registeredUsersOfAllPerMonth : registeredUsersOfCustomerPerMonth) }}
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <p style="margin: 10px;text-align: center"><strong>{{ 'Users who used the platform during the month' | translate }}</strong></p>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Total online time' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex>
                            <div class="value-container">
                                <mat-icon class="card-icon">access_time</mat-icon>
                                <h1 class="card-value">
                                    {{ (allCustomersView.value ? onlineTimeOfAllPerMonth : onlineTimeOfCustomerPerMonth) / 3600  | number : '1.2-2' }} h
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <h3>{{ secondsToDhms(allCustomersView.value ? onlineTimeOfAllPerMonth : onlineTimeOfCustomerPerMonth) }}</h3>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Average daily storage' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex>
                            <div class="value-container">
                                <mat-icon class="card-icon">sd_storage</mat-icon>
                                <h1 class="card-value">
                                    {{ formatKB(allCustomersView.value ? averageDailyStorageOfAllPerMonth : averageDailyStorageOfCustomerPerMonth) }}
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <p style="margin:5px"><strong>{{ 'Average daily storage occupancy of current month' | translate }}</strong></p>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Max daily storage' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxLayoutAlign="center center" fxFlex>
                            <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px">
                                <mat-icon style="width:55px;height:55px;font-size:55px">sd_storage</mat-icon>
                                <h1>
                                    {{ formatKB((allCustomersView.value ? dailyCustomersStorageMaxOfAll?.dailyArchivedStorage : dailyCustomerStorageMaxOfCustomer?.dailyArchivedStorage) ?? 0) }}
                                </h1>
                                <span *ngIf="allCustomersView.value ? dailyCustomersStorageMaxOfAll : dailyCustomerStorageMaxOfCustomer" style="font-size:21px;">
                                    {{ 'on' | translate }} {{ allCustomersView.value ? dailyCustomersStorageMaxOfAll?.day : dailyCustomerStorageMaxOfCustomer?.day }}/{{ allCustomersView.value ? dailyCustomersStorageMaxOfAll?.month : dailyCustomerStorageMaxOfCustomer?.month }}
                                </span>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <p style="margin:5px"><strong>{{ 'Max daily storage occupancy of current month' | translate }}</strong></p>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Current storage size' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex style="margin-bottom:-5%">
                            <div class="value-container">
                                <mat-icon class="card-icon">sd_storage</mat-icon>
                                <h1 class="card-value" style="padding-right:10%">
                                    {{ (allCustomersView.value ? storagePercentageOfAll : storagePercentageOfCustomer) | number: '1.0-2' }} %
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer style="margin-bottom:-5%;margin-left:1%;margin-right:1%" >
                            <h3> {{ (allCustomersView.value ? 'Used' : 'You have used') | translate }} {{ formatGB(allCustomersView.value ? storageCurrentSizeOfAll : storageCurrentSizeOfCustomer) }} {{'out of' | translate }} {{ formatGB(allCustomersView.value ? storageMaxSizeOfAll : storageMaxSizeOfCustomer )}} {{ 'available' | translate}}</h3>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Webinar performed' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex>
                            <div class="value-container">
                                <mat-icon class="card-icon">interpreter_mode</mat-icon>
                                <h1 class="card-value">
                                    {{ (allCustomersView.value ? webinarResultNumberOfAllPerMonth : webinarResultNumberOfCustomerPerMonth) }}/{{ ((allCustomersView.value ? webinarResultStreamingTimeOfAllPerMonth : webinarResultStreamingTimeOfCustomerPerMonth)) / 3600 | number : '1.2-2' }} h
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer style="display:flex;justify-content: space-around;">
                            <h3>{{ 'Participants' | translate }}: {{ (allCustomersView.value ? webinarResultParticipantsOfAllPerMonth : webinarResultParticipantsOfCustomerPerMonth) }}</h3>
                            <h3>{{ 'Presenters' | translate }}: {{ (allCustomersView.value ? presentersOfWebinarOfAllPerMonth : presentersOfWebinarOfCustomerPerMonth) }}</h3>
                        </mat-card-footer>
                    </mat-card>

                    <mat-card appearance="outlined" class="matcard-row">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Lessons performed' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content fxFlex>
                            <div class="value-container">
                                <mat-icon class="card-icon">school</mat-icon>
                                <h1 class="card-value">
                                    {{ (allCustomersView.value ? lessonResultsPerformedOfAllPerMonth : lessonResultsPerformedOfCustomerPerMonth) }}/{{((allCustomersView.value ? lessonResultsStreamingTimeOfAllPerMonth : lessonResultsStreamingTimeOfCustomerPerMonth)) / 3600 | number : '1.2-2' }} h
                                </h1>
                            </div>
                        </mat-card-content>
                        <mat-card-footer>
                            <h3>{{ secondsToDhms(allCustomersView.value ? lessonResultsStreamingTimeOfAllPerMonth : lessonResultsStreamingTimeOfCustomerPerMonth) }}</h3>
                        </mat-card-footer>
                    </mat-card>
                
                </div>
            </ng-container>

        </mat-card-content>

    </mat-card>

    <mat-card appearance="outlined" class="main" style="margin-top:50px" fxLayout="column">

        <mat-card-header>
            <div fxFlex="20"></div>
            <div fxFlex="60" style="text-align: center;">
                <h2>{{ 'Active and Registered users in' | translate }} {{ yearDate.value }}</h2>
            </div>
            <div fxFlex style="margin-right:2.5%" fxLayoutAlign="end center">
                <button mat-fab color="primary"
                    (click)="exportActiveUsers()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <ng-container class="chart-container" loading="lazy">
                <canvas id="ActiveUsersChart" style="width:100%">{{ activeUsersChart }}</canvas>
            </ng-container>
        </mat-card-content> 
       
    </mat-card> 

    <mat-card appearance="outlined" class="main" style="margin-top:50px" fxLayout="column">

        <mat-card-header>
            <div fxFlex="20"></div>
            <div fxFlex="60" style="text-align: center;">
                <h2>{{ 'Total streaming time in' | translate }} {{ yearDate.value }}</h2>
            </div>
            <div fxFlex style="margin-right:2.5%" fxLayoutAlign="end center">
                <button mat-fab color="primary"
                    (click)="exportTotalStreamingTime()" 
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <ng-container class="chart-container" loading="lazy">
                <canvas id="PublishedStreamingChart" style="width:100%">{{ publishedStreamingChart }}</canvas>
            </ng-container>
        </mat-card-content> 
    
    </mat-card>

    <mat-card appearance="outlined" class="main" style="margin-top:50px" fxLayout="column">

        <mat-card-header>
            <div fxFlex="20"></div>
            <div fxFlex="60" style="text-align: center;">
                <h2>{{ 'Total online time in' | translate }} {{ yearDate.value }}</h2> 
            </div>
            <div fxFlex style="margin-right:2.5%" fxLayoutAlign="end center">
                <button mat-fab color="primary"
                    (click)="exportTotalOnlineTime()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <ng-container loading="lazy">
                <canvas id="OnlineTimeChart" style="width:100%">{{ onlineTimeChart }}</canvas>
            </ng-container>
        </mat-card-content> 
       
    </mat-card> 

    <mat-card appearance="outlined" class="main" style="margin-top:50px" fxLayout="column">

        <mat-card-header>
            <div fxFlex="20"></div>
            <div fxFlex="60" style="text-align: center;">
                <h2>{{ 'Webinar data' | translate }} {{ yearDate.value }}</h2> 
            </div>
            <div fxFlex style="margin-right:2.5%" fxLayoutAlign="end center">
                <button mat-fab color="primary"
                    (click)="exportWebinarData()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <mat-card-content>
            <ng-container loading="lazy">
                <canvas id="WebinarPerformedChart" style="width:100%">{{ webinarPerformedChart }}</canvas>
            </ng-container>
        </mat-card-content>
       
    </mat-card> 

</div>

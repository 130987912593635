<div class="component-main" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <h2>{{ 'Certifications' | translate }}</h2>

  <!-- Action & Search Section -->
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="action-area" fxFlexFill>

    <!-- Search Box -->
    <div fxLayout="row" fxFlex="20" class="search-container">
      <mat-form-field fxFlex>
        <mat-label>{{ 'Search' | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="value"
          (ngModelChange)="resetCertifications(false)"
          placeholder="{{ 'Type to search...' | translate }}"
        />
        <button
          matSuffix
          mat-icon-button
          matTooltip="{{ 'Clear search' | translate }}"
          (click)="resetCertifications(true)">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!-- Refresh & Add -->
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="60" fxLayoutGap="15px" style="margin-bottom: 14.6px">
      <button mat-fab color="primary" matTooltip="{{ 'Refresh' | translate }}" (click)="refreshCertifications()">
        <mat-icon>refresh</mat-icon>
      </button>
      <button
        mat-fab
        extended
        color="accent"
        matTooltip="{{ 'Add New Certification' | translate }}"
        (click)="openAddCertificationDialog()"
        *ngIf="auth.isTeacher()"
      >
        <mat-icon>add</mat-icon>
        {{ 'Add Certification' | translate }}
      </button>
    </div>

    <!-- Mode Selection -->
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
      <mat-form-field color="primary">
        <mat-label>{{ 'Mode' | translate }}</mat-label>
        <mat-select [(value)]="mode" (selectionChange)="getCertifications()">
          <mat-option value="incoming">{{ 'Incoming' | translate }}</mat-option>
          <mat-option value="performed">{{ 'Performed' | translate }}</mat-option>
          <mat-option value="expired">{{ 'Expired' | translate }}</mat-option>
          <mat-option value="all">{{ 'All' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Loading -->
  <div *ngIf="loading; else certificationsBlock" fxLayout="column" fxLayoutAlign="center center" style="height: 60vh;">
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <!-- Main -->
  <ng-template #certificationsBlock>

    <!-- If no certifications -->
    <div *ngIf="!backupCertifications?.length" fxLayoutAlign="center center">
      <app-error-message customClass="warning"
        [errorTitle]="'No certifications available' | translate"
        [errorContent]="'Add a certification using the button above' | translate">
      </app-error-message>
    </div>

    <!-- Certifications List -->
    <div style="width: 100%" *ngIf="backupCertifications?.length > 0">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let certification of certifications"
          style="margin-bottom: 10px; border-radius: 4px;"
        >
          <mat-expansion-panel-header>
            <div fxLayout="column" fxFlex>
              <mat-panel-title fxLayout="row">
                <span class="exercise-title" fxFlex style="margin-top: 15px;">
                  <strong>{{ certification.name }}</strong>
                </span>
              </mat-panel-title>

              <mat-panel-description 
                fxLayout="row" 
                fxLayoutAlign="end center" 
                style="margin-bottom: 7px; margin-top: 7px;">
                <div fxFlex></div>
                <div fxLayout="row">
                  <button
                    *ngIf="auth.isTeacher()"
                    mat-icon-button
                    color="primary"
                    (click)="openEditCertificationsDialog(certification); $event.stopPropagation();"
                    [disabled]="!isEditable(certification)"
                    [matTooltip]="'Edit certification' | translate"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button
                    *ngIf="auth.isTeacher()"
                    mat-icon-button
                    color="warn"
                    (click)="deleteCertification(certification); $event.stopPropagation();"
                    [matTooltip]="'Delete certification' | translate"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>

          <mat-divider style="margin-bottom: 20px"></mat-divider>

          <!-- Description Section -->
          <div>
            <strong>{{ 'Description' | translate }}:</strong>
            <p>
              {{ certification.description || ('No additional details available' | translate) }}
            </p>
            <!-- a showMore button:
            <button *ngIf="(certification.description?.length || 0) > 100" (click)="certification.showFullDescription = !certification.showFullDescription">
              {{ certification.showFullDescription ? ('Show Less' | translate) : ('Show More' | translate) }}
            </button>
            -->
          </div>

          <!-- "run" button logic -->

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Pagination -->
    <div style="width: 100%; display: flex; justify-content: center;">
      <mat-paginator
        [showFirstLastButtons]="true"
        [length]="totalCertifications"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20]"
        [color]="darkService.isSetDark ? 'accent' : 'primary'"
        (page)="changePage($event)"
        class="mat-elevation-z3"
      >
      </mat-paginator>
    </div>

  </ng-template>
</div>

import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-smartcabinet360-login',
  templateUrl: './smartcabinet360-login.component.html',
  styleUrls: ['./smartcabinet360-login.component.scss']
})
export class Smartcabinet360LoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  logo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.SmartCabinet, this.themeService.isSetDark ? EnvironmentParameterType.LogoLight : EnvironmentParameterType.LogoDark);
  backgroundImage: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.SmartCabinet, EnvironmentParameterType.BackgroundImage);
  motto: string = environment.applicationMotto;

  idCustomer: number = 79;
  customerCode: string = 'SSC2023';

  constructor(
    private themeService: DarkThemeService,
    private translationService: TranslationService,
    private navBar: NavBarService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.translationService.setLang(LanguageCodes.IT);
    this.themeService.onLogOut();
    this.navBar.hide('topbar');
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

<h1 mat-dialog-title>{{ 'Demo request' | translate }}</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="20px">

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="primary">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput [formControl]="name" name="name" required>
            <mat-error>
                {{ 'The name is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    
        <mat-form-field color="primary">
            <mat-label>{{ 'surname' | translate }}</mat-label>
            <input matInput [formControl]="surname" name="surname" required>
            <mat-error>
                {{ 'The surname is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>     
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" class="field">

        <mat-form-field color="primary">
            <mat-label>{{ 'Customer Name' | translate }}</mat-label>
            <input matInput [formControl]="customerName" name="customerName" required>
              <mat-error *ngIf="customerName.hasError('required')">
                {{ 'The customer name is' | translate }} <strong>{{ 'Required' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="customerName.hasError('alreadyUsed')">
                {{ 'Customer name already used' | translate }}
              </mat-error>
          </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'Email' | translate }}</mat-label>
            <input matInput [formControl]="email" name="email" required>
            <mat-error *ngIf="email.hasError('required')">
                {{ 'The email is' | translate }} <strong>{{ 'required' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="email.hasError('email')" >
                {{ 'Please enter a valid email address' | translate }} 
            </mat-error>
            <mat-error *ngIf="email.hasError('alreadyUsed')" >
                {{ 'Email already used' | translate }} 
            </mat-error>
        </mat-form-field>
    </div>

</div>

<div mat-dialog-actions>
    <button mat-stroked-button 
        (click)="onNoClick()">
        {{ 'Cancel' | translate }}
    </button>
    
    <button mat-flat-button 
        (click)="requestDemo()"
        [disabled]="getDemoBtnDisabled()" 
        color="accent">
        {{ 'Require a demo' | translate }}
    </button>
</div>

<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ 'Received Orders' | translate }}</h2>
    </div>
    <div class="mat-elevation-z8">
        <div fxLayout="row" fxLayoutGap="20px" style="padding: 10px 20px 0px 20px">
            <div fxLayoutGap="20px" fxFlex>
                <mat-form-field color="primary">
                    <mat-label>{{ 'Search' | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event)">
                    <button matSuffix mat-icon-button [matTooltip]="'Search' | translate">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field style="width:120px" color="primary">
                    <mat-label>{{ 'Year' | translate }}</mat-label>
                    <mat-select  [(value)]="selectedYear" (selectionChange)="getOrders()"  placeholder="year">
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field color="primary" style="width:180px">
                    <mat-label>{{ 'Status' | translate }}</mat-label>
                    <mat-select [(value)]="selectedStatus" (selectionChange)="getOrders()">
                        <mat-option value="all">{{ 'All' | translate }}</mat-option>
                        <mat-option *ngFor="let item of statuses" [value]="item">{{ item | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field color="primary" style="width:180px">
                    <mat-label>{{ 'Type' | translate }}</mat-label>
                    <mat-select [(value)]="selectedType" (selectionChange)="getOrders()">
                        <mat-option value="all">{{ 'All' | translate }}</mat-option>
                        <mat-option *ngFor="let item of types" [value]="item">{{ item }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field color="primary" style="width:180px">
                    <mat-label>{{ 'Source' | translate }}</mat-label>
                    <mat-select [(value)]="selectedSource" (selectionChange)="getOrders()">
                        <mat-option value="all">{{ 'All' | translate }}</mat-option>
                        <mat-option *ngFor="let item of sources" [value]="item">{{ item }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="currentUser.isAdmin" color="primary">
                    <mat-label>{{ 'Customer' | translate }}</mat-label>
                    <mat-select [(value)]="selectedCustomer" (selectionChange)="getOrders()">
                        <mat-option [value]="0">{{ 'All Customer' | translate }}</mat-option>
                        <mat-option *ngFor="let item of customers" [value]="item.id">{{ item.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayoutAlign="end center" fxFlex="20" style="font-size: 1.5rem;" [style.font-size]="currentUser.isAdmin ? '1.3rem' : '1.5rem'" fxLayoutGap="20">
                <button mat-fab color="primary" (click)="paymentsExportToCsv()" 
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
                <span *ngIf="selectedStatus !== 'unpaid'"><strong>{{ 'Total paid' | translate }}</strong> {{ getTotal() | number : '1.2-2' }}€</span>
            </div>

        </div>

        <mat-table #table [dataSource]="dataSource" multiTemplateDataRows matSort>

            <ng-container matColumnDef="picture">
                <mat-header-cell *matHeaderCellDef>{{ 'Thumbnail' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <img [src]="element.cartItemImage" loading="lazy"/>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element;">{{ element.created | date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element;">{{ element.user.name + ' ' + element.user.surname }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cartItemName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.cartItemName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Amount' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.amount === 0 ? element.amount : element.amount | number : '1.2-2' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="currency">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Currency' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.currency }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="paymentStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.paymentStatus | translate }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="paymentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.paymentType }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="paymentSource">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Source' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.paymentSource }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>{{ 'Action' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button
                        color="primary"
                        matTooltip="Go to Stripe"
                        (click)="goToStripe(element.paymentIntent)"
                        [disabled]="isNullOrEmpty(element.paymentIntent)">
                        <mat-icon>payment</mat-icon>
                    </button>
                    <button mat-icon-button (click)="goToEntity(element.idsubscription)" [matTooltip]="'View' | translate">
                        <mat-icon>launch</mat-icon>
                    </button>
                    <button mat-icon-button
                        (click)="getById(element)"
                        [matTooltip]="(selectedSubSession?.id === element?.id ? 'Collapse' : 'Expand') | translate">
                        <mat-icon>{{ selectedSubSession?.id === element?.id ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" >
                    <div *ngIf="selectedSubSession" class="element-detail" [@detailExpand]="selectedSubSession.id === element.id ? 'expanded' : 'collapsed'">

                        <mat-table #table [dataSource]="dataSource2.filteredData" style="padding: 20px 0 20px 0">

                            <ng-container matColumnDef="picture">
                                <mat-header-cell *matHeaderCellDef>{{ 'Thumbnail' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <img [src]="element.cartItemImage" loading="lazy"/>
                                </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="cartItemName">
                                <mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.cartItemName }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="cartItemDescription">
                                <mat-header-cell *matHeaderCellDef>{{ 'Description' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <span>
                                        {{ element.cartItemDescription?.length > 100 ? ((element.cartItemDescription | slice:0:97) + '...') : element.cartItemDescription }}
                                    </span>
                                </mat-cell>
                            </ng-container>
                
                            <mat-header-row *matHeaderRowDef="subDisplayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: subDisplayedColumns;"></mat-row>
                        </mat-table>

                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" color="primary">
        </mat-paginator>
    </div>
</div>

<h1 mat-dialog-title class="grade-title">{{ 'Grading' | translate }}</h1>

<div mat-dialog-content class="grade-content">
    <p class="grading-instructions">{{ 'Assign scores below.' | translate }}</p>

    <!-- If only one skill (Overall) -->
    <div *ngIf="assignedSkills.length === 1 && assignedSkills[0]?.overall === 1; else multipleSkillsTemplate"
        class="skill-item">
        <div class="skill-label">{{ assignedSkills[0].name }}</div>
        <mat-form-field appearance="fill" class="grade-form-field">
            <mat-label>{{ 'Score' | translate }}</mat-label>
            <input matInput type="number" [min]="assignedSkills[0].minScore" [max]="assignedSkills[0].maxScore"
                [(ngModel)]="selectedScores[assignedSkills[0].id]" (input)="onScoreChange()" />
        </mat-form-field>

        <mat-form-field appearance="fill" class="grade-form-field notes-field">
            <mat-label>{{ 'Notes' | translate }}</mat-label>
            <textarea matInput rows="5" [(ngModel)]="selectedNotes[assignedSkills[0].id]"
                (input)="onNoteChange()"></textarea>
        </mat-form-field>
    </div>

    <ng-template #multipleSkillsTemplate>
        <mat-tab-group class="grade-tab-group" animationDuration="0ms">
            <mat-tab *ngFor="let skill of assignedSkills" [label]="skill.name">
                <div class="skill-item">
                    <mat-form-field appearance="fill" class="grade-form-field">
                        <mat-label>Score ({{ skill.minScore }} - {{ skill.maxScore }})</mat-label>
                        <input matInput type="number" [min]="skill.minScore" [max]="skill.maxScore"
                            [(ngModel)]="selectedScores[skill.id]" (input)="onScoreChange()" />
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="grade-form-field notes-field">
                        <mat-label>{{ 'Notes' | translate }}</mat-label>
                        <textarea matInput rows="5" [(ngModel)]="selectedNotes[skill.id]"
                            (input)="onNoteChange()"></textarea>
                    </mat-form-field>
                </div>
            </mat-tab>
        </mat-tab-group>
    </ng-template>
</div>

<div class="grade-actions-wrapper">
    <div class="grade-actions">
        <button mat-stroked-button (click)="onClose()">Cancel</button>
        <button mat-flat-button color="primary" (click)="onSubmitGrade()" [disabled]="!canSubmitGrade()">
            Submit Grade
        </button>
    </div>
</div>
<div style="margin-top: 10px">

    <input #fileInput type="file" [accept]="acceptedTypes" [hidden]="true">

    <button #mainBtn mat-button
        (click)="fileSelection()"
        [disabled]="control.disabled"
        [style.display]="fileSelected ? 'none' : 'block'"
        class="main-btn">
        <mat-icon>add</mat-icon>
    </button>

    <div *ngIf="fileSelected">
        <div matRipple [matRippleDisabled]="control.disabled" class="file-selected-container">
            <img *ngIf="fileImg; else noFileImg" [src]="fileImg" loading="lazy" class="selected-image">
        </div>
        <button mat-button (click)="deleteFile()" [disabled]="control.disabled" class="delete-btn">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <div *ngIf="action && action !== 'accepted'">
        <mat-error *ngIf="action === 'rejected'">
            {{ rejectedErrorMessage }}
        </mat-error>
        <mat-error *ngIf="action === 'deleted' && control.hasError('required')">
            {{ deletedErrorMessage }}
        </mat-error>
    </div>

</div>

<ng-template #noFileImg>
    <mat-icon>insert_drive_file</mat-icon>
    <span class="selected-name">{{ fileName }}</span>
</ng-template>

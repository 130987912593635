import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-hroconsulting-login',
  templateUrl: './hroconsulting-login.component.html',
  styleUrls: ['./hroconsulting-login.component.scss']
})
export class HroconsultingLoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  logo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.Hro, EnvironmentParameterType.Logo);
  backgroundImage: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.Hro, EnvironmentParameterType.BackgroundImage)})`;
  name: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.Hro, EnvironmentParameterType.Title).toUpperCase();
  motto: string = 'HR&O Consulting\n formazione on demand';

  idCustomer: number = 11;
  customerCode: string = 'hr&o2023';

  constructor(
    private themeService: DarkThemeService,
    private translationService: TranslationService,
    private navBar: NavBarService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.translationService.setLang(LanguageCodes.IT);
    this.themeService.onLogOut();
    this.navBar.hide('topbar');
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

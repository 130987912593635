import { AfterViewInit, Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyService } from 'src/app/services/survey.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SurveyTemplateDialogComponent } from '../../survey-template-dialog/survey-template-dialog.component';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-survey-template-list-page',
  templateUrl: './survey-template-list-page.component.html',
  styleUrls: ['./survey-template-list-page.component.scss']
})
export class SurveyTemplateListPageComponent implements AfterViewInit, OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<SurveyDTO>();
  displayedColumns = ['name', 'description', 'author', 'created', 'actions'];
  currentUser: User;

  @Output()
  onSurvey = new EventEmitter<{survey: SurveyDTO, idx: number}>();
  
  constructor(private surveyService: SurveyService,
              private dialog: MatDialog,
              private auth: AuthService,
              private router: Router,
              private translate: TranslateService,
              private snackBar: MatSnackBar) { }

  ngOnInit() { 
    this.currentUser = this.auth.getCurrentUser();
  }

  ngAfterViewInit() {
    this.surveyService.getSurveys()
      .subscribe(res => {
        this.dataSource.data = res;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  doFilter(e: any) {
    this.dataSource.filter = e.target.value.trim().toLowerCase();
  }

  newSurveys() {
    const dialogRef = this.dialog.open(SurveyTemplateDialogComponent, {
      width: '500px',
      disableClose: false,
      autoFocus: true,
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!result)
        return;

      this.surveyService.getSurvey(result.Message)
        .subscribe(res => {
          this.dataSource.data.push(res);

          let survey = this.dataSource.data[this.dataSource.data.length - 1];

          this.router.navigate(['/survey-creator/', survey.id]);
        });
    });
  }

  editSurvey(survey: SurveyDTO) {
    this.router.navigate(['/survey-creator', survey.id]);
  }

  async deleteSurvey(survey: SurveyDTO) {
    const dialog = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Warning')),
          body: `${await firstValueFrom(this.translate.get('Are you sure to delete'))} ${survey.name}?`
        }
      });

    dialog.afterClosed()
      .subscribe(res => {

        if (!res)
          return;

        this.surveyService.deleteSurvey(survey.id)
          .subscribe({
            next: () => {
              this.snackBar.open('Survey deleted', undefined, { duration: 3000 });
              this.ngAfterViewInit();
            },
            error: err => {
              console.error(err);
              this.snackBar.open('Error deleting survey', undefined, { duration: 3000 });
            }
          });

      });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { SurveyService } from 'src/app/services/survey.service';
import { ActivatedRoute } from '@angular/router';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Model } from 'survey-core';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { MatStepper } from '@angular/material/stepper';
import { FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-survey-results-page',
  templateUrl: './survey-results-page.component.html',
  styleUrls: ['./survey-results-page.component.scss']
})
export class SurveyResultsPageComponent implements OnInit {

  @ViewChild(MatStepper) private stepper: MatStepper;

  surveyDto: SurveyDTO;
  idClassroom: number;
  idSurvey: number;
  idLesson: number;

  selectedUserId: number;
  selectedUser: string;
  selectedIdAnswer: number;
  selectedScoreA: number;
  selectedScore: FormControl<number> = new FormControl<number>(0, [Validators.required, Validators.min(0)]);
  selectedScoreNote: FormControl<string> = new FormControl<string>(undefined);
  selectedScoreDate: Date;

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.dataSrc.sort = matSort;

    this.dataSrc.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return `${item.name} ${item.surname}`;
        case 'score':
          return item.surveyAnswer.length > 0 ? item.surveyAnswer[0].score : undefined;
        case 'scoreA':
          return item.surveyAnswer.length > 0 ? item.surveyAnswer[0].scoreA : undefined;
        case 'scoreNote':
          return item.surveyAnswer.length > 0 ? item.surveyAnswer[0].scoreNote : undefined;
        case 'scoreDate':
          return item.surveyAnswer.length > 0 ? new Date(item.surveyAnswer[0].scoreDate) : undefined;
        case 'date':
          return item.surveyAnswer.length > 0 ? new Date(item.surveyAnswer[0].created) : undefined;
        default:
          return item[property];
      }
    };
  }

  @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
    this.dataSrc.paginator = matPaginator;
  }

  dataSrc: MatTableDataSource<UserDTO> = new MatTableDataSource<UserDTO>();
  displayedColumns: string[] = ['name', 'score', 'scoreNote', 'scoreDate', 'date', 'survey'];
  
  surveyModel: Model;
  lastStep: boolean = false;

  constructor(private location: Location,
              private surveyService: SurveyService,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar) { }

  ngOnInit(): void {

    if (this.route.snapshot.params['id']) {
      this.idSurvey = this.route.snapshot.params['id'];
      this.idLesson = this.route.snapshot.queryParams['idLesson'];
      this.idClassroom = this.route.snapshot.queryParams['idClassroom'];

      this.surveyService.getSurvey(this.idSurvey)
        .subscribe(output => this.surveyDto = output);

      this.loadAnswers();
    }

  }

  loadAnswers() {
    this.surveyService.getSurveyAnswersClassroom(this.idClassroom, this.idLesson)
      .subscribe(res => {

        let usersWithAnswers = res.filter(u => u.surveyAnswer.length > 0);

        if (usersWithAnswers.some(uwa => uwa.surveyAnswer[0].scoreA != undefined) && !this.displayedColumns.some(dc => dc === 'scoreA'))
          this.displayedColumns.splice(2, 0, 'scoreA');

        this.dataSrc.data = usersWithAnswers;

      });
  }

  showAnswer() {
    let user = this.dataSrc.data.find(d => d.id === this.selectedUserId);

    if (user == undefined)
      return;

    this.selectedUser = `${user.name} ${user.surname}`;
    this.selectedIdAnswer = user.surveyAnswer[0].id;
    this.selectedScoreA = user.surveyAnswer[0].scoreA;
    this.selectedScoreDate = user.surveyAnswer[0].scoreDate;

    this.selectedScore.setValue(user.surveyAnswer[0].score);
    this.selectedScoreNote.setValue(user.surveyAnswer[0].scoreNote);

    setTimeout(() => this.stepper.selectedIndex = 1);
  
    this.showSurvey(this.surveyDto.surveyJSON, user.surveyAnswer[0].surveyJSON);
  }

  async showSurvey(mySurvey: string, risposta: string) {
    this.surveyModel = new Model(mySurvey);

    this.surveyModel.mode = "display";
    this.surveyModel.questionsOnPageMode = "singlePage";
    this.surveyModel.showNavigationButtons = "none";
    this.surveyModel.showProgressBar = "off";
    this.surveyModel.showTimerPanel = "none";
    this.surveyModel.maxTimeToFinishPage = 0;
    this.surveyModel.maxTimeToFinish = 0;
    let correctStr = await firstValueFrom(this.translate.get("Correct"));
    let inCorrectStr = await firstValueFrom(this.translate.get("Incorrect"));
    this.surveyModel.data = JSON.parse(risposta);
      
    function getTextHtml(text, str, isCorrect) {
        if (text.indexOf(str) < 0) return undefined;
        var color = isCorrect ? 'green' : 'red';
        return text.substring(0, text.indexOf(str)) + "<span style='color: " + color + "'>" + str + "</span>";
      }

    function isAnswerCorrect(q) {
        let right=q.correctAnswer;
        if(right === undefined || q.isEmpty()) return undefined;
        var left=q.value;
        if(!Array.isArray(right)) return right == left;
        if(!Array.isArray(left)) left=[left];
        for(var i=0; i < left.length; i ++) {
            if(right.indexOf(left[i]) < 0) return false;
        }
        return true;
    }
    
    function renderCorrectAnswer(q) {
        if(!q) return;
        let isCorrect = isAnswerCorrect(q);
        if (!q.prevTitle) {
            q.prevTitle = q.title;
        }
        if (isCorrect === undefined) {
            q.title = q.prevTitle;
        }
        else
            q.title =  q.prevTitle + ' ' + (isCorrect ? correctStr : inCorrectStr);
    }
    
    this.surveyModel.onValueChanged.add((sender, options) => {
        renderCorrectAnswer(options.question);
    });
    
    this.surveyModel.onTextMarkdown.add((sender, options) => {
        var text = options.text;
        var html = getTextHtml(text, correctStr, true);
        if (!html) {
            html = getTextHtml(text, inCorrectStr, false);
        }
        if (!!html) {
            options.html = html;
        }
    });
    
    this.surveyModel.getAllQuestions().forEach(q => renderCorrectAnswer(q));
    this.surveyModel.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
    });

    this.surveyModel.data = JSON.parse(risposta);
  }

  saveScore() {
    this.surveyService.putSurveyScore(this.selectedIdAnswer, this.selectedScore.value, this.selectedScoreNote.value)
    .subscribe({
      next: data => {
        console.log(data);
        this.snackbar.open('score saved!', '', { duration: 3000 });

        this.lastStep = true;
      },
      error: err => {
        console.log(err);
        this.snackbar.open(err.error.Message, '', { duration: 3000 } );
      }
    });
  }

  resetStepper() {
    this.selectedUserId = undefined;
    this.selectedUser = undefined;
    this.selectedIdAnswer = undefined;
    this.selectedScoreA = undefined;
    this.selectedScoreDate = undefined;

    this.selectedScore.reset();
    this.selectedScoreNote.reset();

    this.surveyModel = undefined;

    this.lastStep = false;

    this.stepper.selectedIndex = 0;
  }

  doFilter(e: any) {
    this.dataSrc.filter = e.target.value.trim().toLowerCase();
  }

  goBack() {
    this.location.back();
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from 'src/app/services/survey.service';
import { Model } from "survey-core";
import { SurveyAnswerDTO } from 'src/app/models/dto/surveyAnswerDTO';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-survey-result-page',
  templateUrl: './survey-result-page.component.html',
  styleUrls: ['./survey-result-page.component.scss']
})
export class SurveyResultPageComponent implements OnInit {

  idLesson: number;
  idSurvey: number;
  surveyAnswer: SurveyAnswerDTO;
  surveyDto: SurveyDTO;
  survey: Model;

  constructor(private surveyService: SurveyService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private location: Location) { }

  ngOnInit(): void {
    this.surveyAnswer = new SurveyAnswerDTO();

    if (this.route.snapshot.params['id']) {

      this.idLesson = this.route.snapshot.params['id'];
      this.idSurvey = this.route.snapshot.queryParams['idSurvey'];

      this.surveyService.getStudentSurvey(this.idLesson)
        .subscribe(res => {
          
          this.surveyDto = res.lessonSession.survey;
          this.surveyAnswer.score = res.score;
          this.surveyAnswer.scoreNote = res.scoreNote;
          this.surveyAnswer.state = res.state;
          this.surveyAnswer.surveyJSON = res.surveyJSON;

          console.log('getStudentSurvey', res);

          this.showSurvey();
          
        });

    }
  }

  async showSurvey() {
    this.survey = new Model(this.surveyDto.surveyJSON);

    this.survey.mode = "display";
    this.survey.questionsOnPageMode = "singlePage";
    this.survey.showNavigationButtons = "none";
    this.survey.showProgressBar = "off";
    this.survey.showTimerPanel = "none";
    this.survey.maxTimeToFinishPage = 0;
    this.survey.maxTimeToFinish = 0;
    let correctStr =  await firstValueFrom(this.translate.get("Correct"));
    let inCorrectStr = await firstValueFrom(this.translate.get("Incorrect"));
    this.survey.data = JSON.parse(this.surveyAnswer.surveyJSON);
      
    function getTextHtml(text, str, isCorrect) {
        if (text.indexOf(str) < 0) return undefined;
        var color = isCorrect ? 'green' : 'red';
        return text.substring(0, text.indexOf(str)) + "<span style='color: " + color + "'>" + str + "</span>";
    }

    function isAnswerCorrect(q) {
        let right=q.correctAnswer;
        if(right === undefined || q.isEmpty()) return undefined;
        var left=q.value;
        if(!Array.isArray(right)) return right == left;
        if(!Array.isArray(left)) left=[left];
        for(var i=0; i < left.length; i ++) {
            if(right.indexOf(left[i]) < 0) return false;
        }
        return true;
    }
    
    function renderCorrectAnswer(q) {
        if(!q) return;
        let isCorrect = isAnswerCorrect(q);
        if (!q.prevTitle) {
            q.prevTitle = q.title;
        }
        if (isCorrect === undefined) {
            q.title = q.prevTitle;
        }
        else
            q.title =  q.prevTitle + ' ' + (isCorrect ? correctStr : inCorrectStr);
    }
    
    this.survey.onValueChanged.add((sender, options) => {
        renderCorrectAnswer(options.question);
    });
    
    this.survey.onTextMarkdown.add((sender, options) => {
        var text = options.text;
        var html = getTextHtml(text, correctStr, true);
        if (!html) {
            html = getTextHtml(text, inCorrectStr, false);
        }
        if (!!html) {
            options.html = html;
        }
    });
    
    this.survey.getAllQuestions().forEach(q => renderCorrectAnswer(q));
    this.survey.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
    });
  }
    
  goBack() {
    this.location.back();
  }

}


<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    
    <div *ngIf="submitProcessing" class="cssload-container" style="top: 30%">
        <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%">

        <mat-form-field fxFlex>
            <mat-label>{{ 'username' | translate }}/{{ 'Email' | translate }}</mat-label>
            <input matInput id="username" [formControl]="username">
            <mat-error *ngIf="username.hasError('required')">
                {{ 'Username is required' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>{{ 'Password' | translate }}</mat-label>
            <input matInput id="password" [formControl]="password" [type]="showPassword ? 'text' : 'password'">
            <button mat-icon-button (click)="showPassword = !showPassword" [matTooltip]="(showPassword ? 'Hide' : 'Show') | translate" matSuffix>
                <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="password.hasError('required')">
                {{ 'Password is required' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex>
            <mat-label>{{ 'Select a language' | translate }}</mat-label>
            <mat-select [formControl]="language" (selectionChange)="changeLanguage($event)">
                <mat-option *ngFor="let lang of languages" [value]="lang.code">{{ lang.description }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" fxFlex style="margin-bottom: 10px">
            <!--mat-checkbox color="accent" [formControl]="remember">
                {{ 'Remember me' | translate }}
            </mat-checkbox-->

            <div style="text-align: center; color: #5092cf; font-size: 14px;">
                <span (click)="goToForgot()" style="cursor: pointer">
                    {{ 'Forgot your password?' | translate }}
                </span>
            </div>
        </div>

        <app-error-message style="margin-top: 10px; margin-bottom: 10px;"
            *ngIf="fieldsIncorrect"
            (eventShowable)="fieldsIncorrect = false"
            [errorTitle]="errorTitle"
            [errorContent]="errorContent"
            [customClass]="customClass"
            [center]="false">
        </app-error-message>

        <button mat-raised-button
            (click)="login()"
            [disabled]="!username.valid ||
                        !password.valid ||
                        submitProcessing"
            color="accent">
            <mat-icon>login</mat-icon>
            {{ 'Log In' | translate}}
        </button>

    </div>

    <!-- Riabilitare quando saranno disponibili le key per Google e Facebook -->
    <!--div *ngIf="isStandardMode() && !isMobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxLayoutAlign="center center">
            <span>{{ 'Or login with' | translate }}</span>
        </div>
    
        <div fxLayoutAlign="center center" fxLayoutGap="10px">
            <button mat-raised-button class="fbButton"
                [disabled]="getSocialBtnDisabled('FACEBOOK')"
                (click)="loginSocial('FACEBOOK')"
                matTooltip="Facebook">
                <fa-icon [icon]="['fab', 'facebook']" size="lg"></fa-icon>
            </button>
    
            <button mat-raised-button class="gButton"
                [disabled]="getSocialBtnDisabled('GOOGLE')"
                (click)="loginSocial('GOOGLE')"
                matTooltip="Google">
                <fa-icon [icon]="['fab', 'google']" size="lg"></fa-icon>
            </button>
        </div>
    </div-->

</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SkillDTO } from 'src/app/models/dto/skillDTO';

@Component({
  selector: 'app-grade-exercise-popup',
  templateUrl: './grade-exercise-popup.component.html',
  styleUrls: ['./grade-exercise-popup.component.scss']
})
export class GradeExercisePopupComponent {
  assignedSkills: SkillDTO[];
  selectedScores: { [skillId: number]: number | null };
  selectedNotes: { [skillId: number]: string }; 
  overallSkillId: number | null;

  constructor(
    public dialogRef: MatDialogRef<GradeExercisePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      assignedSkills: SkillDTO[];
      selectedScores: { [skillId: number]: number | null };
      selectedNotes?: { [skillId: number]: string }; 
      overallSkillId: number | null;
    }
  ) {

    this.assignedSkills = data.assignedSkills;
    this.selectedScores = data.selectedScores;
    this.overallSkillId = data.overallSkillId;
    
    this.selectedNotes = data.selectedNotes || {};
    
    this.assignedSkills.forEach(skill => {
      if (!this.selectedNotes[skill.id]) {
        this.selectedNotes[skill.id] = '';
      }
    });
  }


canSubmitGrade(): boolean {
  // First, check if we have the overall skill
  const overallSkill = this.assignedSkills.find(skill => skill.overall === 1);
  const overallSkillId = overallSkill?.id ?? this.overallSkillId;
  
  if (overallSkillId == null) {
    console.warn('No overall skill ID found');
    return false;
  }
  
  // Check that overall score has been entered
  const overallScore = this.selectedScores[overallSkillId];
  if (overallScore == null || isNaN(overallScore)) {
    return false;
  }
  

  for (const skill of this.assignedSkills) {
    const score = this.selectedScores[skill.id];
    if (score == null || isNaN(score)) {
      return false; 
    }
  }
  
  return true;
}
  onScoreChange(): void {
    console.log('Scores changed:', this.selectedScores);
  }


  onNoteChange(): void {
    console.log('Notes changed:', this.selectedNotes);
  }

  onSubmitGrade(): void {
    this.dialogRef.close({
      action: 'GRADE_SUBMITTED',
      selectedScores: this.selectedScores,
      selectedNotes: this.selectedNotes
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
import { SocialWallDTO } from "./SocialWallDTO"

export class SocialWallContentDTO {
    id: number;
    idsocialWall: number;
    description: string;
    idstringDescription: number;
    type: number;
    contentLink: string;
    state: number;
    hashTags: string;
    created: Date;

    idsocialWallNavigation: SocialWallDTO;
// public virtual Translation IdstringDescriptionNavigation { get; set; }
}

<h1 mat-dialog-title>{{ 'Edit subtitles' | translate }}</h1>
<div mat-dialog-content>
    <mat-form-field color="primary" style="width: 100%">
        <mat-label>{{ 'Search' | translate }}</mat-label>
        <input matInput #searchInput (keyup)="applyFilter(searchInput)">
        <button matSuffix mat-icon-button [matTooltip]="'Search' | translate">
            <mat-icon>search</mat-icon>
        </button>
        <button matSuffix mat-icon-button [disabled]="!searchInput.value" [matTooltip]="'Clear' | translate"
            (click)="searchInput.value = null; applyFilter(searchInput)">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-form-field>
    <mat-table [dataSource]="subtitles" matSort>
        <ng-container matColumnDef="start">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Start' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.subtitle.startTime | slice:0:12 }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="end">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'End' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.subtitle.endTime | slice:0:12 }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lineValues">
            <mat-header-cell *matHeaderCellDef>{{ 'Subtitles' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div *ngIf="currentSubtitle?.index === element.index">
                    <mat-form-field style="width: 100%">
                        <input matInput [formControl]="line1">
                        <mat-error *ngIf="line1.hasError('required') || line1.hasError('minlength') || line1.hasError('maxlength')">
                            {{ 'The text should be between' | translate }} 1 {{ 'and' | translate }} 100 {{ 'characters' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 100%">
                        <input matInput [formControl]="line2">
                        <mat-error *ngIf="line2.hasError('minlength') || line2.hasError('maxlength')">
                            {{ 'The text should be between' | translate }} 1 {{ 'and' | translate }} 100 {{ 'characters' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="currentSubtitle?.index !== element.index">
                    <div *ngFor="let line of element.subtitle.lineValues">{{ line }}</div>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef><!--{{ 'Edit' | translate}}--></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="currentSubtitle?.index !== element.index" [matTooltip]="'Edit' | translate" (click)="edit(element)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="accent" *ngIf="currentSubtitle?.index === element.index" [matTooltip]="'Edit' | translate" (click)="save()" [disabled]="sending || saveDisabled()" style="margin-right: 10px;">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button color="warn" *ngIf="currentSubtitle?.index === element.index" [matTooltip]="'Cancel' | translate" (click)="cancel()" [disabled]="sending">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
    </mat-table>
    <mat-paginator #paginator
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50]"
        color="primary"
        showFirstLastButtons>
    </mat-paginator>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="closeAndSave()" [disabled]="currentSubtitle || sending" color="accent">{{ 'Ok' | translate }}</button>
</div>

<h1 mat-dialog-title>{{ 'New Exercise' | translate }}</h1>

<div mat-dialog-content>
  <mat-horizontal-stepper #stepper="matHorizontalStepper" linear="false" [formGroup]="exerciseForm">

    <!-- Step 1: Basic Exercise Details -->
    <mat-step formGroupName="details">
      <ng-template matStepLabel>{{ 'Exercise Details' | translate }}</ng-template>
      <div class="stepper-gap">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Exercise Title' | translate }}</mat-label>
          <input matInput formControlName="title" required>
          <mat-error *ngIf="exerciseForm.get('details').get('title').hasError('required')">
            {{ 'Title is required' | translate }}
          </mat-error>
        </mat-form-field>

        <p class="descriptive-text">{{ 'Insert start and end dates of the exercise:' | translate }}</p>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'Start Date' | translate }}</mat-label>
            <input matInput type="datetime-local" formControlName="startDate">
            <mat-error *ngIf="exerciseForm.get('details').get('startDate').hasError('required')">
              {{ 'Start Date is required' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{ 'End Date' | translate }}</mat-label>
            <input matInput type="datetime-local" formControlName="endDate">
            <mat-error *ngIf="exerciseForm.get('details').get('endDate').hasError('required')">
              {{ 'End Date is required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ 'Exercise Description' | translate }}</mat-label>
          <textarea matInput formControlName="description" rows="3"></textarea>
          <mat-error *ngIf="exerciseForm.get('details').get('description').hasError('required')">
            {{ 'Description is required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-step>

    <!-- Step 2: Classroom Assignment -->
    <mat-step formGroupName="classroomAssignment">
      <ng-template matStepLabel>{{ 'Classroom Assignment' | translate }}</ng-template>
      <div class="stepper-gap">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Assign to Classroom(s)' | translate }}</mat-label>
          <mat-select formControlName="classroom" multiple>
            <mat-option *ngFor="let classroom of classrooms" [value]="classroom.id">
              {{ classroom.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="exerciseForm.get('classroomAssignment').get('classroom').hasError('required')">
            {{ 'At least one classroom must be selected' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-step>

    <!-- Step 3: Skill Assessment (not really skills but other kind of data)-->
    <mat-step formGroupName="skills">
      <ng-template matStepLabel>{{ 'Skill Assessment' | translate }}</ng-template>
      <div class="stepper-gap">
        <h3>{{ 'Assessment Data Collection' | translate }}</h3>
        <mat-checkbox formControlName="selectAllSkills" (change)="toggleAllSkills($event.checked)"><b><u>{{ 'Select All' | translate }}</u></b></mat-checkbox>
        <mat-checkbox formControlName="videoRecording">{{ 'Video Recording (RGB)' | translate }}</mat-checkbox>
        <mat-checkbox formControlName="poseRecording">{{ 'Pose Information' | translate }}</mat-checkbox>
        <mat-checkbox formControlName="micromotorData">{{ 'Micromotor Speed' | translate }}</mat-checkbox>
        <mat-checkbox formControlName="scannerIntegration">{{ 'Scanner Integration' | translate }}</mat-checkbox>
      </div>
    </mat-step>

    <!-- Step 4: Skill Selection -->
    <mat-step formGroupName="skillSelection">
      <ng-template matStepLabel>{{ 'Available Skills' | translate }}</ng-template>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'Select Skill' | translate }}</mat-label>
        <mat-select formControlName="selectedSkill" multiple>
          <mat-option *ngFor="let skill of skills" [value]="skill.id">
            {{ skill.name }}
          </mat-option>
        </mat-select>
        <mat-hint>{{ 'You can select multiple skills to assign to the exercise' | translate }}</mat-hint>
      </mat-form-field>
    </mat-step>

    <!-- Step 5: Max Duration -->
    <mat-step formGroupName="maxDuration">
      <ng-template matStepLabel>{{ 'Maximum Duration' | translate }}</ng-template>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'Maximum Duration (minutes)' | translate }}</mat-label>
        <input matInput type="number" formControlName="duration" min="1">
        <mat-error *ngIf="exerciseForm.get('maxDuration').get('duration').hasError('min')">
          {{ 'Duration must be at least 1 minute' | translate }}
        </mat-error>
        <mat-hint>{{ 'Enter max duration in minutes' | translate }}</mat-hint>
      </mat-form-field>
    </mat-step>

  </mat-horizontal-stepper>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-stroked-button (click)="onCancel()">{{ 'Cancel' | translate }}</button>
  <div>
    <button mat-stroked-button *ngIf="stepper.selectedIndex !== 0" (click)="stepper.previous()">{{ 'Back' | translate }}</button>
    <button mat-flat-button color="primary" *ngIf="stepper.selectedIndex !== stepper.steps.length - 1" (click)="stepper.next()" [disabled]="!isCurrentStepValid()">{{ 'Next' | translate }}</button>
    <button mat-flat-button color="accent" *ngIf="stepper.selectedIndex === stepper.steps.length - 1" (click)="onSave()" [disabled]="!exerciseForm.valid">{{ mode === 'edit' ? 'Modify Exercise' : 'Add Exercise' | translate }}</button>
  </div>
</div>

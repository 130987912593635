<h1 mat-dialog-title>{{ 'To continue, please change the initial password and add some information' | translate }}</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="20px">

    <!--div fxLayout="row" fxLayoutGap="20px" class="field">
        <mat-form-field color="primary">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput [formControl]="name">
            <mat-error>
                {{ 'The name is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    
        <mat-form-field color="primary">
            <mat-label>{{ 'surname' | translate }}</mat-label>
            <input matInput [formControl]="surname">
            <mat-error>
                {{ 'The surname is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
        
    </div-->

    <div fxLayout="row" fxLayoutGap="20px" class="field">
        <mat-form-field color="primary">
            <mat-label>{{ 'Password' | translate }}</mat-label>
            <input type="password" matInput [formControl]="password">
            <mat-error *ngIf="password.hasError('required')">
                {{ 'The password is' | translate }} <strong>{{ 'required' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="password.hasError('pattern')">
                {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
            </mat-error>
        </mat-form-field>
    
        <mat-form-field color="primary">
            <mat-label>{{ 'Repeat password' | translate }}</mat-label>
            <input type="password" matInput [formControl]="passwordRepeat">
            <mat-error *ngIf="passwordRepeat.hasError('required')">
                {{ 'Please confirm the password' | translate }}
            </mat-error>
        </mat-form-field>
        
    </div>

    <mat-error *ngIf="!checkPassword() &&
                      !password.hasError('required') &&
                      !password.hasError('pattern') &&
                      !passwordRepeat.hasError('required')">
        {{ 'New password and password confirmation are not the same' | translate }}
    </mat-error>

    <!--mat-form-field color="primary" style="width:49%" style="padding-top:25px">
        <mat-label>Timezone</mat-label>
        <mat-select [formControl]="timezone" required>
            <mat-option *ngFor="let zone of timezones" [value]="zone">GMT {{ (zone > 0 ? '+' : '') + zone }}</mat-option>
        </mat-select>
        <mat-error>
            {{ 'The timezone is' | translate }} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field-->

    <div class="field">
        <mat-label>{{ 'Profile picture' | translate }}</mat-label>
        <app-file-input
            [control]="profilePicture"
            [maxSize]="1000"
            [acceptedTypes]="'image/*'"
            [rejectedErrorMessage]="('The file type must be a picture and not exceed' | translate) + ' 1MB'"
            [deletedErrorMessage]="'Please select a file' | translate"
            style="width: 100%">
        </app-file-input>
    </div>

    <span>
        {{ 'The information entered can subsequently be changed at any time' | translate }}
    </span>

</div>

<div mat-dialog-actions class="field">
    <button mat-flat-button (click)="save()" [disabled]="btnDisabled()" color="accent">Ok</button>
</div>

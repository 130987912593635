import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-carousel-prev-button',
  templateUrl: './carousel-prev-button.component.html',
  styleUrls: ['./carousel-prev-button.component.scss']
})
export class CarouselPrevButtonComponent {

  @Input()
  index: number = 0;

  @Input()
  marginLeft: string = '24px';

  @Output()
  indexChange: EventEmitter<number> = new EventEmitter<number>();

  prev() {

    if (this.index <= 0)
      return;

    this.index--;
    this.indexChange.emit(this.index);
  } 

}

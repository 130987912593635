import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SkillDTO } from '../models/dto/skillDTO';
import { Helper } from '../helpers/helper';

@Injectable({
  providedIn: 'root',
})
export class SkillService {
  private readonly bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
  private readonly bckEndUrlSkill = `${this.bckEndUrlBaseUrl}/skill`;

  constructor(private http: HttpClient) {}

  public getSkills(): Observable<SkillDTO[]> {
    return this.http.get<SkillDTO[]>(`${this.bckEndUrlSkill}/list`, {
      headers: Helper.getAuthHeader(),
    });
  }

  ensureOverallSkill(): Observable<SkillDTO> {
    return this.http.get<SkillDTO>(`${this.bckEndUrlSkill}/ensure-overall`, { 
      headers: Helper.getAuthHeader() });
  }  

  public getSkillById(id: number): Observable<SkillDTO> {
    return this.http.get<SkillDTO>(`${this.bckEndUrlSkill}/${id}`, {
      headers: Helper.getAuthHeader(),
    });
  }

  public createSkill(skill: SkillDTO): Observable<SkillDTO> {
    return this.http.post<SkillDTO>(`${this.bckEndUrlSkill}/create`, skill, {
      headers: Helper.getAuthHeader(),
    });
  }

  public updateSkill(skill: SkillDTO): Observable<SkillDTO> {
    return this.http.put<SkillDTO>(`${this.bckEndUrlSkill}/edit/${skill.id}`, skill, {
      headers: Helper.getAuthHeader(),
    });
  }

  public deleteSkill(id: number): Observable<void> {
    return this.http.delete<void>(`${this.bckEndUrlSkill}/delete/${id}`, {
      headers: Helper.getAuthHeader(),
    });
  }
}

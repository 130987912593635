<div class="video-container" 
    cdkDrag
    [cdkDragBoundary]="dragBoundary"
    [class.outline]="displayOutline" 
    [cdkDragDisabled]="!dragEnabled"
    [style.z-index]="z_index"
    [style.cursor]="cursor"
    [style.height]="selectionEnabled ? 'calc(100% - 40px)' : '100%'">

    <div class="userName" fxLayoutAlign="start center" *ngIf="name && displayName">
        <span [style.fontSize]="!compactMode ? '13px' : '10px'">{{ name }}</span>
    </div>

    <div class="audioDetect" [style.padding]="!compactMode ? '5px' : ''" *ngIf="displayAudio">
        <button mat-mini-fab color="warn" *ngIf="!hasAudioActive() && !compactMode; else compactMicOff">
            <mat-icon>mic_off</mat-icon>
        </button>
    </div>

    <div class="handRaised" [style.padding]="!compactMode ? '5px' : ''">
        <button mat-mini-fab
            color="accent"
            *ngIf="handRaised && !compactMode; else compactHandRaised"
            (click)="lowerHand()"
            [matTooltip]="'Lower hand' | translate"
            [matTooltipDisabled]="!lowerHandEnabled">
            <mat-icon>back_hand</mat-icon>
        </button>
    </div>

    <div class="optionsBtn" [style.margin-bottom]="btnOptionsMarginBottom" [style.padding]="!compactMode ? '5px' : ''">
        <ng-content select="[optionBtn]"></ng-content>
    </div>

    <div class="initalsText"
        fxLayoutAlign="center center"
        [style.height]="!compactMode ? '50px' : '25px'"
        [style.width]="!compactMode ? '50px' : '25px'"
        [style.background-color]="color"
        *ngIf="!hasVideoActive()">
        <span [style.fontSize]="!compactMode ? '18px' : '11px'">{{ initials }}</span>
    </div>
    
    <video #videoElement [style.object-fit]="visualType" [hidden]="!streamManager"></video>
    
</div>

<div *ngIf="selectionEnabled">
    <mat-checkbox [(ngModel)]="selectionChecked" [disabled]="selectionDisabled" (change)="selectionCheckedChange.emit(selectionChecked)">
        {{ 'Select' | translate }}
    </mat-checkbox>
</div>

<ng-template #compactMicOff>
    <button mat-icon-button color="warn" *ngIf="!hasAudioActive() && compactMode; else audioSpectrum">
        <mat-icon>mic_off</mat-icon>
    </button>
</ng-template>

<ng-template #compactHandRaised>
    <button mat-icon-button
        color="accent"
        *ngIf="handRaised && compactMode"
        (click)="lowerHand()"
        [matTooltip]="'Lower hand' | translate"
        [matTooltipDisabled]="!lowerHandEnabled">
        <mat-icon>back_hand</mat-icon>
    </button>
</ng-template>

<ng-template #audioSpectrum>
    <div [class.spectrum-container]="!compactMode"
         [class.spectrum-container-compact]="compactMode"
         [class.mat-elevation-z4]="!compactMode">
        <div class="stick normal" [ngClass]="isSpeaking ? 'play' : 'pause'"></div>
        <div class="stick loud" [ngClass]="isSpeaking ? 'play' : 'pause'"></div>
        <div class="stick normal" [ngClass]="isSpeaking ? 'play' : 'pause'"></div>
    </div>
</ng-template>

<ng-template #noStream>
    <div style="background-color: black" id="no-stream"></div>
</ng-template>

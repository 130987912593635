import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { User } from '../../models/user';
import { GenericPopupComponent, GenericPopupData } from '../generic-popup/generic-popup.component';
import { AuthService } from '../../services/auth.service';
import { CourseService } from '../../services/course.service';
import { MasterService } from '../../services/master.service';
import { PackageService } from '../../services/package.service';
import { UserService } from '../../services/user.service';
import { ConferenceService } from 'src/app/services/conference.service';
import { UserRole } from 'src/app/models/userRole';

@Component({
  selector: 'app-add-user-list-popup',
  templateUrl: './add-user-list-popup.component.html',
  styleUrls: ['./add-user-list-popup.component.scss']
})
export class AddUserListPopupComponent implements OnInit {

  currentUser: User;

  type: 'course' | 'webinar' | 'master' | 'package' = undefined;
  subscriptionId: number;
  alreadyPaidUsers: number[] = [];

  datasource: MatTableDataSource<User>;
  displayedColumns: string[] = ['thumbnail', 'username', 'surname', 'name', 'role', 'actions'];
  paginatorDefault: number = 10;

  @ViewChild('usersSort') usersSort: MatSort;
  @ViewChild('usersPaginator') usersPaginator: MatPaginator;

  constructor(private dialogRef: MatDialogRef<AddUserListPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private auth: AuthService,
              private userService: UserService,
              private courseService: CourseService,
              private masterService: MasterService,
              private packageService: PackageService,
              private conferenceService: ConferenceService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('850px');

    this.currentUser = this.auth.getCurrentUser();

    this.type = this.data.type;
    this.subscriptionId = this.data.subscriptionId;
    this.alreadyPaidUsers = this.data.alreadyPaidUsers;

    this.getUsers();
  }

  getUsers(): void {
    let roles: number[] = [UserRole.Student];

    if (this.isCourse() || this.isPackage() || this.isWebinar())
      roles = [... roles, UserRole.Tutor, UserRole.Teacher, UserRole.Speaker];

    this.userService.getAllUsers(roles, undefined, undefined, true)
      .subscribe(res => {

          let users = res as User[];

          if (!this.datasource) {
            this.datasource = new MatTableDataSource();

            this.datasource.sort = this.usersSort;
            this.datasource.paginator = this.usersPaginator;

            this.datasource.sortingDataAccessor = (item, property) => {
              switch (property) {
                case 'role':
                  return this.getRole(item);
                case 'actions':
                  return this.hasAlreadyPaid(item.id);
                default:
                  return item[property];
              };
            }
          }

          users.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());

          this.datasource.data = users;

      });
  }

  async addUserAsPaid(user: any) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Set as paid')),
        body: await firstValueFrom(this.translate.get('Are you sure to add {{surname}} {{name}} as a paid user?', { surname: user.surname, name: user.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;

        let action: Observable<ArrayBuffer>;

        if (this.isCourse())
          action = this.courseService.buyCourse(this.subscriptionId, user.id);
        else if (this.isMaster())
          action = this.masterService.buyMaster(this.subscriptionId, user.id);
        else if (this.isPackage())
          action = this.packageService.buyPackage(this.subscriptionId, user.id);
        else if (this.isWebinar())
          action = this.conferenceService.buyConference(this.subscriptionId, user.id);
    
        action.subscribe({
          next: async () => {
            this.alreadyPaidUsers.push(user.id);
            this.snackBar.open (await firstValueFrom(this.translate.get(`User added to ${this.type}`)), '', { duration: 3000 });
          },
          error: err => this.snackBar.open(err.error.Message, '', { duration: 3000 })
        });
    });
  }

  hasAlreadyPaid(userId: number) {
    return this.alreadyPaidUsers.findIndex(u => u === userId) !== -1;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  applyFilter(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.datasource.filter = value.trim().toLowerCase();
  }

  getRole(user: User) {
    return UserRole.role(user);
  }

  isCourse() {
    return this.type === 'course';
  }

  isMaster() {
    return this.type === 'master';
  }

  isPackage() {
    return this.type === 'package';
  }

  isWebinar() {
    return this.type === 'webinar';
  }

}

import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppLessonPopUpComponent } from 'src/app/pages/lessons/app-lesson-pop-up/app-lesson-pop-up.component';
import { Helper } from 'src/app/helpers/helper';
import { ClassroomContentDTO } from 'src/app/models/dto/classroomContentDTO';
import { LessonSessionDTO, SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { TeacherClassroomDTO } from 'src/app/models/dto/teacherClassroomDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { MasterState } from 'src/app/models/masterState';
import { User } from 'src/app/models/user';
import { MasterAddTeacherComponent } from './master-add-teacher/master-add-teacher.component';
import { CalendarService } from 'src/app/services/calendar.service';
import { MasterService } from 'src/app/services/master.service';
import { MasterAddComponent } from './master-add/master-add.component';
import { MasterPopupComponent } from './master-popup/master-popup.component';
import { AttendanceDTO } from 'src/app/models/dto/attendanceDTO';
import { firstBy } from 'thenby';
import { ChartDataset, ChartOptions } from 'chart.js';
import { AddSurveyPopUpComponent } from 'src/app/survey/add-survey-pop-up/add-survey-pop-up.component';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { MasterAddPreviewComponent } from './master-add-preview/master-add-preview.component';
import { ResDTO } from 'src/app/models/dto/resDTO';
import { CartService } from 'src/app/ecommerce/service/cart.service';
import { ContentVisualizerComponent } from 'src/app/popup/content-visualizer/content-visualizer.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { AddSubscriptionComponent, CrudSubscription, SubscriptionType } from 'src/app/popup/add-subscription/add-subscription.component';
import { SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { SharePopupComponent } from 'src/app/popup/share-popup/share-popup.component';
import { LessonService } from 'src/app/services/lessons.service';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AppConferencePopUpComponent } from '../conferences/app-conference-pop-up/app-conference-pop-up.component';
import { ConferenceService } from 'src/app/services/conference.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { differenceInDays, differenceInSeconds } from 'date-fns';
import { MatSort } from '@angular/material/sort';
import { MasterContentDTO } from 'src/app/models/dto/masterContentDTO';
import { MasterContentViewComponent } from 'src/app/popup/master-content-view/master-content-view.component';

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px
const CARD_WIDTH: number = 250; //px

const GRID_CONTAINER_SIZE: number = 75; //%

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, AfterViewInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor(((window.innerWidth - (PAGE_PADDING * 2)) * (GRID_CONTAINER_SIZE / 100)) / this.cardSize);
  }

  cardSize: number = 0;
  rowSize: number = 0;

  contentsMode: 'cards' | 'table' = 'cards';

  private _master: MasterDTO;

  @Input() 
  get master (): MasterDTO { return this._master; }
  set master (value: MasterDTO) {
    if (!value)
      return;

    this._master = value;
    this.remainingDays = this.getRemainingDays();

    this.getDerivedMasters();
  }

  @Input() set participants (classroom: ClassroomDTO) {
    let author = classroom.teacherClassroom.splice(classroom.teacherClassroom.findIndex(t => t.userId === this.master.idAuthor), 1);
    classroom.teacherClassroom.unshift(author[0]);

    this.teachers.data = classroom.teacherClassroom;
    this.teachers.paginator = this.teachersPag;

    this.students.data = classroom.classroomContent;
    this.students.paginator = this.studentsPag;

    this.currentUserIsTeacher = classroom.teacherClassroom.findIndex(t => t.userId === this.currentUser.id) !== -1;
    this.currentUserIsParticipant = classroom.classroomContent.findIndex(s => s.userId === this.currentUser.id) !== -1;

    if(this.currentUserIsTeacher) {
      this.matchSubscriptionToUser();

      this.getTeachers();
      this.getRooms();
    }
  }

  @Input() set media (value: MasterContentDTO[]) {
    if (!value)
      return;

    this.mediaContents = value;
  }

  @Input() lessonsPlannedDuration: number[] = [0, 0];

  @Input() currentUserHavePaid: boolean = false;

  @Input() availablePlaces: number = 0;

  @Output()
  refreshMaster: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  refreshParticipants: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentUser: User;
  rooms: RoomDTO[] = [];
  customerTeachers: UserDTO[] = [];
  teachersLesson: UserDTO[] = [];
  mediaContents: MasterContentDTO[] = [];
  
  today: Date = new Date();
  currentUserIsParticipant: boolean = false;
  currentUserIsTeacher: boolean = false;
  updateCalendar: boolean = false;
  remainingDays: number = 0;

  href: string = "";

  //KPI
  lessonAverageDuration: string = '0';
  lessonAveragePlannedDuration: string = '0';
  lessonAverageAttendanceTime: number[] = [0, 0];
  lessonAverageAttendance: number = 0.00;
  lessonsDuration: number[] = [0, 0];
  lessonsAttendance: AttendanceDTO[] = [];
  doneLessons: number = 0;
  lessonsDurationSeconds: number = 0;
  spinnerValues: number[] = [0, 0]; //0 => numero di lezioni eseguite, 1 => numero di tempo eseguito. Tutto in percentuale
  currentSpinnerValue: number = 0; //Indice per spinnerValues

  lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        fill: 'origin',
        tension: 0.4
      },
      point: {
        radius: 0,
        hitRadius: 0
      }
    },
    scales: {
      x: {
        display: true,
        ticks: {
          stepSize: 50  
        },
        max: 100,
        min: 0
      },
      y: {
        display: true,
        ticks: {
          stepSize: 50
        },
        max: 100,
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };
  public lineChartType = 'line';
  public lineChartData: ChartDataset[] = [];
  public lineChartLabels: string[] = [];

  lessons: MatTableDataSource<LessonSessionDTO> = new MatTableDataSource();
  teachers: MatTableDataSource<TeacherClassroomDTO> = new MatTableDataSource();
  students: MatTableDataSource<ClassroomContentDTO> = new MatTableDataSource();
  derived: MatTableDataSource<MasterDTO> = new MatTableDataSource();

  lessonsCol: string[] = ['name', 'startPlanned', 'teacher', 'room'];
  teachersCol: string[] = ['participant', 'created', 'customer'];
  studentsCol: string[] = ['participant', 'created', 'customer'];
  derivedMastersCol: string[] = ['user', 'actions'];

  @ViewChild('lessonsPag') set lessonsPag(value: MatPaginator) {
    this.lessons.paginator = value;
  }

  @ViewChild('lessonsSort') set lessonsSort(value: MatSort) {
    this.lessons.sort = value;

    this.lessons.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'teacher':
          return `${item.teacher.name} ${item.teacher.surname}`;
        case 'room':
          return item.room.name ? item.room.name : 'Virtual room';
        default:
          return item[property];
      }
    };
  }

  @ViewChild('teachersPag') set teachersPag(value: MatPaginator) {
    this.teachers.paginator = value;
  }

  @ViewChild('teachersSort') set teachersSort(value: MatSort) {
    this.teachers.sort = value;

    this.teachers.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'participant':
          return `${item.user.name} ${item.user.surname}`;
        case 'customer':
          return item.user.customer.name;
        default:
          return item[property];
      }
    };
  }

  @ViewChild('studentsPag') set studentsPag(value: MatPaginator) {
    this.students.paginator = value;
  }

  @ViewChild('studentsSort') set studentsSort(value: MatSort) {
    this.students.sort = value;

    this.students.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'participant':
          return `${item.user.name} ${item.user.surname}`;
        case 'customer':
          return item.user.customer.name;
        case 'status':
          return item.user.havePaid;
        default:
          return item[property];
      }
    };
  }

  @ViewChild('derivedMastersPag') set derivedMastersPag(value: MatPaginator) {
    this.derived.paginator = value;
  }

  @ViewChild('derivedMastersSort') set derivedMastersSort(value: MatSort) {
    this.derived.sort = value;

    this.derived.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'user':
          return `${item.classroom.classroomContent[0].user.name} ${item.classroom.classroomContent[0].user.surname}`;
        default:
          return item[property];
      }
    };
  }

  constructor(
    private auth: AuthService,
    private router: Router,
    private location: Location,
    private masterService: MasterService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private calendar: CalendarService,
    private lesson: LessonService,
    private conferenceService: ConferenceService,
    private cart: CartService,
    private selectionService: SelectionService,
    public darkService: DarkThemeService,
    private translation: TranslationService,
    private translate: TranslateService
  ) {
    this.currentUser = this.auth.getCurrentUser();
  }

  ngOnInit(): void {
    this.href = Helper.getUrl();

    this.cardSize = CARDS_GAP + CARD_WIDTH;
    this.onResize(undefined);

    if(this.master.idAuthor === this.currentUser.id || this.currentUserIsTeacher) {
      this.lessonsCol.push('actions');
      this.studentsCol.push('status');
    }
    
    this.lessonsCol.unshift('typeId');

    //this.refreshParticipants.emit(true);
    this.getAttendanceClassroom();

    //this.getMaster();
    //this.getLessons(true); spostata in getAttendanceClassroom()
  }

  ngAfterViewInit () {
    this.lessons.paginator = this.lessonsPag;
    this.teachers.paginator = this.teachersPag;
    this.students.paginator = this.studentsPag;
  }

  getMaster(checkParticipants: boolean = false) {

    this.masterService.getMaster(this.master.id, this.auth.isAuthenticated())
      .subscribe(output => {
        this.master = output;

        //this.refreshParticipants.emit(true);
        //this.getAttendanceClassroom();

        if (checkParticipants)
          this.matchSubscriptionToUser();

        if(!this.lessonsCol.includes('actions') && (this.master.idAuthor === this.currentUser.id || this.currentUserIsTeacher))
          this.lessonsCol.push('actions');
      });
  }

  getLessons (init?: boolean) {
    this.masterService.getLessonsOfMaster(this.master.id)
      .subscribe(output => {

        this.lessons.data = output;
        this.lessons.paginator = this.lessonsPag;

        this.doneLessons = this.lessons.data.filter((l: LessonSessionDTO) => l.stopDate).length;

        let duration = output
          .map(l => differenceInSeconds(l.endPlanned, l.startPlanned))
          .reduce((partialSum, a) => partialSum += a, 0);

        this.spinnerValues[0] = Math.round((this.doneLessons / this.lessons.data.length) * 10000) / 100;
        this.spinnerValues[1] = Math.round((this.lessonsDurationSeconds / duration) * 10000) / 100;

        this.lessonsPlannedDuration = Helper.convertToTime(duration);

        if (!init)
          this.updateCalendar = !this.updateCalendar;

      });
  }

  private getRooms(): void {
    this.calendar.getRoomsForAll()
      .subscribe(output => {
        this.rooms = output;
      });
  }

  getTeachers () {
    this.calendar.getTeachersForAll(this.currentUser.idCustomer)
      .subscribe(output => {
        
        this.customerTeachers = output;

        let currentTeachers = this.teachers.data.map(t => t.userId);
        this.teachersLesson = output.filter(t => !currentTeachers.includes(t.id));

      });
  }

  changeState () {
    let newState = this.isTemplate() ? this.master.state + 2 : this.master.state + 1;

    const dialogRef = this.dialog.open(MasterPopupComponent, {
      data: { name: this.master.name, newState: newState }, width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;

      this.masterService.setMasterState(newState, this.master.id)
        .subscribe({
          next: () => {
            this.showMessage("Master state changed");
            this.getMaster();
          },
          error: err => this.showMessage(err.error.Message)
        });
    });
  }

  updateMaster () {
    const dialogRef = this.dialog.open(MasterAddComponent, {
      data: { master: this.master, isTemplate: this.master.type === MasterType.template }, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getMaster();
      }
    });
  }

  addContent () {
    let dialogRef = this.dialog.open(MasterAddPreviewComponent, {
      data: {
        masterId: this.master.id
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.refreshMaster.emit(true);
    });
  }

  editContent (content: MasterContentDTO) {
    let dialogRef = this.dialog.open(MasterAddPreviewComponent, {
      data: {
        masterId: this.master.id,
        masterContent: content
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;
      
      this.refreshMaster.emit(true);
    });
  }
  
  deleteContent (contentId: number) {
    let dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: this.translate.instant('Delete content of') + ' ' + this.master.name,
        body: this.translate.instant('Are you sure to delete this content of') + ' ' + this.master.name + '?'
      }
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res)
          return;

        this.masterService.deleteMasterContent(this.master.id, contentId)
          .subscribe({
            next: () => {
              this.refreshMaster.emit(true);
              this.snackbar.open('Content deleted successfully', '', { duration: 3000 });
            },
            error: err => {
              console.error(err);
              this.snackbar.open(err.error.Message, '', { duration: 5000 });
            }
          });
      });
  }

  viewContent (content: MasterContentDTO) {
    this.masterService.getMasterContent(content.idMaster, content.id)
      .subscribe({
        next: res => this.dialog.open(MasterContentViewComponent, {
          data: {
            masterContent: res
          },
          disableClose: true
        }),
        error: err => this.snackbar.open('cannot get content', '', { duration: 5000 })
      });
  }

  unjoinMaster () {
    this.masterService.unjoinMaster(this.master.id)
      .subscribe({
        next: () => {
          this.showMessage('Master unjoined');
        },
        error: err => {
          this.showMessage(err.message);
        }
      });
  }

  addToCart () {
    this.cart.addMaster(this.master);
    this.router.navigateByUrl('/cart');
  }

  addSubscription () {
    let data: CrudSubscription = new CrudSubscription();
    data.entityId = this.master.id;
    data.entityType = SubscriptionType.master;
    data.subscription = this.master.subscription;
    
    const dialogRef = this.dialog.open(AddSubscriptionComponent, {
      data: data, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getMaster();
      }
    });
  }

  addTeachers () {
    const dialogRef = this.dialog.open(MasterAddTeacherComponent, {
      data: { master: this.master, teachers: this.teachersLesson }, width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshParticipants.emit(true);
      }
    });
  }

  lessonContents(lesson: LessonSessionDTO) {
    if (lesson.state === 2 &&
        !this.todayGreaterThanPlanned(lesson.endPlanned) &&
        (lesson.teacherId === this.currentUser.id || this.currentUser.id === this.master.idAuthor)
       ) {

      this.selectionService.startSelection(lesson.id, SelectionMode.masterContents, this.master.id);
    } else {
      const dialogref = this.dialog.open(ContentVisualizerComponent, {
        data: { lesson: lesson }, width: '500px'
      });
    }
  }

  goToAuthorProfile (id: number) {
    this.router.navigate(['/authorprofile/', id]);
  }

  addLesson(): void {
    let start = new Date();
    let end = new Date(start);

    start.setHours(start.getHours() + 2);
    end.setHours(end.getHours() + 4);

    let eventData: EventDialogData = {
      startData: start,
      endData: end,
      classId: null,
      rooms: this.rooms,
      teacherId: this.master.idAuthor,
      teachers: this.customerTeachers,
      type: 3
    };
    
    const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
      width: '500px',
      data: { eventData, page: "masterNoCalendar", dateOrTime: true }
    });

    dialogRef.afterClosed().subscribe((result: EventDialogData) => {

      if (!result)
        return;

      let lesson: LessonSessionDTO = new LessonSessionDTO();

      lesson.teacherId = result.teacherId;
      lesson.startPlanned = result.startData;
      lesson.endPlanned = result.endData;
      lesson.name = result.title;
      lesson.description = result.description;
      lesson.recodingPlanned = result.recordPlan;
      lesson.privateRecording = result.private;
      lesson.roomId = result.roomId;
      lesson.classId = this.master.idClassroom;

      this.masterService.postLesson(this.master.id, lesson)
        .subscribe({
          next: () => {
            this.getLessons();
            this.refreshParticipants.emit(true);
            this.snackbar.open('Lesson added!', '', { duration: 3000 });
          },
          error: err => this.snackbar.open(err.message, '', { duration: 3000 })
        });
    });
  }

  getMasterState() {
    return MasterState;
  }

  showMessage (message: string) {
    this.snackbar.open(message, 'Dismiss', { duration: 3000 });
  }

  todayGreaterThanPlanned(date: Date | string) {
    return this.today > new Date(date);
  }

  addConference(): void {
    const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
      width: '600px',
      data: { mode: "private", page: "master", idMaster: this.master.id },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.getLessons();
    });
  }

  updateConference(id: number) {
    this.conferenceService.getConference(id).subscribe({
      next: res => {
        const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
          data: { conference: res },
          width: '600px',
          autoFocus: false,
          restoreFocus: false
        });

        dialogRef.afterClosed().subscribe(res => {
          if (!res)
            return;

          this.getLessons();
        });
      }
    });
  }

  async deleteConference(conference: ConferenceDTO) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete conference')),
        body:  await firstValueFrom(this.translate.get('Are you sure you want to delete conference?', { name: conference.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;
      
        this.conferenceService.deleteConference(conference.id)
          .subscribe({
            next: async () => {
             this.snackbar.open(await firstValueFrom(this.translate.get('Conference deleted')), undefined, { duration: 3000 });
             this.getLessons();
            },
            error: err => this.snackbar.open('Error deleting conference', undefined, { duration: 3000 })
          });
    });
  }

  async deleteMaster() {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete master')),
        body:  await firstValueFrom(this.translate.get('Are you sure you want to delete master?', { name: this.master.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;
      
        this.masterService.deleteMaster(this.master.id)
          .subscribe({
            next: async () => {
             this.snackbar.open(await firstValueFrom(this.translate.get('Master deleted')), undefined, { duration: 3000 });
             this.router.navigate(['/masters']);
            },
            error: err => this.snackbar.open(err?.error?.Message ?? 'error deleting master', undefined, { duration: 5000 })
          });
    });
  }

  //KPI

  spinnerValue (value: number) {
    if (value.toFixed(1).split('.')[1] == "00")
      return value + "%";
    
    return value.toFixed(1) + "%";
  }

  getAttendanceClassroom () {
    this.calendar.getClassroomAttendance(this.master.idClassroom)
      .subscribe(output => {
        
        let attendancePercentage = 0;
        let actualDurationPercentage = 0;
        let lessonsDuration = 0;
        let lessonsPlannedDuration = 0;

        let lessonsTotalsSeconds = 0;
        let lessonsActualDurationSeconds = 0;

        this.lessonsAttendance = output;
        this.lessonsAttendance.sort(
          firstBy((a: AttendanceDTO) => new Date(a.startDate).getFullYear(), 1)
          .thenBy((a: AttendanceDTO) => new Date(a.startDate).getMonth(), 1)
        );

        output.forEach(l => {
          attendancePercentage += l.attendancePercentage;
          actualDurationPercentage += l.actualDurationPercentage;
          lessonsPlannedDuration += differenceInSeconds(l.endPlanned, l.startPlanned); //Durata pianificata delle lezioni GIA eseguite
          lessonsDuration += differenceInSeconds(l.stopDate, l.startDate);

          lessonsTotalsSeconds += l.attendanceTotalSeconds;
          lessonsActualDurationSeconds += l.actualDurationSeconds;
        });

        if (lessonsTotalsSeconds !== 0 && lessonsActualDurationSeconds !== 0)
          this.lessonAverageAttendanceTime = Helper.convertToTime(lessonsTotalsSeconds / output.length);

        this.lessonsDuration = Helper.convertToTime(lessonsDuration);
        //this.lessonsPlannedDuration = ((lessonsPlannedDuration) / 60 / 60).toFixed(2);

        this.lessonAverageAttendance = Number((attendancePercentage / output.length).toFixed(2));
        if (!this.lessonAverageAttendance)
          this.lessonAverageAttendance = 0;

        this.lessonsDurationSeconds = lessonsDuration;
        //this.durationSpinnerValue = ((lessonsDuration / lessonsPlannedDuration) * 100).toFixed(2);

        this.lessonAverageDuration = Helper.convertToHMS(lessonsDuration / output.length);
        this.lessonAveragePlannedDuration = Helper.convertToHMS(lessonsPlannedDuration / output.length);

        this.setChartData();
        this.getLessons(true);

      });
  }

  getDerivedMasters() {
    this.masterService.getDerivedMastersOfTemplate(this.master.id)
      .subscribe(res => {
        this.derived.data = res;
      });
  }

  setChartData () {
    let data = [];

    for(var i=0; i<this.lessonsAttendance.length; i++) {
      if(this.lessonsAttendance[i].actualDurationPercentage >= 10)
      {
        data.push(this.lessonsAttendance[i].attendancePercentage);
        let date = new Date(this.lessonsAttendance[i].startDate);

        this.lineChartLabels.push(date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear());
      }
    }

    this.lineChartData = [{ data: data, label: "%", borderColor: 'rgba(103, 58, 183, 1)', backgroundColor: 'rgba(103, 58, 183, 0.5)' }];
  }

  goToMaster(masterId: number) {
    this.router.navigate(['/master', masterId], { queryParams: { page: 'sidenav' } });
  }

  showSurvey(id:number) {
    this.router.navigate(['/survey', id], { queryParams: { view: 'preview' } });
  }

  editLessonElement(elem: LessonSessionDTO): void {
    elem.idSurvey > 0 ? this.editLessonSurvey(elem) : this.editLesson(elem);
  }

  editLesson(elem: LessonSessionDTO): void {
    let eventData: EventDialogData = {
      startData: new Date(elem.startPlanned),
      endData: new Date(elem.endPlanned),
      title: elem.name,
      description: elem.description,
      recordPlan: elem.recodingPlanned,
      private: elem.privateRecording,
      roomId: elem.roomId,
      classId: elem.classId,
      rooms: this.rooms,
      teacherId: elem.teacherId,
      teachers: this.customerTeachers,
      type: elem.typeId
    };

    const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
      width: '500px',
      data: { eventData, page: "masterNoCalendar", dateOrTime: true }
    });

    dialogRef.afterClosed().subscribe((result: EventDialogData) => {

      if (!result)
        return;

      elem.teacherId = result.teacherId;
      elem.startPlanned = result.startData;
      elem.endPlanned = result.endData;
      elem.name = result.title;
      elem.description = result.description;
      elem.recodingPlanned = result.recordPlan;
      elem.privateRecording = result.private;
      elem.roomId = result.roomId;

      this.masterService.putLesson(elem.id, elem)
        .subscribe({
          next: () => {
            this.getLessons();
            this.refreshParticipants.emit(true);
            this.snackbar.open('Lesson edited', '', { duration: 3000 });
          },
          error: err => this.snackbar.open(err.message, '', { duration: 3000 })
        });
    });
  }

  async deleteLesson(lesson: LessonSessionDTO) {
    let msg = lesson.recodingPlanned
            ? `Are you sure you want to delete lesson? All associated records will be removed`
            : `Are you sure you want to delete lesson?`;

    const dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete lesson')),
        body: await firstValueFrom(this.translate.get(msg, { name: lesson.name }))
      }
    });

    dialogRef.afterClosed().subscribe(async res => {

      if (!res)
        return;

      if (lesson.state === 1) {
        this.lesson.deleteLesson(lesson.id)
          .then(() => {
            this.getLessons();
            this.snackbar.open('Lesson deleted', '', { duration: 3000 });
          })
          .catch(err => this.snackbar.open(err.error.Message, '', { duration: 3000 }));
      } else {
        this.calendar.deleteLesson(lesson.id)
          .subscribe({
            next: () => {
              this.getLessons();
              this.snackbar.open('Lesson deleted', '', { duration: 3000 });
            },
            error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
          });
      }

    });
  }

  addSurvey(): void {
    let eventData: EventDialogData = {
      dialogTitle : 'Add new survey',
      classId: this.master.idClassroom,
      rooms: this.rooms,
      teacherId: this.master.idAuthor,
      teachers: this.customerTeachers,
      type: 4
    };

    const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
      width: '500px',
      data: { eventData, page: "master" }
    });
    
    dialogRef.afterClosed().subscribe(result => {

      if (!result)
        return;
      
      let surveyLesson: SurveyLessonDTO = new SurveyLessonDTO();

      surveyLesson.name = result.title;
      surveyLesson.description = result.description;
      surveyLesson.teacherId = result.teacherId;
      surveyLesson.startPlanned = result.startData;
      surveyLesson.endPlanned = result.endData;
      surveyLesson.typeId = 4;
      surveyLesson.classId = this.master.idClassroom;
      surveyLesson.survey = new SurveyDTO();
      surveyLesson.survey.surveyJSON = '';

      if (result.survey) {
        surveyLesson.name = result.survey.name;
        surveyLesson.description = result.survey.description;
        surveyLesson.survey.surveyJSON = result.survey.surveyJSON;
      }

      this.masterService.postSurvey(this.master.id, surveyLesson)
        .subscribe({
          next: (res: ResDTO) => {

            if (res.Message)
              this.router.navigate(['/survey-creator', res.Message]);

          },
          error: err => this.snackbar.open(err.message, '', { duration: 3000 })
        });
    }); 
  }

  editLessonSurvey(elem: LessonSessionDTO){
    let eventData: EventDialogData = {
      dialogTitle: 'Edit survey',
      startData: new Date(elem.startPlanned),
      endData: new Date(elem.endPlanned),
      title: elem.name,
      description: elem.description,
      classId: elem.classId,
      rooms: this.rooms,
      teacherId: elem.teacherId,
      teachers: this.customerTeachers,
      type: elem.typeId
    };

    const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
      width: '500px',
      data: { eventData, page: "master" }
    });
    
    dialogRef.afterClosed().subscribe(result => {

      if (!result)
        return;

      elem.teacherId = result.teacherId;
      elem.startPlanned = result.startData;
      elem.endPlanned = result.endData;
      elem.name = result.title;
      elem.description = result.description;
      elem.classId = this.master.idClassroom;
      // elem.type = 4;
      // surveyLesson.survey=new SurveyDTO();
      // surveyLesson.survey.surveyJSON='';
      
      this.masterService.putLesson(elem.id , elem)
        .subscribe({
          next: () => {
            this.getLessons();
            this.refreshParticipants.emit(true);
            this.snackbar.open('Lesson edited', '', { duration: 3000 });
          },
          error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
        });
    });
  }

  editSurvey(element: LessonSessionDTO) {
    this.router.navigate(['/survey-creator', element.idSurvey]);
  }

  showResultSurvey(element: LessonSessionDTO) {
    this.router.navigate(['/survey-results', element.idSurvey], { queryParams: { idLesson: element.id, idClassroom: this.master.idClassroom } });
  }

  showAnalyticsSurvey(element: LessonSessionDTO) {
    this.router.navigate(['/survey-analytics', element.idSurvey], { queryParams: { idLesson: element.id, idClassroom: this.master.idClassroom } } );
  }

  matchSubscriptionToUser() {
    this.students.data.forEach(classcontent => {
      if (this.master.subscription.subscriptionUser.findIndex(s => s.idUser === classcontent.userId) !== -1)
      //if (Helper.getLatestSubscriptionUser(this.master.subscription.subscriptionUser, classcontent.userId) != null)
        classcontent.user.havePaid = true;
    });
  }

  addUserAsPayed(idUser: number) {
    this.masterService.buyMaster(this.master.idSubscription, idUser)
      .subscribe({
        next: () => {
          this.getMaster(true);
          this.snackbar.open('User set as paid', '', { duration: 3000 });
        },
        error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
      });
  }

  payMasterFree() {
    this.masterService.buyMaster(this.master.idSubscription)
      .subscribe({
        next: () => {

          //this.snackbar.open('Master paid', '', { duration: 3000 });
          this.router.navigate(['/master', this.master.id], { queryParams: { page: 'sidenav' } });

        },
        error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
      });
  }

  isOneToOne() {
    return this.master.type === MasterType.oneToOne;
  }

  isTemplate() {
    return this.master.type === MasterType.template;
  }

  getDisabledPayBtn() {
    if (this.isOneToOne())
      return !this.currentUserHavePaid || this.getRemainingPercentage() > 20;

    return this.availablePlaces <= 0 || this.currentUserHavePaid;
  }

  getDisplayPayBtn() {
    if (this.isOneToOne())
      return this.currentUser.isStudent &&
             this.master.subscription != null;
    
    return this.master.state === this.getMasterState().planned &&
           this.currentUser.isStudent &&
           this.master.subscription != null;
  }

  goBack() {
    this.location.back();
  }

  getHelper() {
    return Helper;
  }

  //Inizio - Utilizzati per il titolo
  getTitile() {
    if (this.master.type === MasterType.standard)
      return 'Live Digital Academy Dashboard';

    return 'Personal Live Digital Academy';
  }

  getTitleConn() {
    if (this.isOneToOne())
      return this.currentUser.isStudent ? ' Dashboard' : ' of ';
    
    return '';
  }

  getOneToOneUser() {
    return this.students.data != null && this.students.data.length > 0 ?
           `${this.students.data[0].user.name} ${this.students.data[0].user.surname}` :
           null;
  }
  //Fine

  selectSpinnerValue() {
    this.currentSpinnerValue = this.currentSpinnerValue ? 0 : 1;
  }

  getSpinnerTip() {
    return this.currentSpinnerValue ? 'Done time' : 'Done lessons';
  }

  getRemainingDays() {
    if (!this.master)
      return 0;

    if (this.master.state === MasterState.finished)
      return 0;

    let now = new Date();
    let startDate = new Date(this.master.startDate);

    if (now < startDate)
      return this.master.durationInDays;

    let stopDate = new Date(this.master.stopDate);
    
    if (now < stopDate)
      return differenceInDays(stopDate, now);

    return 0;
  }

  getRemainingPercentage() {
    return (this.remainingDays / this.master.durationInDays) * 100;
  }

  getRemainingColor() {
    let remainingPercentage = this.getRemainingPercentage();

    if (remainingPercentage < 10)
      return 'red';

    if (remainingPercentage < 20)
      return 'orange';

    if (remainingPercentage < 30)
      return 'yellow';
  }

  share() {
    this.dialog.open(SharePopupComponent, {
      width: '400px', data: { title: this.master.name }
    });
  }

  getSubTitle() {
    return this.translation.currentLang.code === LanguageCodes.IT ? 'Iva inclusa' : null;
  }

  getLessonType(typeId: number) {
    if (typeId === 8)
      return 'Conference';

    if (typeId === 4)
      return 'Survey';

    return 'Lesson';
  }

  getLessonIcon(typeId: number) {
    if (typeId === 8)
      return 'interpreter_mode';

    if (typeId === 4)
      return 'assignment';

    return 'school';
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.mediaContents.length < total)
      total = this.mediaContents.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

  isDeleted() {
    return this.master?.state === MasterState.deleted;
  }

}

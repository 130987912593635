<h1 mat-dialog-title>
  {{ mode === 'edit' ? ('Edit Certification' | translate) : ('New Certification' | translate) }}
</h1>

<div mat-dialog-content>
  <mat-horizontal-stepper #stepper="matHorizontalStepper" [formGroup]="certificationForm">

    <!-- Step 1: Basic Certification Details -->
    <mat-step formGroupName="details">
      <ng-template matStepLabel>{{ 'Certification Details' | translate }}</ng-template>
      <div class="stepper-gap">

        <mat-form-field appearance="fill">
          <mat-label>{{ 'Certification Title' | translate }}</mat-label>
          <input matInput formControlName="title" required />
          <mat-error *ngIf="certificationForm.get('details.title')?.hasError('required')">
            {{ 'Title is required' | translate }}
          </mat-error>
        </mat-form-field>

        <p class="descriptive-text">
          {{ 'Insert start and end dates of the certification:' | translate }}
        </p>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'Start Date' | translate }}</mat-label>
            <input matInput type="datetime-local" formControlName="startDate" />
            <mat-error *ngIf="certificationForm.get('details.startDate')?.hasError('required')">
              {{ 'Start Date is required' | translate }}
            </mat-error>
            <mat-error *ngIf="certificationForm.get('details.startDate')?.hasError('invalidDate')">
              {{ 'Cannot set a start date in the past' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>{{ 'End Date' | translate }}</mat-label>
            <input matInput type="datetime-local" formControlName="endDate" />
            <mat-error *ngIf="certificationForm.get('details.endDate')?.hasError('required')">
              {{ 'End Date is required' | translate }}
            </mat-error>
            <mat-error *ngIf="certificationForm.get('details.endDate')?.hasError('invalidDate')">
              {{ 'Cannot set an end date in the past' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ 'Certification Description' | translate }}</mat-label>
          <textarea matInput formControlName="description" rows="3"></textarea>
          <mat-error *ngIf="certificationForm.get('details.description')?.hasError('required')">
            {{ 'Description is required' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-step>

    <!-- Step 2: Single Classroom -->
    <mat-step formGroupName="classroomAssignment">
      <ng-template matStepLabel>{{ 'Classroom Assignment' | translate }}</ng-template>
      <div class="stepper-gap">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ 'Assign to Classroom' | translate }}</mat-label>
          <mat-select formControlName="classroom">
            <mat-option *ngFor="let c of classrooms" [value]="c.id">
              {{ c.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="certificationForm.get('classroomAssignment.classroom')?.hasError('required')">
            {{ 'Must select one classroom' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-step>

    <!-- Step 3: Skills -->
    <mat-step formGroupName="skillSelection">
      <ng-template matStepLabel>{{ 'Available Skills' | translate }}</ng-template>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'Select Skill(s)' | translate }}</mat-label>
        <!-- formControlName MUST match "selectedSkills" from TS -->
        <mat-select formControlName="selectedSkills" multiple (selectionChange)="onSkillChange()">
          <mat-option *ngFor="let skill of skills" [value]="skill.id">
            {{ skill.name }}
          </mat-option>
        </mat-select>
        <mat-hint>{{ 'You can select multiple skills' | translate }}</mat-hint>
      </mat-form-field>
    </mat-step>

    <!-- Step 4: Optional Single Exercise -->
    <mat-step formGroupName="materialSelection">
      <ng-template matStepLabel>{{ 'Material Selection' | translate }}</ng-template>
      <div class="stepper-gap">
        <mat-checkbox formControlName="includeExercises" (change)="onIncludeExercisesChange()">
          {{ 'Include Exercise' | translate }}
        </mat-checkbox>

        <div *ngIf="certificationForm.get('materialSelection.includeExercises')?.value" style="margin-top: 1em;">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>{{ 'Select Exercise' | translate }}</mat-label>
            <!-- formControlName MUST match "selectedExercise" from TS -->
            <mat-select formControlName="selectedExercise">
              <mat-option *ngFor="let ex of filteredExercises" [value]="ex.id">
                {{ ex.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="certificationForm.get('materialSelection')?.hasError('exerciseRequired')">
              {{ 'An exercise is required if checkbox is checked' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-step>

  </mat-horizontal-stepper>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-stroked-button (click)="onCancel()">
    {{ 'Cancel' | translate }}
  </button>
  <div>
    <button
      mat-stroked-button
      *ngIf="stepper.selectedIndex !== 0"
      (click)="stepper.previous()"
    >
      {{ 'Back' | translate }}
    </button>
    <button
      mat-flat-button color="primary"
      *ngIf="stepper.selectedIndex !== stepper.steps.length - 1"
      (click)="stepper.next()"
      [disabled]="!isCurrentStepValid()"
    >
      {{ 'Next' | translate }}
    </button>
    <button
      mat-flat-button color="accent"
      *ngIf="stepper.selectedIndex === stepper.steps.length - 1"
      (click)="onSave()"
      [disabled]="!certificationForm.valid"
    >
      {{ mode === 'edit' ? ('Save Changes' | translate) : ('Add Certification' | translate) }}
    </button>
  </div>
</div>

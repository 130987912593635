<div class="component-main" [class.margin]="!isAuthenticated()" [hidden]="!level">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-stroked-button (click)="goBack()">
            <mat-icon matListIcon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
    </div>

    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex="50">
            <mat-card-header>
                <mat-card-title>
                    {{ 'An offer for you' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                <div fxLayoutAlign="center end" fxFlex="20">
                    <button mat-fab style="background-color: #ffb22b;" class="mat-elevation-z0 btnNoHover">
                        <mat-icon style="color: white;">
                            local_offer
                        </mat-icon>
                    </button>
                </div>
                <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                    <h3>{{ level?.summary }}</h3>
                </div>
            </mat-card-content>
        </mat-card>
        <app-price-card class="mat-elevation-z4" fxFlex="50"
            [subscription]="level?.subscription"
            [subCrudShow]="currentUser?.isAdmin"
            [subtitle]="getSubTitle()"
            [buyShow]="isAuthenticated() &&
                       level?.authoring === 1 &&
                       !currentUser?.isAdmin &&
                       currentUser?.isGlobal"
            [buyDisabled]="!canpurchased || currentstate != 1 || !isstartget"
            (subAdd)="addSubscription()"
            (subEdit)="addSubscription()"
            (buyPay)="pay()">
            <button mat-raised-button additionalButton color="primary"
                *ngIf="!isAuthenticated() || !currentUser?.isGlobal"
                (click)="addSubscriptionUser()"
                style="width: 100%; margin: 5px 0 0 !important;">
                <mat-icon>
                    person_add
                </mat-icon>
                {{ 'Signup Community to purchase' | translate }}
            </button>
        </app-price-card>
    </div>

    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
        <mat-card appearance="outlined" *ngIf="level" class="mat-elevation-z4" fxLayout="column" fxFlex="20" style="min-width: 450px;">
            <img mat-card-image [src]="level.imageUrl" [alt]="level.name" loading="lazy">
            <!--div class="statusText mat-elevation-z3">
                <h5 style="margin: 0%;">{{ (getPackageState().getState(package.state) | translate).toUpperCase() }}
                </h5>
            </div-->
            <mat-card-header>
                <mat-card-title>
                    {{ level.name }}
                </mat-card-title>
                <mat-card-subtitle style="font-size: 12px;">
                    {{ level.header }}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content [innerHtml]="getHelper().getTruncateShowcase(level.description, 'No description', 250)" style="overflow: hidden;">
            </mat-card-content>
            <div fxFlex></div>
            <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
                <!--button mat-button [matTooltip]="'Share' | translate" (click)="share()">
                    <mat-icon>share</mat-icon>
                </button-->
                <button mat-fab color="primary"
                    *ngIf="currentUser?.isAdmin"
                    (click)="editSubscriptionLevel()"
                    [matTooltip]="'Edit' | translate">
                    <mat-icon>edit</mat-icon>
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card appearance="outlined" class="mat-elevation-z4" style="width: 100%;">
            <table mat-table [dataSource]="levels" #levelsSort="matSort" style="width: 100%;" matSort>
      
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Title' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
          
                <ng-container matColumnDef="userNoMax">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Users' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.userNoMax }}</td>
                </ng-container>
          
                <ng-container matColumnDef="storageMax">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Storage size' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.storageMax }} GB</td>
                </ng-container>
          
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Price' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ getPrice(element.subscription) }}</td>
                </ng-container>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Select' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox color="primary"
                            (change)="level = element"
                            [checked]="element.id === level?.id"
                            [disabled]="element.id === level?.id">
                        </mat-checkbox>
                    </td>
                </ng-container>
          
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>
    </div>

</div>

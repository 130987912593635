import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { EduPlanClassDTO } from 'src/app/models/dto/eduPlanClassDTO';
import { EduPlanDTO } from 'src/app/models/dto/eduPlanDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { EduPlanService } from 'src/app/services/eduplan.service';

@Component({
  selector: 'app-add-eduplan',
  templateUrl: './add-eduplan.component.html',
  styleUrls: ['./add-eduplan.component.scss']
})
export class AddEduplanComponent implements OnInit {
  currentUser: User = null;
  currentYear: number = new Date().getFullYear();

  name: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]);
  description: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]);
  classification: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  customer: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  year: UntypedFormControl = new UntypedFormControl(this.currentYear, [Validators.required, Validators.min(this.currentYear)]);

  className: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]);
  classDesc: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]);

  classifications: EduPlanClassDTO[] = [];
  customers: CustomerDTO[] = [];

  isEdit: boolean = false;
  id: number = null;

  constructor(private dialogRef: MatDialogRef<AddEduplanComponent>,
              @Inject(MAT_DIALOG_DATA) private data,
              private auth: AuthService,
              private eduPlanService: EduPlanService,
              private darkService: DarkThemeService,
              private calendar: CalendarService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.customer.setValue(this.currentUser.idCustomer);

    this.eduPlanService.getEduPlansClassifications()
      .subscribe(res => this.classifications = res);

    if (this.data?.eduPlan) {
      this.isEdit = true;
      this.id = this.data.eduPlan.id;

      this.name.setValue(this.data.eduPlan.name);
      this.description.setValue(this.data.eduPlan.description);
      this.classification.setValue(this.data.eduPlan.idClassification);
      this.customer.setValue(this.data.eduPlan.idCustomer);
      this.year.setValue(this.data.eduPlan.year);
    }

    if (this.currentUser.isAdmin)
      this.calendar.getCustomerForAdmin()
        .subscribe(res => this.customers = res);
  }

  savePlan() {
    let newPlan: EduPlanDTO = new EduPlanDTO();
    newPlan.idCustomer = this.customer.value;
    newPlan.name = this.name.value;
    newPlan.description = this.description.value;
    newPlan.idClassification = this.classification.value;
    newPlan.year = this.year.value;

    if (newPlan.idClassification === 0) {
      newPlan.classification = new EduPlanClassDTO();
      newPlan.classification.name = this.className.value;
      newPlan.classification.description = this.classDesc.value;
    }

    if (!this.isEdit)
      this.eduPlanService.postEduPlan(newPlan)
        .subscribe(() => {
          this.snackBar.open('Educational plan added successfully', 'Dismiss', { duration: 3000 });
          this.dialogRef.close(true);
        },
        err => {
          this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
        });
    else
        this.eduPlanService.putEduPlan(this.id, newPlan)
          .subscribe(() => {
            this.snackBar.open('Educational plan edited successfully', 'Dismiss', { duration: 3000 });
            this.dialogRef.close(true);
          },
          err => {
            this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
          });
  }

  btnDisabled(): boolean {
    let check = this.classification.value === 0 ?
                !this.className.valid || !this.classDesc.valid :
                !this.classification.valid;

    return !this.name.valid
        || !this.description.valid
        || !this.year.valid
        || check;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  isDark() {
    return this.darkService.isSetDark;
  }

}

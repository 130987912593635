<div class="component-main">

    <div fxLayout="row" fxLayoutAlign="center center">
      <h2>{{ 'Survey Templates' | translate }}</h2>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px">
      <button mat-fab
        (click)="newSurveys()"
        [matTooltip]="'New surveys' | translate"
        color="accent">
        <mat-icon>add</mat-icon>
      </button>    
    </div> 

    <table mat-table [dataSource]="dataSource" class="full-width-table mat-elevation-z8" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
  
       <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Description' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
      </ng-container>
  
      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Author' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.user.name }}  {{ row.user.surname }}</td>
      </ng-container>
      
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Created' | translate }}</th>
        <td mat-cell *matCellDef="let row" >
          {{ row.created | date: "HH:mm  dd/MM/yyyy" }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Actions' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <button mat-icon-button (click)="editSurvey(row)" [matTooltip]="row.idAuthor === currentUser.id ? ( 'Edit' | translate ) : ( 'Look' | translate )"> 
              <mat-icon>{{ row.idAuthor === currentUser.id ? 'edit' : 'preview' }}</mat-icon> 
            </button>
            <button mat-icon-button *ngIf="row.idAuthor === currentUser.id" (click)="deleteSurvey(row)" [matTooltip]="'Delete' | translate" color="warn"> 
              <mat-icon>delete</mat-icon> 
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="header-search">
        <th mat-header-cell *matHeaderCellDef
          [style.text-align]="'center'"
          [attr.colspan]="5">
          <div fxLayout fxLayoutAlign="center center" fxLayoutGap="5px">
            <mat-form-field fxFlex color="primary">
              <mat-label>{{ "search" | translate }}</mat-label>
              <input matInput type="text" (keyup)="doFilter($event)" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </th>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="['header-search']"></tr>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      #paginator
      [length]="dataSource?.data.length"
      [pageIndex]="0"
      [pageSize]="25"
      [pageSizeOptions]="[25, 50, 100]"
      color="primary">
    </mat-paginator>
</div>

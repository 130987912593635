import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-add-room',
  templateUrl: './add-room.component.html',
  styleUrls: ['./add-room.component.scss']
})
export class AddRoomComponent implements OnInit {

  name: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(4)]);
  description: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  customer: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);

  currentUser: User = null;
  customers: CustomerDTO[] = [];

  isEdit: boolean = false;

  constructor(private dialogRef: MatDialogRef<AddRoomComponent>,
              @Inject(MAT_DIALOG_DATA) private data,
              private auth: AuthService,
              private calendar: CalendarService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    if (this.data != null && this.data.room != null) {
      this.isEdit = true;

      let room: RoomDTO = this.data.room;

      this.name.setValue(room.name);
      this.description.setValue(room.description);
      this.customer.setValue(room.customerId);
    } else {
      if (this.currentUser.isAdmin)
        this.calendar.getCustomerForAdmin()
          .subscribe(res => this.customers = res);

      this.customer.setValue(this.currentUser.idCustomer);
    }
  }

  saveRoom() {
    let room = new RoomDTO();
    room.name = this.name.value;
    room.description = this.description.value;
    room.customerId = this.customer.value === 0 ? null : this.customer.value;

    if (this.isEdit) {
      this.calendar.editRoom(this.data.room.id, room)
        .subscribe({
          next: () => {
            this.snackBar.open('Room edited successfully', 'Dismiss', { duration: 3000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
          }
        });
    } else {
      this.calendar.addRoom(room)
        .subscribe({
          next: () => {
            this.snackBar.open('Room created successfully', 'Dismiss', { duration: 3000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
          }
        });
    }
  }

  btnDisabled() {
    return !this.name.valid ||
           !this.description.valid ||
           !this.customer.valid;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

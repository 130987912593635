import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Publisher, StreamManager, Subscriber } from 'openvidu-browser';

@Component({
  selector: 'app-user-video',
  templateUrl: './user-video.component.html',
  styleUrls: ['./user-video.component.scss']
})
export class UserVideoComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('videoElement') elementRef: ElementRef;

  private _streamManager: Publisher | Subscriber;
  private _name: string;

  private _enableVideo: boolean = true;
  private _enableAudio: boolean = true;

  @Input()
  get streamManager(): Publisher | Subscriber { return this._streamManager; }
  set streamManager(streamManager: Publisher | Subscriber) {

    if (!streamManager)
      return;

    this.disposeStreamManager();
    
    this._streamManager = streamManager;

    this.initStreamManager();

  }

  @Input()
  set enableVideo(value: boolean) {
   
    if (!(this._streamManager instanceof Publisher) || value == this._enableVideo)
      return;

    this._enableVideo = value;

    let pub = <Publisher>this._streamManager;
    pub?.publishVideo(this._enableVideo);
    
  }

  @Input()
  set enableAudio(value: boolean) {

    if (!(this._streamManager instanceof Publisher) || value == this._enableAudio)
      return;

    this._enableAudio = value;

    let pub = <Publisher>this._streamManager;
    pub?.publishAudio(this._enableAudio);

  }

  @Input()
  get name(): string { return this._name; }
  set name(value: string) {
    this._name = value;

    if (!this._name || this._name.length === 0)
      return;

    this.initials = this._name.at(0);
    let spaceIndex = this._name.lastIndexOf(' ');

    if (spaceIndex !== -1 && this._name.length > spaceIndex + 1)
      this.initials += this._name[spaceIndex + 1];

    this.initials = this.initials.toUpperCase();
  }

  @Input()
  color: string;

  @Input()
  dragEnabled: boolean = false;

  @Input()
  z_index: number = 1;

  @Input()
  cursor: string;

  @Input()
  displayName: boolean = true;

  @Input()
  displayAudio: boolean = true;

  @Input()
  dragBoundary: string = null;

  @Input()
  mode: string;

  @Input()
  visualType: 'contain' | 'cover' | 'fill' = 'contain';

  @Input()
  handRaised: boolean = false;

  @Input()
  displayOutline: boolean = false;

  @Input()
  compactMode: boolean = false;

  @Input()
  lowerHandEnabled: boolean = false;

  @Input()
  selectionEnabled: boolean = false;

  @Input()
  selectionDisabled: boolean = false;

  @Input()
  selectionChecked: boolean = false;

  @Output()
  isVideoActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  isAudioActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  selectionCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onLowerHand: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  btnOptionsMarginBottom: string;

  initials: string = '';
  isSpeaking: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {

    setTimeout(() => {

      if (this._streamManager)
        this.initStreamManager();

    });

  }

  ngOnDestroy(): void {
    //this.disposeStreamManager();
  }

  hasAudioActive() {
    return this._streamManager?.stream.audioActive ?? false;
  }

  hasVideoActive() {
    return this._streamManager?.stream.videoActive ?? false;
  }

  lowerHand() {
    if (this.lowerHandEnabled)
      this.onLowerHand.emit(true);
  }

  private initStreamManager() {

    if (!this._streamManager || !this.elementRef)
      return;

    this._streamManager.addVideoElement(this.elementRef.nativeElement);

    this.elementRef.nativeElement.muted = this._streamManager instanceof Publisher;

    (<StreamManager>this._streamManager).on('publisherStartSpeaking', () => this.isSpeaking = true);
    (<StreamManager>this._streamManager).on('publisherStopSpeaking', () => this.isSpeaking = false);

    (<StreamManager>this._streamManager).on('streamPropertyChanged', (event) => {
      
      if (event.changedProperty === 'videoActive')
        this.isVideoActive.emit(Boolean(event.newValue));

      if (event.changedProperty === 'audioActive')
        this.isAudioActive.emit(Boolean(event.newValue));

    });

  }

  private disposeStreamManager() {

    if (!this._streamManager)
      return;

    (<StreamManager>this._streamManager)?.off('publisherStartSpeaking');
    (<StreamManager>this._streamManager)?.off('publisherStopSpeaking');
    (<StreamManager>this._streamManager)?.off('streamPropertyChanged');

    this.isSpeaking = false;

  }

}

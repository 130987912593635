<div class="component-main" fxLayoutAlign="center start" *ngIf="!loading">
  <div fxLayout="column" fxLayoutGap="30px" class="cart-container">

    <div fxLayout="row" fxLayoutAlign="space-between center">

      <div fxFlex="70">
        <h1>{{ "Your Cart" | translate }}</h1>
      </div>

      <mat-form-field appearance="outline" fxFlex="30">
        <mat-label>{{ "Currency" | translate }}</mat-label>
        <mat-select [(value)]="currency">
          <mat-option value="eur">€ {{ "Euro" | translate }}</mat-option>
          <mat-option value="usd">$ {{ "Dollars" | translate }}</mat-option>
          <mat-option value="gbp">£ {{ "Pounds" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div fxLayout="column" fxLayoutGap="20px">

      <div *ngFor="let i of listofCustomer; index as ii">

        <div *ngIf="getItems(ii).length > 0" fxLayout="column" fxLayoutGap="20px">

          <div>
            <h3 style="margin: 0">{{ "Provided By" | translate }}: {{ i.name }}</h3>
          </div>

          <div class="mat-elevation-z8 table-container">

            <table mat-table [dataSource]="getItems(ii)" style="width: 100%" matSort>

              <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef>{{ "Thumbnail" | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  <img [src]="element.image" loading="lazy"/>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{ "Product" | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.name }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="unitAmount">
                <th mat-header-cell *matHeaderCellDef>{{ "Amount" | translate }} ({{currencySymbol}})</th>
                <td mat-cell *matCellDef="let element">{{ element.unitAmount }}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{ "Action" | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button (click)="removeItem(element)" [matTooltip]="'Remove' | translate" color="warn">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between end">

            <div>
              <mat-slide-toggle *ngIf="getInvoiceEnabled(ii)" [(ngModel)]="invoice">
                {{ "Invoice" | translate }}
              </mat-slide-toggle>
            </div>

            <div>
              <h2 style="margin: 0">{{ "Total" | translate }}: {{ getTotal(getItems(ii)) }} {{ currencySymbol }}</h2>
            </div>

          </div>

          <div fxLayout="column" fxLayoutAlign="center end">

            <mat-form-field appearance="outline">
              <mat-label>{{ "Discount code" | translate }}</mat-label>
              <input matInput [(ngModel)]="discountCode">
            </mat-form-field>

            <button mat-raised-button (click)="payNowwithIndex(ii)" color="primary">
              <mat-icon>payment</mat-icon>
              {{ "Pay Now" | translate }}
            </button>

          </div>
        </div>

      </div>

    </div>

  </div>
</div>

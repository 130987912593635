import { CustomerDTO } from "src/app/models/dto/customerDTO";
import { SubscriptionDTO } from "src/app/models/dto/subscriptionDTO";

export class CartItemDTO {
  id: number;
  subscriptionId: number;
  name: string;
  description: string;
  image: string;
  priceEuro: number;
  priceUsd: number;
  priceGbp: number;
  unitAmount: number;
  currency: string;
  dollar: number;
  euro: number;
  pound: number;
  jsonData: string;
  customer : CustomerDTO;
  subscription: SubscriptionDTO;
  nestedSubscriptions: number[] = [];
}

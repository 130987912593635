<h1 mat-dialog-title>{{ ((userId ? 'Edit' : 'Add') | translate) + ' User' }}</h1>
<div mat-dialog-content>

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="primary">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput [formControl]="name">
            <mat-error>
                {{ 'The name is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    
        <mat-form-field color="primary">
            <mat-label>{{ 'surname' | translate }}</mat-label>
            <input matInput [formControl]="surname">
            <mat-error>
                {{ 'The surname is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="primary">
            <mat-label>{{ 'Email' | translate }}</mat-label>
            <input matInput [formControl]="email">
            <mat-error>
              {{ 'The email is' | translate }} <strong>{{ 'required' | translate }}</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'username' | translate }}</mat-label>
            <input matInput [formControl]="username" autocomplete="off">
            <mat-error *ngIf="username.hasError('required')">
                {{ 'The username is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="username.hasError('pattern')">
                {{ 'Length between 6 and 200 characters and only letters, digits, dot, underscore dash' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    
    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field *ngIf="!userId" color="primary">
            <mat-label>{{ 'Password' | translate }}</mat-label>
            <input matInput type="password" [formControl]="password" autocomplete="off">
            <mat-error *ngIf="password.hasError('required')">
                {{ 'The password is' | translate }} <strong>{{ 'required' | translate }}</strong>
            </mat-error>
            <mat-error style="font-size: 8px;" *ngIf="password.hasError('pattern')">
                {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
            </mat-error>
        </mat-form-field>
    
        <mat-form-field *ngIf="!userId" color="primary">
            <mat-label>{{ 'Repeat password' | translate }}</mat-label>
            <input type="password" matInput [formControl]="passwordRepeat" autocomplete="off">
            <mat-error *ngIf="passwordRepeat.hasError('required')">
                {{ 'The password is' | translate }} <strong>{{ 'required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    
    <mat-error *ngIf="!userId &&
                      !checkPassword() &&
                      !password.hasError('required') &&
                      !password.hasError('pattern') &&
                      !passwordRepeat.hasError('required')">
        {{ 'New password and password confirmation are not the same' | translate }}
    </mat-error>

    <div fxLayout="row" style="margin-top:15px" fxLayoutGap="20px" *ngIf="!isLevelMode()">
        <mat-form-field color="primary">
            <mat-label>{{ 'Role' | translate }}</mat-label>
            <mat-select [formControl]="role" required>
                <mat-option *ngFor="let r of roles" [value]="r.role">
                    {{ r.label | role | translate }}
                </mat-option>
            </mat-select>
            <mat-error>
                {{ 'The role is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    
        <mat-form-field color="primary">
            <mat-label>{{ 'State' | translate }}</mat-label>
            <mat-select [formControl]="state" required>
                <mat-option [value]="0">{{ 'Disabled' | translate }}</mat-option>
                <mat-option [value]="1">{{ 'Enabled' | translate }}</mat-option>
            </mat-select>
            <mat-error>
              {{ 'The state is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" *ngIf="!isLevelMode()">
        <!--mat-form-field color="primary" *ngIf="isTeacherRole() && (currentUser.isAdmin || currentUser.isCustomerAdmin)">
            <mat-label>{{ 'Author' | translate }}</mat-label>
            <mat-select [formControl]="author"  >
                <mat-option [value]="0">{{ 'No' | translate }}</mat-option>
                <mat-option [value]="1">{{ 'Yes' | translate }}</mat-option>
            </mat-select>
        </mat-form-field-->
    
        <mat-form-field color="primary"
            *ngIf="(currentUser.isAdmin || currentUser.isCustomerAdmin) &&
                   currentUser.smilyEnabled &&
                   (isAdminRole() || isCustomerAdminRole() || isTeacherRole())">
            <mat-label>{{ 'Bidirectional Smily communication' | translate }}</mat-label>
            <mat-select [formControl]="signalRAccount">
                <mat-option [value]="0">{{ 'No' | translate }}</mat-option>
                <mat-option [value]="1">{{ 'Yes' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!--mat-form-field color="primary" *ngIf="!isLevelMode()">
        <mat-label>Timezone</mat-label>
        <mat-select [formControl]="timezone" required>
            <mat-option *ngFor="let zone of timezones" [value]="zone">GMT {{ (zone > 0 ? '+' : '') + zone }}</mat-option>
        </mat-select>
        <mat-error>
            {{ 'The timezone is' | translate }} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field-->

    <div *ngIf="!isLevelMode()">
        <mat-label>{{ 'Profile picture' | translate }}</mat-label>
        <app-file-input
            [control]="profilePicture"
            [maxSize]="1000"
            [acceptedTypes]="'image/*'"
            [rejectedErrorMessage]="('The file type must be a picture and not exceed' | translate) + ' 1MB'"
            [deletedErrorMessage]="'Please select a file' | translate"
            style="width: 100%">
        </app-file-input>
    </div>

    <div *ngIf="currentUser?.isAdmin">
        {{ 'You are adding this user to' | translate }} <strong>{{ customerName }} {{ 'client' | translate }}</strong>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="save()" [disabled]="btnDisabled()" color="accent">Ok</button>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';
import { environment } from 'src/environments/environment';

interface SessionIdDTO {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  //stripePromise = loadStripe(environment.stripeApi);

  bckEndUrlpayment = `${environment.apiUrl}/api/v2/ConnectedAccount`;

  getnewlycreateStripeLink () {
    let domain = window.location.protocol + "//" + window.location.hostname + ":443"; // :" + window.location.port;
    domain = btoa(domain);
    console.log(domain);
    return this.http.post(`${this.bckEndUrlpayment}/create`, {},
      { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  getStripeLink (stripeId: string) {
    let domain = window.location.protocol + "//" + window.location.hostname + ":443"; // :" + window.location.port;
    domain = btoa(domain);
    console.log(domain);
    return this.http.post(`${this.bckEndUrlpayment}/startonboarding/${domain}/${stripeId}`, {},
      { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  getStripeacct (stripeId: string) {
    return this.http.post(`${this.bckEndUrlpayment}/getstripeacct/${stripeId}`, {},
      { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  getStripeacct_customer () {
    return this.http.post(`${this.bckEndUrlpayment}/getcustomerstripeId/`, {},
      { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }



}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Chart, ChartConfiguration } from 'chart.js';
import { SmilyRecordedBlobDTO } from 'src/app/models/dto/smilyRecordedBlobDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { BufferItemCloud } from 'src/app/models/smilyRecording/bufferItemCloud';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { AzureStorageService } from 'src/app/services/storage.service';

const CHART_BORDER_COLORS: string[] = [
  "rgba(154, 208, 245, 1)",
  "rgba(255, 177, 193, 1)",
  "rgba(255, 230, 170, 1)",
  "rgba(165, 223, 223, 1)",
  "rgba(255, 207, 159, 1)",
  "rgba(228, 229, 231, 1)"
];

const CHART_BACKGROUND_COLORS: string[] = [
  "rgba(154, 208, 245, 0.5)",
  "rgba(255, 177, 193, 0.5)",
  "rgba(255, 230, 170, 0.5)",
  "rgba(165, 223, 223, 0.5)",
  "rgba(255, 207, 159, 0.5)",
  "rgba(228, 229, 231, 0.5)"
];

@Component({
  selector: 'app-smily-recording',
  templateUrl: './smily-recording.component.html',
  styleUrls: ['./smily-recording.component.scss']
})
export class SmilyRecordingComponent implements OnInit, AfterViewInit {

  @ViewChild("lineChartCanvas", {static: false}) lineChartCanvas: ElementRef;

  private lineChart: Chart = null;
  
  userForm: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  recForm: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  dpForm: UntypedFormControl = new UntypedFormControl("all", [Validators.required]);

  lessonId: number = null;
  users: UserDTO[] = [];
  recordings: SmilyRecordedBlobDTO[] = [];
  items: BufferItemCloud[] = [];

  private _zoomLevel: number = 2000;
  public get zoomLevel(): string { return `${this._zoomLevel}px` }

  private dualYConfig: ChartConfiguration = {
    type: "line",
    data: {
      labels: [],
      datasets: []
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          fill: 'origin',
          tension: 0.4
        },
        point: {
          radius: 0
        }
      },
      scales: {
        speedY: {
          type: 'linear',
          display: true,
          position: 'left',
          beginAtZero: true
        },
        torqueY: {
          type: 'linear',
          display: true,
          position: 'right',
          beginAtZero: true
        }
      }
    }
  };

  private singleYConfig: ChartConfiguration = {
    type: "line",
    data: {
      labels: [],
      datasets: []
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          fill: 'origin',
          tension: 0.4
        },
        point: {
          radius: 0
        }
      },
      scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          beginAtZero: true
        }
      }
    }
  };

  constructor(private route: ActivatedRoute,
              private calendarService: CalendarService,
              private azureService: AzureStorageService,
              private auth: AuthService) { }

  ngAfterViewInit(): void {
    //this.lineChart = new Chart(this.lineChartCanvas.nativeElement, this.dualYConfig);
    let requestedUser: number = null;

    if (this.route.snapshot.queryParamMap.has('userId'))
      requestedUser = Number(this.route.snapshot.queryParamMap.get('userId'));

    this.calendarService.getLessonUsersSmilyRecording(this.lessonId)
      .subscribe(res => {
        this.users = res;

        if (this.users.length > 0)
          this.userForm.setValue(requestedUser != null ? requestedUser : 0);

          if (requestedUser != null)
            this.getRecOfUser(requestedUser);
      });
  }

  ngOnInit(): void {
    this.lessonId = Number(this.route.snapshot.paramMap.get('id'));
    this.recForm.disable();
    this.dpForm.disable();
  }

  getRecOfUser(userId: number) {
    this.resetGraph();

    this.calendarService.getLessonSmilyRecording(this.lessonId, userId)
      .subscribe(res => {
        this.recordings = res;

        if (this.recordings.length > 0) {
          this.recForm.enable();
          this.recForm.setValue(this.recordings[0].id);
          this.updateGraph(this.recForm.value);

          return;
        }

        this.recForm.disable();
        this.dpForm.disable();
      });
  }

  updateGraph(recId: number) {
    this.resetGraph();

    let recording = this.recordings.findIndex(r => r.id === recId);

    if (recording === -1)
      return;

    this.azureService.getFileFromUrl(this.recordings[recording].blobPath, "application/json")
      .then(async res => {

        if (res == null)
          return;

        this.items = <BufferItemCloud[]>JSON.parse(await res.text());

        if (this.items.length === 0) {
          this.dpForm.setValue("all");
          this.dpForm.disable();

          return;
        }

        this.dpForm.enable();
        this.dpChange(this.dpForm.value);

      });
  }

  dpChange(dp: string) {
    this.resetGraph();

    let index = this.items.findIndex(i => i.tag === dp);

    if (dp !== "all" && index === -1)
      return;

    if (this.lineChart != null)
      this.lineChart.destroy();

    if (dp === "all") {

      this.lineChart = new Chart(this.lineChartCanvas.nativeElement, this.dualYConfig);

      index = 0;

      this.items.forEach(bi => {

        let colorIndex = this.lineChart.data.datasets.length % 6;
        this.lineChart.data.datasets.push({
          data: bi.values.map(v => v.value),
          label: bi.displayTag,
          backgroundColor: CHART_BACKGROUND_COLORS[colorIndex],
          borderColor: CHART_BORDER_COLORS[colorIndex],
          borderWidth: 1,
          yAxisID: `${bi.tag}Y`
        });

      });

    } else {

      this.lineChart = new Chart(this.lineChartCanvas.nativeElement, this.singleYConfig);

      let colorIndex = this.lineChart.data.datasets.length % 6;
      this.lineChart.data.datasets = [{
        data: this.items[index].values.map(v => v.value),
        label: this.items[index].displayTag,
        backgroundColor: CHART_BACKGROUND_COLORS[colorIndex],
        borderColor: CHART_BORDER_COLORS[colorIndex],
        borderWidth: 1
      }];
      
    }

    this.lineChart.data.labels = this.items[index].values.map(v => v.label);
    this.lineChart.update();
  }

  zoomIn() { // fino a 10000
    if (this._zoomLevel <= 9500) {
      this._zoomLevel += 500;
      setTimeout(() => this.lineChart.resize(), 50)
    }
  }

  zoomOut() { // Fino a 1500
    if (this._zoomLevel >= 2000)
      this._zoomLevel -= 500;
      setTimeout(() => this.lineChart.resize(), 50)
  }

  private resetGraph() {
    if (this.lineChart != null) {
      this.lineChart.data.datasets = [];
      this.lineChart.data.labels = [];
  
      this.lineChart.update();
    }
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExerciseService } from 'src/app/services/exercise.service';
import { Location } from '@angular/common';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { ExerciseDTO } from 'src/app/models/dto/exerciseDTO';
import { ExerciseSessionDTO } from 'src/app/models/dto/exerciseSessionDTO';
import { SkillDTO } from 'src/app/models/dto/skillDTO';
import { AzureStorageService } from 'src/app/services/storage.service';
import { firstValueFrom } from 'rxjs';
import { ExerciseSkillResultDTO } from 'src/app/models/dto/exerciseSkillResultDTO';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { GradeExercisePopupComponent } from 'src/app/pages/exercises/grade-exercise-pop-up/grade-exercise-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';

Chart.register(zoomPlugin);

@Component({
  selector: 'app-exercise-details',
  templateUrl: './exercise-details.component.html',
  styleUrls: ['./exercise-details.component.scss'],
})
export class ExerciseDetailsComponent implements OnInit, OnDestroy {
  exerciseDetails: {
    overallScore: number;
    exercise?: ExerciseDTO;
    session?: ExerciseSessionDTO;
    skills?: SkillDTO[];
    customer?: CustomerDTO;
  } | null = null;

  loading: boolean = true;
  error: string | null = null;
  chartData: { labels: string[], speed: number[], torque: number[] } | null = null;
  grade: string = '';
  addingGrade: boolean = false;
  isGradeSubmitted: boolean = false;
  finalGrade: number | null = null;

  //IntervalId: any = null;

  assignedSkills: SkillDTO[] = [];
  selectedScores: { [skillId: number]: number | null } = {};
  selectedNotes: { [skillId: number]: string } = {};
  overallSkillId: number | null = null;
  skillGrades: { [skillId: number]: number } = {};

  // Update the activeTab type to include 'skillResults'

  activeTab: 'recordings' | 'skillResults' = 'recordings';
  activeRecordingSubTab: 'rgb' | 'poseva' | 'graph' | 'io' = 'graph';

  chartInstance: Chart | null = null;
  gradingExpanded: boolean;
  notarizationId: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private exerciseService: ExerciseService,
    private location: Location,
    private azureService: AzureStorageService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private auth: AuthService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    const exerciseId = this.route.snapshot.queryParamMap.get('exerciseId');
    const userId = this.route.snapshot.queryParamMap.get('userId');

    if (exerciseId && userId) {
      this.exerciseService.getExerciseDetails(+exerciseId, +userId).subscribe({
        next: async (details) => {
          console.log('API Response:', details);

          if (details.session) {

            if (details.session.SkillScores) {
              details.session.skillScores = details.session.SkillScores;
            }
            if (details.session.SkillNotes) {
              details.session.skillNotes = details.session.SkillNotes;
            }
          }

          this.exerciseDetails = details;
          this.loading = false;

          if (
            details?.session?.score != null ||
            details?.session?.finalGrade != null ||
            details?.session?.state === 4 ||
            details?.session?.state === 5
          ) {
            this.isGradeSubmitted = true;
          }

          if (this.exerciseDetails?.session?.notarizationId) {
            this.notarizationId = this.exerciseDetails.session.notarizationId;
          }

          console.log("Assigned User ID:", this.exerciseDetails.session?.userId);

          const speedPath = details.session?.mmSpeedPath;
          const torquePath = details.session?.mmTorquePath;
          if (speedPath && torquePath) {
            this.fetchChartData(speedPath, torquePath);
          }

          if (details.exercise) {
            this.fetchAssignedSkills(details.exercise.id);
          }

        },
        error: (err) => {
          console.error('Error fetching exercise details:', err);
          this.error = 'Failed to load exercise details. Please try again later.';
          this.loading = false;
        }
      });
    } else {
      this.error = 'Invalid exercise or user ID.';
      this.loading = false;
    }
  }

  getTotalDuration(): string {
    if (this.exerciseDetails?.session?.startDate && this.exerciseDetails?.session?.stopDate) {
      const startDate = new Date(this.exerciseDetails.session.startDate);
      const stopDate = new Date(this.exerciseDetails.session.stopDate);
      const duration = stopDate.getTime() - startDate.getTime();
      const minutes = Math.floor(duration / 60000);
      const usedMinutes = minutes % 60;
      return `${usedMinutes} minutes`;
    }
    return 'Not Available';
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  goBack(): void {
    this.location.back();
  }
  selectTab(tab: 'recordings' | 'skillResults'): void {
    this.activeTab = tab;
  }

  selectRecordingSubTab(subtab: 'rgb' | 'poseva' | 'graph' | 'io'): void {
    this.activeRecordingSubTab = subtab;
    if (subtab === 'graph' && this.chartData) {
      setTimeout(() => this.renderChart(), 0);
    } else {
      this.destroyChart();
    }
  }

  fetchChartData(speedPath: string, torquePath: string): void {
    Promise.all([
      fetch(speedPath).then(response => response.json()),
      fetch(torquePath).then(response => response.json())
    ])
      .then(([speedData, torqueData]) => {
        const labels = speedData.map((item: any) => new Date(item.Time).toLocaleTimeString());
        const speedValues = speedData.map((item: any) => item.Speed);
        const torqueValues = torqueData.map((item: any) => item.Torque);
        this.chartData = { labels, speed: speedValues, torque: torqueValues };
        this.renderChart();
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
      });
  }

  renderChart(): void {
    const ctx = document.getElementById('speedTorqueChart') as HTMLCanvasElement;
    if (!ctx) {
      console.warn('Canvas element not found.');
      return;
    }
    this.destroyChart();
    this.chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.chartData.labels,
        datasets: [
          {
            label: 'Speed (RPM)',
            data: this.chartData.speed,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            yAxisID: 'ySpeed',
            fill: true,
            tension: 0.3,
          },
          {
            label: 'Torque (Nm)',
            data: this.chartData.torque,
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            yAxisID: 'yTorque',
            fill: true,
            tension: 0.3,
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true, position: 'top' },
          zoom: {
            zoom: {
              wheel: { enabled: true },
              pinch: { enabled: true },
              mode: 'x'
            },
            pan: { enabled: true, mode: 'x' }
          }
        },
        scales: {
          x: {
            title: { display: true, text: 'Time' },
            ticks: { maxRotation: 45, minRotation: 45 }
          },
          ySpeed: {
            position: 'left',
            title: { display: true, text: 'Speed (RPM)' },
            grid: { drawOnChartArea: true },
            ticks: { stepSize: 5000 }
          },
          yTorque: {
            position: 'right',
            title: { display: true, text: 'Torque (Nm)' },
            grid: { drawOnChartArea: false },
            ticks: { stepSize: 1 }
          }
        },
        layout: {
          padding: { top: 10, bottom: 10 }
        }
      }
    });
  }

  destroyChart(): void {
    if (this.chartInstance) {
      this.chartInstance.destroy();
      this.chartInstance = null;
    }
  }

  openPath(path: string): void {
    if (path) {
      window.open(path, '_blank');
    } else {
      console.error('Path is invalid or empty.');
    }
  }

  fetchAssignedSkills(exerciseId: number): void {
    this.exerciseService.getAssignedSkills(exerciseId).subscribe({
      next: (skills) => {
        console.log("Assigned skills from API:", skills);
        this.assignedSkills = skills;

        // Verify assigned skills against skill IDs in the scores
        const skillScores = this.exerciseDetails?.session?.skillScores ||
          this.exerciseDetails?.session?.skillScores;

        if (skillScores) {
          const skillIds = Object.keys(skillScores).map(id => parseInt(id));
          console.log("Skill IDs from scores:", skillIds);

          // Check if we have all skills
          const missingSkills = skillIds.filter(id => !this.assignedSkills.some(skill => skill.id === id));
          if (missingSkills.length > 0) {
            console.warn("Missing skills:", missingSkills);
          }
        }
      },
      error: (err) => {
        console.error('Error fetching assigned skills:', err);
      }
    });
  }

  fetchExistingSkillResults(exerciseId: number): void {
    const userId = this.exerciseDetails?.session?.userId;
    if (!userId) return;
    this.exerciseService.getExerciseSkillResults(exerciseId, userId).subscribe({
      next: (skillResults: ExerciseSkillResultDTO[]) => {
        console.log('Existing skill results:', skillResults);
        skillResults.forEach(res => {
          if (this.selectedScores.hasOwnProperty(res.idSkill)) {
            this.selectedScores[res.idSkill] = res.score;
          }
        });

        console.log('Pre-populated selectedScores:', this.selectedScores);
      },
      error: (err) => {
        console.error('Error fetching skill results:', err);
      }
    });
  }

  onScoreChange(): void {
    console.log('Scores changed:', this.selectedScores);
  }

  canSubmitGrade(): boolean {
    // Get the overall skill
    const overallSkill = this.assignedSkills.find(skill => skill.overall === 1);
    this.overallSkillId = overallSkill?.id ?? null;

    if (this.overallSkillId == null) {
      console.warn('No overall skill found in assigned skills');
      return false;
    }

    // Check if there's a valid score for the overall skill
    const overallScore = this.selectedScores[this.overallSkillId];
    console.log(`Overall skill ID: ${this.overallSkillId}, Score: ${overallScore}`);

    if (overallScore == null || isNaN(overallScore)) {
      return false;
    }

    return true;
  }

  updateScore(skillId: number, score: number | null): void {
    console.log(`Updating score for skill ID ${skillId}:`, score);
    this.selectedScores[skillId] = score;
  }

  toggleGradingSection(): void {
    this.gradingExpanded = !this.gradingExpanded;
  }

  openGradePopup(): void {
    const dialogRef = this.dialog.open(GradeExercisePopupComponent, {
      width: '600px',
      data: {
        assignedSkills: this.assignedSkills,
        selectedScores: this.selectedScores,
        selectedNotes: this.selectedNotes,
        overallSkillId: this.overallSkillId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.action === 'GRADE_SUBMITTED') {
        this.selectedScores = result.selectedScores;
        this.selectedNotes = result.selectedNotes;

        // Submit grade and refresh the page after submission
        this.submitGrade(this.selectedScores, this.selectedNotes).then(() => {
          this.refreshPage();
        });
      }
    });
  }

  async submitGrade(scores: { [skillId: number]: number | null }, notes: { [skillId: number]: string }): Promise<void> {
    if (!this.canSubmitGrade()) {
      alert("Please enter at least the Overall skill's score before submitting.");
      return Promise.reject("Missing overall score");
    }

    if (!this.exerciseDetails?.session?.userId) {
      console.error("Error: Missing userId in session.");
      const dialogRef = this.dialog.open(GenericPopupComponent, {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Missing Overall Score')),
          body: await firstValueFrom(this.translate.get('Please enter at least the Overall skill\'s score before submitting.'))
        }
      });
      return Promise.reject("Missing userId");
    }

    const exerciseId = this.exerciseDetails.exercise.id;
    const userId = this.exerciseDetails.session.userId;
    console.log("Submitting scores:", scores, "and notes:", notes);

    try {
      await firstValueFrom(this.exerciseService.setExerciseGradedWithNotes(exerciseId, userId, {
        skillScores: scores,
        skillNotes: notes
      }));

      const dialogRef = this.dialog.open(GenericPopupComponent, {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Grade Submission')),
          body: await firstValueFrom(this.translate.get('Grades submitted successfully!')),
          hideCancelBtn: true
        }
      });

      this.isGradeSubmitted = true;
      return Promise.resolve();
    } catch (err) {
      console.error('Error submitting grade:', err);
      this.dialog.open(GenericPopupComponent, {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Error')),
          body: await firstValueFrom(this.translate.get('Failed to submit grade. Please try again.'))
        }
      });
      return Promise.reject(err);
    }
  }

  // startRefreshInterval(): void {
  //   if (this.refreshIntervalId) {
  //     clearInterval(this.refreshIntervalId);
  //   }
  //   this.refreshIntervalId = setInterval(() => {
  //     const exerciseId = this.exerciseDetails.exercise.id;
  //     const userId = this.exerciseDetails.session.userId;
  //     this.exerciseService.getExerciseDetails(exerciseId, userId).subscribe({
  //       next: (details) => {
  //         this.exerciseDetails = details;
  //         if (this.exerciseDetails.session?.certificatePath) {
  //           clearInterval(this.refreshIntervalId);
  //           this.refreshIntervalId = null;
  //         }
  //       },
  //       error: (err) => {
  //         console.error("Error refreshing exercise details:", err);
  //       }
  //     });
  //   }, 10000); // 10 seconds
  // }

  refreshPage(): void {
    this.getExerciseDetails();
  }

  private getExerciseDetails(): void {
    const exerciseId = this.route.snapshot.queryParamMap.get('exerciseId');
    const userId = this.route.snapshot.queryParamMap.get('userId');
    if (exerciseId && userId) {
      this.exerciseService.getExerciseDetails(+exerciseId, +userId).subscribe({
        next: (details) => {
          console.log('Refreshed details:', details);
          this.exerciseDetails = details;
        },
        error: (err) => {
          console.error('Error refreshing exercise details:', err);
          this.error = 'Failed to refresh exercise details. Please try again later.';
        }
      });
    }
  }


  private async downloadPDF(): Promise<void> {
    try {
      const exerciseId = this.exerciseDetails.exercise?.id;
      const userId = this.exerciseDetails.session?.userId;

      const response = await firstValueFrom(this.exerciseService.getPdfDownloadUrl(exerciseId, userId));
      const pdfUrl = response?.url;

      if (!pdfUrl) {
        console.error("Failed to get PDF download URL.");
        this.dialog.open(GenericPopupComponent, {
          width: '400px',
          data: <GenericPopupData>{
            title: await firstValueFrom(this.translate.get('Error')),
            body: await firstValueFrom(this.translate.get('Error retrieving certificate. Please try again later.'))
          }
        });
        return;
      }
      window.open(pdfUrl, "_blank");
    } catch (error) {
      console.error("Error downloading PDF:", error);
      this.dialog.open(GenericPopupComponent, {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Error')),
          body: await firstValueFrom(this.translate.get('Failed to download PDF.'))
        }
      });
    }
  }

  copyNotarizationId(): void {
    if (this.notarizationId) {
      navigator.clipboard.writeText(this.notarizationId)
        .then(() => {
          this.snackBar.open('Notarization ID copied to clipboard', 'Close', {
            duration: 7000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          });
        })
        .catch(err => {
          console.error('Failed to copy notarization ID: ', err);
        });
    }
  }

  private async notarizePDF(): Promise<void> {
    try {
      const exerciseId = this.exerciseDetails.exercise?.id;
      const userId = this.exerciseDetails.session?.userId;
      if (!exerciseId || !userId) {
        console.error("Invalid exercise or user ID.");
        this.dialog.open(GenericPopupComponent, {
          width: '400px',
          data: <GenericPopupData>{
            title: await firstValueFrom(this.translate.get('Error')),
            body: 'Error: Invalid exercise or user ID.'
          }
        });
        return;
      }

      const response = await firstValueFrom(this.exerciseService.notarizeExercisePdf(exerciseId, userId));
      console.log("PDF notarized successfully:", response);
      this.notarizationId = response?.id?.toString() || null;
      this.dialog.open(GenericPopupComponent, {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Success')),
          body: await firstValueFrom(this.translate.get('PDF notarized successfully!')),
          hideCancelBtn: true
        }
      });

    } catch (error) {
      console.error("Error notarizing PDF:", error);
      const errorMessage = error?.error?.message || error.message || "Failed to notarize PDF. Please try again.";
      if (errorMessage.includes("Monthly notarization limit reached")) {
        const dialogRef = this.dialog.open(GenericPopupComponent, {
          width: '400px',
          panelClass: 'custom-dialog',
          data: <GenericPopupData>{
            title: await firstValueFrom(this.translate.get('Notarization limit reached')),
            body: await firstValueFrom(this.translate.get(
              'Monthly notarization limit reached. You cannot proceed with this notarization. Your monthly limit is: {{limit}}',
              { limit: this.exerciseDetails?.customer?.MaxNotarizationNoMonthly }
            )),
            btnAlign: 'center center',
            hideCancelBtn: true,
            hideOkBtn: false,
            showGoBtn: false
          }
        });
        dialogRef.afterClosed().subscribe();
      } else {
        this.dialog.open(GenericPopupComponent, {
          width: '400px',
          data: <GenericPopupData>{
            title: await firstValueFrom(this.translate.get('Error')),
            body: errorMessage
          }
        });
      }
    }
  }
}

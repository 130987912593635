import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, UntypedFormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { addHours, addMinutes, isBefore } from 'date-fns';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { SkillService } from 'src/app/services/skill.service';

import { CertificationsDTO } from 'src/app/models/dto/CertificationsDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { SkillDTO } from 'src/app/models/dto/skillDTO';
import { ExerciseDTO } from 'src/app/models/dto/exerciseDTO';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-certification-pop-up',
  templateUrl: './certification-pop-up.component.html',
  styleUrls: ['./certification-pop-up.component.scss'],
  providers: [DatePipe]
})
export class CertificationPopUpComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;

  certificationForm: FormGroup;
  classrooms: ClassroomDTO[] = [];
  skills: SkillDTO[] = [];
  exercises: ExerciseDTO[] = [];
  filteredExercises: ExerciseDTO[] = [];

  mode: 'add' | 'edit';
  currentUser: User;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private auth: AuthService,
    private classroomService: ClassroomService,
    private skillService: SkillService,
    private exerciseService: ExerciseService,
    public dialogRef: MatDialogRef<CertificationPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'add' | 'edit'; certification?: CertificationsDTO }
  ) {
    this.mode = data.mode || 'add';
  }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.initForm();
    this.loadClassrooms();
    this.loadExercises();
    this.ensureSkills();

    if (this.mode === 'edit' && this.data.certification) {
      this.prepareEditData();
    }
  }

  private initForm(): void {
    const now = new Date();
    const defaultStart = addHours(now, 2);
    const defaultStop = addHours(defaultStart, 1);

    this.certificationForm = this.fb.group({
      details: this.fb.group({
        title: ['', Validators.required],
        startDate: [
          defaultStart.toISOString().slice(0, 16),
          [Validators.required]
        ],
        endDate: [
          defaultStop.toISOString().slice(0, 16),
          [Validators.required]
        ],
        description: ['', Validators.required]
      }),
      classroomAssignment: this.fb.group({
        classroom: [null, Validators.required]
      }),
      skillSelection: this.fb.group({
        selectedSkills: [[]]
      }),
      materialSelection: this.fb.group({
        includeExercises: [false],
        selectedExercise: [null]
      }, { validators: [this.validateOptionalExercise] })
    });
  }

  private validateOptionalExercise(control: AbstractControl): ValidationErrors | null {
    const includeExercises = control.get('includeExercises')?.value;
    const chosenExercise = control.get('selectedExercise')?.value;
    if (includeExercises && !chosenExercise) {
      return { exerciseRequired: true };
    }
    return null;
  }

  private dateInFutureValidator(control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) return null;
    const chosenDate = new Date(control.value);
    if (isBefore(chosenDate, new Date())) {
      return { invalidDate: true };
    }
    return null;
  }

  private loadClassrooms(): void {
    this.classroomService.getClassrooms().subscribe({
      next: (rooms) => {
        this.classrooms = (rooms || []).filter((room): room is ClassroomDTO => 'customerId' in room && 'type' in room);
      },
      error: (err) => {
        console.error('Error fetching classrooms:', err);
      }
    });
  }

  private loadExercises(): void {
    this.exerciseService.getExercises().subscribe({
      next: (data) => {
        this.exercises = data || [];
        this.filterExercises();
      },
      error: (err) => {
        console.error('Error fetching exercises:', err);
      }
    });
  }

  private ensureSkills(): void {
    this.skillService.ensureOverallSkill().subscribe({
      next: () => {
        this.loadSkills();
      },
      error: (err) => {
        console.error('Error ensuring overall skill:', err);
        this.loadSkills();
      }
    });
  }

  private loadSkills(): void {
    this.skillService.getSkills().subscribe({
      next: (skillList) => {
        this.skills = skillList.filter(s => s.overall !== 1);
      },
      error: (err) => {
        console.error('Error fetching skills:', err);
      }
    });
  }

  private prepareEditData(): void {
    const existing = this.data.certification;
    if (!existing) return;

    const now = new Date();
    let startDate = existing.plannedStartDate ? new Date(existing.plannedStartDate) : addMinutes(now, 5);
    let stopDate = existing.plannedStopDate ? new Date(existing.plannedStopDate) : addHours(startDate, 2);

    if (startDate <= now) {
      console.error('Cannot edit a certification that has already started or ended.');
      this.dialogRef.close();
      return;
    }
    if (stopDate <= startDate) {
      stopDate = addHours(startDate, 2);
    }

    this.certificationForm.patchValue({
      details: {
        title: existing.name || '',
        startDate: this.formatDateForInput(startDate),
        endDate: this.formatDateForInput(stopDate),
        description: existing.description || ''
      },
      classroomAssignment: {
        classroom: existing.idClassroom || null
      },
      skillSelection: {
        selectedSkills: existing.skillIds || []
      },
      materialSelection: {
        includeExercises: !!existing.idExercise,
        selectedExercise: existing.idExercise || null
      }
    });

    this.filterExercises();
  }

  private formatDateForInput(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm');
  }

  filterExercises(): void {
    const selectedSkillIds: number[] =
      this.certificationForm.get('skillSelection.selectedSkills')?.value || [];
    if (!selectedSkillIds.length) {
      this.filteredExercises = this.exercises;
      return;
    }
    this.filteredExercises = this.exercises.filter(ex =>
      ex.skillIds?.some(skillId => selectedSkillIds.includes(skillId))
    );
  }

  onSkillChange(): void {
    this.filterExercises();
    const currentExercise = this.certificationForm.get('materialSelection.selectedExercise')?.value;
    if (currentExercise && !this.filteredExercises.some(e => e.id === currentExercise)) {
      this.certificationForm.get('materialSelection.selectedExercise')?.setValue(null);
    }
  }

  onIncludeExercisesChange(): void {
    const include = this.certificationForm.get('materialSelection.includeExercises')?.value;
    if (!include) {
      this.certificationForm.get('materialSelection.selectedExercise')?.setValue(null);
    }
  }

  isCurrentStepValid(): boolean {
    const group = this.getCurrentStepFormGroup();
    return group ? group.valid : false;
  }

  getCurrentStepFormGroup(): FormGroup | null {
    if (!this.stepper || this.stepper.selectedIndex == null) return null;
    const stepGroups = ['details','classroomAssignment','skillSelection','materialSelection'];
    return this.certificationForm.get(stepGroups[this.stepper.selectedIndex]) as FormGroup;
  }

  canFinish(): boolean {
    if (!this.certificationForm.valid) return false;
    const sDate = new Date(this.certificationForm.value.details.startDate);
    const eDate = new Date(this.certificationForm.value.details.endDate);
    return (eDate > sDate);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async onSave(): Promise<void> {
    if (!this.canFinish()) {
      console.error("Form is invalid or date range is incorrect.");
      return;
    }
    const fv = this.certificationForm.value;

    const dto: CertificationsDTO = {
      id: this.data.certification?.id || 0,
      idClassroom: fv.classroomAssignment.classroom,
      idExercise: fv.materialSelection.includeExercises
        ? fv.materialSelection.selectedExercise
        : null,
      name: fv.details.title,
      description: fv.details.description,
      plannedStartDate: new Date(fv.details.startDate),
      plannedStopDate: new Date(fv.details.endDate),
      skillIds: fv.skillSelection.selectedSkills || []
    };

    try {
      let result: CertificationsDTO;
      if (this.mode === 'add') {
        result = await firstValueFrom(this.exerciseService.addCertification(dto));
      } else {
        // result = await firstValueFrom(this.exerciseService.editCertification(dto));
        result = dto; // placeholder
      }
      this.snackBar.open('Certification saved successfully.', 'Close', { duration: 3000 });
      this.dialogRef.close(result);
    } catch (err) {
      console.error('Error saving certification:', err);
      this.snackBar.open('Error saving certification.', 'Close', { duration: 3000 });
    }
  }
}
